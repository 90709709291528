// @mui material components
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";

import WalletIcon from "@mui/icons-material/Wallet";
import DashboardIcon from "@mui/icons-material/Dashboard";
import KeyIcon from "@mui/icons-material/Key";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DomainIcon from "@mui/icons-material/Domain";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import { a11yProps } from "components/TabPanel";

function BranchNavBar({ onChange, value }) {
  return (
    <SoftBox sx={{ maxWidth: 800 }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={onChange}
          aria-label="basic tabs example"
          sx={{ backgroundColor: "transparent" }}
          variant="standard"
        >
          <Tab icon={<DashboardIcon />} label="Overview" {...a11yProps(0)} />
          <Tab icon={<WalletIcon />} label="Balances" {...a11yProps(1)} />
          <Tab icon={<KeyIcon />} label="Api Keys" {...a11yProps(2)} />
          <Tab icon={<EngineeringIcon />} label="Psp Mids" {...a11yProps(3)} />
          <Tab icon={<DomainIcon />} label="Domains" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
    </SoftBox>
  );
}

BranchNavBar.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
};

export default BranchNavBar;
