import { getMe, getRoles } from "./cache";
import { Roles } from "constants/roles";
export const isSuperAdmin = () => {
  const roles = getRoles();
  const roleNames = _.map(roles, (role) => role.name);
  return roleNames.includes(Roles.SUPER_ADMIN);
};

export const hasPermission = (permission) => {
  if (!permission || _.isEmpty(permission)) {
    return false;
  }
  if (isSuperAdmin()) {
    return true;
  }
  const me = getMe();
  const permissionIds = Object.keys(_.get(me, "permissions", {}));
  return permissionIds.includes(permission);
};

export const hasPermissions = (permissions) => {
  if (!permissions || permissions.length == 0) {
    return false;
  }
  if (isSuperAdmin()) {
    return true;
  }
  const me = getMe();
  const permissionIds = Object.keys(_.get(me, "permissions", {}));
  const intersectionPerms = _.intersection(permissionIds, permissions);

  return intersectionPerms.length == permissions.length;
};
