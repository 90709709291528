import { format, getTime, formatDistanceToNow } from "date-fns";

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), "dd MMMM yyyy");
}

export function fDateTime(date) {
  return format(new Date(date), "dd MMM yyyy p");
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  try {
    let formattedDate = format(new Date(date), "dd/MM/yyyy HH:mm p").split(" ");
    formattedDate.splice(2, 1);
    return formattedDate.join(" ");
  } catch (error) {
    return "-";
  }
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
