import React from "react";

import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import { SwitchField, TextInputField } from "components/FormField";

const MidForm = () => {
  return (
    <SoftBox p={3}>
      <SoftBox component="form" role="form">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextInputField label="Name" name="name" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputField label="Mid" name="mid" />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextInputField label="Monthly USD Limit" name="monthlyUsdLimit" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputField label="Daily USD Limit" name="dailyUsdLimit" />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextInputField label="Min Success Tx" name="minSuccessTx" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputField label="Hours since first successful tx" name="hsfst" />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3} sm={3}>
            <SwitchField label="Default" name="default" sx={{ ml: "3px" }} />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
};

export default MidForm;
