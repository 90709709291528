//
// VISA = 'Visa',
//   MASTERCARD = 'Mastercard',
//   AMERICAN_EXPRESS = 'American Express',
//   DINERS_CLUB = 'Diners Club',
//   DISCOVER = 'Discover',
//   JCB = 'JCB',
//   UNIONPAY = 'UnionPay',
//   MAESTRO = 'Maestro',
//   MIR = 'Mir',
//   ELO = 'Elo',
//   HIPER = 'Hiper',
//   HIPERCARD = 'Hipercard',
export const cardValues = [
  {
    value: "Visa",
    label: "Visa",
  },
  {
    value: "Mastercard",
    label: "Mastercard",
  },
  {
    value: "American Express",
    label: "American Express",
  },
  {
    value: "Diners Club",
    label: "Diners Club",
  },
  {
    value: "Discover",
    label: "Discover",
  },
  {
    value: "JCB",
    label: "JCB",
  },
  {
    value: "UnionPay",
    label: "UnionPay",
  },
  {
    value: "Maestro",
    label: "Maestro",
  },
  {
    value: "Mir",
    label: "Mir",
  },
  {
    value: "Elo",
    label: "Elo",
  },
  {
    value: "Hiper",
    label: "Hiper",
  },
  {
    value: "Hipercard",
    label: "Hipercard",
  },
];
