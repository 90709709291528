import React, { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import SoftBox from "../../../components/SoftBox";
import SoftButton from "../../../components/SoftButton";
import SoftTypography from "../../../components/SoftTypography";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import FormProvider from "../../../components/FormProviders";

import { DeleteForever } from "@mui/icons-material";
import { useDeleteCardFromBlacklist } from "../hooks";

const DeleteCustomerFromBlacklist = ({ id, updateData }) => {
  const [isOpen, setIsOpen] = useState(false);
  //form provider
  const methods = useForm();
  const { handleSubmit } = methods;
  //hooks
  const { isLoading: isLoadingBlack, mutate: mutateBlacklist } = useDeleteCardFromBlacklist();
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  // create a function that handles the form submission
  const onSubmit = () => {
    mutateBlacklist(
      { id },
      {
        onSuccess: () => {
          updateData();
          handleClose();
        },
      }
    );
  };

  return (
    <>
      <Tooltip title="Delete">
        <IconButton onClick={handleOpen} disabled={isLoadingBlack}>
          <DeleteForever />
        </IconButton>
      </Tooltip>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="xs">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Delete Card from Whitelist
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3}>
              <SoftBox>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <DeleteForeverIcon
                          sx={{
                            width: pxToRem(48),
                            height: pxToRem(48),
                            color: (theme) => theme.palette.error.main,
                          }}
                        />
                        <SoftBox ml={2} lineHeight={0}>
                          <SoftTypography variant="h6" fontWeight="medium">
                            Delete Customer from Blacklist
                          </SoftTypography>
                          <SoftTypography variant="button" color="text" fontWeight="regular">
                            Are you sure you want to delete Customer {id}?
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px" }}
          >
            <SoftButton
              variant="gradient"
              color="error"
              type="submit"
              sx={{ mt: 2 }}
              disabled={isLoadingBlack}
              isLoading={isLoadingBlack}
            >
              Delete
            </SoftButton>
            <SoftButton variant="gradient" sx={{ mt: 2 }} onClick={handleClose}>
              Cancel
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

// Typechecking props for the DeleteMerchantModal
DeleteCustomerFromBlacklist.propTypes = {
  id: PropTypes.string.isRequired,
  cardNumber: PropTypes.string.isRequired,
  tab: PropTypes.number.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default DeleteCustomerFromBlacklist;
