import { Card, Grid } from "@mui/material";
import { CurrencySelect, MultipleSelectField, SelectInputField } from "components/FormField";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import _ from "lodash";
import PropTypes from "prop-types";
import { statusOptions } from "constants/status";
import { generateOptions } from "utils/options";

const Filters = ({ psps }) => {
  const pspOptions = generateOptions(psps);
  return (
    <Card sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <MultipleSelectField
              name="pspIds"
              label="Psps"
              options={pspOptions}
              placeholder="Select a provider"
              isMulti
              multiLine
            />
          </Grid>

          <Grid item xs={6}>
            <SelectInputField label="Status" name="status" options={statusOptions} />
          </Grid>
        </Grid>

        <Grid container display="flex" justifyContent="space-start">
          <SoftButton variant="gradient" color="info" type="submit" sx={{ mt: 2 }}>
            Search
          </SoftButton>
        </Grid>
      </SoftBox>
    </Card>
  );
};

Filters.propTypes = {
  psps: PropTypes.array,
};

export default Filters;
