import { Grid, Divider, Icon, IconButton } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

import { PaymentOptionSelect } from "components/FormField";
import SoftButton from "components/SoftButton";
import { MultipleCountriesCollapse } from "components/FormField";
import { useGetPaymentOptions } from "pages/brand-detail/hooks";
import SoftBox from "../../../../../components/SoftBox";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { a11yProps } from "../../../../../components/TabPanel";
import CurrenciesArray from "./CurrencyArray";

const PaymentMethodForm = ({ excludeOptions, showPaymentOption }) => {
  const { data: paymentOptions } = useGetPaymentOptions();
  const { control, setValue, getValues } = useFormContext();
  const [tab, setTab] = useState(0);

  const {
    fields: countries,
    append: appendCountry,
    remove: removeCountry,
  } = useFieldArray({ control, name: "payinCountries" });
  const {
    fields: payoutCountries,
    append: appendPayoutCountry,
    remove: removePayoutCountry,
  } = useFieldArray({ control, name: "payoutCountries" });

  //tab handle
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  const copyFromPayIn = () => {
    const payin = getValues("payinCountries");
    setValue("payoutCountries", payin);
  };
  return (
    <div>
      {showPaymentOption && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <PaymentOptionSelect
                name="paymentOptionId"
                label="Payment Option"
                paymentOptions={_.filter(paymentOptions, (p) => !excludeOptions?.includes(p.id))}
              />
            </Grid>
          </Grid>
          <Divider />
        </>
      )}

      <Grid>
        <SoftBox sx={{ maxWidth: 400, marginTop: 2, marginBottom: 2 }}>
          <AppBar position="relative">
            <Tabs
              value={tab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              sx={{ backgroundColor: "transparent" }}
              variant="standard"
            >
              <Tab
                icon={<TableRowsIcon />}
                label="Pay-in Countries"
                {...a11yProps(0)}
                sx={{ marginRight: 2 }}
              />
              <Tab icon={<TableRowsIcon />} label="Pay-out Countries" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
        </SoftBox>
      </Grid>
      {tab === 0 &&
        _.map(countries, (field, index) => (
          <Grid key={field.id}>
            <Grid container spacing={2}>
              <Grid item xs={10.5}>
                <MultipleCountriesCollapse
                  name={`payinCountries[${index}].code`}
                  label="Country"
                  required
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => removeCountry(index)} sx={{ mt: 2 }}>
                  <Icon>delete_forever</Icon>
                </IconButton>
              </Grid>
            </Grid>
            <CurrenciesArray nestIndex={index} tab={tab} />
          </Grid>
        ))}
      {tab === 1 && (
        <SoftBox>
          <SoftButton variant="gradient" color="secondary" onClick={copyFromPayIn} sx={{ mb: 2 }}>
            Copy from Pay-In
          </SoftButton>
          {_.map(payoutCountries, (field, index) => (
            <Grid key={field.id}>
              <Grid container spacing={2}>
                <Grid item xs={10.5}>
                  <MultipleCountriesCollapse
                    name={`payoutCountries[${index}].code`}
                    label="Country"
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => removePayoutCountry(index)} sx={{ mt: 2 }}>
                    <Icon>delete_forever</Icon>
                  </IconButton>
                </Grid>
              </Grid>
              <CurrenciesArray nestIndex={index} tab={tab} />
            </Grid>
          ))}
        </SoftBox>
      )}

      <SoftButton
        sx={{ mt: 2 }}
        onClick={() => (tab === 0 ? appendCountry({}) : appendPayoutCountry({}))}
      >
        Add country
      </SoftButton>
    </div>
  );
};
PaymentMethodForm.propTypes = {
  excludeOptions: PropTypes.array,
  showPaymentOption: PropTypes.bool,
};
export default PaymentMethodForm;
