import React from "react";
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import DataTable from "examples/Tables/DataTable";

import { getCountry } from "utils/country";
import SoftAvatar from "components/SoftAvatar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Stepper, Step, StepLabel } from "@mui/material";

import { getSymbolFromCurrency } from "utils/string";
import AddCurrency from "./AddCurrency";
import DeleteCountry from "./DeleteCountry";

const CurrencyArrow = ({ fromCurrency, toCurrency }) => {
  const _toCurrency = _.isString(toCurrency) ? toCurrency : fromCurrency;
  const fromCurrencySymbol = getSymbolFromCurrency(fromCurrency);
  const toCurrencySymbol = getSymbolFromCurrency(_toCurrency);
  return (
    <Stepper activeStep={1} sx={{ m: 0 }} connector={<ArrowForwardIcon sx={{ width: "5rem" }} />}>
      <Step key={0} sx={{ marginBottom: 1 }}>
        <StepLabel icon={<span />}>{`${fromCurrency}(${fromCurrencySymbol})`}</StepLabel>
      </Step>
      <Step key={1} sx={{ marginBottom: 1 }}>
        <StepLabel icon={<span />}>{`${_toCurrency}(${toCurrencySymbol})`}</StepLabel>
      </Step>
    </Stepper>
  );
};
CurrencyArrow.propTypes = {
  fromCurrency: PropTypes.string.isRequired,
  toCurrency: PropTypes.string,
};
const CountriesTable = ({ data, type }) => {
  const getRows = () => {
    return _.map(data, (item) => {
      const country = getCountry(item.code);
      return {
        flag: (
          <SoftBox mr={1}>
            <SoftAvatar src={country.image} size="sm" alt="title" />
          </SoftBox>
        ),
        code: country.code,
        country: country.name,
        currencies: (
          <SoftBox sx={{ p: 0, m: 0 }}>
            {_.map(item?.currencies, (c) => (
              <CurrencyArrow
                key={c.code}
                fromCurrency={c.code}
                toCurrency={_.get(c, "fxCurrency", null)}
              />
            ))}
          </SoftBox>
        ),
        actions: (
          <>
            <AddCurrency country={item} type={type} />
            <DeleteCountry country={country} type={type} />
          </>
        ),
      };
    });
  };

  return (
    <SoftBox m={2} mt={1} sx={{ maxWidth: "1400px", margin: "auto" }}>
      <DataTable
        entriesPerPage={false}
        showTotalEntries={false}
        canSearch={true}
        table={{
          columns: [
            {
              Header: "flag",
              accessor: "flag",
              width: "10px",
            },
            {
              Header: "code",
              accessor: "code",
              width: "10px",
            },
            {
              Header: "country",
              accessor: "country",
            },
            { Header: "Currencies", accessor: "currencies" },
            { Header: "actions", accessor: "actions", width: "10px" },
          ],
          rows: getRows(),
        }}
      />
    </SoftBox>
  );
};

CountriesTable.propTypes = {
  data: PropTypes.array,
  type: PropTypes.number,
};
export default CountriesTable;
