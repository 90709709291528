import { create } from "zustand";

export interface PaymentMethod {
  _id: string | null;
  paymentOptionId: string | null;
  payinCountries: Country[] | null;
  payoutCountries: Country[] | null;
  pspMidId: string | null;
}

interface Country {
  code: string;
  currencies: Currency[];
  fees: Fees;
  _id: string;
}

interface Currency {
  code: string;
  fxCurrency?: string;
}

interface Fees {
  fixedFee: number;
  percentageFee: number;
}

type PaymentMethodStore = {
  paymentMethod: PaymentMethod;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
};

const initialState: PaymentMethodStore = {
  paymentMethod: {
    _id: null,
    paymentOptionId: null,
    payinCountries: [],
    payoutCountries: [],
    pspMidId: null,
  },
  setPaymentMethod: () => null,
};

export const usePaymentMethodStore = create((set) => ({
  ...initialState,
  setPaymentMethod: (values: PaymentMethod) => {
    return set(({ pm }) => ({ paymentMethod: { ...pm, ...values } }));
  },
}));
