import React from "react";

import { Grid, Divider } from "@mui/material";

import SoftTypography from "../../../components/SoftTypography";

export const CardTypeDivider = () => {
  return (
    <Grid item xs={12} sm={12} mt={-2} mb={-2}>
      <Divider
        textAlign="left"
        sx={{
          opacity: 1,
          backgroundImage: "none !important",
          height: "auto",
        }}
      >
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          Card types
        </SoftTypography>
      </Divider>
    </Grid>
  );
};

export default CardTypeDivider;
