import _ from "lodash";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import DataTable from "examples/Tables/DataTable";
import SoftButton from "components/SoftButton";
import StatusChip from "components/StatusChip";
import { useGetMismatchedTransactions } from "./hooks";

const MismatchedTransactions = () => {
  const { data } = useGetMismatchedTransactions();
  const navigate = useNavigate();

  const getRows = () => {
    return _.map(data, (item) => ({
      reason: <Box maxWidth="420px">{item.reason}</Box>,
      externalId: item.externalId,
      status: <StatusChip label={item.status} size="small" />,
      actions: (
        <SoftButton
          variant="gradient"
          color="info"
          size="small"
          onClick={() => navigate(`/mismatched-transactions/${item.id}`)}
        >
          Detail
        </SoftButton>
      ),
    }));
  };

  return (
    <MainLayout>
      <MainNavbar />
      <DataTable
        table={{
          columns: [
            { Header: "reason", accessor: "reason" },
            { Header: "externalId", accessor: "externalId" },
            { Header: "status", accessor: "status" },
            { Header: "actions", accessor: "actions" },
          ],
          rows: getRows(),
        }}
      />
    </MainLayout>
  );
};

export default MismatchedTransactions;
