import React, { useState } from "react";
import { useParams } from "react-router-dom";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import SoftBox from "components/SoftBox";
import { useGetOrganization } from "./hooks";
import OrganizationUsers from "./components/organization-users";
// import { DeleteModal } from "components/Modal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import ListAltIcon from "@mui/icons-material/ListAlt";
import TabPanel, { a11yProps } from "components/TabPanel";
import { PeopleAlt } from "@mui/icons-material";
import OrgDetails from "./components/details";
import OrganizationMerchants from "./components/merchants";

const OrganizationDetails = () => {
  const { id: organizationId } = useParams();
  const [value, setValue] = useState(0);

  // hooks
  const { data } = useGetOrganization(organizationId);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MainLayout>
      <MainNavbar
        customRoutes={[
          { label: "Organizations", route: "organizations" },
          { label: _.get(data, "name", organizationId), route: `organizations/${organizationId}` },
        ]}
      />
      <SoftBox sx={{ maxWidth: 400 }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ backgroundColor: "transparent" }}
            variant="standard"
          >
            <Tab icon={<ContactPageIcon />} label="Details" {...a11yProps(0)} />
            <Tab icon={<ListAltIcon />} label="Merchants" {...a11yProps(1)} />
            <Tab icon={<PeopleAlt />} label="Users" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
      </SoftBox>
      <TabPanel value={value} index={0}>
        <OrgDetails org={data} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OrganizationMerchants />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OrganizationUsers />
      </TabPanel>
    </MainLayout>
  );
};

export default OrganizationDetails;
