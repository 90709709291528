import { Dialog, DialogContent, DialogTitle, Stack, Grid, Icon, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { useForm, useFormContext, useFieldArray } from "react-hook-form";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { useDisclosure } from "hooks/useDisclosure";

import FormProvider from "components/FormProviders";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { useUpdatePaymentMethod } from "pages/brand-detail/hooks";
import { CurrencySelect } from "components/FormField";
import SortedCountrySelect from "../../../../../components/SortedCountrySelect";

import { usePaymentMethodStore } from "stores/paymentMethod";
import { PaymentMethodTypeEnum, PaymentMethodMapping } from "constants/tabs";
import { sanitizeUpdateCurrenciesRequest } from "pages/brand-detail/schemas/paymentMethod";

const CurrenciesArray = () => {
  const { control } = useFormContext();
  const {
    fields: currencies,
    remove,
    append,
  } = useFieldArray({
    control,
    name: `currencies`,
  });

  return (
    <div>
      {_.map(currencies, (field, index) => (
        <Grid
          container
          key={field.id}
          spacing={2}
          display="flex"
          alignItems="center"
          justifyContent={"start"}
        >
          <Grid sm={1.75} xs={false}></Grid>
          <Grid container spacing={2} xs={10} sm={9} mt={2}>
            <Grid sm={5.5}>
              <CurrencySelect
                name={`currencies[${index}].code`}
                label={`Currency ${index + 1}`}
                required
              />
            </Grid>
            <Grid sm={5.5} ml={2}>
              <CurrencySelect
                name={`currencies[${index}].fxCurrency`}
                label={`fxCurrency ${index + 1}`}
              />
            </Grid>
          </Grid>
          <Grid xs={0.5} mt={2} sm={1}>
            <IconButton onClick={() => remove(index)} sx={{ mt: 2 }}>
              <Icon>delete_forever</Icon>
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid container spacing={2}>
        <Grid xs={2}></Grid>
        <Grid xs={8.5}>
          <SoftBox display="flex" justifyContent="space-between">
            <SoftBox></SoftBox>
            <SoftBox>
              <SoftButton sx={{ mt: 2, mb: 2 }} onClick={() => append({})}>
                Add currency
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
    </div>
  );
};

const AddCurrency = ({ type, country }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { paymentMethod } = usePaymentMethodStore();
  const { id: merchantId, brandId } = useParams();

  // // query
  const { mutate, isLoading } = useUpdatePaymentMethod({
    merchantId,
    brandId,
    pspMidId: paymentMethod.pspMidId,
  });

  // // hook form
  const methods = useForm();
  const { setValue, handleSubmit, reset } = methods;

  // // function
  const handleSubmitForm = (values) => {
    let payload = paymentMethod;
    const typeStr = _.get(PaymentMethodMapping, Object.keys(PaymentMethodTypeEnum)[type]);
    let countries = _.get(payload, typeStr, []);
    const indexFromId = _.findIndex(countries, { _id: country._id });
    countries[indexFromId] = values;
    _.set(payload, typeStr, countries);

    mutate(
      sanitizeUpdateCurrenciesRequest({
        merchantId,
        brandId,
        pspMidId: paymentMethod.pspMidId,
        methodId: paymentMethod._id,
        ...payload,
      }),
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    reset(country);
  }, [country]);

  return (
    <>
      <SoftBox>
        <SoftButton variant="text" color="dark" onClick={onOpen}>
          <Icon>edit</Icon>&nbsp;update
        </SoftButton>
      </SoftBox>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        sx={{ overflow: "visible" }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Add Currency</DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
            <SoftBox py={2}>
              <Grid>
                <Grid container spacing={2}>
                  <Grid xs={12} mt={2} ml={2}>
                    <SortedCountrySelect
                      name={`code`}
                      label="Country"
                      setValue={setValue}
                      required
                    />
                  </Grid>
                </Grid>
                <CurrenciesArray />
              </Grid>
              <Stack direction="row" spacing={1} justifyContent="center" mt={4}>
                <SoftButton onClick={handleClose}>Cancel</SoftButton>
                <SoftButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Update
                </SoftButton>
              </Stack>
            </SoftBox>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

AddCurrency.propTypes = {
  type: PropTypes.number,
  country: PropTypes.object,
};

export default AddCurrency;
