import PropTypes from "prop-types";
import { Grid, IconButton, Icon, Divider } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";

import SoftButton from "components/SoftButton";

import FieldRender from "./FieldRender";
import ObjectRender from "./ObjectRender";

const FieldArrayRender = ({ arrayFieldModels, arrayFieldName }) => {
  if (_.isEmpty(arrayFieldModels)) return null;
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: arrayFieldName });
  return (
    <>
      <Grid item xs={12} sm={12} mt={-2} mb={-2}>
        <Divider
          textAlign="left"
          sx={{
            opacity: 1,
            backgroundImage: "none !important",
            height: "auto",
          }}
        >
          {_.startCase(arrayFieldName)}
        </Divider>
      </Grid>
      <Grid item xs={1} sm={1} mt={-1}></Grid>
      <Grid item xs={11} sm={11} mt={-1}>
        {_.map(fields, (field, index) => (
          <Grid container spacing={3}>
            <Grid item xs={11}>
              <Grid container columnSpacing={3}>
                {_.map(arrayFieldModels, (item, key) => {
                  if (item.type === "object")
                    return (
                      <ObjectRender
                        model={item}
                        objectName={`${arrayFieldName}[${index}].${item.name}`}
                        key={item.name}
                        title={item.name}
                      />
                    );
                  return (
                    <FieldRender
                      item={item}
                      key={key}
                      label={key}
                      name={`${arrayFieldName}[${index}].${key}`}
                    />
                  );
                })}
              </Grid>
            </Grid>

            <Grid item xs={1} sm={1}>
              <IconButton onClick={() => remove(index)} sx={{ mt: 2 }}>
                <Icon>delete_forever</Icon>
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={12} mt={-3}>
              <Divider />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} sm={12}>
        <SoftButton sx={{ mt: 2 }} onClick={() => append("")}>
          Add {_.startCase(arrayFieldName)}
        </SoftButton>
      </Grid>
    </>
  );
};
FieldArrayRender.propTypes = {
  arrayFieldModels: PropTypes.object,
  arrayFieldName: PropTypes.string,
};
export default FieldArrayRender;
