import { useState } from "react";
import PropTypes from "prop-types";

import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import TreeView from "./TreeView";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { a11yProps } from "components/TabPanel";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ArticleIcon from "@mui/icons-material/Article";
import JsonView from "./JsonView";
import { toastSuccess } from "utils/toast";

const TreeViewModal = ({ data, buttonText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tab, setTab] = useState(0);

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  const handleCopy = () => {
    const strJson = JSON.stringify(data, null, 2);
    navigator.clipboard.writeText(strJson);
    toastSuccess({ description: "Copied to Clipboard!" });
  };

  return (
    <>
      <a onClick={handleOpen} href="#">
        {buttonText}
      </a>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle
          sx={{
            background: (theme) => theme.palette.grey[500],
            fontWeight: (theme) => theme.typography.h6,
            color: (theme) => theme.palette.common.white,
            px: 4,
            py: 1,
          }}
        >
          Data Tree View
        </DialogTitle>
        <DialogContent>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            aria-label="data views"
            sx={{ backgroundColor: "transparent" }}
            variant="standard"
          >
            <Tab
              icon={<AccountTreeIcon />}
              label="Tree View"
              {...a11yProps(0)}
              sx={{ marginRight: 2 }}
            />
            <Tab icon={<ArticleIcon />} label="Json View" {...a11yProps(1)} />
          </Tabs>
          <SoftBox p={3} pt={0} mt={3}>
            {tab === 0 && <TreeView data={data} allData={data} name="Payload" />}
            {tab === 1 && <JsonView data={data} />}
          </SoftBox>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <SoftButton variant="gradient" color="success" sx={{ mt: 2 }} onClick={handleCopy}>
            Copy
          </SoftButton>
          <SoftButton variant="gradient" sx={{ mt: 2 }} onClick={handleClose}>
            Quit
          </SoftButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

TreeViewModal.propTypes = {
  data: PropTypes.object,
  buttonText: PropTypes.string,
};

export default TreeViewModal;
