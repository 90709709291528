import * as Yup from "yup";

export const defaultValues = { enableCardScoreRouting: null };
export const schema = Yup.object().shape({
  enableCardScoreRouting: Yup.boolean().required("Enabled is required"),
});

export const sanitizeRequest = ({
  enableBinCountryCheck,
  enableCardScoreRouting,
  enableCustomerCheck,
  minCustomerRisk,
}) => {
  return {
    enableBinCountryCheck: enableBinCountryCheck,
    enableCardScoreRouting: enableCardScoreRouting,
    enableCustomerCheck: enableCustomerCheck,
    minCustomerRisk: parseFloat(minCustomerRisk),
  };
};
