import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import SoftBox from "components/SoftBox";
import { useGetTemplate, useUpdateTemplate, useDeleteTemplate } from "./hooks";
import FormProvider from "components/FormProviders";
import { Card, DialogActions } from "@mui/material";
import SoftButton from "components/SoftButton";
import { useForm } from "react-hook-form";
import { schema, defaultValues, sanitizeRequest } from "pages/new-reconciliation-template/schemas";
import { yupResolver } from "@hookform/resolvers/yup";
import ReconciliationForm from "pages/new-reconciliation/components/ReconciliationForm";
import { useGetPsps } from "pages/psp-management/hooks";
import { DeleteModal } from "components/Modal";
import { useNavigate } from "react-router-dom";

const ReconciliationTemplateDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [sheetOptions, setSheetOptions] = useState([]);

  // hooks
  const { data } = useGetTemplate(id);
  const { mutate: updateTemplate, isLoading } = useUpdateTemplate(id);
  const { data: psps } = useGetPsps();
  const { mutateAsync: deleteTemplate, isLoading: isDeleting } = useDeleteTemplate(id);
  // forms
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = methods;

  // functions
  const onSubmit = (values) => {
    updateTemplate({ id, ...sanitizeRequest(values) });
  };

  useEffect(() => {
    if (data) {
      const sheets = _.get(data, "selectedSheets", []);
      const _sheetOptions = _.uniqBy(sheets, "name").map((sheet) => ({
        label: sheet.name,
        value: sheet.name,
      }));
      setSheetOptions(_sheetOptions);
      reset(data);
    }
  }, [data]);

  return (
    <MainLayout>
      <MainNavbar
        customRoutes={[
          { label: "Templates", route: "reconciliation-templates" },
          { label: _.get(data, "name", id), route: `reconciliation-templates/${id}` },
        ]}
      />
      <Card>
        <SoftBox p={2}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <ReconciliationForm psps={psps} sheetOptions={sheetOptions} />
            <DialogActions style={{ justifyContent: "flex-start" }}>
              <SoftButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 2 }}
                disabled={isLoading}
                isLoading={isLoading}
              >
                Update
              </SoftButton>
              <DeleteModal
                type="button"
                title="Delete Template"
                message={
                  <>
                    Are you sure you want to delete template <b>{_.get(data, "name")}</b>? you will
                    not be able to recover this template?
                  </>
                }
                isLoading={isDeleting}
                onDelete={async () => {
                  await deleteTemplate({ id });
                }}
                onClose={() => {
                  navigate("/reconciliation-templates");
                }}
              />
            </DialogActions>
          </FormProvider>
        </SoftBox>
      </Card>
    </MainLayout>
  );
};

export default ReconciliationTemplateDetail;
