import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema, sanitizeRequest } from "../users/schemas/role";
import { useDisclosure } from "hooks/useDisclosure";
import { useUpdateRole } from "../users/hooks";
import RoleForm from "./RoleForm";
import { ModalWrapper } from "components/Modal";

const EditRoleModal = ({ role }) => {
  const modalState = useDisclosure();
  const { onClose } = modalState;
  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit, reset } = methods;

  //hooks
  const { isLoading, mutate } = useUpdateRole();

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    mutate(
      { _id: role._id, ...sanitizeRequest(values) },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  useEffect(() => {
    if (role) {
      const permissions = _.map(role.permissions, (p) => p._id);
      reset({ ...role, permissions });
    }
  }, [role]);

  return (
    <ModalWrapper
      title="Update Role"
      modalState={modalState}
      isLoading={isLoading}
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      minHeight="315px"
      maxWidth="md"
    >
      <RoleForm />
    </ModalWrapper>
  );
};
EditRoleModal.propTypes = {
  role: PropTypes.object,
};
export default EditRoleModal;
