import React, { useEffect, useMemo, useState } from "react";

import { Grid } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";

import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";

import { MultipleSelectField, TextInputField } from "components/FormField";
import PropTypes from "prop-types";
import { generateOptions } from "utils/options";
import Sheet from "./Sheet";
import CollapseWrapper from "components/FormField/CollapseWrapper";
import _ from "lodash";
import Dropzone from "react-dropzone";
import SoftDropzoneRoot from "components/SoftDropzone/SoftDropzoneRoot";
import SoftTypography from "components/SoftTypography";
import Icon from "@mui/material/Icon";
import { shortText } from "utils/string";
import { useGetSheets } from "../hooks";
import { CircularProgress } from "@mui/material";

const ReconciliationForm = ({ psps, sheetOptions: initialSheetOptions }) => {
  const [sheetOptions, setSheetOptions] = useState(initialSheetOptions);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const { mutate: getSheets, isLoading: isGettingSheets } = useGetSheets();

  const { control } = useFormContext();
  const {
    fields: sheets,
    append: appendSheet,
    remove: removeSheet,
  } = useFieldArray({
    control,
    name: "selectedSheets",
  });

  // options
  const pspOptions = useMemo(() => {
    if (psps) {
      return generateOptions(psps, false);
    } else {
      return [];
    }
  }, [psps]);

  //hooks

  const onFileUpload = (files) => {
    if (files && files.length > 0) {
      setUploadedFiles(files[0]);
    }
  };

  useEffect(() => {
    if (initialSheetOptions) {
      setSheetOptions(initialSheetOptions);
    }
  }, [initialSheetOptions]);

  useEffect(() => {
    if (uploadedFiles) {
      const formData = new FormData();
      formData.append("file", uploadedFiles);
      getSheets(
        { formData },
        {
          onSuccess: (data) => {
            setSheetOptions(
              _.map(data.sheets, (sheet) => ({
                label: sheet,
                value: sheet,
              }))
            );
          },
        }
      );
    }
  }, [uploadedFiles]);

  return (
    <SoftBox p={3}>
      <Grid container spacing={2} rowSpacing={1}>
        <Grid item xs={6}>
          <TextInputField name="name" label="Name" />
        </Grid>
        <Grid item xs={6}>
          <MultipleSelectField
            menuPosition={"fixed"}
            name="pspIds"
            label="Psps"
            options={pspOptions}
          />
        </Grid>
      </Grid>
      {_.map(sheets, (field, index) => (
        <CollapseWrapper title={`Sheet ${index + 1}`} key={field.id} openDefault={true}>
          <SoftBox>
            <Sheet nestedIndex={index} removeSheet={removeSheet} sheetOptions={sheetOptions} />
          </SoftBox>
        </CollapseWrapper>
      ))}
      <SoftButton
        sx={{ mt: 2 }}
        onClick={() => appendSheet({ type: "PAYIN", headerLine: 1, startFrom: 2 })}
      >
        Add Sheet
      </SoftButton>
      <SoftBox mt={2}>
        <Dropzone onDrop={(acceptedFiles) => onFileUpload(acceptedFiles)} maxFiles={1}>
          {({ getRootProps, getInputProps }) => (
            <SoftDropzoneRoot
              {...getRootProps()}
              sx={{ height: "5rem", cursor: "pointer" }}
              display="flex"
              flexDirection={"column"}
              justifyContent={"center"}
            >
              {uploadedFiles && (
                <SoftTypography variant="caption2">
                  {shortText(uploadedFiles.name, 30)}
                </SoftTypography>
              )}
              {!uploadedFiles && (
                <>
                  <Icon fontSize="large">upload</Icon>
                  <SoftTypography variant="caption">Drag document file here</SoftTypography>
                </>
              )}

              <SoftBox component="input" name="file" type="file" multiple {...getInputProps()} />
            </SoftDropzoneRoot>
          )}
        </Dropzone>
      </SoftBox>
      {isGettingSheets && (
        <SoftBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#000",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            opacity: 0.9,
            flexDirection: "column",
            gap: 2,
            zIndex: 1,
          }}
        >
          <SoftTypography variant="h6" sx={{ color: "#ffffff" }}>
            Loading Sheets...
          </SoftTypography>
          <CircularProgress sx={{ color: "#ffffff" }} />
        </SoftBox>
      )}
    </SoftBox>
  );
};
ReconciliationForm.propTypes = {
  psps: PropTypes.array,
  sheetOptions: PropTypes.array,
};
export default ReconciliationForm;
