import _ from "lodash";
import SimpleTimeLineItem from "./SimpleTimeLineItem";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { countriesFlags } from "constants/countriesFlags";
import { currencies } from "constants/currencies";
import PropTypes from "prop-types";
import TreeViewModal from "./TreeViewModal";

export const transactionSingleTimeLine = (transaction, isLast) => {
  if (!transaction) return {};

  return {
    color: !isLast ? "secondary" : "success",
    icon: timeLineIcon(transaction.slug),
    title: transaction.action,
    dateTime: _.get(transaction, "timestamp", "N/A"),
    description: transactionStep(transaction),
    badges: timelineBadges(transaction, isLast),
    lastItem: isLast,
  };
};

const timelineBadges = (transaction, isLast) => {
  if (transaction.slug == "payment.submit.response") {
    const status = _.get(transaction, "payload.result.status");
    if (status) return [status];
    const adapterStatus = _.get(transaction, "payload.adapterResponse.result.status");
    return [adapterStatus];
  }
  if (!isLast) {
    return ["DONE"];
  }
};

const timeLineIcon = (slug) => {
  switch (slug) {
    case "payment.init.request":
      return "flag";
    case "payment.init.response":
      return "arrow_back";
    case "payment.suboption.request":
      return "list";
    case "payment.suboption.response":
      return "arrow_back";
    case "payment.submit.request":
      return "payment";
    case "payment.submit.response":
      return "check";
    case "notification.outgoing":
      return "notifications";
    case "notification.incoming":
      return "notifications";
    default:
      return "question_mark";
  }
};

const transactionStep = (transaction) => {
  switch (transaction?.slug) {
    case "payment.init.request":
      return <InitRequest payload={transaction.payload} log={transaction.log} />;
    case "payment.init.response":
      return (
        <InitResponse
          sessionId={transaction.payload.sessionId}
          paymentOptions={transaction.payload.paymentOptions}
          log={transaction.log}
        />
      );
    case "payment.suboption.request":
      return <SubOptionRequest payload={transaction.payload} log={transaction.log} />;
    case "payment.suboption.response":
      return <SubOptionResponse payload={transaction.payload} log={transaction.log} />;
    case "payment.submit.request":
      return <SubmitPayment payload={transaction.payload} log={transaction.log} />;
    case "payment.submit.response":
      return <PaymentResponse payload={transaction.payload} log={transaction.log} />;
    case "notification.outgoing":
      return <DefaultTimeLineItem payload={transaction.payload} log={transaction.log} />;
    case "notification.incoming":
      return <DefaultTimeLineItem payload={transaction.payload} log={transaction.log} />;
    case "fx.request":
      return <DefaultTimeLineItem payload={transaction.payload} log={transaction.log} />;
    case "fx.response":
      return <DefaultTimeLineItem payload={transaction.payload} log={transaction.log} />;
    default:
      return <DefaultTimeLineItem payload={transaction.payload} log={transaction.log} />;
  }
};

const LogLink = ({ url }) => {
  if (!url) return null;
  return (
    <SoftBox display="flex">
      <SoftBox display="flex" flexDirection="column" justifyContent="center">
        <SoftTypography variant="caption" color="text">
          Log :&nbsp;&nbsp;
        </SoftTypography>
      </SoftBox>
      <SoftBox display="flex" flexDirection="column" justifyContent="center">
        <SoftTypography
          component="div"
          textTransform="capitalize"
          variant="caption"
          fontWeight="medium"
        >
          &nbsp;&nbsp;
          <a href={`${url}`} target="_blank" rel="noreferrer">
            Visit Kibana
          </a>
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};
LogLink.propTypes = {
  url: PropTypes.string,
};
const DefaultTimeLineItem = ({ payload, log }) => {
  return (
    <>
      {log && <LogLink url={log} />}

      {payload && (
        <SoftBox display="flex">
          <SoftBox display="flex" flexDirection="column" justifyContent="center">
            <SoftTypography variant="caption" color="text">
              Payload :&nbsp;&nbsp;
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" flexDirection="column" justifyContent="center">
            <SoftTypography
              component="div"
              textTransform="capitalize"
              variant="caption"
              fontWeight="medium"
            >
              &nbsp;&nbsp;
              <TreeViewModal data={payload} buttonText={"View Payload"} />
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      )}
    </>
  );
};

DefaultTimeLineItem.propTypes = {
  payload: PropTypes.object,
  log: PropTypes.string,
};

const PaymentResponse = ({ payload, log }) => {
  return (
    <>
      <LogLink url={log} />
      <SoftBox display="flex">
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography variant="caption" color="text">
            Payment Response:&nbsp;&nbsp;
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography
            component="div"
            textTransform="capitalize"
            variant="caption"
            fontWeight="medium"
          >
            &nbsp;&nbsp;
            <TreeViewModal data={payload} buttonText={"View Response"} />
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </>
  );
};
PaymentResponse.propTypes = {
  payload: PropTypes.object,
  log: PropTypes.string,
};

const SubmitPayment = ({ payload, log }) => {
  return (
    <>
      <LogLink url={log} />
      <SoftBox display="flex">
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography variant="caption" color="text">
            Payment Payload:&nbsp;&nbsp;
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography
            component="div"
            textTransform="capitalize"
            variant="caption"
            fontWeight="medium"
          >
            &nbsp;&nbsp;
            <TreeViewModal data={payload} buttonText={"View Payload"} />
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </>
  );
};
SubmitPayment.propTypes = {
  log: PropTypes.string,
  payload: PropTypes.object,
};

const SubOptionResponse = ({ payload, log }) => {
  return (
    <>
      <LogLink url={log} />
      <SoftBox display="flex">
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography variant="caption" color="text">
            SubOption Response:&nbsp;&nbsp;
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography
            component="div"
            textTransform="capitalize"
            variant="caption"
            fontWeight="medium"
          >
            &nbsp;&nbsp;
            <TreeViewModal data={payload} buttonText={"View Response"} />
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </>
  );
};
SubOptionResponse.propTypes = {
  payload: PropTypes.array,
  log: PropTypes.string,
};

const SubOptionRequest = ({ payload, log }) => {
  return (
    <>
      <LogLink url={log} />
      <SoftBox display="flex">
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography variant="caption" color="text">
            SubOption Payload:&nbsp;&nbsp;
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography
            component="div"
            textTransform="capitalize"
            variant="caption"
            fontWeight="medium"
          >
            &nbsp;&nbsp;
            <TreeViewModal data={payload} buttonText={"View Payload"} />
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </>
  );
};
SubOptionRequest.propTypes = {
  payload: PropTypes.array,
  log: PropTypes.string,
};

const InitResponse = ({ sessionId, paymentOptions, log }) => {
  return (
    <>
      <SoftBox display="flex">
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography variant="caption" color="text">
            Session ID:&nbsp;&nbsp;
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography
            component="div"
            textTransform="capitalize"
            variant="caption"
            fontWeight="medium"
          >
            &nbsp;&nbsp;{sessionId}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <LogLink url={log} />
      <SoftBox display="flex">
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography variant="caption" color="text">
            Payment Options:&nbsp;&nbsp;
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography
            component="div"
            textTransform="capitalize"
            variant="caption"
            fontWeight="medium"
          >
            &nbsp;&nbsp;
            <TreeViewModal data={paymentOptions} buttonText={"View Payment Options"} />
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </>
  );
};

InitResponse.propTypes = {
  sessionId: PropTypes.string,
  paymentOptions: PropTypes.array,
  log: PropTypes.string,
};

const InitRequest = ({ payload, log }) => {
  const countryObj = _.find(countriesFlags, { code: payload.country });
  const symbol = _.find(currencies, {
    code: payload.currency,
  })?.symbol;
  return (
    <>
      <SoftBox display="flex">
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography variant="caption" color="text">
            Country:&nbsp;&nbsp;
          </SoftTypography>
        </SoftBox>

        <SoftBox>
          <SoftAvatar src={countryObj?.image} size="sm" alt="title" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography
            component="div"
            textTransform="capitalize"
            variant="caption"
            fontWeight="medium"
          >
            &nbsp;&nbsp;{countryObj?.name}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox display="flex">
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography variant="caption" color="text">
            Currency:&nbsp;&nbsp;
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography
            component="div"
            textTransform="capitalize"
            variant="caption"
            fontWeight="medium"
          >
            &nbsp;&nbsp;{payload.currency}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox sx={{ marginTop: 1 }}>
        <SimpleTimeLineItem label="Amount" value={`${symbol} ${payload.amount}`} />
      </SoftBox>
      <LogLink url={log} />
      <SoftBox display="flex">
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography variant="caption" color="text">
            Payload:&nbsp;&nbsp;
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <SoftTypography
            component="div"
            textTransform="capitalize"
            variant="caption"
            fontWeight="medium"
          >
            &nbsp;&nbsp;
            <TreeViewModal data={payload} buttonText={"View Payload"} />
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </>
  );
};

InitRequest.propTypes = {
  payload: PropTypes.object,
  log: PropTypes.string,
};
