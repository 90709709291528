import * as Yup from "yup";

export const defaultValues = {
  action: "",
  reason: "",
  bins: "",
};
export const schema = Yup.object().shape({
  action: Yup.string().required("Action is required"),
});

export const sanitizeRequest = ({ action, reason, bins, pspId, pspMidId }) => {
  return _.pickBy(
    {
      action,
      reason,
      bins,
      pspId,
      pspMidId,
    },
    _.identity
  );
};
