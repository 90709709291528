import * as Yup from "yup";
import ipRegex from "ip-regex";

export const defaultValues = {
  name: null,
  notificationUrl: null,
  enablePoweredBy: false,
  headerVisibility: {
    enableTitle: true,
    enableAmount: true,
  },
  enforceBodySignature: false,
  ipWhitelists: [],
  enableIpBlacklist: true,
  enableCustomerCheck: false,
  enableBinCountryCheck: false,
};

export const schema = Yup.object().shape({
  notificationUrl: Yup.string()
    .required("Notification Url is required")
    .max(2000, "Notification Url is too long, should be less than 2000 characters")
    .nullable(),
  name: Yup.string()
    .required("Brand name is required")
    .max(100, "Brand name is too long, should be less than 100 characters")
    .nullable(),
  ipWhitelists: Yup.array().of(
    Yup.object().shape({
      startingIp: Yup.string().test("is-IP", "Invalid IP address", (value) => {
        return ipRegex({ exact: true }).test(value);
      }),
      endingIp: Yup.string().test("is-IP", "Invalid IP address", (value) => {
        return ipRegex({ exact: true }).test(value);
      }),
    })
  ),
});
