import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import _ from "lodash";
import FormProvider from "components/FormProviders";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import DataTable from "examples/Tables/DataTable";
import StatusChip from "components/StatusChip";
import SoftBox from "components/SoftBox";
import PropTypes from "prop-types";

import TransactionFilters from "./TransactionFilters";

import {
  useGetCsv,
  useGetFilterApprovalTransactions,
  useGetFilterSummaryTransactions,
  useGetFilterTransactions,
} from "../useTransactions";
import { useForm } from "react-hook-form";
import { set, get, decodePayload } from "utils/localStorage";

import TabPanel from "components/TabPanel";
import { Switch } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Chip, Stack } from "@mui/material";
import CurrencyReport from "./CurrencyReport";
import { GroupedBarChart } from "../../../examples/Charts/BarCharts/GroupedBarChart";
import { saveDataToFile } from "utils/file";
import TransactionsTabs from "./TransactionsTabs";
import { sanitizeSummaryApproval, sanitizeSummaryReport, sanitizeTransaction } from "../schemas";
import { updateSelectedFilters } from "utils/filters";

// hooks
import { useGetMerchants } from "pages/merchant-management/hooks";
import { useGetPaymentOptions } from "pages/brand-detail/hooks";
import { useGetPsps } from "pages/psp-management/hooks";
import { useGetBrands } from "pages/merchant-management-detail/hooks";
import { useDataTable } from "hooks/useDataTable";
import { useGetInternalErrorCodes } from "pages/error-mapping/hooks";

const LC_PAYLOAD = "transactionReportingPayload";

export const defaultTableColumns = [
  { Header: "id", accessor: "id", hide: true },
  { Header: "externalId", accessor: "externalId", hide: true },
  { Header: "merchant", accessor: "merchant" },
  { Header: "brand", accessor: "brand" },
  { Header: "domain", accessor: "domain" },
  { Header: "country", accessor: "country" },
  { Header: "currency", accessor: "currency" },
  { Header: "amount", accessor: "amount" },
  { Header: "usd Amount", accessor: "usdAmount" },
  { Header: "psp", accessor: "psp" },
  { Header: "mid", accessor: "mid" },
  { Header: "option", accessor: "option" },
  { Header: "Customer Name", accessor: "customerName", hide: true },
  { Header: "Customer Email", accessor: "customerEmail", hide: true },
  { Header: "status", accessor: "status" },
  { Header: "created time", accessor: "createdAt" },
  { Header: "error reason", accessor: "errorReason", hide: true },
];

// create a function that returns the rows for the table
const defaultGetRowsFn = (data) => {
  return _.map(_.get(data, "docs", []), (item) => {
    return {
      country: item.country,
      currency: item.currency,
      merchant: item.merchant,
      brand: item.brand,
      amount: item.paymentAmount ? item.paymentAmount : item.amount,
      usdAmount: item.usdAmount,
      mid: item.mid,
      psp: item.psp,
      id: item.id,
      externalId: item.externalId,
      errorReason: item.errorReason,
      option: item.paymentOption,
      status: <StatusChip label={item.status} size="small" tooltip={item.errorReason} />,
      createdAt: item.createdAt,
      domain: item.merchantDomain ?? "N/A",
      customerName: item.customer?.name,
      customerEmail: item.customer?.email,
    };
  });
};

const TransactionReportingPage = ({
  showTabs = true,
  tableColumns = defaultTableColumns,
  getRowsFn = defaultGetRowsFn,
  Filters = TransactionFilters,
  filterTransactionQueryFn = useGetFilterTransactions,
  original = "transactions",
}) => {
  // init values
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(25);
  const [tab, setTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [checked, setChecked] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [firstRender, setFirstRender] = useState(true);
  const [fetchTime, setFetchTime] = useState({
    submit: null,
    transaction: null,
    sum: null,
    approval: null,
  });

  const location = useLocation();
  const transactionReportingPayload = decodePayload(get(LC_PAYLOAD) || {});
  const defaultValues = transactionReportingPayload;
  const { onSortChange, sortDirection, setSortDirection } = useDataTable();

  // forms
  const methods = useForm({ defaultValues });
  const { handleSubmit, setValue, getValues, watch, reset } = methods;
  const watchMerchant = watch("merchantId", null);

  // hooks
  const { data: merchants } = useGetMerchants();
  const { data: paymentOptions } = useGetPaymentOptions();
  const { data: pspsData } = useGetPsps();
  const { data: brands } = useGetBrands(watchMerchant);
  const { data: errorCodes } = useGetInternalErrorCodes();
  const sum = useGetFilterSummaryTransactions();

  // query param
  const queryParams = new URLSearchParams(location.search);
  const txR = queryParams.get("txR");
  const pageParameter = queryParams.get("page");
  const qEmail = queryParams.get("email");
  const qPan = queryParams.get("pan");
  const qMerchantId = queryParams.get("merchantId");

  // Destructuring the object of all selected filters to obtain only the necessary
  const filters = _.omit(selectedFilters, ["page", "merchantId", "limit"]);
  const { mutate, isLoading, data } = filterTransactionQueryFn();
  const { data: approvalData, mutate: approvalMutate } = useGetFilterApprovalTransactions();
  const { mutate: downloadCsv } = useGetCsv();

  useEffect(() => {
    if (txR) {
      handlePageChange(pageParameter - 1);
      const payload = transactionReportingPayload;
      if (transactionReportingPayload.sortDirection)
        setSortDirection(transactionReportingPayload.sortDirection);
      sum.mutate(sanitizeSummaryReport(payload));
      approvalMutate(sanitizeSummaryApproval(payload));
    }
    if (qEmail || qPan) {
      const payload = { email: qEmail, merchantId: qMerchantId, pan: qPan };
      reset({});
      setValue("merchantId", qMerchantId);
      setValue("email", qEmail);
      setValue("pan", qPan);
      set(LC_PAYLOAD, payload);
      mutate(sanitizeTransaction(payload));
    }
    const fieldsData = getValues();
    updateFilters(fieldsData);
  }, []);

  const updateFilters = (data) => {
    updateSelectedFilters(data, setSelectedFilters, {
      merchants,
      paymentOptions,
      brands,
      psps: pspsData,
    });
  };

  // create a function that handles the cleaning the AutoFetch
  const cleanAutoFetch = (page) => {
    autoFetchRef.current = true;
    setChecked(false);
    setCurrentPage(page);
  };

  const removeQueryParam = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("txR");
    queryParams.delete("page");
    navigate({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  };

  // create a function that handles the form submission
  const onSubmit = (values) => {
    if (txR) removeQueryParam();
    cleanAutoFetch(1);
    const payload = sanitizeTransaction({
      pageSize,
      sortDirection,
      ...values,
    });

    const now = new Date();
    switch (tab) {
      case 0:
        mutate(sanitizeSummaryApproval(payload));
        setFetchTime({ ...fetchTime, submit: now, transaction: now });
        break;
      case 1:
        sum.mutate(sanitizeSummaryReport(payload));
        setFetchTime({ ...fetchTime, submit: now, sum: now });
        break;
      case 2:
        approvalMutate(
          sanitizeSummaryApproval({
            ...payload,
            effectiveAr: get(LC_PAYLOAD).effectiveAr,
            excludeAbandoned: get(LC_PAYLOAD).excludeAbandoned,
          })
        );
        setFetchTime({ ...fetchTime, submit: now, approval: now });
        break;
      default:
        break;
    }
    set(LC_PAYLOAD, payload);
    updateFilters(values);
  };

  // create a function that handles the request to receive new transactions
  const onAutoFetch = (values) => {
    const payload = {
      ...values,
      limit: pageSize,
      page: currentPage,
      pageSize,
      sortDirection,
    };
    set(LC_PAYLOAD, payload);
    const now = new Date();
    switch (tab) {
      case 0:
        mutate(sanitizeSummaryApproval(payload));
        setFetchTime({ ...fetchTime, submit: now, transaction: now });
        break;
      case 1:
        sum.mutate(sanitizeSummaryReport(payload));
        setFetchTime({ ...fetchTime, submit: now, sum: now });
        break;
      case 2:
        approvalMutate(sanitizeSummaryApproval(payload));
        setFetchTime({ ...fetchTime, submit: now, approval: now });
        break;
      default:
        break;
    }
  };

  const autoFetchRef = useRef(true); // Create a mutable ref for autoFetch

  // create a function that handles the auto fetch the transactions
  const handleAutoFetch = () => {
    autoFetchRef.current = !autoFetchRef.current;
    checked ? setChecked(false) : setChecked(true);
    if (!autoFetchRef.current) {
      const interval = setInterval(() => {
        if (autoFetchRef.current) {
          clearInterval(interval);
          return;
        }
        handleSubmit(onAutoFetch)();
      }, 30000);
    }
  };

  // create a function that handles the page change
  const handlePageChange = (page) => {
    if (txR) removeQueryParam();
    cleanAutoFetch(page + 1);
    const payload = transactionReportingPayload;
    payload.page = page + 1;
    set(LC_PAYLOAD, payload);
    mutate(sanitizeSummaryApproval(payload));
  };
  // create a function that handles the page size change
  const handlePageSizeChange = (newPageSize) => {
    if (newPageSize !== pageSize) {
      cleanAutoFetch(1);
      setPageSize(newPageSize);
      const payload = transactionReportingPayload;
      payload.page = 1;
      payload.limit = newPageSize;
      set(LC_PAYLOAD, payload);
      firstRender ? setFirstRender(false) : mutate(sanitizeSummaryApproval(payload));
    }
  };

  //tab handle
  const handleTabChange = (newValue) => {
    if (data) {
      setTab(newValue);
    }
  };

  const handleDownload = async () => {
    return new Promise(function (resolve) {
      downloadCsv(transactionReportingPayload, {
        onSuccess: async (data) => {
          setTimeout(() => {
            saveDataToFile(data, "Transaction_Reporting_Kasha.csv", "text/csv");
            resolve();
          }, 500);
        },
      });
    });
  };

  const handleViewDetails = (id, openNewTab) => {
    if (openNewTab) {
      window.open(`${window.location.origin}/transactions/${id}?txR=true&original=${original}`);
    } else {
      navigate(`/transactions/${id}?txR=true&original=${original}`);
    }
  };

  const onClearFilters = () => {
    updateFilters({});
  };

  const handleSortChange = (column) => {
    onSortChange({
      column,
      sortColumns: ["createdAt"],
      lcPayload: LC_PAYLOAD,
      mutate,
      setCurrentPage,
      sanitizeRequest: sanitizeTransaction,
    });
  };

  useEffect(() => {
    if (fetchTime.submit) {
      const payload = get(LC_PAYLOAD);
      const now = new Date();
      switch (tab) {
        case 0:
          if (!fetchTime.transaction || fetchTime.transaction < fetchTime.submit) {
            mutate(sanitizeSummaryApproval(payload));
            setFetchTime({ ...fetchTime, transaction: now });
          }
          break;
        case 1:
          if (!fetchTime.sum || fetchTime.sum < fetchTime.submit) {
            sum.mutate(sanitizeSummaryReport(payload));
            setFetchTime({ ...fetchTime, sum: now });
          }
          break;
        case 2:
          if (!fetchTime.approval || fetchTime.approval < fetchTime.submit) {
            set(LC_PAYLOAD, { ...payload, effectiveAr: true });
            approvalMutate(sanitizeSummaryApproval({ ...payload, effectiveAr: true }));
            setFetchTime({ ...fetchTime, approval: now });
          }
          break;
        default:
          break;
      }
      // reset auto fetch
      if (checked) {
        handleAutoFetch();
        setChecked(true);
        setTimeout(() => {
          setTimeout(() => {
            handleAutoFetch();
            setChecked(true);
          }, 1000);
        }, 30000);
      }
    }
  }, [tab]);

  return (
    <MainLayout>
      <MainNavbar />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Filters
          paymentOptions={paymentOptions}
          pspsData={pspsData}
          merchants={merchants}
          onClearFilters={onClearFilters}
          brands={brands}
          errorCodes={errorCodes}
        />
      </FormProvider>

      {/* define tab */}
      <Grid
        container
        spacing={2}
        sx={{ flexGrow: 1, marginTop: 2 }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        {showTabs && <TransactionsTabs onChange={handleTabChange} value={tab} />}
        {!showTabs && <SoftBox />}
        <Grid
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: 125, marginTop: 2 }}
          fontSize={16}
        >
          <Switch color="info" onClick={handleAutoFetch} checked={checked} />
          <SoftBox>Autofetch</SoftBox>
        </Grid>
      </Grid>

      <Stack direction="row" spacing={1} sx={{ mt: 2 }} display="flex" flexWrap="wrap" gap={1}>
        {Object.values(filters)?.map((i, index) =>
          i ? <Chip key={i + index} label={i} color="secondary" textColor="white" /> : null
        )}
      </Stack>

      <TabPanel value={tab} index={0}>
        <DataTable
          table={{
            columns: tableColumns,
            rows: getRowsFn(data),
          }}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          pageCount={_.get(data, "pagination.totalPages", 0)}
          manualPagination={true}
          rowCount={_.get(data, "pagination.totalDocs", 0)}
          isLoading={isLoading}
          currentPage={currentPage}
          onCellClick={(row) => {
            handleViewDetails(row?.original?.id, false);
          }}
          onCellAuxClick={(row) => {
            handleViewDetails(row?.original?.id, true);
          }}
          isDownloadable={true}
          onDownloadClick={() => handleDownload()}
          sortDirection={sortDirection}
          columnsFilter={true}
          onHeaderCellClick={handleSortChange}
          isClickable={true}
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <CurrencyReport data={sum.data && sum.data} />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <GroupedBarChart sum={data} approvalData={approvalData} approvalMutate={approvalMutate} />
      </TabPanel>
    </MainLayout>
  );
};
TransactionReportingPage.propTypes = {
  showTabs: PropTypes.bool,
  tableColumns: PropTypes.array,
  getRowsFn: PropTypes.func,
  Filters: PropTypes.node,
  filterTransactionQueryFn: PropTypes.func,
  original: PropTypes.string,
};
export default TransactionReportingPage;
