import _ from "lodash";
import moment from "moment";
import { generateDateRange } from "utils/time";

const sanitizeOptions = (data) => {
  if (data && data === "") return null;
  return data;
};

export const sanitizeRequest = (values) => {
  let fromDate = values.fromDate;
  let toDate = values.toDate;
  if (values.last) {
    const { last } = values;
    const dateRange = generateDateRange(last);
    fromDate = dateRange.fromDate;
    toDate = dateRange.toDate;
  }
  const payload = {
    ...values,
    fromDate: fromDate && moment(new Date(fromDate)).format("YYYY-MM-DD HH:mm:ss"),
    toDate: toDate && moment(new Date(toDate)).format("YYYY-MM-DD HH:mm:ss"),
    status: sanitizeOptions(values.status),
    reconStatus: sanitizeOptions(values.reconStatus),
  };
  return _.pickBy(payload, _.identity);
};
