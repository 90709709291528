import { create } from "zustand";

type Psp = {
  _id: string | null;
  name: string | null;
  active: boolean | null;
};

type PspsStore = {
  psps: Psp[];
  setPsps: (psps: Psp[]) => void;
};

const initialState: PspsStore = {
  psps: [],
  setPsps: () => null,
};

export const usePspsStore = create((set) => ({
  ...initialState,
  setPsps: (values: Psp[]) => {
    return set(({ psps }) => ({ psps: { ...psps, ...values } }));
  },
}));
