import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, Box, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import SoftButton from "components/SoftButton";
import FormProvider from "components/FormProviders";
import SoftBox from "components/SoftBox";

import { credentialsModels } from "../schemas/models";
import { useUpdateCredential } from "../hooks";
import { sanitizeRequest } from "../schemas/models";
import FieldArrayRender from "./FieldArrayRender";
import FieldRender from "./FieldRender";
import ObjectRender from "./ObjectRender";
import { getSchema, getDefaultValues } from "../schemas/schema";

const UpdateCredentialModal = ({ credentials }) => {
  const { id, midId } = useParams();
  const [isOpenAddMerchantModal, setIsOpenAddMerchantModal] = useState(false);
  // store
  const models = _.get(credentialsModels, id, {});
  const arrayFields = _.pickBy(models, (item) => _.get(item, "type") === "listOfObjects");
  const objectModel = _.pickBy(models, (item) => _.get(item, "type") === "object");
  const defaultValues = getDefaultValues(id);
  const schema = getSchema(id);
  //form provider
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { handleSubmit, reset } = methods;

  //hooks
  const { mutate, isLoading } = useUpdateCredential();

  // create a function that handles the form submission
  const onSubmit = (values) => {
    mutate(
      { pspId: id, midId, ...sanitizeRequest(values, id) },
      {
        onSuccess: () => {
          handleCloseAddMerchantModal();
        },
      }
    );
  };
  const handleOpenAddMerchantModal = () => {
    setIsOpenAddMerchantModal(true);
  };
  const handleCloseAddMerchantModal = () => {
    setIsOpenAddMerchantModal(false);
  };

  // effect
  useEffect(() => {
    if (credentials) reset(_.get(credentials, "data", {}));
  }, [credentials]);

  return (
    <>
      <SoftBox pt={2} px={2}>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <SoftButton variant="gradient" color="info" onClick={handleOpenAddMerchantModal}>
            Update
          </SoftButton>
        </Stack>
      </SoftBox>
      <Dialog
        open={isOpenAddMerchantModal}
        onClose={handleCloseAddMerchantModal}
        fullWidth
        maxWidth="lg"
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Update credential
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3} pt={0}>
              <Box>
                <Grid container spacing={3} mt={1}>
                  {_.map(models, (item, key) => {
                    return <FieldRender item={item} key={key} label={key} name={key} />;
                  })}
                  {_.map(objectModel, (model, objectName) => {
                    return <ObjectRender model={model} objectName={objectName} key={objectName} />;
                  })}
                  {_.map(arrayFields, (arrayModel, arrayField) => {
                    return (
                      <FieldArrayRender
                        key={arrayField}
                        arrayFieldModels={_.get(arrayModel, `child`, {})}
                        arrayFieldName={arrayField}
                      />
                    );
                  })}
                </Grid>
              </Box>
            </SoftBox>
          </DialogContent>
          <DialogActions>
            <SoftButton variant="gradient" sx={{ mt: 2 }} onClick={handleCloseAddMerchantModal}>
              Cancel
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              sx={{ mt: 2 }}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Update
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

UpdateCredentialModal.propTypes = {
  credentials: PropTypes.object,
};

export default UpdateCredentialModal;
