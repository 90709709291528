import * as Yup from "yup";

export const blackDefaultValues = {
  ip: null,
  email: null,
};

export const blackSchema = Yup.object().shape({
  ip: Yup.string().nullable(),
  email: Yup.string().email().nullable(),
});
