import { Card, Grid } from "@mui/material";
import _ from "lodash";

import {
  DatePickerField,
  PaymentOptionSelect,
  TextInputField,
  TimeRangeInput,
} from "components/FormField";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { SelectInputField } from "components/FormField";

import { StatusEnum } from "../../../constants/status";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import React from "react";
import SortedCountrySelect from "../../../components/SortedCountrySelect";
import { dateTimePickerOptions } from "constants/dateFilter";

const TransactionManagementFilters = (props) => {
  const { onSubmit, merchants, paymentOptions } = props;
  const { setValue, getValues } = useFormContext();
  const merchantOptions = [{ value: "", label: "All" }].concat(
    _.map(merchants, (item) => ({ value: item._id, label: item.name }))
  );

  const statusOptions = [{ value: "", label: "All" }].concat(
    _.map(StatusEnum, (item) => ({ value: item, label: item }))
  );
  const handleClickCleanFilters = async () => {
    const currentFilters = getValues();
    setValue("merchantId", merchantOptions[0].value);
    Object.keys(_.omit(currentFilters, ["limit", "page"])).forEach((key) => {
      setValue(key, "");
    }); // Reset the values of all current filters
    await onSubmit();
  };

  const clearDateSelect = () => {
    setValue("fromDate", null);
    setValue("toDate", null);
  };
  const clearLast = () => {
    setValue("last", "");
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} lg={12}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SelectInputField
                  name="merchantId"
                  label="Merchants"
                  options={merchantOptions}
                  placeholder="Select an merchant"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextInputField label="Transaction ID" name="transactionId" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInputField
                  name="status"
                  label="Status"
                  options={statusOptions}
                  placeholder="Select an status"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <PaymentOptionSelect
                  name="paymentOptionId"
                  label="Payment Option"
                  paymentOptions={paymentOptions}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInputField label="Merchant Reference" name="merchantReference" />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SortedCountrySelect
                  name="country"
                  label="Country"
                  setValue={setValue}
                  isClearable
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInputField label="Email" name="email" />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <DatePickerField
                  name="fromDate"
                  options={dateTimePickerOptions()}
                  label="From Date"
                  onChange={clearLast}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DatePickerField
                  name="toDate"
                  options={dateTimePickerOptions()}
                  label="To Date"
                  onChange={clearLast}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TimeRangeInput name="last" label="Timeframe" onChange={clearDateSelect} />
              </Grid>
            </Grid>

            <Grid container display="flex" justifyContent="space-between">
              <SoftButton variant="gradient" color="info" type="submit" sx={{ mt: 2 }}>
                Search
              </SoftButton>
              <SoftButton
                variant="gradient"
                color="info"
                type="button"
                sx={{ mt: 2 }}
                onClick={handleClickCleanFilters}
              >
                Clear filters
              </SoftButton>
            </Grid>
          </SoftBox>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TransactionManagementFilters;

// Typechecking props of the TransactionManagementFilters
TransactionManagementFilters.propTypes = {
  onSubmit: PropTypes.func,
  merchants: PropTypes.object,
  paymentOptions: PropTypes.object,
};
