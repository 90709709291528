import _ from "lodash";
import { useParams } from "react-router-dom";

import { Card, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useGetTemplates as useGetPmTemplates } from "pages/brand-detail/hooks";
import { useGetPspMid } from "pages/psp-details/hooks";
import { isSuperAdmin } from "utils/roles";
import Error403 from "components/Error403";
import DataTable from "examples/Tables/DataTable";
import { useGetPaymentOptions } from "pages/brand-detail/hooks";
import { countries } from "constants/countries";
import CreateTemplateModal from "./CreateTemplateModal";
import { PaymentOptionSelect, SelectInputField } from "components/FormField";
import SoftButton from "components/SoftButton";
import { useForm } from "react-hook-form";
import FormProvider from "components/FormProviders";
import { useEffect, useMemo } from "react";
import { useGetPsps } from "pages/psp-management/hooks";
import { toastError } from "utils/toast";
import { useGetTemplates, useDeleteTemplate } from "../hooks";
import EditTemplateModal from "./EditTemplateModal";
import { DeleteModal } from "components/Modal";

const Templates = () => {
  const { id } = useParams();
  if (!isSuperAdmin()) {
    return <Error403 />;
  }
  // form
  const methods = useForm();
  const { handleSubmit, getValues, watch } = methods;
  const watchPsp = watch("pspId");

  // hooks
  const { data: paymentOptions } = useGetPaymentOptions();
  const { data: templates, isLoading, isFetching, mutate } = useGetTemplates(id);
  const { data: psps } = useGetPsps();
  const { data: mids } = useGetPspMid(watchPsp);
  const { data: pmTemplates } = useGetPmTemplates();
  const { mutateAsync: deleteTemplate, isLoading: isDeleting } = useDeleteTemplate();

  const pspOptions = useMemo(() => {
    return _.map(psps, (item) => ({
      label: item.name,
      value: item._id,
    }));
  }, [psps]);

  const midOptions = useMemo(() => {
    return [{ label: "All", value: null }].concat(
      _.map(mids, (item) => ({
        label: item.name,
        value: item._id,
      }))
    );
  }, [mids]);

  const onDelete = async (id, pspId) => {
    await deleteTemplate({ templateId: id, pspId });
    onRefetch();
  };

  const getRows = () => {
    return _.map(templates, (item) => {
      const supportedCountries = countries.filter((c) => item.supportedCountries.includes(c.code));
      const countryName = _.map(supportedCountries, "label").join(", ");

      return {
        ...item,
        id: item._id,
        supportedCountries: countryName || "N/A",
        actions: (
          <>
            <DeleteModal
              message={
                <>
                  Are you sure you want to delete template <b>{_.get(item, "name")}</b>? You will
                  not be able to recover this template?
                </>
              }
              type="icon"
              title="Delete Template"
              onDelete={() => onDelete(item._id, item.pspId)}
              isLoading={isDeleting}
              size="xs"
            />
            <EditTemplateModal
              template={item}
              paymentOptions={paymentOptions}
              onCloseSuccess={onRefetch}
              pmTemplates={pmTemplates}
              psps={psps}
            />
          </>
        ),
      };
    });
  };

  const onSubmit = (values) => {
    if (!values.pspId) {
      toastError({ description: "Please select a psp" });
      return;
    }
    const payload = _.pickBy(values, _.identity);
    mutate({ pspId: values.pspId, ...payload });
  };

  const onRefetch = () => {
    const payload = _.pickBy(getValues(), _.identity);
    mutate({ pspId: payload.pspId, ...payload });
  };

  useEffect(() => {
    if (watchPsp) {
      mutate({ pspId: watchPsp });
    }
  }, [psps]);

  return (
    <>
      <Grid container display="flex" alignItems="center" justifyContent="space-between">
        <Grid item>
          <SoftTypography variant="h5" fontWeight="medium">
            Templates
          </SoftTypography>
        </Grid>
        <Grid item>
          <CreateTemplateModal
            paymentOptions={paymentOptions}
            onCloseSuccess={onRefetch}
            pmTemplates={pmTemplates}
            psps={psps}
          />
        </Grid>
      </Grid>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card sx={{ overflow: "visible" }}>
              <SoftBox p={2}>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={6}>
                    <SelectInputField
                      label="PSP"
                      name="pspId"
                      options={pspOptions}
                      menuPosition={"fixed"}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={6}>
                    <PaymentOptionSelect
                      label="Payment option"
                      name="paymentOptionId"
                      paymentOptions={paymentOptions}
                      menuPosition={"fixed"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SelectInputField
                      label="MID"
                      name="pspMidId"
                      options={midOptions}
                      menuPosition={"fixed"}
                    />
                  </Grid>
                </Grid>

                <Grid container display="flex" justifyContent="space-between">
                  <SoftButton variant="gradient" color="info" type="submit" sx={{ mt: 1 }}>
                    Search Templates
                  </SoftButton>
                </Grid>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>

      <SoftBox mt={2}>
        <DataTable
          table={{
            columns: [
              { Header: "ID", accessor: "id" },
              { Header: "Name", accessor: "name" },
              { Header: "PSP ID", accessor: "pspId", hide: true },
              { Header: "MID ID", accessor: "pspMidId", hide: true },
              { Header: "Payment Option ID", accessor: "paymentOptionId", hide: true },
              { Header: "Method Template ID", accessor: "methodTemplateId", hide: true },
              { Header: "PSP", accessor: "psp" },
              { Header: "MID", accessor: "mid" },
              { Header: "Supported Countries", accessor: "supportedCountries" },
              { Header: "payment Option", accessor: "paymentOption" },
              { Header: "method template", accessor: "methodTemplate" },
              { Header: "actions", accessor: "actions" },
            ],
            rows: getRows(),
          }}
          entriesPerPage={{
            defaultValue: 25,
            entries: [25, 50, 200, 500],
          }}
          canSearch={true}
          columnsFilter={true}
          isClickable={true}
          isLoading={isLoading || isFetching}
        />
      </SoftBox>
    </>
  );
};

export default Templates;
