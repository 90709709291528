import { Dialog, DialogContent, DialogTitle, Stack, Icon } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { useDisclosure } from "hooks/useDisclosure";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { useUpdatePaymentMethod } from "pages/brand-detail/hooks";
import SoftTypography from "components/SoftTypography";
import { usePaymentMethodStore } from "stores/paymentMethod";
import { PaymentMethodTypeEnum, PaymentMethodMapping } from "constants/tabs";
import { sanitizeUpdateCurrenciesRequest } from "pages/brand-detail/schemas/paymentMethod";

const DeleteCountry = ({ country, type }) => {
  const { id: merchantId, brandId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { paymentMethod } = usePaymentMethodStore();

  // // query
  const { mutate, isLoading } = useUpdatePaymentMethod({
    merchantId,
    brandId,
    pspMidId: paymentMethod.pspMidId,
  });

  const submit = () => {
    let payload = paymentMethod;
    const typeStr = _.get(PaymentMethodMapping, Object.keys(PaymentMethodTypeEnum)[type]);
    let countries = _.get(payload, typeStr, []);
    const newCountries = _.filter(countries, (c) => c.code !== country.code);
    _.set(payload, typeStr, newCountries);

    mutate(
      sanitizeUpdateCurrenciesRequest({
        merchantId,
        brandId,
        pspMidId: paymentMethod.pspMidId,
        methodId: paymentMethod._id,
        ...payload,
      }),
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  return (
    <>
      <SoftBox>
        <SoftButton variant="text" color="error" onClick={onOpen}>
          <Icon>delete_forever</Icon>&nbsp;delete
        </SoftButton>
      </SoftBox>
      <Dialog open={isOpen} onClose={onClose} sx={{ overflow: "visible" }} fullWidth maxWidth="sm">
        <DialogTitle>Delete Country</DialogTitle>
        <DialogContent>
          <SoftBox p={3}>
            <SoftTypography textAlign="center">
              Are you sure want to delete country <b>{country?.name}</b> from payment method{" "}
              <b>{paymentMethod.paymentOption}</b>?
            </SoftTypography>
          </SoftBox>
          <Stack direction="row" spacing={1} justifyContent="center" mt={4}>
            <SoftButton
              variant="gradient"
              color="error"
              onClick={submit}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Delete
            </SoftButton>
            <SoftButton onClick={onClose}>Cancel</SoftButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

DeleteCountry.propTypes = {
  country: PropTypes.object,
  type: PropTypes.number,
};

export default DeleteCountry;
