import { useMutation, useQuery, useQueryClient } from "react-query";
import { getTemplatePath } from "utils/string";
import { toastSuccess } from "utils/toast";
import { useMerchantStore } from "stores/merchant";

const { default: request } = require("utils/request");

const GET_MERCHANT_INFO = "/merchants/:merchantId";
const UPDATE_MERCHANT_INFO = "/merchants";
export const GET_BRANDS = "/merchants/:merchantId/brands";
const CREATE_BRAND = "/merchants/:merchantId/brands";
const DELETE_BRAND = "/merchants/:merchantId/brands/:brandId";

const getMerchantInfo = (merchantId) => {
  const URL = getTemplatePath(GET_MERCHANT_INFO, { merchantId });
  return request.get(URL);
};

const updateMerchantInfo = (data) => {
  return request.put(`${UPDATE_MERCHANT_INFO}/${data.id}`, data);
};

const getBrands = (merchantId) => {
  const URL = getTemplatePath(GET_BRANDS, { merchantId });
  return request.get(URL);
};

const createBrand = ({ merchantId, ...data }) => {
  const URL = getTemplatePath(CREATE_BRAND, { merchantId });
  return request.post(URL, data);
};

const deleteBrand = ({ merchantId, brandId }) => {
  const URL = getTemplatePath(DELETE_BRAND, { merchantId, brandId });
  return request.delete(URL);
};

export const useGetMerchantInfo = (id) => {
  const { setMerchant } = useMerchantStore();
  return useQuery([GET_MERCHANT_INFO, id], () => getMerchantInfo(id), {
    enabled: !!id,
    onSuccess: (data) => {
      setMerchant(data);
    },
  });
};

export const useUpdateMerchantInfo = () => {
  return useMutation(updateMerchantInfo, {
    onSuccess: () => {
      toastSuccess({ description: "Update merchant info has been success" });
    },
  });
};

export const useGetBrands = (merchantId) => {
  return useQuery([GET_BRANDS, merchantId], () => getBrands(merchantId), {
    enabled: !!merchantId,
    refetchOnWindowFocus: false,
  });
};

export const useCreateBrand = () => {
  const queryClient = useQueryClient();
  return useMutation(createBrand, {
    onSuccess: () => {
      toastSuccess({ description: "Create brand has been success" });
      queryClient.invalidateQueries([GET_BRANDS]);
    },
  });
};

export const useDeleteBrand = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteBrand, {
    onSuccess: () => {
      toastSuccess({ description: "Delete brand has been success" });
      queryClient.invalidateQueries([GET_BRANDS]);
    },
  });
};
