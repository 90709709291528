export const ErrorCategory = {
  Internal: 1,
  User: 2,
  Provider: 3,
};

export const ErrorAction = {
  BLACKLIST_IP: "blacklist.ip",
  BLACKLIST_CARD: "blacklist.card",
  BLACKLIST_EMAIL: "blacklist.email",
};
