import { Dialog, DialogContent, DialogTitle, Stack, Icon, Grid } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { useDisclosure } from "hooks/useDisclosure";

import FormProvider from "components/FormProviders";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { useUpdateVirtualAccount } from "pages/brand-detail/hooks";

import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema } from "pages/brand-detail/schemas/editVirtualAccount";
import { SelectInputField } from "../../../../../components/FormField";

const EditPaymentMethod = ({ id }) => {
  const { id: merchantId, brandId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();

  // query
  const { mutate, isLoading } = useUpdateVirtualAccount({ merchantId, brandId });

  // hook form
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = methods;

  // function
  const handleSubmitForm = (values) => {
    mutate(
      {
        merchantId,
        brandId,
        id,
        ...values,
      },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <SoftBox mr={1}>
        <SoftButton variant="text" color="dark" onClick={onOpen}>
          <Icon>edit</Icon>&nbsp;update
        </SoftButton>
      </SoftBox>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        sx={{ overflow: "visible" }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Update Virtual Account</DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
            <SoftBox py={2}>
              <Grid item sx={12} width={"100%"}>
                <SelectInputField
                  name="enabled"
                  label="Enabled"
                  options={[
                    { label: "true", value: true },
                    { label: "false", value: false },
                  ]}
                  placeholder="Select an option"
                />
              </Grid>

              <Stack direction="row" spacing={1} justifyContent="center" mt={4}>
                <SoftButton onClick={handleClose}>Cancel</SoftButton>
                <SoftButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Update
                </SoftButton>
              </Stack>
            </SoftBox>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

EditPaymentMethod.propTypes = {
  id: PropTypes.string.isRequired,
};

export default EditPaymentMethod;
