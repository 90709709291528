import React from "react";
import { Card, Grid } from "@mui/material";
import _ from "lodash";

import { DatePickerField, TextInputField, TimeRangeInput } from "components/FormField";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { SelectInputField } from "components/FormField";

import { useFormContext } from "react-hook-form";
import { dateTimePickerOptions } from "constants/dateFilter";
import { statusOptions } from "constants/status";
import { statusOptions as reconStatusOptions } from "constants/reconciliation";

const Filters = () => {
  const { setValue } = useFormContext();

  const clearDateSelect = () => {
    setValue("fromDate", null);
    setValue("toDate", null);
  };
  const clearLast = () => {
    setValue("last", "");
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} lg={12}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextInputField
                  label="Search"
                  name="search"
                  placeholder="Search for transactions"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectInputField
                  name="status"
                  label="Status"
                  options={statusOptions}
                  placeholder="Select a status"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectInputField
                  name="reconStatus"
                  label="Reconciliation status"
                  options={reconStatusOptions}
                  placeholder="Select a status"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <DatePickerField
                  name="fromDate"
                  options={dateTimePickerOptions()}
                  label="From Date"
                  onChange={clearLast}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DatePickerField
                  name="toDate"
                  options={dateTimePickerOptions()}
                  label="To Date"
                  onChange={clearLast}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TimeRangeInput name="last" label="Timeframe" onChange={clearDateSelect} />
              </Grid>
            </Grid>

            <Grid container display="flex" justifyContent="flex-end">
              <SoftButton variant="gradient" color="info" type="submit" sx={{ mt: 2 }}>
                Search
              </SoftButton>
            </Grid>
          </SoftBox>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Filters;
