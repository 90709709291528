import { useQuery, useQueryClient, useMutation } from "react-query";
import { toastSuccess } from "utils/toast";
import request from "utils/request";

const TEMPLATES = "/reconciliation-templates";

const getTemplates = (pspId) => {
  if (pspId) {
    return request.get(`${TEMPLATES}?pspId=${pspId}`);
  } else {
    return request.get(TEMPLATES);
  }
};
const createTemplate = (payload) => request.post(TEMPLATES, payload);

export const useGetTemplates = (pspId) => {
  return useQuery([TEMPLATES, pspId], () => getTemplates(pspId), { refetchOnWindowFocus: false });
};

export const useGetMerchantsByOrg = (organizationId) => {
  return useQuery([TEMPLATES, organizationId], () => getMerchants(organizationId), {
    refetchOnWindowFocus: false,
  });
};

export const useCreateTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation(createTemplate, {
    onSuccess: () => {
      toastSuccess({ description: "Create template has been success" });
      queryClient.invalidateQueries([TEMPLATES]);
    },
  });
};

export const useMutateGetMerchants = () => {
  return useMutation(getMerchants);
};

const getTemplate = (id) => request.get(`${TEMPLATES}/${id}`);
export const useMutateGetTemplate = () => {
  return useMutation(getTemplate);
};
