import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";

import DataTable from "examples/Tables/DataTable";
import { useGetPspMids } from "pages/brand-detail/hooks";
import SoftBadgeDot from "components/SoftBadgeDot";
import AssignPspMidToBrand from "./AssignPspMidToBrand";

const PspMidGrid = ({ onEditClick }) => {
  const { id: merchantId, brandId } = useParams();

  const { data } = useGetPspMids({ merchantId, brandId });

  const getRows = () => {
    return _.map(data, (item) => ({
      id: item._id,
      name: item.name,
      psp: item.psp,
      weight: item.weight,
      payinEnabled: <SoftBadgeDot color={item.payinEnabled ? "success" : "light"} size="md" />,
      payoutEnabled: <SoftBadgeDot color={item.payoutEnabled ? "success" : "light"} size="md" />,
    }));
  };

  return (
    <SoftBox>
      <SoftBox display="flex" justifyContent="space-between">
        <SoftBox></SoftBox>
        <SoftBox>
          <AssignPspMidToBrand brandId={brandId} />
        </SoftBox>
      </SoftBox>
      <SoftBox mt={2}>
        <DataTable
          table={{
            columns: [
              { Header: "Psp", accessor: "psp" },
              { Header: "Mid", accessor: "name" },
              { Header: "Weight", accessor: "weight" },
              { Header: "Payin Enabled", accessor: "payinEnabled" },
              { Header: "Payout Enabled", accessor: "payoutEnabled" },
              { Header: "id", accessor: "id", hide: true },
            ],
            rows: getRows(),
          }}
          onCellClick={(row) => onEditClick(row?.original?.id, row?.original?.name)}
          isClickable={true}
        />
      </SoftBox>
    </SoftBox>
  );
};

PspMidGrid.defaultProps = {
  onEditClick: () => {},
};

PspMidGrid.propTypes = {
  onEditClick: PropTypes.func,
};

export default PspMidGrid;
