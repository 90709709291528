import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useForm } from "react-hook-form";

import SoftButton from "components/SoftButton";
import FormProvider from "components/FormProviders";
import MidForm from "./MidForm";

import { useAddMid } from "../hooks";

import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema } from "../schemas/psp";
import ButtonSet from "../../../components/ButtonSet";

const sanitizeRequest = ({
  name,
  mid,
  pspId,
  dailyUsdLimit,
  monthlyUsdLimit,
  minSuccessTx,
  hsfst,
  isDefault,
}) => {
  const payload = _.pickBy(
    {
      name,
      mid,
      pspId,
      dailyUsdLimit,
      monthlyUsdLimit,
      minSuccessTx,
      hsfst,
    },
    _.identity
  );
  return { ...payload, default: isDefault };
};
const AddMidModal = ({ isOpen, onClose, pspId }) => {
  //form provider
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = methods;

  //hooks
  const { isLoading, mutate } = useAddMid();

  // create a function that handles the form submission
  const onSubmit = (values) => {
    mutate(sanitizeRequest({ ...values, isDefault: values.default, pspId }), {
      onSuccess: () => {
        reset();
        onClose();
      },
    });
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Create Psp Mid
          </DialogTitle>
          <DialogContent>
            <MidForm />
          </DialogContent>
          <ButtonSet onClose={onClose} isLoading={isLoading} title={"Create Mid"} />
        </FormProvider>
      </Dialog>
    </>
  );
};

// Setting default values for the props of AddUserModal
AddMidModal.defaultProps = {
  isOpen: false,
};

// Typechecking props for the AddUserModal
AddMidModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  pspId: PropTypes.string.isRequired,
};

export default AddMidModal;
