import { generateDateRange } from "utils/time";

export const sanitizeRequest = ({
  userId,
  fromDate,
  toDate,
  limit,
  requestId,
  page,
  method,
  last,
}) => {
  const identityObj = _.pickBy(
    {
      userId,
      fromDate,
      toDate,
      limit,
      requestId,
      fromDate,
      toDate,
      limit,
      page,
      method,
      last,
    },
    _.identity
  );
  const sanitizeValues = _.pickBy(identityObj, (value) => value !== "All");
  if (last) {
    const dateRange = generateDateRange(last);
    sanitizeValues.fromDate = dateRange.fromDate;
    sanitizeValues.toDate = dateRange.toDate;
  }
  return sanitizeValues;
};
