import React from "react";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import SoftBox from "components/SoftBox";

import {
  CountrySelect,
  CurrencySelect,
  PaymentOptionSelect,
  SelectInputField,
  TextInputField,
} from "components/FormField";

import { categoryOptionsSlim } from "constants/status";

const AmountLimitForm = ({ paymentOptions }) => {
  return (
    <SoftBox p={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <PaymentOptionSelect
            name="paymentOptionId"
            label="Payment Option"
            paymentOptions={paymentOptions}
            maxMenuHeight={180}
            removeSelectAll={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectInputField label="Category" name="category" options={categoryOptionsSlim} />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <CountrySelect name="country" label="Country" maxMenuHeight={110} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CurrencySelect name="currency" label="Currency" maxMenuHeight={110} />
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Min Amount" name="min" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Max Amount" name="max" />
        </Grid>
      </Grid>
    </SoftBox>
  );
};
AmountLimitForm.propTypes = {
  paymentOptions: PropTypes.array,
};
export default AmountLimitForm;
