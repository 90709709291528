import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import {
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  Stack,
  DialogActions,
  Tabs,
  Tab,
} from "@mui/material";
import { useForm } from "react-hook-form";

import SoftButton from "components/SoftButton";
import FormProvider from "components/FormProviders";
import SoftBox from "components/SoftBox";

import { yupResolver } from "@hookform/resolvers/yup";
import { useDisclosure } from "hooks/useDisclosure";
import { SelectInputField, TextInputField } from "components/FormField";
import { actionOptions } from "constants/bins";
import { defaultValues, schema, sanitizeRequest } from "../schemas/bins";
import { useCreateBin, useCreateBinFromCSV } from "../hooks";
import AppBar from "@mui/material/AppBar";
import TableRowsIcon from "@mui/icons-material/TableRows";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import TabPanel, { a11yProps } from "components/TabPanel";
import SoftDropzone from "components/SoftDropzone";
import SoftTypography from "components/SoftTypography";
import { toastError } from "utils/toast";

const AddBinModal = ({ onClose: onCloseCallback }) => {
  const { id: pspId, midId: pspMidId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [tab, setTab] = useState(0);
  const { midId } = useParams();
  let dz;

  // form
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { handleSubmit, reset } = methods;

  //hooks
  const { mutate: createBin, isLoading } = useCreateBin();
  const { mutate: uploadCsv, isLoading: isUploading } = useCreateBinFromCSV();

  const onSubmit = (values) => {
    if (dz && dz.files.length > 1) {
      toastError({ description: "Multiple files upload is not supported" });
    } else if (dz && dz.files.length == 1) {
      const formData = new FormData();
      formData.append("file", dz.files[0]);
      formData.append("action", values.action);
      formData.append("reason", values.reason);
      uploadCsv(
        { pspId, pspMidId, formData },
        {
          onSuccess: () => {
            onCloseCallback();
            onClose();
            reset({});
            dz.removeFile(dz.files[0]);
          },
        }
      );
    } else {
      if (_.isEmpty(values.bins)) {
        toastError({ description: "Bins is required" });
      } else {
        createBin(sanitizeRequest({ ...values, pspId, pspMidId }), {
          onSuccess: () => {
            reset({});
            onCloseCallback();
            onClose();
          },
        });
      }
    }
  };

  return (
    <>
      <SoftBox pt={2} px={2} mb={3}>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <SoftButton variant="gradient" color="info" size="small" onClick={onOpen}>
            Import Bins
          </SoftButton>
        </Stack>
      </SoftBox>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Import Bins
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <SelectInputField label="action" name="action" options={actionOptions} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInputField label="reason" name="reason" />
                </Grid>
                <Grid>
                  <SoftBox sx={{ maxWidth: 750, marginTop: 2, ml: 2 }}>
                    <AppBar position="static">
                      <Tabs
                        value={tab}
                        onChange={(e, val) => setTab(val)}
                        aria-label="basic tabs example"
                        sx={{ backgroundColor: "transparent", display: { xs: "none", sm: "flex" } }}
                        variant="standard"
                      >
                        <Tab
                          icon={<TableRowsIcon />}
                          label="Import bins file"
                          {...a11yProps(0)}
                          sx={{ marginRight: 2 }}
                        />

                        <Tab
                          icon={<MonetizationOnOutlinedIcon />}
                          label="Input bins"
                          {...a11yProps(2)}
                          sx={{ marginRight: 2 }}
                        />
                      </Tabs>
                    </AppBar>
                  </SoftBox>
                </Grid>

                <Grid xs={12} sm={12}>
                  <TabPanel value={tab} index={0}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                      mt={-1}
                    >
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Bins File
                        </SoftTypography>
                      </SoftBox>
                      <SoftDropzone
                        options={{
                          url: null,
                          addRemoveLinks: true,
                          maxFiles: 1,
                          acceptedFiles: ".csv",
                          autoProcessQueue: false,
                          init: function () {
                            dz = this;
                            this.on("addedfile", function (file) {
                              while (this.files.length > 1) this.removeFile(this.files[0]);
                            });
                          },
                        }}
                      />
                    </SoftBox>
                  </TabPanel>
                  <TabPanel value={tab} index={1}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                      mt={-1}
                    >
                      <TextInputField label="bins" name="bins" multiline rows={8} />
                    </SoftBox>
                  </TabPanel>
                </Grid>
              </Grid>
            </SoftBox>
          </DialogContent>
          <DialogActions>
            <SoftButton onClick={onClose}>Cancel</SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              disabled={isLoading || isUploading}
              isLoading={isLoading || isUploading}
            >
              Add Bin
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};
AddBinModal.propTypes = {
  onClose: PropTypes.func,
};
export default AddBinModal;
