import PropTypes from "prop-types";
import { Card, Divider, CardContent, RadioGroup, FormControl } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import ErrorItem from "./ErrorItem";
import SoftButton from "components/SoftButton";

const ErrorList = ({
  title,
  group,
  data,
  onChange,
  allowAdd,
  onAddClick,
  allowCellDelete,
  allowCellEdit,
}) => {
  const handleValueChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <Card sx={{ height: "27vw" }}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SoftTypography variant="h6" fontWeight="medium">
          {title}
        </SoftTypography>
        {allowAdd && (
          <SoftButton variant="outlined" color="secondary" size="small" onClick={onAddClick}>
            add
          </SoftButton>
        )}
      </SoftBox>
      <Divider />
      <CardContent sx={{ height: "50vw", overflow: "auto" }}>
        <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <FormControl>
            <RadioGroup name={group} onChange={handleValueChange}>
              {_.map(data, (e) => (
                <ErrorItem
                  key={e._id}
                  id={e._id}
                  code={e.code}
                  description={e.description}
                  message={e.message}
                  status={e.status}
                  psp={e.psp}
                  allowDelete={allowCellDelete}
                  allowEdit={allowCellEdit}
                  category={e.category}
                  actions={e.actions}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </SoftBox>
      </CardContent>
    </Card>
  );
};
ErrorList.defaultProps = {
  data: [],
  allowAdd: false,
  allowCellDelete: false,
  allowCellEdit: false,
};

ErrorList.propTypes = {
  title: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  data: PropTypes.array,
  onChange: PropTypes.func,
  allowAdd: PropTypes.bool,
  allowCellDelete: PropTypes.bool,
  onAddClick: PropTypes.func,
  allowCellEdit: PropTypes.bool,
};

export default ErrorList;
