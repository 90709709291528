export const routeSelectorsOptions = [
  { label: "Random", value: "RANDOM" },
  { label: "Round Robin", value: "ROUND_ROBIN" },
  { label: "Weighted Round Robin", value: "WEIGHTED_ROUND_ROBIN" },
];

export const historyByOptions = [
  { label: "Completed", value: "COMPLETED" },
  { label: "Attempted", value: "ATTEMPTED" },
];
