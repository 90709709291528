import { useMutation, useQuery, useQueryClient } from "react-query";
import { toastSuccess } from "../../../../utils/toast";
import request from "../../../../utils/request";
import { getTemplatePath } from "../../../../utils/string";

export const ORGANIZATION_USERS = "/organizations/:id/users";
const ORGANIZATION_USER = "/organizations/:id/users/:userId";
const RESET_USER_PASSWORD = "/auth/forgot-password";

const getOrganizationUsers = (id) => request.get(getTemplatePath(ORGANIZATION_USERS, { id }));

const createOrganizationUser = ({ id, payload }) => {
  return request.post(getTemplatePath(ORGANIZATION_USERS, { id }), payload);
};

const deleteOrganizationUser = ({ id, userId }) => {
  return request.delete(getTemplatePath(ORGANIZATION_USER, { id, userId }));
};
const resetUserPassword = (payload) => request.post(RESET_USER_PASSWORD, payload);

export const useGetOrganizationUsers = (id) => {
  return useQuery([ORGANIZATION_USERS, id], () => getOrganizationUsers(id), {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
};
export const useCreateOrganizationUser = () => {
  const queryClient = useQueryClient();
  return useMutation(createOrganizationUser, {
    onSuccess: () => {
      toastSuccess({ description: "Create organization has been success" });
      queryClient.invalidateQueries([ORGANIZATION_USERS]);
    },
  });
};

export const useDeleteOrganizationUser = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteOrganizationUser, {
    onSuccess: () => {
      toastSuccess({ description: "Organization User deleted successfully" });
      queryClient.invalidateQueries([ORGANIZATION_USERS]);
    },
  });
};

export const useResetUserPassword = () => {
  const queryClient = useQueryClient();
  return useMutation(resetUserPassword, {
    onSuccess: () => {
      queryClient.invalidateQueries([ORGANIZATION_USERS]);
    },
  });
};
