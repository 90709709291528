import _ from "lodash";
import PropTypes from "prop-types";
import DataTable from "examples/Tables/DataTable";
import SoftBadgeDot from "components/SoftBadgeDot";

const FieldArrayTable = ({ isLoading, data, models }) => {
  const getRows = () => {
    const arrayField = _.findKey(models, (item) => _.get(item, "type") === "listOfObjects");
    const arrayObj = _.get(models, arrayField, {});
    let keys = _.keys(arrayObj.child);
    const rows = _.get(data, `data.${arrayField}`, []);

    return _.map(rows, (item) => {
      let row = {};
      _.forEach(keys, (val) => {
        if (_.get(arrayObj.child, `${val}.type`) === "bool") {
          row[val] = (
            <SoftBadgeDot color={_.get(item, val, false) ? "success" : "light"} size="md" />
          );
        } else {
          row[val] = _.get(item, val, "");
        }
      });
      return row;
    });
  };

  const getColumns = () => {
    const arrayObj = _.find(models, (item) => _.get(item, "type") === "listOfObjects");
    const columns = _.map(arrayObj?.child, (item, key) => {
      return { Header: _.startCase(key), accessor: key };
    });
    return columns;
  };

  return (
    <DataTable
      table={{
        columns: getColumns(),
        rows: getRows(),
      }}
      isLoading={isLoading}
    />
  );
};
// Typechecking props for the DeleteMerchantModal
FieldArrayTable.propTypes = {
  models: PropTypes.object,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
};
export default FieldArrayTable;
