import React, { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import SoftTypography from "../../../components/SoftTypography";
import Collapse from "@mui/material/Collapse";
import SoftBox from "components/SoftBox";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

const PermissionCard = ({ permission }) => {
  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox component="li" display="flex" alignItems="center" p={1}>
        <SoftBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <SoftTypography variant="caption">
            <b>Name:</b> &nbsp;{_.get(permission, "name", "N/A")}
          </SoftTypography>
          <SoftTypography variant="caption">
            <b>Info:</b> &nbsp;{_.get(permission, "info", "N/A")}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </Card>
  );
};
PermissionCard.propTypes = {
  permission: PropTypes.object.isRequired,
};

const PermissionCollapse = ({ permissions, ...rest }) => {
  const [open, setOpen] = useState(false);

  const handleCollapse = () => {
    setOpen(!open);
  };

  if (permissions.length > 1) {
    return (
      <SoftBox mb={0.5}>
        <SoftBox
          {...rest}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={0.3}
          sx={{ cursor: "pointer" }}
          onClick={handleCollapse}
        >
          <SoftTypography variant="h6" color={open ? "dark" : "text"} sx={{ userSelect: "none" }}>
            expand permissions
          </SoftTypography>
          <SoftBox color={open ? "dark" : "text"}>
            <Icon sx={{ fontWeight: "bold" }} fontSize="small">
              {open ? "remove" : "add"}
            </Icon>
          </SoftBox>
        </SoftBox>

        <Collapse timeout={400} in={open}>
          <SoftBox p={1} lineHeight={1}>
            {_.map(permissions, (permission, index) => (
              <PermissionCard permission={permission} key={index} />
            ))}
          </SoftBox>
        </Collapse>
      </SoftBox>
    );
  } else {
    return <PermissionCard permission={permissions[0]} />;
  }
};

PermissionCollapse.propTypes = {
  permissions: PropTypes.array,
};
export default PermissionCollapse;
