import React from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema, sanitizeRequest } from "../schemas/subOption";
import { useDisclosure } from "hooks/useDisclosure";
import { ModalWrapper } from "components/Modal";

import { useCreateSubOption } from "../hooks";
import SubOptionForm from "./SubOptionForm";

const CreateSubOption = () => {
  const { id: pspId, methodId } = useParams();
  const modalState = useDisclosure();
  const { onClose } = modalState;

  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit } = methods;

  //hooks
  const { isLoading, mutate: createSubOption } = useCreateSubOption({ pspId, methodId });

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    createSubOption(
      { pspId, methodId, ...sanitizeRequest(values) },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  return (
    <ModalWrapper
      title="Create SubOption"
      modalState={modalState}
      isLoading={isLoading}
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      minHeight="350px"
      maxWidth="md"
      type="button"
      submitButtonText={"Create SubOption"}
    >
      <SubOptionForm />
    </ModalWrapper>
  );
};

export default CreateSubOption;
