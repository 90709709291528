import * as Yup from "yup";

export const defaultValues = {
  name: null,
  pspMidId: null,
  paymentOptionId: null,
  methodTemplateId: null,
  supportedCountries: null,
  pspId: null,
};
export const schema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  pspMidId: Yup.string().required("MID is required"),
  paymentOptionId: Yup.string().required("Payment option is required"),
  methodTemplateId: Yup.string().required("Method template is required"),
  supportedCountries: Yup.array().of(Yup.string().nullable()).nullable(),
  pspId: Yup.string().required("PSP is required"),
});

export const sanitizeRequest = (values) => {
  let countries = values.supportedCountries;
  if (countries && countries.length > 0 && countries.includes(null)) {
    countries = null;
  }
  const payload = { ...values, supportedCountries: countries };
  return _.pickBy(payload, _.identity);
};
