import { useEffect, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";

import { Card } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { useGetPsps } from "pages/psp-management/hooks";

import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";
import { SwitchField, TextInputField } from "components/FormField";
import FormProvider from "components/FormProviders";
import { useUpdatePsp } from "pages/psp-management/hooks";
import { useForm } from "react-hook-form";

const sanitizeRequest = ({ name, minScore, maxScore, active, pspId }) => {
  const filteredPayload = _.omitBy(
    {
      name,
      minScore: minScore && typeof minScore === "number" ? minScore : parseFloat(minScore),
      maxScore: maxScore && typeof maxScore === "number" ? maxScore : parseFloat(maxScore),
      pspId,
    },
    _.isNil
  );
  return { ...filteredPayload, active };
};
const GeneralSettings = () => {
  const { id } = useParams();
  if (!hasPermission(Permissions.PSP_MID.LIST)) {
    return <Error403 />;
  }

  const { data } = useGetPsps();
  const [psp, setPsp] = useState({});

  //form provider
  const methods = useForm({
    defaultValues: { name: "", minScore: "", maxScore: "", active: false },
  });
  const { handleSubmit, reset } = methods;

  //hooks
  const { isLoading, mutate } = useUpdatePsp();

  useEffect(() => {
    const psp = _.find(data, (item) => _.get(item, "_id") === id);
    reset(psp);
    setPsp(psp);
  }, [data]);

  // create a function that handles the form submission
  const onSubmit = (values) => {
    mutate(sanitizeRequest({ ...values, pspId: id }));
  };

  return (
    <Card sx={{ height: "100%" }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Psp Details
          </SoftTypography>
        </SoftBox>
        <SoftBox p={2}>
          <SoftBox display="flex" mb={2}>
            <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Psp name: &nbsp;
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              {psp.name}
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex">
            <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize" mt={1}>
              Mix Score: &nbsp;
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              <TextInputField name="minScore" />
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex">
            <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize" mt={1}>
              Max Score: &nbsp;
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              <TextInputField name="maxScore" />
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex">
            <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize" mt={0.5}>
              Active: &nbsp;
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text" ml={5}>
              <SwitchField name="active" />
            </SoftTypography>
          </SoftBox>

          <SoftButton
            variant="gradient"
            color="info"
            type="submit"
            sx={{ mt: 2 }}
            disabled={isLoading}
            isLoading={isLoading}
          >
            Update
          </SoftButton>
        </SoftBox>
      </FormProvider>
    </Card>
  );
};

export default GeneralSettings;
