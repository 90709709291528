import { useMutation } from "react-query";
import { getTemplatePath, getQueryParam } from "utils/string";
import { toastError, toastSuccess } from "utils/toast";

const { default: request } = require("utils/request");

const GET_REPORT = "/merchants/:merchantId/reports";
const SEND_NOTIFICATION = "/notifications/send/:transactionId";
const REFUND = "/refund/by-id";
const GET_MERCHANTS = "/transactions";

const getReport = async (filterTransactionProps) => {
  const queryParams = getQueryParam(filterTransactionProps);
  if (!filterTransactionProps.merchantId || filterTransactionProps.merchantId == "All") {
    return request.get(`${GET_MERCHANTS}?${queryParams}`);
  }
  const URL = getTemplatePath(GET_REPORT, filterTransactionProps);
  const params = getQueryParam(_.omit(filterTransactionProps, ["merchantId"]));
  return request.get(`${URL}?${params}`);
};
const sendNotification = ({ transactionId }) => {
  const URL = getTemplatePath(SEND_NOTIFICATION, { transactionId });
  return request.post(URL);
};
const refund = ({ transactionId, amount }) => {
  return request.post(REFUND, { transactionId, amount });
};

export const useGetReporting = () => {
  return useMutation(getReport, {
    onError: (error) => {
      const message = _.get(error, "response.data.message[0]");
      toastError({ description: message });
    },
  });
};

export const useSendNotification = () => {
  return useMutation(sendNotification, {
    onSuccess: () => {
      toastSuccess({ description: "Send notification successfully" });
    },
  });
};

export const useRefund = () => {
  return useMutation(refund, {
    onSuccess: () => {
      toastSuccess({ description: "Send refund successfully" });
    },
  });
};
