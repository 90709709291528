export const statusOptions = [
  { label: "ALL", value: "" },
  { label: "PENDING", value: "PENDING" },
  { label: "PROCESSING", value: "PROCESSING" },
  { label: "MISSING_FROM_PSP", value: "MISSING_FROM_PSP" },
  { label: "MISSING_FROM_DB", value: "MISSING_FROM_DB" },
  { label: "RECONCILED", value: "RECONCILED" },
  { label: "STATUS_MISMATCH", value: "STATUS_MISMATCH" },
  { label: "AMOUNT_MISMATCH", value: "AMOUNT_MISMATCH" },
];

export const localFieldOptions = [
  {
    label: "idField",
    value: "idField",
  },
  {
    label: "paymentAmount",
    value: "paymentAmount",
  },
  {
    label: "currency",
    value: "currency",
  },
  {
    label: "status",
    value: "status",
  },
  {
    label: "usdAmount",
    value: "usdAmount",
  },
];

export const statusColorEnum = {
  PENDING: "secondary",
  PROCESSING: "warning",
  MISSING_FROM_PSP: "error",
  MISSING_FROM_DB: "error",
  RECONCILED: "success",
  STATUS_MISMATCH: "error",
  AMOUNT_MISMATCH: "error",
};
