export const dateTimePickerOptions = () => {
  const now = new Date();
  now.setHours(23, 59, 59, 999);

  return {
    allowInput: false,
    enableTime: true,
    maxDate: now,
    time_24hr: true,
    defaultHour: "0",
  };
};

export const isoDateTimePickerOptions = () => {
  return {
    ...dateTimePickerOptions(),
    dateFormat: "Z",
    altInput: true,
    altFormat: "Z",
  };
};
