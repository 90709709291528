import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import {
  CountrySelect,
  PaymentOptionSelect,
  SelectInputField,
  TextInputField,
} from "components/FormField";
import SoftBox from "components/SoftBox";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useGetPspMid } from "pages/psp-details/hooks";

const TemplateForm = ({ paymentOptions, pmTemplates, psps, isUpdateForm }) => {
  const { watch } = useFormContext();
  const watchPsp = watch("pspId");
  //hooks
  const { data: mids } = useGetPspMid(watchPsp);

  // options
  const pspOptions = useMemo(() => {
    return _.map(psps, (item) => ({
      label: item.name,
      value: item._id,
    }));
  }, [psps]);
  const midOptions = useMemo(() => {
    return _.map(mids, (item) => ({
      label: item.name,
      value: item._id,
    }));
  }, [mids]);

  const pmTemplateOptions = useMemo(() => {
    return _.map(pmTemplates, (item) => ({
      label: item.name,
      value: item._id,
    }));
  }, [pmTemplates]);

  return (
    <SoftBox>
      <Grid container spacing={3}>
        {!isUpdateForm && (
          <Grid item xs={12} sm={6}>
            <SelectInputField
              label="PSP"
              name="pspId"
              options={pspOptions}
              menuPosition={"fixed"}
              readOnly={isUpdateForm}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={!isUpdateForm ? 6 : 12}>
          <SelectInputField
            label="MID"
            name="pspMidId"
            options={midOptions}
            menuPosition={"fixed"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Name" name="name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PaymentOptionSelect
            label="Payment Option"
            name="paymentOptionId"
            paymentOptions={paymentOptions}
            removeSelectAll={true}
            menuPosition={"fixed"}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SelectInputField
            label="Method Template"
            name="methodTemplateId"
            options={pmTemplateOptions}
            menuPosition={"fixed"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CountrySelect
            label="Supported Countries"
            name="supportedCountries"
            isMulti
            useAll={true}
            menuPosition={"fixed"}
            placeholder=" "
          />
        </Grid>
      </Grid>
    </SoftBox>
  );
};
TemplateForm.propTypes = {
  onCloseSuccess: PropTypes.func,
  paymentOptions: PropTypes.array,
  pmTemplates: PropTypes.array,
  psps: PropTypes.array,
  isUpdateForm: PropTypes.bool,
};
export default TemplateForm;
