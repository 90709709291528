export const sanitizeRequest = ({
  merchantId,
  brandId,
  fromDate,
  toDate,
  limit,
  page,
  sortBy,
  sortDirection,
}) => {
  const identityObj = _.pickBy(
    {
      brandId,
      merchantId,
      fromDate,
      toDate,
      limit,
      page,
      sortBy,
      sortDirection,
    },
    _.identity
  );
  const removeAll = _.pickBy(identityObj, (value) => value !== "All");
  if (!removeAll.merchantId) {
    return _.omit(removeAll, ["brandId"]);
  }

  return removeAll;
};
