import { useState, useEffect } from "react";
import { Card, Collapse, List, ListItem, styled } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import SoftTypography from "components/SoftTypography";
import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import SoftBox from "components/SoftBox";
import { useGetMismatchedTransactionDetail } from "./hooks";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SoftButton from "../../components/SoftButton";
import PropTypes from "prop-types";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.spacing(1),
  borderLeft: `1px dashed ${theme.palette.grey[400]}`,
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),

  ":hover": {
    background: theme.palette.grey[100],
  },
}));

// eslint-disable-next-line react/prop-types
export const TreeNode = ({ name, value, openNodes, setOpenNodes, allData }) => {
  // eslint-disable-next-line react/prop-types
  const isOpen = openNodes.includes(name);
  const [open, setOpen] = useState(isOpen);
  const navigate = useNavigate();
  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (open) {
      setOpenNodes((prevOpenNodes) => [...prevOpenNodes, name]);
    } else {
      setOpenNodes((prevOpenNodes) => prevOpenNodes.filter((nodeName) => nodeName !== name));
    }
  }, [name, open, setOpenNodes]);

  if (value === null || typeof value === "undefined") {
    return null;
  }

  if (typeof value === "string" || typeof value === "number") {
    let link;
    if (_.startCase(name) === "Merchant" && allData.merchantId) {
      link = `/merchants/${allData.merchantId}?tab=profile`;
    } else if (_.startCase(name) === "Brand" && allData.merchantId && allData.brandId) {
      link = `/merchants/${allData.merchantId}/brands/${allData.brandId}`;
    } else if (_.startCase(name) === "Mid" && allData.pspId && allData.midId) {
      link = `/psps/${allData.pspId}/mid/${allData.midId}`;
    } else if (_.startCase(name) === "Provider" && allData.pspId) {
      link = `/psps/${allData.pspId}`;
    } else if (_.startCase(name) === "Email" && allData.customer) {
      link = `/transactions?email=${encodeURIComponent(allData.customer.email)}&merchantId=${
        allData.merchantId
      }`;
    } else if (_.startCase(name) === "Pan" && allData.cardData.pan) {
      link = `/transactions?pan=${allData.cardData.pan}&merchantId=${allData.merchantId}`;
    }

    return (
      <StyledListItem>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="dark"
          width="100%"
          sx={{
            cursor: link ? "pointer" : "auto",
            "&:hover": link
              ? {
                  textDecoration: "underline",
                }
              : "auto",
          }}
          gap={2}
          onClick={() => {
            if (link) navigate(link);
          }}
        >
          <SoftTypography variant="body2">{_.startCase(name)}</SoftTypography>
          <SoftTypography variant="body2" color="secondary">
            {value}
          </SoftTypography>
        </SoftBox>
      </StyledListItem>
    );
  }

  const children = Object.entries(value).map(([childName, childValue]) => (
    <TreeNode
      key={childName}
      name={childName}
      value={childValue}
      allData={allData}
      openNodes={openNodes}
      setOpenNodes={setOpenNodes}
    />
  ));

  return (
    <>
      <StyledListItem button onClick={handleClick}>
        <SoftTypography variant="body2" color="info" textGradient>
          {_.startCase(name)}
        </SoftTypography>
      </StyledListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ pl: 2 }}>
          {children}
        </List>
      </Collapse>
    </>
  );
};

TreeNode.propTypes = {
  allData: PropTypes.object,
};
// eslint-disable-next-line react/prop-types
const TreeView = ({ data }) => {
  const [openNodes, setOpenNodes] = useState([
    "MismatchedTransactionDetail",
    "providerResponse",
    "data",
  ]);

  return (
    <List component="nav">
      <TreeNode
        name="MismatchedTransactionDetail"
        value={data}
        openNodes={openNodes}
        setOpenNodes={setOpenNodes}
      />
    </List>
  );
};

const MismatchedTransactionDetail = () => {
  if (!hasPermission(Permissions.MISMATCHED_TRANSACTION.GET)) {
    return (
      <MainLayout>
        <MainNavbar />
        <Error403 />{" "}
      </MainLayout>
    );
  }
  const { id } = useParams();
  const { data } = useGetMismatchedTransactionDetail(id);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <MainLayout>
      <MainNavbar />
      <Card>
        <SoftBox p={4}>
          <TreeView data={data || {}} />
        </SoftBox>
        <SoftButton variant="gradient" onClick={handleGoBack}>
          <ArrowBackIosIcon />
          Back
        </SoftButton>
      </Card>
    </MainLayout>
  );
};

export default MismatchedTransactionDetail;
