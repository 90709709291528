import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, AppBar, Tabs, Tab } from "@mui/material";

import SoftBox from "components/SoftBox";

import { a11yProps } from "components/TabPanel";
import { PaymentMethodTypeEnum } from "constants/tabs";

const PaymentMethodTypeNav = ({ onChange }) => {
  const [paymentMethodType, setPaymentMethodType] = useState(PaymentMethodTypeEnum.Payin);

  const continents = Object.keys(PaymentMethodTypeEnum);
  const handleValueChange = (event, value) => {
    setPaymentMethodType(value);
    onChange(value);
  };
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} ml={3}>
        <SoftBox>
          <AppBar position="static">
            <Tabs
              value={paymentMethodType}
              onChange={handleValueChange}
              aria-label="basic tabs example"
              sx={{ backgroundColor: "#f7f8f9" }}
              variant="standard"
            >
              {_.map(continents, (c) => {
                return <Tab label={c} {...a11yProps(_.get(PaymentMethodTypeEnum, c))} />;
              })}
            </Tabs>
          </AppBar>
        </SoftBox>
      </Grid>
    </Grid>
  );
};
PaymentMethodTypeNav.defaultProps = {
  onChange: () => {},
};
PaymentMethodTypeNav.propTypes = {
  onChange: PropTypes.func,
};
export default PaymentMethodTypeNav;
