import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";

import { TableRow, Table, TableCell, TableBody } from "@mui/material";

import DataTableHeadCell from "../../../examples/Tables/DataTable/DataTableHeadCell";
import { useEditPspMid } from "../../brand-detail/hooks";
import DeleteFallback from "./DeleteFallback";
import NoData from "components/NoData";
const DragHandle = sortableHandle(() => <span style={{ cursor: "grab" }}>::</span>);

// Sortable item (table row)
const SortableItem = sortableElement(({ value, pspMid, setItems, items }) => (
  <TableRow sx={{ width: "100%" }}>
    <TableCell width={40}>
      <DragHandle />
    </TableCell>
    <TableCell width={40}>{value.position}</TableCell>
    <TableCell width={180}>{value.psp}</TableCell>
    <TableCell>{value.mid}</TableCell>
    <TableCell>
      <DeleteFallback fallback={value} pspMidInfo={pspMid} setItems={setItems} items={items} />
    </TableCell>
  </TableRow>
));

// Sortable container (table)
const SortableTable = sortableContainer(({ children }) => {
  return <TableBody>{children}</TableBody>;
});

function RowOrderingGrid({ pspMid, fallbackItems, setFallbacksItems }) {
  const { _id: id, payinEnabled, payoutEnabled } = pspMid;
  const { id: merchantId, brandId } = useParams();
  const { mutate } = useEditPspMid();
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setFallbacksItems((currentItems) => arrayMoveImmutable(currentItems, oldIndex, newIndex));
    mutate(
      {
        merchantId,
        brandId,
        pspMidId: id,
        payinEnabled,
        payoutEnabled,
        fallbacks: arrayMoveImmutable(fallbackItems, oldIndex, newIndex),
      },
      {
        onSuccess: (res) => {
          setFallbacksItems(res?.fallbacks);
        },
      }
    );
  };

  return fallbackItems?.length > 0 ? (
    <Table>
      <DataTableHeadCell width={40} sorted={false} />
      <DataTableHeadCell width={40} sorted={false}>
        №
      </DataTableHeadCell>
      <DataTableHeadCell width={180}>PSP</DataTableHeadCell>
      <DataTableHeadCell>MID</DataTableHeadCell>
      <DataTableHeadCell>Action</DataTableHeadCell>
      <SortableTable onSortEnd={onSortEnd} useDragHandle>
        {fallbackItems?.map((value, index) => (
          <SortableItem
            key={`item-${value.pspId + value.position}`}
            index={index}
            value={value}
            pspMid={pspMid}
            setItems={setFallbacksItems}
            items={fallbackItems}
          />
        ))}
      </SortableTable>
    </Table>
  ) : (
    <NoData message="There are no fallbacks" />
  );
}

export default RowOrderingGrid;

RowOrderingGrid.propTypes = {
  pspMid: PropTypes.object,
  fallbackItems: PropTypes.array.isRequired,
  setFallbacksItems: PropTypes.func.isRequired,
};
