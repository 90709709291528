import PropTypes from "prop-types";
import _ from "lodash";

import { Card } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { countries } from "../../../constants/countries";

const Address = ({ item }) => {
  if (!item) return null;
  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox component="li" display="flex" alignItems="center" p={1}>
        <SoftBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <SoftTypography variant="caption">
            <b>Country:</b> &nbsp;{countries.find((i) => i.code === item?.country)?.label}
          </SoftTypography>
          <SoftTypography variant="caption">
            <b>City:</b> &nbsp;{_.get(item, "city", "N/A")}
          </SoftTypography>
          <SoftTypography variant="caption">
            <b>Address Line:</b> &nbsp;{_.get(item, "addressLine", "N/A")}
          </SoftTypography>
          <SoftTypography variant="caption">
            <b>Post Code:</b> &nbsp;{_.get(item, "postCode", "N/A")}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </Card>
  );
};

Address.defaultProps = {
  item: {},
};

// Typechecking props of the Info
Address.propTypes = {
  item: PropTypes.object,
};
export default Address;
