import styled from "@emotion/styled";
import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { SelectInputField, SwitchField } from "components/FormField";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { useDisclosure } from "hooks/useDisclosure";
import { useAssignPspMidToBrand } from "pages/brand-detail/hooks";
import { useGetPspMid } from "pages/psp-details/hooks";
import { useGetPsps } from "pages/psp-management/hooks";
import FormProvider from "components/FormProviders";

import {
  assignMidDefaultValues as defaultValues,
  assignMidSchema,
} from "pages/brand-detail/schemas/pspMid";
import { yupResolver } from "@hookform/resolvers/yup";

const AssignPspMidToBrand = ({ brandId }) => {
  const { id: merchantId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();

  // form
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(assignMidSchema),
  });
  const { handleSubmit, watch, reset } = methods;

  const psp = watch("psp");
  const pspMid = watch("pspMid");

  // hooks
  const { data: psps } = useGetPsps();
  const { data: pspMids } = useGetPspMid(psp);
  const { mutate, isLoading } = useAssignPspMidToBrand();

  // function
  const handleSubmitForm = ({ pspMid, payinEnabled, payoutEnabled }) => {
    mutate(
      {
        merchantId,
        brandId,
        pspMidId: pspMid,
        payinEnabled: !!payinEnabled,
        payoutEnabled: !!payoutEnabled,
      },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <>
      <SoftButton variant="gradient" color="info" onClick={onOpen}>
        Assign psp mid to brand
      </SoftButton>
      <StyleDialog
        open={isOpen}
        onClose={handleClose}
        sx={{ overflow: "visible" }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Assign psp mid to brand</DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
            <SoftBox py={2}>
              <Stack spacing={1}>
                <SelectInputField
                  name="psp"
                  label="Psp"
                  options={_.map(psps, (item) => ({ label: item.name, value: item._id }))}
                  isClearable
                  placeholder="Select an option"
                />
                <SelectInputField
                  name="pspMid"
                  label="Psp mid"
                  options={_.map(pspMids, (item) => ({ label: item.name, value: item._id }))}
                  isClearable
                  placeholder="Select an option"
                />
                {pspMid && (
                  <SoftBox display="flex" gap={2} px={2} pt={2}>
                    <SwitchField label="Payin Enabled" name="payinEnabled" />
                    <SwitchField label="Payout Enabled" name="payoutEnabled" />
                  </SoftBox>
                )}
              </Stack>
              <Stack direction="row" spacing={1} justifyContent="center" mt={4}>
                <SoftButton onClick={handleClose}>Cancel</SoftButton>
                <SoftButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Assign
                </SoftButton>
              </Stack>
            </SoftBox>
          </FormProvider>
        </DialogContent>
      </StyleDialog>
    </>
  );
};

const StyleDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    overflowY: "visible",
  },
  "& .MuiDialogTitle-root": {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
}));

AssignPspMidToBrand.defaultProps = {
  brandId: "",
};

AssignPspMidToBrand.propTypes = {
  brandId: PropTypes.string,
};

export default AssignPspMidToBrand;
