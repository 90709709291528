export const generateOptions = (data, useAll = true, allValue = "") => {
  const options = _.map(data, (item) => ({ value: item._id, label: item.name }));
  if (useAll) {
    return [{ value: allValue, label: "All" }].concat(options);
  }
  return options;
};

export const generateOptionsFromEnum = (enumObj, useAll = true) => {
  const options = _.map(Object.keys(enumObj), (e) => ({ label: e, value: enumObj[e] }));
  if (useAll) {
    return [{ value: "", label: "All" }].concat(options);
  }
  return options;
};

export const generateOptionsFromErrorCodes = (errorCodes) => {
  const options = _.map(errorCodes, ({ code, message }) => ({ value: code, label: message }));

  return [{ value: "", label: "All" }].concat(options);
};

export const generateUserOptions = (users) => {
  const options = _.map(users, (user) => ({ value: user.id, label: user.email }));
  return [{ value: "", label: "All" }].concat(options);
};
