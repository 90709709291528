import { useQuery, useMutation, useQueryClient } from "react-query";
import { toastSuccess } from "utils/toast";
import request from "utils/request";
import { getTemplatePath } from "utils/string";

const ORGANIZATION = "/organizations/:id";

// update
const updateOrganization = ({ id, ...payload }) => {
  const URL = getTemplatePath(ORGANIZATION, { id });
  return request.put(URL, payload);
};
export const useUpdateOrganization = (id) => {
  const queryClient = useQueryClient();
  return useMutation(updateOrganization, {
    onSuccess: () => {
      queryClient.invalidateQueries([ORGANIZATION, id]);
      toastSuccess({ description: "Update organization info has been success" });
    },
  });
};

// delete
const deleteOrganization = ({ id }) => request.delete(getTemplatePath(ORGANIZATION, { id }));

export const useDeleteOrganization = (id) => {
  return useMutation(deleteOrganization, {
    onSuccess: () => {
      toastSuccess({ description: "Organization deleted successfully" });
    },
  });
};

// get
const getOrganization = (id) => {
  const URL = getTemplatePath(ORGANIZATION, { id });
  return request.get(URL);
};
export const useGetOrganization = (id) => {
  return useQuery([ORGANIZATION, id], () => getOrganization(id));
};
