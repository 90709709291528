import * as Yup from "yup";
import { findDefaultCurrency } from "utils/country";
export const defaultValues = {
  paymentOptionId: null,
  payinCountries: [
    {
      code: null,
      currencies: [{ code: "", fxCurrency: "" }],
    },
  ],
  payoutCountries: [],
};
export const schema = Yup.object().shape({
  paymentOptionId: Yup.string().required("Payment Option is required").nullable(),
  payinCountries: Yup.array()
    .of(
      Yup.object().shape({
        code: Yup.array().required("Pay-In country is required").nullable(),
        currencies: Yup.array().of(
          Yup.object().shape({
            code: Yup.string().required("Pay-In currency code is required").nullable(),
            fxCurrency: Yup.string().nullable(),
          })
        ),
      })
    )
    .required("Pay-In country is required"),
  payoutCountries: Yup.array()
    .of(
      Yup.object().shape({
        code: Yup.array().required("Pay-Out country is required").nullable(),
        currencies: Yup.array().of(
          Yup.object().shape({
            code: Yup.string().required("Pay-Out currency code is required").nullable(),
            fxCurrency: Yup.string().nullable(),
          })
        ),
      })
    )
    .nullable(),
});

const sanitizeCurrencies = (country, currencies) => {
  return currencies?.map(({ code, fxCurrency }) => {
    let _fx = fxCurrency;
    let _code = code;
    if (!fxCurrency) {
      _fx = code;
    }
    if (code == "0") {
      _code = findDefaultCurrency(country);
    }
    if (fxCurrency == "0") {
      _fx = findDefaultCurrency(country);
    }
    return _.pickBy({ code: _code, fxCurrency: _fx }, _.identity);
  });
};

const sanitizeGroupCountries = (countries) => {
  const codes = _.get(countries, "code", []);
  const countriesWithCurrencies = _.map(codes, (c) => ({
    code: c,
    currencies: _.uniqWith(sanitizeCurrencies(c, _.get(countries, "currencies", null)), _.isEqual),
  }));
  return countriesWithCurrencies;
};
const sanitizeCountries = (countries) => {
  let countriesSettings = [];
  countries.forEach((group) => {
    countriesSettings.push(...sanitizeGroupCountries(group));
  });
  return countriesSettings;
};
export const sanitizeRequest = ({
  merchantId,
  brandId,
  pspMidId,
  payinCountries,
  payoutCountries,
  paymentOptionId,
  methodId,
}) => {
  return {
    paymentOptionId,
    merchantId,
    brandId,
    pspMidId,
    methodId,
    payinCountries:
      payinCountries.length === 1 && !payinCountries[0].code
        ? []
        : sanitizeCountries(payinCountries),
    payoutCountries:
      payoutCountries.length === 1 && !payoutCountries[0].code
        ? []
        : sanitizeCountries(payoutCountries),
  };
};

export const sanitizeUpdateCurrenciesRequest = ({
  merchantId,
  brandId,
  pspMidId,
  payinCountries,
  payoutCountries,
  paymentOptionId,
  methodId,
}) => {
  return {
    paymentOptionId,
    merchantId,
    brandId,
    pspMidId,
    methodId,
    payinCountries:
      payinCountries.length === 1 && !payinCountries[0].code
        ? []
        : payinCountries?.map((country) => ({
            code: country.code,
            currencies: _.map(country.currencies, ({ code, fxCurrency }) =>
              _.pickBy({ code, fxCurrency }, _.identity)
            ),
          })),
    payoutCountries:
      payoutCountries.length === 1 && !payoutCountries[0].code
        ? []
        : payoutCountries.map((country) => ({
            code: country.code,
            currencies: _.map(country.currencies, ({ code, fxCurrency }) =>
              _.pickBy({ code, fxCurrency }, _.identity)
            ),
          })),
  };
};
