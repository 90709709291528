import React, { useEffect } from "react";

import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";

import { useForm } from "react-hook-form";

import SoftBox from "../../../components/SoftBox";

import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema, sanitizeRequest } from "../users/schemas/user";
import { useDisclosure } from "hooks/useDisclosure";
import { useGetUserRoles, useUpdateAdminUser } from "../users/hooks";
import { LoginTypes } from "constants/roles";
import { TextInputField, MultipleSelectField } from "components/FormField";
import { generateOptions } from "utils/options";
import { ModalWrapper } from "components/Modal";

const EditUserModal = ({ email, name, userId, currentRoles }) => {
  const modalState = useDisclosure();
  const { onClose } = modalState;
  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit, reset } = methods;

  //hooks
  const { isLoading, mutate } = useUpdateAdminUser();
  const { data: roles } = useGetUserRoles({ loginType: LoginTypes.ADMIN });
  const rolesOptions = generateOptions(roles, false);

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    mutate(sanitizeRequest({ ...values, id: userId }), {
      onSuccess: () => {
        onClose();
      },
    });
  };

  useEffect(() => {
    const rolesStr = _.map(currentRoles, (r) => r._id);
    reset({
      email,
      name,
      roles: rolesStr,
    });
  }, [email, name, userId, currentRoles]);

  return (
    <ModalWrapper
      title="Update User"
      modalState={modalState}
      isLoading={isLoading}
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      maxWidth="lg"
      minHeight={"300px"}
    >
      <SoftBox p={3} pb={1}>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextInputField label="Name" name="name" disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInputField label="Email" name="email" disabled />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MultipleSelectField
                name="roles"
                label="Roles"
                options={rolesOptions}
                placeholder="Select roles"
                maxMenuHeight={80}
              />
            </Grid>
          </Grid>
        </Box>
      </SoftBox>
    </ModalWrapper>
  );
};
EditUserModal.propTypes = {
  email: PropTypes.string,
  name: PropTypes.string,
  currentRoles: PropTypes.array,
  userId: PropTypes.string,
};
export default EditUserModal;
