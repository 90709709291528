import PropTypes from "prop-types";

import Checkbox from "@mui/material/Checkbox";

import SoftBox from "components/SoftBox";

function TableSelectBox({ transaction, checked, onChange: onCheckChange }) {
  const onChange = (e) => {
    onCheckChange(e.target.checked, transaction);
  };
  return (
    <SoftBox display="flex" alignItems="center">
      <Checkbox onChange={onChange} checked={checked} sx={{ ml: 2 }} />
    </SoftBox>
  );
}

TableSelectBox.propTypes = {
  transaction: PropTypes.object,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default TableSelectBox;
