import _ from "lodash";
import { currencies } from "constants/currencies";
export const getTemplatePath = (url, params) => {
  // Set the placeholder syntax to match the ":" syntax
  _.templateSettings.interpolate = /:([\w]+)/g;

  const compiled = _.template(url);
  const result = compiled(params);
  return result;
};

export const shortApiKey = (value = "") => {
  return shortText(value);
};

export const shortText = (value = "", textNum = 10) => {
  if (value.length < textNum) {
    return value;
  }
  return value ? `${value.slice(0, textNum)}...` : "-";
};

export const getQueryParam = (obj) => {
  // Remove empty keys from the object
  const queryParams = _.toPairs(obj)
    .map(([key, value]) => {
      if (!value) {
        return "";
      }
      if (key === "toDate" || key === "fromDate") {
        const tempValue = typeof value === "string" ? value : value?.toISOString();
        return `${key}=${tempValue}`;
      }
      if (_.isArray(value)) {
        const tempValue = value.map((v) => `${key}=${v}`).join("&");
        return tempValue;
      }
      return `${key}=${value}`;
    })
    .join("&");
  return queryParams;
};

export const getSymbolFromCurrency = (currencyCode) => {
  const currency = _.find(currencies, { code: currencyCode });
  return _.get(currency, "symbol", currencyCode);
};
