import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import InternalErrorForm from "./InternalErrorForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { addInternalErrorSchema } from "../schemas";
import { useEditError } from "../hooks";
import { useDisclosure } from "hooks/useDisclosure";
import { ModalWrapper } from "components/Modal";

const EditInternalError = ({ error }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const methods = useForm({ resolver: yupResolver(addInternalErrorSchema) });
  const { handleSubmit, reset } = methods;

  const { mutate: editError, isLoading } = useEditError();

  const handleSubmitForm = (values) => {
    editError(values, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  useEffect(() => {
    reset(error);
  }, []);

  return (
    <ModalWrapper
      methods={methods}
      onSubmit={handleSubmit(handleSubmitForm)}
      maxWidth="lg"
      title="Update Internal Error"
      isLoading={isLoading}
      modalState={{ isOpen, onClose, onOpen }}
    >
      <InternalErrorForm />
    </ModalWrapper>
  );
};

EditInternalError.propTypes = {
  error: PropTypes.object.isRequired,
};

export default EditInternalError;
