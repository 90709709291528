import _ from "lodash";

export const handleDownloadClick = async (
  { ref },
  savedPayload,
  sanitizeRequest,
  data,
  downloadMutate,
  setDataToDownload,
  selectedColumns
) => {
  const visibleColumnsObj = selectedColumns.filter((column) => !column.hide);
  const visibleColumnsName = visibleColumnsObj.map((column) => column.accessor);
  // eslint-disable-next-line no-unused-vars
  return new Promise(function (resolve, reject) {
    // eslint-disable-next-line no-unused-vars
    const { limit, page, ...other } = savedPayload;
    const payload = {
      ...savedPayload,
      limit: data ? _.get(data, "pagination.totalDocs", 0) : Number(1000000000000),
    };
    downloadMutate(sanitizeRequest ? sanitizeRequest(payload) : payload, {
      onSuccess: async (data) => {
        const dataToDownload = _.map(data, (item) => {
          return _.pick(item, visibleColumnsName);
        });
        setDataToDownload(dataToDownload || []);
        await setTimeout(() => {
          ref.current.link.click();
          resolve();
        }, 3000);
      },
    });
  });
};
