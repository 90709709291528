import { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import PropTypes from "prop-types";

import { Stepper, Step, StepLabel } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { MessageDetail } from "./ErrorItem";
import { StatusColorEnum } from "constants/status";
import { shortText } from "utils/string";
function StepperItemCell({ code, message, status, psp }) {
  const color = _.get(StatusColorEnum, status, "info");

  return (
    <SoftBox pl={1} ml={2}>
      <SoftBox ml={-3}>
        {code && (
          <SoftBox
            ml={{ xs: 0, sm: 3 }}
            mb={{ xs: 1, sm: 0 }}
            lineHeight={1}
            sx={{ width: "35vw" }}
          >
            <SoftTypography variant="button" fontWeight="bold" sx={{ width: "20vw" }}>
              {`${code} - ${shortText(message, 20)}`}
            </SoftTypography>
          </SoftBox>
        )}
        {status && <MessageDetail label="Status" value={status} color={color} />}
        {psp && <MessageDetail label="Psp" value={psp} />}
      </SoftBox>
    </SoftBox>
  );
}

StepperItemCell.defaultProps = {
  color: "info",
  noDivider: false,
};

StepperItemCell.propTypes = {
  code: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  status: PropTypes.string,
  psp: PropTypes.string,
};
const ErrorStepper = forwardRef(({ internalError, providerError }, ref) => {
  const [step, setStep] = useState(-1);
  function isErrorCode(value) {
    return /^\d+$/.test(value);
  }
  useEffect(() => {
    if (isErrorCode(internalError?.code)) {
      setStep(0);
    }
    if (isErrorCode(providerError?.code)) {
      setStep(1);
    }
  }, [internalError, providerError]);

  useImperativeHandle(ref, () => ({
    resetStep() {
      setStep(0);
    },
  }));

  return (
    <Stepper activeStep={step} alternativeLabel sx={{ height: "1vw" }}>
      <Step key={1} sx={{ display: "flex-end" }}>
        <StepLabel>
          {internalError && (
            <StepperItemCell
              code={internalError.code}
              message={internalError.message}
              status={internalError.status}
            />
          )}
          {!internalError && <p>Select internal error</p>}
        </StepLabel>
      </Step>
      <Step key={2}>
        <StepLabel>
          {providerError && (
            <StepperItemCell
              code={providerError.code}
              psp={providerError.psp}
              message={providerError.message}
            />
          )}
          {!providerError && <p>Select provider error</p>}
        </StepLabel>
      </Step>
    </Stepper>
  );
});
ErrorStepper.propTypes = {
  internalError: PropTypes.object.isRequired,
  providerError: PropTypes.object.isRequired,
};
export default ErrorStepper;
