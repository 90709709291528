import { useState } from "react";
import { useNavigate } from "react-router-dom";

import _ from "lodash";
import { useParams } from "react-router-dom";

import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { useGetPspMid } from "../hooks";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";
import AddMidModal from "./AddMidModal";
import DataTable from "examples/Tables/DataTable";
import SoftBadgeDot from "components/SoftBadgeDot";

const Mids = () => {
  const { id } = useParams();
  if (!hasPermission(Permissions.PSP_MID.LIST)) {
    return <Error403 />;
  }
  const navigate = useNavigate();
  const { data: pspMids } = useGetPspMid(id);
  const [addModal, setAddModal] = useState(false);

  const handleOpenAddModal = () => {
    setAddModal(true);
  };
  const handleCloseAddModal = () => {
    setAddModal(false);
  };
  const getRows = () => {
    return _.map(pspMids, (item) => {
      return {
        id: item._id,
        psp: item.psp,
        name: item.name,
        mid: item.mid,
        active: <SoftBadgeDot color={item.active ? "success" : "light"} size="md" />,
        monthlyUsdLimit: item.monthlyUsdLimit,
        dailyUsdLimit: item.dailyUsdLimit,
        minSuccessTx: item.minSuccessTx,
        hsfst: item.hsfst,
        velocities: _.get(item, "velocities", []),
        allowCardTypes: _.get(item, "allowCardTypes", []).join(","),
        disallowCardTypes: _.get(item, "disallowCardTypes", []).join(","),
        default: <SoftBadgeDot color={item.default ? "success" : "light"} size="md" />,
      };
    });
  };

  const onCellClick = (row) => {
    navigate(`/psps/${id}/mid/${row.original.id}`);
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox pt={2} px={2}>
        <Stack spacing={1} direction="row" justifyContent="space-between">
          <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Mids
          </SoftTypography>
          <SoftButton variant="gradient" color="info" size="small" onClick={handleOpenAddModal}>
            Create Mid
          </SoftButton>
        </Stack>
      </SoftBox>
      <SoftBox p={2}>
        <DataTable
          table={{
            columns: [
              { Header: "ID", accessor: "id" },
              { Header: "Name", accessor: "name" },
              { Header: "Mid", accessor: "mid" },
              { Header: "Default", accessor: "default" },
              { Header: "Active", accessor: "active" },
              { Header: "Monthly Usd Limit", accessor: "monthlyUsdLimit", hide: true },
              { Header: "Daily Usd Limit", accessor: "dailyUsdLimit", hide: true },
              { Header: "Min Success Tx", accessor: "minSuccessTx", hide: true },
              { Header: "Hsfst", accessor: "hsfst", hide: true },
              { Header: "Velocities", accessor: "velocities", hide: true },
              { Header: "Allow Card Types", accessor: "allowCardTypes", hide: true },
              { Header: "Disallow Card Types", accessor: "disallowCardTypes", hide: true },
            ],
            rows: getRows(),
          }}
          entriesPerPage={{
            defaultValue: 25,
            entries: [25, 50, 200, 500],
          }}
          onCellClick={onCellClick}
          onCellAuxClick={onCellClick}
          canSearch={true}
          columnsFilter={true}
          isClickable={true}
        />
      </SoftBox>
      <AddMidModal isOpen={addModal} onClose={handleCloseAddModal} pspId={id} />
    </Card>
  );
};

export default Mids;
