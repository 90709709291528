import { useState } from "react";

import _ from "lodash";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import DataTable from "examples/Tables/DataTable";
import UserLogsFilters from "./components/UserLogsFilters";
import { useForm } from "react-hook-form";
import FormProvider from "components/FormProviders";
import { set, get } from "utils/localStorage";
import { Chip, Stack } from "@mui/material";
import { sanitizeRequest } from "./schemas";
import { updateSelectedFilters } from "utils/filters";
import { useGetUserLogs } from "./hooks";
import { fDateTimeSuffix } from "utils/formatTime";
import JsonView from "pages/transaction-detail/components/JsonView";
import SoftBox from "components/SoftBox";

const LC_PAYLOAD = "userLogs";

const UserLogs = () => {
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedFilters, setSelectedFilters] = useState({});
  // Destructuring the object of all selected filters to obtain only the necessary
  const filters = _.omit(selectedFilters, ["page", "limit"]);
  const { mutate, isLoading, data } = useGetUserLogs();

  const userLogsPayload = get(LC_PAYLOAD) || {};

  const methods = useForm({ userLogsPayload });
  const { handleSubmit } = methods;

  // create a function that returns the rows for the table
  const getRows = () => {
    return _.map(_.get(data, "docs", []), (item) => ({
      id: item.id,
      requestId: item.requestId,
      userId: item.userId,
      endpoint: (
        <SoftBox component="div" flexWrap="wrap" wordWrap="break-word" sx={{ maxWidth: "400px" }}>
          {item.endpoint}
        </SoftBox>
      ),
      method: item.method,
      payload: <JsonView data={item.payload} collapsed={true} />,
      createdAt: fDateTimeSuffix(item.createdAt),
      userEmail: _.get(item, "userEmail", ""),
      controller: _.get(item, "controller", ""),
      action: _.get(item, "action", ""),
    }));
  };

  // create a function that handles the form submission
  const onSubmit = (values) => {
    const payload = sanitizeRequest(values);
    setCurrentPage(1);
    set(LC_PAYLOAD, payload);
    payload.limit = pageSize;
    mutate(payload);
    updateSelectedFilters(values, setSelectedFilters, {});
  };

  // create a function that handles the page change
  const handlePageChange = (page) => {
    setCurrentPage(page + 1);
    const payload = userLogsPayload;
    payload.page = page + 1;
    payload.limit = pageSize;
    set(LC_PAYLOAD, payload);
    mutate(sanitizeRequest(payload));
  };
  // create a function that handles the page size change
  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    const payload = userLogsPayload;
    payload.page = 1;
    payload.limit = pageSize;
    set(LC_PAYLOAD, payload);
    mutate(sanitizeRequest(payload));
  };

  return (
    <MainLayout>
      <MainNavbar />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <UserLogsFilters onSubmit={handleSubmit(onSubmit)} />
      </FormProvider>
      <Stack
        direction="row"
        spacing={1}
        sx={{ mt: 2, mb: 2 }}
        display="flex"
        flexWrap="wrap"
        gap={1}
      >
        {Object.values(filters)?.map((i, index) =>
          i ? <Chip key={i + index} label={i} color="secondary" textColor="white" /> : null
        )}
      </Stack>
      <DataTable
        table={{
          columns: [
            { Header: "ID", accessor: "id" },
            { Header: "Request Id", accessor: "requestId" },
            { Header: "User Id", accessor: "userId" },
            { Header: "User Email", accessor: "userEmail" },
            { Header: "Controller", accessor: "controller" },
            { Header: "Action", accessor: "action" },
            { Header: "Endpoint", accessor: "endpoint" },
            { Header: "Method", accessor: "method" },
            { Header: "Payload", accessor: "payload" },
            { Header: "created time", accessor: "createdAt" },
          ],
          rows: getRows(),
        }}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        pageCount={_.get(data, "totalPages", 0)}
        manualPagination={true}
        rowCount={_.get(data, "totalDocs", 0)}
        isLoading={isLoading}
        currentPage={currentPage}
        columnsFilter={true}
      />
    </MainLayout>
  );
};

export default UserLogs;
