import { Grid } from "@mui/material";
import React from "react";

import { TextInputField, SelectInputField, MultipleSelectField } from "components/FormField";
import { StatusEnum } from "constants/status";
import { generateOptionsFromEnum } from "utils/options";
import { ErrorAction, ErrorCategory } from "constants/errorCodes";

const InternalErrorForm = () => {
  const statusOptions = _.map(StatusEnum, (item) => ({ value: item, label: item }));
  const categoryOptions = generateOptionsFromEnum(ErrorCategory, false);
  const errorActionOptions = generateOptionsFromEnum(ErrorAction, false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextInputField name="code" label="Error code" />
      </Grid>
      <Grid item xs={6}>
        <SelectInputField
          name="status"
          label="Status Code"
          options={statusOptions}
          placeholder="Select an status"
          maxMenuHeight={120}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInputField
          name="category"
          label="Category"
          options={categoryOptions}
          placeholder="Select an category"
          maxMenuHeight={120}
        />
      </Grid>
      <Grid item xs={6}>
        <MultipleSelectField name="actions" label="Actions" options={errorActionOptions} />
      </Grid>

      <Grid item xs={6}>
        <TextInputField name="message" label="Message" />
      </Grid>
      <Grid item xs={6}>
        <TextInputField name="description" label="Description" />
      </Grid>
    </Grid>
  );
};

export default InternalErrorForm;
