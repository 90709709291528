import { useState } from "react";

export const useDisclosure = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = () => {
    setIsOpen((current) => !current);
  };

  const onOpen = () => setIsOpen(true);

  const onClose = () => setIsOpen(false);

  return {
    isOpen,
    onToggle,
    onOpen,
    onClose,
  };
};
