import { Dialog, DialogContent, DialogTitle, Divider, Grid, Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { useDisclosure } from "hooks/useDisclosure";

import FormProvider from "components/FormProviders";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { useCreateVirtualAccount } from "pages/brand-detail/hooks";

import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema } from "pages/brand-detail/schemas/virtualAccount";
import { useGetCredentials } from "../../../../psp-mid-details/hooks";
import { countriesCode } from "../../../../../constants/countriesCode";
import { SelectInputField, TextInputField } from "../../../../../components/FormField";

const AddVirtualAccount = ({ pspMidId, merchantId, brandId }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { data } = useGetCredentials({
    pspId: "0ea3bc80-eae0-4567-89fb-64ac7faee7f8",
    midId: pspMidId,
  });

  // query
  const { mutate, isLoading } = useCreateVirtualAccount({ merchantId, brandId });

  // hook form
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, setValue, reset } = methods;

  // function
  const handleSubmitForm = (values) => {
    mutate(
      {
        merchantId,
        brandId,
        ...values,
      },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };
  const handleClose = () => {
    setValue("accountNumber", []);
    setValue("documentId", []);
    setValue("country", []);
    reset();
    onClose();
  };

  return (
    <>
      <SoftButton variant="gradient" color="info" onClick={onOpen}>
        Add Virtual Account
      </SoftButton>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        sx={{ overflow: "visible" }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Add Virtual Account</DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
            <SoftBox py={2}>
              <div>
                <Grid container spacing={3} zIndex={100} position={"relative"}>
                  <Grid item sx={12} width={"100%"}>
                    <SelectInputField
                      name="accountNumber"
                      label="Account Number"
                      options={_.map(data?.data?.accounts, (option) => ({
                        label: `${option.accountCode} - ${option.accountNumber}`,
                        value: `${option.accountNumber}`,
                      }))}
                      placeholder="Select an option"
                      onSelect={(val) => {
                        setValue(
                          "country",
                          data?.data?.accounts.find((acc) => acc.accountNumber === val.value)
                            ?.country
                        );
                      }}
                    />
                  </Grid>
                  <Grid item sx={12} width={"100%"}>
                    <SelectInputField
                      name="country"
                      label="Country"
                      options={_.map(data?.data?.accounts, (option) => ({
                        label: `${
                          countriesCode.find((country) => country.code === option.country)?.label
                        }`,
                        value: `${option.country}`,
                      }))}
                      placeholder="Select an option"
                      onSelect={(val) => {
                        setValue(
                          "accountNumber",
                          data?.data?.accounts.find((acc) => acc.country === val.value)
                            ?.accountNumber
                        );
                      }}
                    />
                  </Grid>
                  <Grid item sx={12} width={"100%"}>
                    <TextInputField label="Document ID" name="documentId" />
                  </Grid>
                </Grid>
                <Divider />
              </div>
              <Stack direction="row" spacing={1} justifyContent="center" mt={4}>
                <SoftButton onClick={handleClose}>Cancel</SoftButton>
                <SoftButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Add
                </SoftButton>
              </Stack>
            </SoftBox>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

AddVirtualAccount.propTypes = {
  pspMidId: PropTypes.string.isRequired,
  merchantId: PropTypes.string.isRequired,
  brandId: PropTypes.string.isRequired,
};

export default AddVirtualAccount;
