import React, { useState } from "react";

import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useForm } from "react-hook-form";

import SoftButton from "../../../components/SoftButton";
import SoftBox from "../../../components/SoftBox";

import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema } from "../users/schemas/merchant";
import { useCreateManageUser } from "../users/hooks";
import FormProvider from "../../../components/FormProviders";
import ManageUserForm from "./ManageUserForm";
import ButtonSet from "../../../components/ButtonSet";

const AddManageUserModal = () => {
  const [isOpenAddMerchantModal, setIsOpenAddMerchantModal] = useState(false);
  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit } = methods;

  //hooks
  const { isLoading, mutate } = useCreateManageUser();

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    const { code, ...val } = values;
    mutate(
      { ...val, phone: code + val.phone },
      {
        onSuccess: () => {
          handleCloseAddMerchantModal();
        },
      }
    );
  };
  const handleOpenAddMerchantModal = () => {
    setIsOpenAddMerchantModal(true);
  };
  const handleCloseAddMerchantModal = () => {
    setIsOpenAddMerchantModal(false);
  };

  return (
    <>
      <SoftBox pt={2} px={2} mb={3}>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleOpenAddMerchantModal}
          >
            Add User
          </SoftButton>
        </Stack>
      </SoftBox>
      <Dialog
        open={isOpenAddMerchantModal}
        onClose={handleCloseAddMerchantModal}
        fullWidth
        maxWidth="lg"
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Create New User
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3} pb={1}>
              <ManageUserForm />
            </SoftBox>
          </DialogContent>
          <ButtonSet
            onClose={handleCloseAddMerchantModal}
            isLoading={isLoading}
            title={"Create User"}
          />
        </FormProvider>
      </Dialog>
    </>
  );
};

export default AddManageUserModal;
