import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Card, Grid } from "@mui/material";

import SoftBox from "components/SoftBox";
import { useForm } from "react-hook-form";

import FormProvider from "components/FormProviders";

import { useGetPsps } from "pages/psp-management/hooks";
import { Divider } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import StatusChip from "components/StatusChip";
import { fDateTimeSuffix } from "utils/formatTime";
import { DeleteModal } from "components/Modal";
import { useDeleteReconciliation, useGetReconciliation } from "pages/reconciliation-detail/hooks";
import { statusColorEnum } from "constants/reconciliation";

const ReconciliationOverview = () => {
  const { id } = useParams();
  const { data: reconciliation } = useGetReconciliation(id);
  const { data: psps } = useGetPsps();

  const [foundPsps, setFoundPsps] = useState([]);

  const navigate = useNavigate();

  const methods = useForm();

  const { mutateAsync: deleteReconciliation, isLoading: isDeleting } = useDeleteReconciliation();

  useEffect(() => {
    if (reconciliation && psps) {
      const foundPsps = psps?.filter((psp) => reconciliation?.file?.pspIds?.includes(psp._id));
      const pspsNames = foundPsps?.map((psp) => psp.name).join(", ");
      setFoundPsps(pspsNames);
    }
  }, [reconciliation, psps]);

  return (
    <Card sx={{ p: 2 }}>
      <FormProvider methods={methods}>
        <SoftBox py={1} px={3}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Reconciliation File
              </SoftTypography>
              <SoftBox display="flex" py={1} pr={2} mt={2}>
                <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  ID:
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{reconciliation?.file?._id}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" py={1} pr={2}>
                <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  File Name:
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{reconciliation?.file.filename}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" py={1} pr={2}>
                <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Status:
                </SoftTypography>
                <SoftBox mt={-1}>
                  <StatusChip label={reconciliation?.file.status} sx={{ ml: 1 }} />
                </SoftBox>
              </SoftBox>
              <SoftBox display="flex" py={1} pr={2}>
                <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Psps:
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{foundPsps}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" py={1} pr={2}>
                <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Start Datetime:
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{fDateTimeSuffix(reconciliation?.file?.startDatetime)}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" py={1} pr={2}>
                <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  End Datetime:
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{fDateTimeSuffix(reconciliation?.file?.endDatetime)}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-start" gap={2} mt={4}>
                <DeleteModal
                  type="button"
                  title="Delete Reconciliation"
                  message={
                    <>
                      Are you sure want to delete this reconciliation{" "}
                      <b>{reconciliation?.file?.filename}</b>?
                    </>
                  }
                  isLoading={isDeleting}
                  onDelete={async () => {
                    await deleteReconciliation(id);
                  }}
                  onClose={() => {
                    navigate(`/reconciliation`);
                  }}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={1}>
              <Divider orientation="vertical" flexItem />
            </Grid>
            <Grid item xs={4}>
              <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Statistics
              </SoftTypography>
              <SoftBox display="flex" pr={2} mt={2} flexDirection="column" gap={1}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize">
                      Status:
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={4}>
                    <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize">
                      Count:
                    </SoftTypography>
                  </Grid>
                </Grid>
                {_.get(reconciliation, "stats", [])?.map((stat, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={8}>
                      <StatusChip
                        label={stat?.status}
                        sx={{ ml: -2 }}
                        colorEnum={statusColorEnum}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SoftTypography variant="button" fontWeight="regular" color="text">
                        {stat?.count}
                      </SoftTypography>
                    </Grid>
                  </Grid>
                ))}
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </FormProvider>
    </Card>
  );
};

export default ReconciliationOverview;
