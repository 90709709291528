import SelectInputField from "./SelectInputField";

import PropTypes from "prop-types";
import React from "react";

const TimeRangeInput = ({ name, label, onChange }) => {
  return (
    <SelectInputField
      name={name}
      label={label}
      onSelect={onChange}
      options={[
        { label: "Select", value: "" },
        { label: "Today", value: "Today" },
        { label: "Yesterday", value: "Yesterday" },
        { label: "This week", value: "This week" },
        { label: "Last week", value: "Last week" },
        { label: "This month", value: "This month" },
        { label: "Last month", value: "Last month" },
      ]}
      filterOption={(option) => option.value !== ""}
      placeholder="Select"
      isClearable
    />
  );
};

export default TimeRangeInput;

// Typechecking props of the TimeRangeInput
TimeRangeInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};
