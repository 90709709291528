import * as Yup from "yup";

export const defaultValues = {
  name: null,
  loginType: null,
  permissions: [],
};

export const schema = Yup.object().shape({
  name: Yup.string().required("role name is required"),
  loginType: Yup.string().required("login type is required"),
  permissions: Yup.array(),
});

export const sanitizeRequest = ({ name, loginType, permissions }) => {
  return _.pickBy(
    {
      name,
      loginType,
      permissions,
    },
    _.identity
  );
};
