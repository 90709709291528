import React, { useState, useEffect } from "react";
import _ from "lodash";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import DataTable from "examples/Tables/DataTable";
import FormProvider from "components/FormProviders";
import TransactionFilters from "./components/TransactionFilters";
import { useForm } from "react-hook-form";
import { useGetPsps } from "../psp-management/hooks";
import { useGetPaymentOptions } from "../brand-detail/hooks";

import { useGetMerchantSummary } from "./hooks";

import { set, get } from "utils/localStorage";
import moment from "moment";
import { generateDateRange } from "utils/time";
import { updateSelectedFilters } from "utils/filters";
import { compareFormattedNumber, compareFormattedCurrency } from "utils/number";
import { handleDownloadClick } from "../../utils/downloadTable";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";

const LC_PAYLOAD = "merchantsSummaryPayload";

const MerchantsSummary = () => {
  const downloadAble = hasPermission(
    Permissions.TRANSACTION.GET_TRANSACTIONS_MERCHANTS_SUMMARY_CSV
  );
  const columns = [
    { Header: "Merchant", accessor: "name" },
    { Header: "Total", accessor: "totalTxCount", sortType: compareFormattedNumber },
    { Header: "Payin", accessor: "payinTxCount", sortType: compareFormattedNumber },
    { Header: "Payout", accessor: "payoutTxCount", sortType: compareFormattedNumber },
    { Header: "AR", accessor: "ar" },
    {
      Header: "Payin Completed",
      accessor: "payinCompletedCount",
      sortType: compareFormattedNumber,
    },
    {
      Header: "Payin Processing",
      accessor: "payinProcessingCount",
      sortType: compareFormattedNumber,
    },
    {
      Header: "Payin Declined",
      accessor: "payinDeclinedCount",
      sortType: compareFormattedNumber,
    },
    {
      Header: "Payin Expired",
      accessor: "payinExpiredCount",
      hide: true,
      sortType: compareFormattedNumber,
    },
    {
      Header: "Payin USD Completed",
      accessor: "payinAmountCompleted",
      sortType: compareFormattedCurrency,
    },
    {
      Header: "Payin USD Declined",
      accessor: "payinAmountDeclined",
      hide: true,
      sortType: compareFormattedCurrency,
    },
    {
      Header: "Payin USD Expired",
      accessor: "payinAmountExpired",
      hide: true,
      sortType: compareFormattedCurrency,
    },
    {
      Header: "Payin USD Processing",
      accessor: "payinAmountProcessing",
      hide: true,
      sortType: compareFormattedCurrency,
    },
    {
      Header: "Payout Completed",
      accessor: "payoutCompletedCount",
      sortType: compareFormattedNumber,
    },
    {
      Header: "Payout Processing",
      accessor: "payoutProcessingCount",
      sortType: compareFormattedNumber,
    },
    {
      Header: "Payout Declined",
      accessor: "payoutDeclinedCount",
      sortType: compareFormattedNumber,
    },
    {
      Header: "Payout Expired",
      accessor: "payoutExpiredCount",
      hide: true,
      sortType: compareFormattedNumber,
    },
    {
      Header: "Payout USD Completed",
      accessor: "payoutAmountCompleted",
      sortType: compareFormattedCurrency,
    },
    {
      Header: "Payout USD Declined",
      accessor: "payoutAmountDeclined",
      hide: true,
      sortType: compareFormattedCurrency,
    },
    {
      Header: "Payout USD Processing",
      accessor: "payoutAmountProcessing",
      hide: true,
      sortType: compareFormattedCurrency,
    },
  ];
  const [dataToDownload, setDataToDownload] = useState([{}]);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const { data, isLoading, mutate } = useGetMerchantSummary();
  const { data: paymentOptions } = useGetPaymentOptions();
  const { data: pspsData } = useGetPsps();

  const [selectedFilters, setSelectedFilters] = useState({});

  const merchantsSummaryPayload = get(LC_PAYLOAD) || {};
  // Destructuring the object of all selected filters to obtain only the necessary
  const { ...filters } = selectedFilters;

  const methods = useForm({ defaultValues: merchantsSummaryPayload });
  const { handleSubmit, reset } = methods;

  const onSubmit = (values) => {
    let fromDate = values.fromDate;
    let toDate = values.toDate;
    if (values.last) {
      const { last } = values;
      const dateRange = generateDateRange(last);
      fromDate = dateRange.fromDate;
      toDate = dateRange.toDate;
    }
    const payload = {
      ...values,
      fromDate: fromDate && moment(new Date(fromDate)).format("YYYY-MM-DD HH:mm:ss"),
      toDate: toDate && moment(new Date(toDate)).format("YYYY-MM-DD HH:mm:ss"),
    };
    mutate(payload);
    updateSelectedFilters(values, setSelectedFilters, { paymentOptions, psps: pspsData });
    set(LC_PAYLOAD, payload);
  };

  const getRows = () => {
    return _.map(data, (item) => ({
      ...item,
      payinAmountCompleted: `$${item.payinAmountCompleted}`,
      payinAmountDeclined: `$${item.payinAmountDeclined}`,
      payinAmountExpired: `$${item.payinAmountExpired}`,
      payinAmountProcessing: `$${item.payinAmountProcessing}`,
      payoutAmountCompleted: `$${item.payoutAmountCompleted}`,
      payoutAmountDeclined: `$${item.payoutAmountDeclined}`,
      payoutAmountProcessing: `$${item.payoutAmountProcessing}`,
    }));
  };

  const handleClickCleanFilters = async () => {
    reset({
      toDate: "",
      country: "",
      fromDate: "",
      paymentOptionId: "",
      pspId: "",
      currency: "",
      last: "",
    });
    setSelectedFilters({});
    set(LC_PAYLOAD, {});
  };

  const handleColumnsChange = (columns) => {
    setSelectedColumns(columns);
  };

  useEffect(() => {
    mutate(merchantsSummaryPayload);
    setSelectedColumns(columns);
  }, []);

  useEffect(() => {
    updateSelectedFilters(merchantsSummaryPayload, setSelectedFilters, {
      paymentOptions,
      psps: pspsData,
    });
  }, [paymentOptions]);

  // create a function that update the list of selected filters

  return (
    <MainLayout>
      <MainNavbar />
      <div style={{ paddingBottom: "20px" }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <TransactionFilters
            onSubmit={handleSubmit(onSubmit)}
            filters={filters}
            handleClickCleanFilters={handleClickCleanFilters}
            paymentOptions={paymentOptions}
            pspsData={pspsData}
          />
        </FormProvider>
      </div>

      <DataTable
        table={{
          columns: columns,
          rows: getRows() || [],
        }}
        columnsFilter={true}
        entriesPerPage={{
          defaultValue: 200,
          entries: [25, 50, 200, 500],
        }}
        canSearch={true}
        isLoading={isLoading}
        isDownloadable={downloadAble}
        dataToDownload={dataToDownload}
        isServerDownload={false}
        onDownloadClick={({ ref }) =>
          handleDownloadClick(
            { ref },
            merchantsSummaryPayload,
            false,
            data,
            mutate,
            setDataToDownload,
            selectedColumns
          )
        }
        filename="Merchant_Summary.csv"
        columnsOnChange={handleColumnsChange}
      />
    </MainLayout>
  );
};

export default MerchantsSummary;
