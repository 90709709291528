import React, { useEffect, useMemo } from "react";
import SoftBox from "components/SoftBox";
import { Grid } from "@mui/material";
import {
  TextInputField,
  CountrySelect,
  PaymentOptionSelect,
  SelectInputField,
} from "components/FormField";
import { categoryOptionsSlim } from "constants/status";
import { useGetPspMethod, useGetPspMethods } from "../hooks";
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import pxToRem from "assets/theme/functions/pxToRem";
import PropTypes from "prop-types";

const PspFeeForm = ({ fee = null }) => {
  const { id: pspId } = useParams();

  const { watch, setValue, getValues } = useFormContext();
  const watchParent = watch("parentPaymentOptionId");
  const paymentOption = watch("paymentOptionId");

  //hooks
  const { data: parentPaymentOptions } = useGetPspMethods(pspId);
  const {
    data: subPaymentOptions,
    isLoading: isLoadingSubOptions,
    isFetching,
  } = useGetPspMethod(pspId, watchParent);

  const subPaymentMethodsOptions = useMemo(() => {
    // if parentPaymentOptions is not loaded yet or not set, return main option
    if (!watchParent) {
      return parentPaymentOptions;
    } else {
      return subPaymentOptions?.suboptions || [];
    }
  }, [parentPaymentOptions, subPaymentOptions, watchParent]);

  useEffect(() => {
    const found = _.find(subPaymentMethodsOptions, { id: fee?.paymentOptionId });
    if (found) {
      setValue("paymentOptionId", fee.paymentOptionId);
    } else {
      setValue("paymentOptionId", null);
    }
  }, [subPaymentMethodsOptions, fee]);

  return (
    <SoftBox p={3} pb={1}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <PaymentOptionSelect
            label="Parent Payment option"
            name="parentPaymentOptionId"
            paymentOptions={parentPaymentOptions}
            removeSelectAll={true}
            isClearable
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {isLoadingSubOptions || isFetching ? (
            <CircularProgress size={pxToRem(18)} sx={{ mt: 4 }} />
          ) : (
            <PaymentOptionSelect
              label="Payment option"
              name="paymentOptionId"
              paymentOptions={subPaymentMethodsOptions}
              removeSelectAll={true}
              useLogo={false}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SelectInputField label="Type" name="type" options={categoryOptionsSlim} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CountrySelect
            name="country"
            label="Country"
            placeholder="Select country"
            useAll={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextInputField label="Auth Fee FTD Amount" name="authFeeFTDAmount" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInputField label="Auth Fee Amount" name="authFeeAmount" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInputField label="ftd" name="ftd" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextInputField label="nftd" name="nftd" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInputField label="Credit Card" name="creditCard" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInputField label="Debit Card" name="debitCard" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Payin" name="payin" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Payout" name="payout" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Settlement" name="settlement" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInputField label="fxFee" name="fxFee" />
        </Grid>
      </Grid>
    </SoftBox>
  );
};
PspFeeForm.propTypes = {
  fee: PropTypes.object,
};
export default PspFeeForm;
