import { useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { Card, DialogContent } from "@mui/material";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { useGetCredentials, useDeleteCredential } from "./hooks";
import EditMid from "./components/EditMid";
import borders from "assets/theme/base/borders";

import { credentialsModels } from "./schemas/models";
import { usePspsStore } from "stores/psps";
import { useMidStore } from "stores/mid";
import CredentialOverview from "./components/CredentialOverview";
import UpdateCredentialModal from "./components/UpdateCredentialModal";
import FieldArrayTable from "./components/FieldArrayTable";
import BinCard from "./components/BinCard";
import { hasPermission } from "utils/roles";
import AmountLimit from "./components/AmountLimit";
import { Permissions } from "constants/roles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import LockIcon from "@mui/icons-material/Lock";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TabPanel, { a11yProps } from "components/TabPanel";
import MidBrands from "./components/MidBrands";
import { DeleteModal } from "components/Modal";

const PspMidDetails = () => {
  const { borderWidth, borderColor } = borders;
  const { id, midId } = useParams();
  const [tab, setTab] = useState(0);

  // store
  const { psps } = usePspsStore();
  const currentPsp = _.find(psps, (item) => _.get(item, "_id") === id);
  const { mid } = useMidStore();
  const models = _.get(credentialsModels, _.get(currentPsp, "name", "").toLowerCase(), {});
  const isContainTable = _.findKey(models, (item) => _.get(item, "type") === "listOfObjects");
  // hooks
  const { data, isError, isLoading } = useGetCredentials({ pspId: id, midId });
  const { isLoading: isDeleting, mutateAsync: deleteCred } = useDeleteCredential();

  const onTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <MainLayout>
      <MainNavbar
        customRoutes={[
          { label: "psps", route: "psps" },
          { label: _.get(currentPsp, "name", id), route: `psps/${id}` },
          { label: "mid", route: `psps/${id}/mid` },
          { label: _.get(mid, "name", midId), route: `psps/${id}/mid/${midId}` },
        ]}
      />
      <SoftBox sx={{ maxWidth: 1000, marginTop: 2, marginBottom: 2 }}>
        <AppBar position="static">
          <Tabs
            value={tab}
            onChange={onTabChange}
            aria-label="basic tabs example"
            sx={{ backgroundColor: "transparent" }}
            variant="standard"
          >
            <Tab icon={<FormatListBulletedIcon />} label="General Settings" {...a11yProps(0)} />
            <Tab icon={<HourglassFullIcon />} label="Amount Limits" {...a11yProps(1)} />
            <Tab icon={<CreditCardIcon />} label="Bins" {...a11yProps(2)} />
            <Tab icon={<LockIcon />} label="Credentials" {...a11yProps(3)} />
            <Tab icon={<AssessmentIcon />} label="Usage" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
      </SoftBox>
      <TabPanel value={tab} index={0}>
        <EditMid />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <AmountLimit showHeader={true} />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <BinCard />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        {hasPermission(Permissions.MID_CREDENTIALS.LIST) ? (
          <Card sx={{ mt: 2 }}>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection={{ xs: "column", sm: "row" }}
              borderBottom={`${borderWidth[1]} solid ${borderColor}`}
              py={2}
              px={3}
            >
              {/* <SoftAvatar src={team4} alt="profile-image" variant="rounded" /> */}
              <SoftBox mx={2} lineHeight={1}>
                <SoftTypography component="a" href="#" variant="button" fontWeight="regular">
                  Credentials settings
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <DialogContent>
              <SoftBox>
                <SoftBox display="flex" gap={2}>
                  {isError && <SoftTypography variant="body2">No credentials found</SoftTypography>}
                </SoftBox>

                {!isError && <CredentialOverview credentials={data} />}
                {!_.isEmpty(models) && !isError && !_.isEmpty(isContainTable) && (
                  <FieldArrayTable isLoading={isLoading} data={data} models={models} />
                )}

                <SoftBox display="flex" justifyContent="flex-start" gap={2} mt={4}>
                  <UpdateCredentialModal credentials={data} />
                  {!isError && (
                    <DeleteModal
                      size="xs"
                      type="button"
                      title="Delete Credential"
                      message={
                        <>
                          {" "}
                          Are you sure you want to delete credential <b>{_.get(data, "_id", "")}</b>
                          ? you will not be able to recover this credential?
                        </>
                      }
                      onDelete={async () => {
                        await deleteCred({ pspId: id, midId });
                      }}
                      isLoading={isDeleting}
                    />
                  )}
                </SoftBox>
              </SoftBox>
            </DialogContent>
          </Card>
        ) : null}
      </TabPanel>
      <TabPanel value={tab} index={4}>
        <MidBrands />
      </TabPanel>
    </MainLayout>
  );
};

export default PspMidDetails;
