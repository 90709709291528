import { toastSuccess } from "utils/toast";
import { useQuery, useMutation, useQueryClient } from "react-query";
import request from "utils/request";
import { getTemplatePath } from "utils/string";

const GET_MID_PSP = "psp/:pspId/mids";
const DELETE_MID_PSP = "psp/:pspId/mids/:midId";
const ADD_MID_PSP = "psp/:pspId/mids";
const PAYMENT_OPTIONS = "psp/:pspId/methods";
const FEES = "psps/:pspId/fees";
const PSP_METHODS = "/psp/:pspId/methods";
const PSP_METHOD = "/psp/:pspId/methods/:methodId";

const getMidPsp = (pspId) => request.get(getTemplatePath(GET_MID_PSP, { pspId }));
const deletePspMid = ({ pspId, midId }) => {
  const URL = getTemplatePath(DELETE_MID_PSP, { pspId, midId });
  return request.delete(URL);
};
const addPspMid = ({ pspId, ...payload }) => {
  const URL = getTemplatePath(ADD_MID_PSP, { pspId });
  return request.post(URL, payload);
};

const getPaymentOptions = (pspId) => {
  const URL = getTemplatePath(PAYMENT_OPTIONS, { pspId });
  return request.get(URL);
};

export const useGetPspMid = (id) => {
  return useQuery([GET_MID_PSP, id], () => getMidPsp(id), {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
};

export const useDeleteMid = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePspMid, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_MID_PSP]);
      toastSuccess({ description: "Psp Mid deleted successfully" });
    },
  });
};

export const useAddMid = () => {
  const queryClient = useQueryClient();
  return useMutation(addPspMid, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_MID_PSP]);
      toastSuccess({ description: "Psp Mid added successfully" });
    },
  });
};

export const useGetPaymentOptions = (id) => {
  return useQuery([PAYMENT_OPTIONS], () => getPaymentOptions(id));
};

// fees
const getFees = (pspId) => {
  const URL = getTemplatePath(FEES, { pspId });
  return request.get(URL);
};
export const useGetPspsFees = (id) => {
  return useQuery([FEES, id], () => getFees(id), {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
};

const createFee = ({ pspId, ...payload }) => {
  const URL = getTemplatePath(FEES, { pspId });
  return request.post(URL, payload);
};
export const useCreateFee = (id) => {
  const queryClient = useQueryClient();
  return useMutation(createFee, {
    onSuccess: () => {
      queryClient.invalidateQueries([FEES, id]);
      toastSuccess({ description: "Fee created successfully" });
    },
  });
};

const getPspMethods = (pspId) => {
  const URL = getTemplatePath(PSP_METHODS, { pspId });
  return request.get(URL);
};

export const useGetPspMethods = (id) => {
  return useQuery([PSP_METHODS, id], () => getPspMethods(id), {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
};

const getPspMethod = ({ pspId, methodId }) => {
  const URL = getTemplatePath(PSP_METHOD, { pspId, methodId });
  return request.get(URL);
};
export const useGetPspMethod = (pspId, methodId) => {
  return useQuery([PSP_METHOD, pspId, methodId], () => getPspMethod({ pspId, methodId }), {
    enabled: !!pspId && !!methodId,
    refetchOnWindowFocus: false,
  });
};
