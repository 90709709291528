import { useMutation, useQuery } from "react-query";
import request from "utils/request";
import { toastError, toastSuccess } from "../../utils/toast";

const GET_TRANSACTION_DETAIL = "transactions/find-one";
const PAYMENT_EVENTS = "payment-events";

// define the function to fetch transaction detail by id
const getTransaction = (id) => request.get(`${GET_TRANSACTION_DETAIL}?transactionId=${id}`);
export const checkTxStatus = (id) => request.get(`transactions/${id}/check-status`);
const getEvents = (id) => request.get(`${PAYMENT_EVENTS}/${id}`);

// define the custom hook to get transaction detail
export const useGetTransactionDetail = (id) => {
  return useMutation(() => getTransaction(id), {
    enabled: !!id,
  });
};

export const useCheckTxStatus = ({ id }) => {
  return useMutation(() => checkTxStatus(id), {
    onSuccess: () => {
      toastSuccess({ description: "Update status has been success" });
    },
    onError: (data) => {
      let message;
      message = Array.isArray(data?.response?.data?.message)
        ? data?.response?.data?.message.join(" ")
        : data?.response?.data?.message;
      toastError({ description: message });
    },
  });
};

export const useGetPaymentEvents = ({ id }) => {
  return useQuery([PAYMENT_EVENTS], () => getEvents(id));
};
