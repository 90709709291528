import PropTypes from "prop-types";

import { Grid, Divider } from "@mui/material";

import FieldRender from "./FieldRender";

const ObjectRender = ({ model, objectName, title }) => {
  return (
    <>
      <Grid item xs={12} sm={12} mt={-2} mb={-2}>
        <Divider
          textAlign="left"
          sx={{
            opacity: 1,
            backgroundImage: "none !important",
            height: "auto",
          }}
        >
          {title ? _.startCase(title) : _.startCase(objectName)}
        </Divider>
      </Grid>
      {_.map(model.child, (item, key) => {
        return <FieldRender item={item} key={key} label={key} name={`${objectName}.${key}`} />;
      })}
    </>
  );
};
ObjectRender.propTypes = {
  model: PropTypes.object,
  objectName: PropTypes.string,
  title: PropTypes.string,
};

export default ObjectRender;
