import { useMutation } from "react-query";
import { getTemplatePath, getQueryParam } from "utils/string";
import { toastError } from "utils/toast";
import { GET_BRANDS } from "pages/brand-detail/hooks";

const { default: request } = require("utils/request");

const GET_TRANSACTIONS = "/transactions/attempts";

const getTransactionAttempts = async (filterTransactionProps) => {
  const params = getQueryParam(filterTransactionProps);
  return request.get(`${GET_TRANSACTIONS}?${params}`);
};

export const useGetTransactionAttempts = () => {
  return useMutation(getTransactionAttempts, {
    onError: (error) => {
      const message = _.get(error, "response.data.message[0]");
      toastError({ description: message });
    },
  });
};

const getBrand = ({ merchantId }) => {
  const URL = getTemplatePath(GET_BRANDS, { merchantId });
  return request.get(URL);
};
export const useGetBrands = () => {
  return useMutation(getBrand);
};
