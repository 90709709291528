import React from "react";
import { Card, Grid } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import { set } from "utils/localStorage";
import { defaultValues } from "pages/transactions/schemas/index";
import FilterForm from "pages/transactions/components/FilterForm";
import { TextInputField } from "components/FormField";
import { useTransactionStore } from "stores/transactions";
import UpdateTransactionStatusModal from "./UpdateTransactionStatusModal";
import { SwitchField } from "components/FormField";
const LC_PAYLOAD = "transactionReportingPayload";

const TransactionFilters = (props) => {
  const { selectedTransactions } = useTransactionStore();
  const { onClearFilters, paymentOptions, pspsData, merchants, brands, errorCodes } = props;

  const { reset } = useFormContext();

  const handleClickCleanFilters = async () => {
    set(LC_PAYLOAD, {});
    reset(defaultValues);
    onClearFilters();
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} lg={12}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox p={3}>
            <FilterForm
              paymentOptions={paymentOptions}
              pspsData={pspsData}
              merchants={merchants}
              brands={brands}
              errorCodes={errorCodes}
            />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextInputField
                  label="Transaction Ids"
                  name="transactionIds"
                  placeholder="List of transaction ids, separated by comma"
                  multiline
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item>
                <SoftBox sx={{ pl: 2 }}>
                  <SwitchField name="searchArchives" label="Search Archives" />
                </SoftBox>
              </Grid>
            </Grid>

            <Grid container display="flex" justifyContent="space-between">
              <SoftButton variant="gradient" color="info" type="submit" sx={{ mt: 2 }}>
                Search
              </SoftButton>
              <SoftBox>
                {selectedTransactions && selectedTransactions.length > 0 && (
                  <UpdateTransactionStatusModal />
                )}

                <SoftButton
                  variant="gradient"
                  color="info"
                  type="button"
                  sx={{ mt: 2 }}
                  onClick={handleClickCleanFilters}
                >
                  Clear filters
                </SoftButton>
              </SoftBox>
            </Grid>
          </SoftBox>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TransactionFilters;

// Typechecking props of the TransactionFilters
TransactionFilters.propTypes = {
  paymentOptions: PropTypes.object,
  pspsData: PropTypes.object,
  merchants: PropTypes.object,
  onClearFilters: PropTypes.func,
  brands: PropTypes.object,
  errorCodes: PropTypes.object,
};
