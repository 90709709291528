import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema, sanitizeRequest } from "../schemas/templates";
import { useDisclosure } from "hooks/useDisclosure";
import { useCreateTemplate } from "../hooks";

import SoftBox from "components/SoftBox";
import PropTypes from "prop-types";
import FormProvider from "components/FormProviders";
import { Stack, Dialog, DialogContent, DialogTitle } from "@mui/material";
import SoftButton from "components/SoftButton";
import TemplateForm from "./TemplateForm";

const CreateTemplateModal = ({ paymentOptions, onCloseSuccess, pmTemplates, psps }) => {
  const modalState = useDisclosure();
  const { onClose, onOpen, isOpen } = modalState;

  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit, reset } = methods;
  //hooks
  const { mutate: createTemplate, isLoading: isCreatingTemplate } = useCreateTemplate();

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    createTemplate(sanitizeRequest(values), {
      onSuccess: () => {
        onCloseSuccess();
        onClose();
      },
    });
  };

  useEffect(() => {
    reset(defaultValues);
  }, []);

  return (
    <>
      <SoftBox pt={2} px={2} mb={3}>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <SoftButton variant="gradient" color="info" size="small" onClick={onOpen}>
            Add Template
          </SoftButton>
        </Stack>
      </SoftBox>
      <Dialog open={isOpen} onClose={onClose} sx={{ overflow: "visible" }} fullWidth maxWidth="md">
        <DialogTitle>Add New Template</DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <SoftBox p={2}>
              <TemplateForm paymentOptions={paymentOptions} pmTemplates={pmTemplates} psps={psps} />
              <Stack direction="row" spacing={1} justifyContent="flex-end" mt={4}>
                <SoftButton onClick={onClose}>Cancel</SoftButton>
                <SoftButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  disabled={isCreatingTemplate}
                  isLoading={isCreatingTemplate}
                >
                  Add Template
                </SoftButton>
              </Stack>
            </SoftBox>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};
CreateTemplateModal.propTypes = {
  onCloseSuccess: PropTypes.func,
  paymentOptions: PropTypes.array,
  pmTemplates: PropTypes.array,
  psps: PropTypes.array,
};
export default CreateTemplateModal;
