import React, { useEffect, useState } from "react";

import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import SoftButton from "components/SoftButton";
import FormProvider from "components/FormProviders";
import SoftBox from "components/SoftBox";
import UserForm from "./UserForm";
import { useCreateOrganizationUser } from "../hooks";

import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema } from "../schemas/merchant";

const AddOrganizationUserModal = () => {
  const { id: organizationId } = useParams();
  const [isOpenAddMerchantModal, setIsOpenAddMerchantModal] = useState(false);
  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit, getValues, setValue } = methods;
  useEffect(() => {
    if (!isOpenAddMerchantModal) {
      Object.keys(getValues()).map((val) => {
        setValue(val, null);
      });
    }
  }, [isOpenAddMerchantModal]);

  //hooks
  const { isLoading, mutate } = useCreateOrganizationUser();

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    const { code, ...val } = values;
    mutate(
      { id: organizationId, payload: { ...val, phone: code + val.phone } },
      {
        onSuccess: () => {
          handleCloseAddMerchantModal();
        },
      }
    );
  };
  const handleOpenAddMerchantModal = () => {
    setIsOpenAddMerchantModal(true);
  };
  const handleCloseAddMerchantModal = () => {
    setIsOpenAddMerchantModal(false);
  };

  return (
    <>
      <Stack spacing={1} direction="row" justifyContent="flex-end">
        <SoftButton
          variant="gradient"
          color="info"
          size="small"
          onClick={handleOpenAddMerchantModal}
        >
          Add User
        </SoftButton>
      </Stack>
      <Dialog
        open={isOpenAddMerchantModal}
        onClose={handleCloseAddMerchantModal}
        fullWidth
        maxWidth="lg"
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
            }}
          >
            Create New User
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3} pb={0}>
              <UserForm organizationId={organizationId} />
            </SoftBox>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              pt: 0,
            }}
          >
            <SoftButton variant="gradient" onClick={handleCloseAddMerchantModal}>
              Cancel
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              disabled={isLoading}
              isLoading={isLoading}
            >
              Create User
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default AddOrganizationUserModal;
