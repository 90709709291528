import { max } from "lodash";
import * as Yup from "yup";

export const defaultValues = {
  name: "",
  externalId: "",
  country: null,
  active: false,
  payin: false,
  payout: false,
  currency: null,
  min: null,
  max: null,
};
export const schema = Yup.object().shape({
  name: Yup.string().required("SubOption name is required"),
  externalId: Yup.string().required("External ID is required"),
  country: Yup.string().required("Country is required"),
  currency: Yup.string().required("Currency is required"),
  min: Yup.number().nullable(),
  max: Yup.number().nullable(),
  active: Yup.boolean(),
  payin: Yup.boolean(),
  payout: Yup.boolean(),
});

export const sanitizeRequest = ({
  name,
  externalId,
  active,
  payin,
  payout,
  country,
  currency,
  min,
  max,
}) => {
  const payload = _.pickBy({ name, externalId, country, currency, min, max }, _.identity);
  return { ...payload, active, payin, payout };
};
