import { useQuery, useMutation, useQueryClient } from "react-query";
import { toastSuccess } from "utils/toast";
import request from "utils/request";
import { getTemplatePath } from "utils/string";

const TEMPLATE = "/reconciliation-templates/:id";

// update
const updateTemplate = ({ id, ...payload }) => {
  const URL = getTemplatePath(TEMPLATE, { id });
  return request.put(URL, payload);
};
export const useUpdateTemplate = (id) => {
  const queryClient = useQueryClient();
  return useMutation(updateTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries([TEMPLATE, id]);
      toastSuccess({ description: "Update template info has been success" });
    },
  });
};

// delete
const deleteTemplate = ({ id }) => request.delete(getTemplatePath(TEMPLATE, { id }));

export const useDeleteTemplate = (id) => {
  return useMutation(deleteTemplate, {
    onSuccess: () => {
      toastSuccess({ description: "Template deleted successfully" });
    },
  });
};

// get
const getTemplate = (id) => {
  const URL = getTemplatePath(TEMPLATE, { id });
  return request.get(URL);
};
export const useGetTemplate = (id) => {
  return useQuery([TEMPLATE, id], () => getTemplate(id));
};
