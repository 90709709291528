import { Divider, Grid, Icon, IconButton, Box } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import _ from "lodash";

import { SelectInputField, SwitchField, TextInputField } from "components/FormField";
import SoftButton from "components/SoftButton";
import PropTypes from "prop-types";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import { historyByOptions, routeSelectorsOptions } from "constants/brands";

const BrandForm = ({ signKey = "" }) => {
  const { control } = useFormContext();
  const {
    fields: ipWhitelistFields,
    append,
    remove,
  } = useFieldArray({ control, name: "ipWhitelists" });
  return (
    <Box mt={1}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextInputField label="Name" name="name" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextInputField label="Notification Url" name="notificationUrl" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6}>
          <SelectInputField
            label="Routing Policy"
            name="routeSelector"
            options={routeSelectorsOptions}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <SelectInputField label="Stats History By" name="historyBy" options={historyByOptions} />
        </Grid>
      </Grid>
      <Grid container spacing={3} mt={2} ml={1} display={"flex"} pl={4}>
        <Grid xs={12} sm={8} mt={2}>
          <Grid container spacing={3} display={"flex"}>
            <Grid xs={12} sm={6}>
              <SoftBox>
                <SwitchField label="Payment Fac" name="paymentFac" sx={{ width: "fit-content" }} />
                <SwitchField
                  label="Enforce Body Signature"
                  name="enforceBodySignature"
                  sx={{ width: "fit-content" }}
                />
                <SwitchField
                  label="Enable IP Blacklist"
                  name="enableIpBlacklist"
                  sx={{ width: "fit-content" }}
                />
                <SwitchField
                  label="Enable Bin Country Check"
                  name="enableBinCountryCheck"
                  sx={{ width: "fit-content" }}
                />
              </SoftBox>
            </Grid>
            <Grid xs={12} sm={6}>
              <SoftBox>
                <SwitchField
                  label="Auto Approve Payouts"
                  name="autoApprovePayouts"
                  sx={{ width: "fit-content" }}
                />
                <SwitchField
                  label="Enable Power By"
                  name="enablePoweredBy"
                  sx={{ width: "fit-content" }}
                />
                <SwitchField
                  label="Enable Customer Check"
                  name="enableCustomerCheck"
                  sx={{ width: "fit-content" }}
                />
              </SoftBox>
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12} sm={4}>
          <SoftBox>
            <SoftTypography component="div" variant="button" fontWeight="normal">
              Header visibility
            </SoftTypography>
            <SwitchField
              label="Enable title"
              name="headerVisibility.enableTitle"
              sx={{ width: "fit-content" }}
            />
            <SwitchField
              label="Enable amount"
              name="headerVisibility.enableAmount"
              sx={{ width: "fit-content" }}
            />
          </SoftBox>
        </Grid>

        {signKey && (
          <Grid item xs={6} sm={6}>
            <SoftBox>
              <SoftTypography component="div" href="#" variant="button" fontWeight="semibold">
                Signature Key
              </SoftTypography>
              <SoftTypography component="div" variant="button" color="text" fontWeight="regular">
                {signKey}
              </SoftTypography>
            </SoftBox>
          </Grid>
        )}
      </Grid>

      <Divider />
      {_.map(ipWhitelistFields, (field, index) => (
        <Grid container key={field.id} spacing={2}>
          <Grid item xs={5.5}>
            <TextInputField label="Starting IP" name={`ipWhitelists.${index}.startingIp`} />
          </Grid>
          <Grid item xs={5.5}>
            <TextInputField label="Ending IP" name={`ipWhitelists.${index}.endingIp`} />
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => remove(index)} sx={{ mt: 2 }}>
              <Icon>delete_forever</Icon>
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <SoftButton sx={{ mt: 2 }} onClick={() => append({})}>
        Add Whitelist IP
      </SoftButton>
    </Box>
  );
};

export default BrandForm;

BrandForm.propTypes = {
  signKey: PropTypes.string,
};
