import React from "react";
import _ from "lodash";

import { useGetRoles } from "../users/hooks";
import DataTable from "../../../examples/Tables/DataTable";

import SoftTypography from "../../../components/SoftTypography";
import Grid from "@mui/material/Grid";

import PermissionCollapse from "./PermissionCollapse";
import AddRoleModal from "./AddRoleModal";
import EditRoleModal from "./EditRoleModal";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";
import { DeleteModal } from "components/Modal";
import { useDeleteRole } from "../users/hooks";

const ManageRoles = () => {
  if (!hasPermission(Permissions.ROLE.LIST)) {
    return <Error403 />;
  }
  const { data: roles } = useGetRoles();
  const { isLoading, mutateAsync: deleteRole } = useDeleteRole();

  const getRows = () => {
    return _.map(roles, (item) => ({
      id: item._id,
      name: item.name,
      loginType: item.loginType,
      permissions: <PermissionCollapse permissions={item.permissions} />,
      actions: (
        <>
          <EditRoleModal role={item} />
          <DeleteModal
            title="Delete Role"
            type="icon"
            size="xs"
            message={
              <>
                Are you sure you want to delete role <b>{_.get(item, "name")}</b>? You will not be
                able to recover this role?
              </>
            }
            isLoading={isLoading}
            onDelete={async () => {
              await deleteRole({ id: item._id });
            }}
          />
        </>
      ),
    }));
  };

  return (
    <>
      <Grid container display="flex" alignItems="center" justifyContent="space-between">
        <Grid item>
          <SoftTypography variant="h5" fontWeight="medium">
            Manage Roles
          </SoftTypography>
        </Grid>
        <Grid item>
          <AddRoleModal />
        </Grid>
      </Grid>

      <DataTable
        table={{
          columns: [
            { Header: "Name", accessor: "name" },
            { Header: "login type", accessor: "loginType" },
            { Header: "permissions", accessor: "permissions" },
            { Header: "actions", accessor: "actions" },
          ],
          rows: getRows() || [],
        }}
      />
    </>
  );
};

export default ManageRoles;
