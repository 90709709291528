import React from "react";
import _ from "lodash";

import SoftBox from "components/SoftBox";

import DataTable from "examples/Tables/DataTable";
import { Typography, Grid } from "@mui/material";
import { useGetTemplates } from "pages/brand-detail/hooks";
import AddNewTemplate from "./AddNewTemplate";
import UpdateTemplate from "./UpdateTemplate";
import { DeleteModal } from "components/Modal";
import { useDeleteTemplate } from "pages/brand-detail/hooks";
import SoftTypography from "components/SoftTypography";

const PaymentMethodsTemplates = () => {
  const { data: templates } = useGetTemplates();
  const { mutateAsync: deletePM, isLoading: isDeleting } = useDeleteTemplate();

  const getRows = () => {
    return _.map(templates, (item) => ({
      id: item._id,
      name: item.name,
      actions: (
        <SoftBox display="flex" gap={1}>
          <UpdateTemplate template={item} />
          <DeleteModal
            size="xs"
            type="icon"
            title="Delete Payment Method Template"
            message={
              <>
                Are you sure want to delete Payment Method Template <b>{item.name}</b>
              </>
            }
            onDelete={async () => {
              await deletePM({ id: item._id });
            }}
            isLoading={isDeleting}
          />
        </SoftBox>
      ),
    }));
  };

  return (
    <>
      <Grid container display="flex" alignItems="center" justifyContent="space-between">
        <Grid item>
          <SoftTypography variant="h5" fontWeight="medium">
            Manage Payment Method Templates
          </SoftTypography>
        </Grid>
        <Grid item>
          <AddNewTemplate />
        </Grid>
      </Grid>

      <>
        {templates?.length ? (
          <DataTable
            table={{
              columns: [
                { Header: "id", accessor: "id" },
                { Header: "name", accessor: "name" },
                { Header: "actions", accessor: "actions" },
              ],
              rows: getRows(),
            }}
          />
        ) : (
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              my: 3,
              color: "#67748e",
            }}
          >
            There are no Template
          </Typography>
        )}
      </>
    </>
  );
};

export default PaymentMethodsTemplates;
