import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { StatusColorEnum } from "constants/status";
import { MessageDetail } from "./ErrorItem";
import { shortText } from "utils/string";

function ErrorItemCell({ code, message, status, description, psp }) {
  const color = _.get(StatusColorEnum, status, "info");

  return (
    <SoftBox pl={1} ml={2} sx={{ maxWidth: "35vw" }}>
      <SoftBox display="flex" alignItems="center">
        <SoftBox lineHeight={1}>
          <SoftTypography variant="button" fontWeight="medium">
            {code} - {message}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox ml={-3}>
        {status && <MessageDetail label="Status" value={status} color={color} />}
        {description && <MessageDetail label="Description" value={description} />}
        {psp && <MessageDetail label="Psp" value={psp} />}
      </SoftBox>
    </SoftBox>
  );
}

ErrorItemCell.defaultProps = {
  color: "info",
  noDivider: false,
};

ErrorItemCell.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  code: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  status: PropTypes.string,
  description: PropTypes.string,
  psp: PropTypes.string,
};

export default ErrorItemCell;
