import { toastSuccess } from "utils/toast";
import { useQuery, useMutation, useQueryClient } from "react-query";
import request from "utils/request";
import { getTemplatePath } from "utils/string";
import { getQueryParam } from "utils/string";

const AMOUNT_LIMITS = "psp/:pspId/amount-limits";
const AMOUNT_LIMIT = "psp/:pspId/amount-limits/:id";

const getAmountLimits = ({ pspId, ...query }) => {
  const URL = getTemplatePath(AMOUNT_LIMITS, { pspId });
  const queryParams = getQueryParam({ ...query });
  return request.get(`${URL}?${queryParams}`);
};

export const useGetAmountLimits = () => {
  return useMutation(getAmountLimits);
};

// add
const addAmountLimit = ({ pspId, ...payload }) => {
  const URL = getTemplatePath(AMOUNT_LIMITS, { pspId });
  return request.post(URL, payload);
};
export const useAddAmountLimit = () => {
  return useMutation(addAmountLimit, {
    onSuccess: () => {
      toastSuccess({ description: "add amount limit has been success" });
    },
  });
};

// update
const updateAmountLimit = ({ pspId, id, min, max }) => {
  const URL = getTemplatePath(AMOUNT_LIMIT, { pspId, id });
  return request.patch(URL, { min, max });
};
export const useUpdateAmountLimit = () => {
  return useMutation(updateAmountLimit, {
    onSuccess: () => {
      toastSuccess({ description: "update amount limit has been success" });
    },
  });
};

// del
const deleteAmountLimit = ({ pspId, id }) => {
  const URL = getTemplatePath(AMOUNT_LIMIT, { pspId, id });
  return request.delete(URL);
};
export const useDeleteAmountLimit = () => {
  return useMutation(deleteAmountLimit, {
    onSuccess: () => {
      toastSuccess({ description: "delete amount limit has been success" });
    },
  });
};
