import _ from "lodash";

const sanitizePspIds = (pspIds) => {
  if (pspIds && pspIds.length > 0 && pspIds.includes("")) {
    return null;
  }
  return pspIds;
};

const sanitizeStatus = (status) => {
  if (status === "") {
    return null;
  }
  return status;
};

export const sanitizeFilter = (data) => {
  return _.pickBy(
    { status: sanitizeStatus(data.status), pspIds: sanitizePspIds(data.pspIds) },
    _.identity
  );
};
