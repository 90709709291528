import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import SoftBox from "components/SoftBox";
import {
  TextInputField,
  SwitchField,
  MultipleSelectField,
  CountrySelect,
  CurrencySelect,
  TagsInput,
} from "components/FormField";

import { currencies } from "constants/currencies";

const FieldRender = ({ item, label, name }) => {
  const smSize = _.get(item, "size", "md") === "md" ? 6 : 12;
  if (["text", "number"].includes(item.type))
    return (
      <Grid item xs={12} sm={smSize}>
        <TextInputField label={label} name={name} />
      </Grid>
    );
  if (item.type === "bool") {
    const mt = _.get(item, "mt", 3);
    return (
      <Grid item xs={12} sm={smSize}>
        <SoftBox ml={2} mt={mt}>
          <SwitchField label={label} name={name} />
        </SoftBox>
      </Grid>
    );
  }
  if (item.type === "listOfCurrenciesTexts") {
    const options = _.map(currencies, (item) => {
      if (item && item.code !== "All") return { label: item.code, value: item.code };
    }).filter((item) => item !== undefined);
    return (
      <Grid item xs={12} sm={smSize}>
        <MultipleSelectField label={label} name={name} options={options} />
      </Grid>
    );
  }
  if (item.type === "countrySelect") {
    return (
      <Grid item xs={12} sm={smSize}>
        <CountrySelect name={name} label={label} />
      </Grid>
    );
  }
  if (item.type === "currencySelect") {
    return (
      <Grid item xs={12} sm={smSize}>
        <CurrencySelect name={name} label={label} />
      </Grid>
    );
  }
  if (item.type === "listOfTexts") {
    return (
      <Grid item xs={12} sm={smSize}>
        <TagsInput name={name} label={label} />
      </Grid>
    );
  }
  return null;
};

FieldRender.propTypes = {
  item: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
};
export default FieldRender;
