import React from "react";
import _ from "lodash";

import { useGetManageUsers } from "../users/hooks";
import EnabledChip from "../../../components/EnabledChip";
import DataTable from "../../../examples/Tables/DataTable";
import AddManageUserModal from "./AddManageUserModal";
import ResetUserPassword from "./ResetUserPasswordModal";
import SoftTypography from "../../../components/SoftTypography";
import Grid from "@mui/material/Grid";
import EditUserModal from "./EditUserModal";
import Roles from "components/Roles";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";
import { DeleteModal } from "components/Modal";
import { useDeleteManageUser } from "../users/hooks";

const ManageUsers = () => {
  if (!hasPermission(Permissions.ADMIN_USER.LIST)) {
    return <Error403 />;
  }
  const { data } = useGetManageUsers();
  const { isLoading, mutateAsync: deleteUser } = useDeleteManageUser();

  const onDelete = async (id) => {
    await deleteUser({ id });
  };

  const getRows = () => {
    return _.map(data, (item) => ({
      id: item.id,
      name: item.name,
      surname: item.surname,
      email: item.email,
      promptPassChange: <EnabledChip label={item.promptPassChange} size="small" />,
      roles: <Roles roles={item.roles} />,
      merchantId: item.merchantId,
      actions: (
        <>
          <DeleteModal
            message={
              <>
                Are you sure you want to delete user <b>{_.get(item, "name")}</b>? You will not be
                able to recover this user?
              </>
            }
            type="icon"
            title="Delete User"
            onDelete={() => onDelete(item.id)}
            isLoading={isLoading}
            size="xs"
          />
          <ResetUserPassword merchant={item} />
          <EditUserModal
            name={item.name}
            email={item.email}
            userId={item.id}
            currentRoles={item.roles}
          />
        </>
      ),
    }));
  };

  return (
    <>
      <Grid container display="flex" alignItems="center" justifyContent="space-between">
        <Grid item>
          <SoftTypography variant="h5" fontWeight="medium">
            Manage Users
          </SoftTypography>
        </Grid>
        <Grid item>
          <AddManageUserModal />
        </Grid>
      </Grid>

      <DataTable
        table={{
          columns: [
            { Header: "Name", accessor: "name" },
            { Header: "Surname", accessor: "surname" },
            { Header: "Email Address", accessor: "email" },
            { Header: "Prompt Password Change", accessor: "promptPassChange" },
            { Header: "Roles", accessor: "roles" },
            { Header: "actions", accessor: "actions" },
          ],
          rows: getRows() || [],
        }}
      />
    </>
  );
};

export default ManageUsers;
