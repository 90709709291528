import { create } from "zustand";

type Transaction = {
  id: string | null;
  country: string | null;
  currency: string | null;
  merchant: string | null;
  brand: string | null;
  amount: string | null;
  paymentAmount: string | null;
};

type TransactionStore = {
  selectedTransactions: Transaction[];
  transactions: Transaction[];
  removeSelectedTransaction: (transactionId: string) => void;
  appendSelectedTransaction: (transaction: Transaction) => void;
};

const initialState: TransactionStore = {
  selectedTransactions: [],
  transactions: [],
  appendSelectedTransaction: () => null,
  removeSelectedTransaction: () => null,
};

export const useTransactionStore = create((set) => ({
  ...initialState,
  removeSelectedTransaction: (transactionId: string) => {
    return set(({ selectedTransactions }) => ({
      selectedTransactions: selectedTransactions.filter(
        (item: Transaction) => item.id !== transactionId
      ),
    }));
  },
  appendSelectedTransaction: (values: Transaction) => {
    return set(({ selectedTransactions }) => ({
      selectedTransactions: [...selectedTransactions, values],
    }));
  },
  clearSelectedTransactions: () => {
    return set(({ selectedTransactions }) => ({ selectedTransactions: [] }));
  },
  setTransactions: (values: Transaction[]) => {
    return set(({ transactions }) => ({ transactions: values }));
  },
  clearTransactions: () => {
    return set(({ transactions }) => ({ transactions: [] }));
  },
}));
