import PropTypes from "prop-types";

import Radio from "@mui/material/Radio";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import { StatusColorEnum } from "constants/status";
import WrapperItem from "components/ActionCell/WrapperItem";
import EditInternalError from "./EditInternalError";
import { getErrorActionsNames, getErrorCategoryName } from "utils/errorCodes";
import { DeleteModal } from "components/Modal";
import { useDeleteInternalError } from "../hooks";

export const MessageDetail = ({ label, value, color }) => {
  return (
    <SoftBox ml={{ xs: 0, sm: 3 }} mb={{ xs: 1, sm: 0 }} lineHeight={1} sx={{ width: "35vw" }}>
      <SoftTypography
        variant="caption"
        fontWeight="medium"
        color="secondary"
        sx={{ wordWrap: "break-word" }}
      >
        {label}:&nbsp;
      </SoftTypography>
      <SoftTypography variant="caption" fontWeight="bold" color={color}>
        {value}
      </SoftTypography>
    </SoftBox>
  );
};
MessageDetail.defaultProps = {
  color: "text",
};
MessageDetail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string,
};

function ErrorItem({
  code,
  message,
  status,
  description,
  noDivider,
  psp,
  id,
  category,
  allowDelete,
  allowEdit,
  actions = [],
}) {
  const { borderWidth } = borders;
  const color = _.get(StatusColorEnum, status, "info");
  const borderColor = _.get(colors, `${color}.main`, colors.text.main);
  // hooks
  const { mutate: deleteInternalError, isLoading } = useDeleteInternalError();

  const deleteMessage = () => {
    return (
      <SoftBox p={3} minWidth="300px">
        <SoftTypography variant="h6" textAlign="center">
          Are you sure want to delete this internal error ?
        </SoftTypography>
        <SoftBox ml={{ xs: 0, sm: "auto" }} mb={{ xs: 1, sm: 0 }} lineHeight={1}>
          <SoftTypography variant="caption" fontWeight="medium" color="secondary">
            Code:&nbsp;
          </SoftTypography>
          <SoftTypography variant="caption" fontWeight="bold" color={"secondary"}>
            {code}
          </SoftTypography>
        </SoftBox>
        <SoftBox ml={{ xs: 0, sm: "auto" }} mb={{ xs: 1, sm: 0 }} lineHeight={1}>
          <SoftTypography variant="caption" fontWeight="medium" color="secondary">
            Id:&nbsp;
          </SoftTypography>
          <SoftTypography variant="caption" fontWeight="bold" color={"secondary"}>
            {id}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  };
  return (
    <SoftBox
      component="li"
      width="100%"
      pr={2}
      mb={2}
      borderLeft={`${borderWidth[3]} solid ${borderColor}`}
      sx={{ listStyle: "none" }}
    >
      <SoftBox width="100%" pl={1} ml={2}>
        <SoftBox display="flex" alignItems="center">
          <FormControlLabel value={id} control={<Radio />} />
          <SoftBox width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <SoftBox ml={-1} lineHeight={1}>
              <SoftTypography variant="button" fontWeight="medium">
                {code} - {message}
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" alignItems="center">
              <WrapperItem actionsLength={2} index={1}>
                {allowEdit && (
                  <EditInternalError
                    error={{ description, code, id, message, status, category, actions }}
                  />
                )}
              </WrapperItem>
              <WrapperItem actionsLength={2} index={0}>
                {allowDelete && (
                  <DeleteModal
                    size="md"
                    type="icon"
                    title="Delete Internal Error"
                    message={deleteMessage()}
                    iconColor="error"
                    isLoading={isLoading}
                    onDelete={async () => {
                      await deleteInternalError({ id });
                    }}
                  />
                )}
              </WrapperItem>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox>
          {description && <MessageDetail label="Description" value={description} />}
          {status && <MessageDetail label="Status" value={status} color={color} />}
          {psp && <MessageDetail label="Provider" value={psp} />}
          {category && <MessageDetail label="Category" value={getErrorCategoryName(category)} />}
          {actions && actions.length > 0 && (
            <MessageDetail label="Actions" value={getErrorActionsNames(actions)} />
          )}
        </SoftBox>
      </SoftBox>
      {noDivider ? null : <Divider sx={{ marginBottom: 0 }} />}
    </SoftBox>
  );
}

ErrorItem.defaultProps = {
  color: "info",
  noDivider: false,
  allowDelete: false,
  allowEdit: false,
};

ErrorItem.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  code: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  status: PropTypes.string,
  description: PropTypes.string,
  noDivider: PropTypes.bool,
  psp: PropTypes.string,
  id: PropTypes.string.isRequired,
  allowDelete: PropTypes.bool,
  allowEdit: PropTypes.bool,
  category: PropTypes.string,
  actions: PropTypes.array,
};

export default ErrorItem;
