import { countriesFlags } from "../constants/countriesFlags";

export const amountGroupByStatus = (data) => {
  const statusSums = {};

  data?.forEach((item) => {
    const status = item.status;
    if (statusSums[status]) {
      statusSums[status] += 1;
    } else {
      statusSums[status] = 1;
    }
  });

  return statusSums;
};

export const getStatusReport = (data) => {
  const reportData = amountGroupByStatus(data);
  return reportData;
};

export const getLabelsStat = (data) => {
  if (!data) {
    return null;
  }

  const complexReportsChartData = {
    labels: [],
    datasets: [
      {
        type: "bar",
        label: "Completed transactions",
        backgroundColor: "rgb(75, 192, 192)",
        data: [],
        stack: "Stack 0",
      },
      {
        type: "bar",
        label: "Others transactions",
        backgroundColor: "rgb(255, 99, 132)",
        data: [],
        stack: "Stack 0",
      },
    ],
  };

  data.forEach((item) => {
    const countryName =
      countriesFlags.find((country) => country.code === item.code)?.name || item.code;
    complexReportsChartData.labels.push(`${countryName} ${item.approvalRate}%`);
    complexReportsChartData.datasets[0].data.push(item.txCompleted);
    complexReportsChartData.datasets[1].data.push(item.txOthers);
  });
  return complexReportsChartData;
};
