import { useQuery, useQueryClient, useMutation } from "react-query";
import request from "utils/request";
import { getTemplatePath } from "utils/string";
import { toastError, toastSuccess } from "utils/toast";

const PAYMENT_OPTIONS = "psp/:pspId/methods/:methodId";
const SUBOPTION = "psp/:pspId/methods/:methodId/suboption";
const UPDATE_SUBOPTION = "psp/:pspId/methods/:methodId/suboption/:subOptionId";

const getPaymentOption = ({ pspId, methodId }) => {
  const URL = getTemplatePath(PAYMENT_OPTIONS, { pspId, methodId });
  return request.get(URL);
};
export const useGetPaymentOption = ({ pspId, methodId }) => {
  return useQuery([PAYMENT_OPTIONS, pspId, methodId], () => getPaymentOption({ pspId, methodId }), {
    enabled: !!pspId,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toastError({ description: error.message });
    },
  });
};

// create sub option
const createSubOption = ({ pspId, methodId, ...payload }) => {
  const URL = getTemplatePath(SUBOPTION, { pspId, methodId });
  return request.post(URL, payload);
};
export const useCreateSubOption = ({ pspId, methodId }) => {
  const queryClient = useQueryClient();
  return useMutation(createSubOption, {
    onSuccess: () => {
      toastSuccess({ description: "Create SubOption has been success" });
      queryClient.invalidateQueries([PAYMENT_OPTIONS, pspId, methodId]);
    },
  });
};

// update sub option
const updateSubOption = ({ pspId, methodId, subOptionId, ...payload }) => {
  const URL = getTemplatePath(UPDATE_SUBOPTION, { pspId, methodId, subOptionId });
  return request.patch(URL, payload);
};
export const useUpdateSubOption = ({ pspId, methodId }) => {
  const queryClient = useQueryClient();
  return useMutation(updateSubOption, {
    onSuccess: () => {
      toastSuccess({ description: "Update SubOption has been success" });
      queryClient.invalidateQueries([PAYMENT_OPTIONS, pspId, methodId]);
    },
  });
};
