import PropTypes from "prop-types";
import _ from "lodash";

import { Chip, Divider } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { fDateTimeSuffix } from "utils/formatTime";
import DataTable from "../../../examples/Tables/DataTable";
import EnabledChip from "../../../components/EnabledChip";
import Address from "./Address";

const CredentialOverview = ({ credentials }) => {
  const renderSNItem = (key, value) => {
    if (value)
      return (
        <SoftBox key={key} display="flex" py={1} pr={2}>
          <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {key}: &nbsp;
          </SoftTypography>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{value}
          </SoftTypography>
        </SoftBox>
      );
  };
  const renderItems = _.map(_.get(credentials, "data", {}), (value, key) => {
    if (_.isString(value) || _.isNumber(value)) {
      return renderSNItem(key, value);
    }
  });
  const getRows = (array) => {
    return _.map(array, (item, index) => {
      return {
        number: index + 1,
        ...item,
        enabled: <EnabledChip label={item.enabled} size="small" />,
        isSecure: <EnabledChip label={item.isSecure} size="small" />,
        address: <Address item={item?.address} />,
      };
    });
  };
  const renderArrayItems = _.map(_.get(credentials, "data", {}), (value, key) => {
    if (_.isArray(value)) {
      if (!value || value.length === 0) return null;
      if (!_.isString(value[0])) {
        return (
          <>
            <Divider
              textAlign="left"
              sx={{
                opacity: 1,
                backgroundImage: "none !important",
                height: "auto",
              }}
            >
              {_.startCase(key)}
            </Divider>
            <DataTable
              key={key}
              table={{
                columns: [
                  { Header: "№", accessor: "number" },
                  ...Object.keys(value[0])?.map((i) => ({ Header: i, accessor: i })),
                ],
                rows: getRows(value),
              }}
              entriesPerPage={false}
            />
          </>
        );
      } else {
        return (
          <SoftBox key={key} display="flex" py={1} pr={2} alignItems="center">
            <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
              {key}: &nbsp;
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;
              {_.map(value, (val) => (
                <Chip
                  key={val}
                  variant="contained"
                  label={val}
                  sx={{ ml: 1, backgroundColor: "#344767", color: "#fff" }}
                />
              ))}
            </SoftTypography>
          </SoftBox>
        );
      }
    }
  });
  const renderObjectItems = _.map(_.get(credentials, "data", {}), (value, key) => {
    if (_.isObject(value) && !_.isArray(value)) {
      return (
        <>
          <Divider
            textAlign="left"
            sx={{
              opacity: 1,
              backgroundImage: "none !important",
              height: "auto",
            }}
          >
            {_.startCase(key)}
          </Divider>
          {_.map(value, (iValue, iKey) => {
            if (_.isString(iValue) || _.isNumber(iValue)) {
              return renderSNItem(iKey, iValue);
            }
          })}
        </>
      );
    }
  });
  return (
    <SoftBox ml={3}>
      {renderItems}
      {renderObjectItems}
      {renderArrayItems}
      <SoftBox display="flex" py={1} pr={2}>
        <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
          created At: &nbsp;
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          &nbsp;{fDateTimeSuffix(_.get(credentials, "createdAt", null))}
        </SoftTypography>
      </SoftBox>
      <SoftBox display="flex" py={1} pr={2}>
        <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
          updated At: &nbsp;
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          &nbsp;{fDateTimeSuffix(_.get(credentials, "createdAt", null))}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};

CredentialOverview.propTypes = {
  credentials: PropTypes.object,
};

export default CredentialOverview;
