import { Grid, Icon, IconButton } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

import { CurrencySelect } from "components/FormField";
import SoftButton from "components/SoftButton";

const CurrenciesArray = ({ nestIndex, tab }) => {
  const { control } = useFormContext();
  const {
    fields: currencies,
    remove,
    append,
  } = useFieldArray({
    control,
    name:
      tab === 0
        ? `payinCountries[${nestIndex}].currencies`
        : `payoutCountries[${nestIndex}].currencies`,
  });

  return (
    <div>
      {_.map(currencies, (field, index) => (
        <Grid
          container
          key={field.id}
          spacing={2}
          display="flex"
          alignItems="center"
          justifyContent={"start"}
        >
          <Grid item sm={1.75} xs={false}></Grid>
          <Grid container spacing={2} xs={10} sm={9} mt={2}>
            <Grid item sm={6}>
              <CurrencySelect
                name={
                  tab === 0
                    ? `payinCountries[${nestIndex}].currencies[${index}].code`
                    : `payoutCountries[${nestIndex}].currencies[${index}].code`
                }
                label={`Currency ${index + 1}`}
                additionalCurrencies={[{ name: "All local currencies", code: "0" }]}
              />
            </Grid>
            <Grid item sm={6}>
              <CurrencySelect
                name={
                  tab === 0
                    ? `payinCountries[${nestIndex}].currencies[${index}].fxCurrency`
                    : `payoutCountries[${nestIndex}].currencies[${index}].fxCurrency`
                }
                label={`fxCurrency ${index + 1}`}
                additionalCurrencies={[{ name: "All local currencies", code: "0" }]}
              />
            </Grid>
          </Grid>
          <Grid item xs={0.5} mt={2} sm={1}>
            <IconButton onClick={() => remove(index)} sx={{ mt: 2 }}>
              <Icon>delete_forever</Icon>
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid container spacing={2}>
        <Grid item xs={2}></Grid>
        <Grid item xs={9.5}>
          <SoftButton sx={{ mt: 2, mb: 2 }} onClick={() => append({})}>
            Add currency
          </SoftButton>
        </Grid>
      </Grid>
    </div>
  );
};

CurrenciesArray.propTypes = {
  nestIndex: PropTypes.number.isRequired,
  tab: PropTypes.number.isRequired,
};

export default CurrenciesArray;
