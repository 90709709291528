import React from "react";

import { Grid, IconButton, Icon, Tooltip } from "@mui/material";

import SoftBox from "components/SoftBox";

import { SelectInputField, TextInputField } from "components/FormField";
import PropTypes from "prop-types";
import SoftButton from "components/SoftButton";
import { useFormContext, useFieldArray } from "react-hook-form";
import zIndex from "@mui/material/styles/zIndex";
import { localFieldOptions } from "constants/reconciliation";
import { statusOptions as defaultStatusOptions } from "constants/status";
import _ from "lodash";

const Sheet = ({ nestedIndex, removeSheet, sheetOptions }) => {
  const { control } = useFormContext();
  const statusOptions = _.filter(defaultStatusOptions, (option) => option.label !== "All");
  const {
    fields,
    append: appendField,
    remove: removeField,
  } = useFieldArray({
    control,
    name: `selectedSheets[${nestedIndex}].fieldsOfInterest`,
  });
  const {
    fields: statuses,
    append: appendStatus,
    remove: removeStatus,
  } = useFieldArray({
    control,
    name: `selectedSheets[${nestedIndex}].statusMap`,
  });
  return (
    <SoftBox>
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid container rowSpacing={1} spacing={2}>
            <Grid item xs={6}>
              <SelectInputField
                name={`selectedSheets[${nestedIndex}].name`}
                label="Sheet Name"
                options={sheetOptions}
                menuPosition={"fixed"}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInputField
                name={`selectedSheets[${nestedIndex}].type`}
                label="Type"
                options={[
                  { label: "PAYIN", value: "PAYIN" },
                  { label: "PAYOUT", value: "PAYOUT" },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInputField
                name={`selectedSheets[${nestedIndex}].headerLine`}
                label="Header Line"
              />
            </Grid>
            <Grid item xs={6}>
              <TextInputField
                name={`selectedSheets[${nestedIndex}].startFrom`}
                label="Start From"
              />
            </Grid>
            <Grid item xs={6} mt={-1}>
              {fields && fields.length > 0 && (
                <SoftBox
                  sx={{
                    border: "1px solid #e0e0e0",
                    p: 2,
                    borderRadius: 4,
                    position: "relative",
                  }}
                >
                  <Tooltip title="Map the header fields with the internal properties.">
                    <Icon sx={{ fontSize: 10, ml: 0.5, position: "absolute", top: 3, right: 6 }}>
                      help
                    </Icon>
                  </Tooltip>
                  {_.map(fields, (field, index) => (
                    <Grid container rowSpacing={1} spacing={1} key={field.id}>
                      <Grid item xs={5}>
                        <TextInputField
                          name={`selectedSheets[${nestedIndex}].fieldsOfInterest[${index}].field`}
                          label="Field"
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <SelectInputField
                          name={`selectedSheets[${nestedIndex}].fieldsOfInterest[${index}].localField`}
                          label="Local Field"
                          options={localFieldOptions}
                          menuPosition={"fixed"}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton onClick={() => removeField(index)} sx={{ mt: 2 }}>
                          <Icon>delete_forever</Icon>
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <SoftButton
                    onClick={() => appendField({})}
                    variant="outlined"
                    color="info"
                    size="small"
                  >
                    Add Field
                  </SoftButton>
                </SoftBox>
              )}
              {!fields ||
                (fields.length === 0 && (
                  <SoftButton
                    onClick={() => appendField({})}
                    variant="outlined"
                    color="info"
                    size="small"
                  >
                    Add Field
                  </SoftButton>
                ))}
            </Grid>
            <Grid item xs={6} mt={-1}>
              {statuses && statuses.length > 0 && (
                <SoftBox
                  sx={{
                    border: "1px solid #e0e0e0",
                    p: 2,
                    borderRadius: 4,
                    position: "relative",
                  }}
                >
                  <Tooltip title="Map the psp statuses with the kasha statuses.">
                    <Icon sx={{ fontSize: 10, ml: 0.5, position: "absolute", top: 3, right: 6 }}>
                      help
                    </Icon>
                  </Tooltip>
                  {_.map(statuses, (field, index) => (
                    <Grid container rowSpacing={1} spacing={1} key={field.id}>
                      <Grid item xs={5}>
                        <TextInputField
                          name={`selectedSheets[${nestedIndex}].statusMap[${index}].status`}
                          label="Status"
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <SelectInputField
                          name={`selectedSheets[${nestedIndex}].statusMap[${index}].localStatus`}
                          label="Local Status"
                          options={statusOptions}
                          menuPosition={"fixed"}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton onClick={() => removeStatus(index)} sx={{ mt: 2 }}>
                          <Icon>delete_forever</Icon>
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <SoftButton
                    onClick={() => appendStatus({ localStatus: "COMPLETED" })}
                    variant="outlined"
                    color="info"
                    size="small"
                  >
                    Add Status Map
                  </SoftButton>
                </SoftBox>
              )}
              {!statuses ||
                (statuses.length === 0 && (
                  <SoftButton
                    onClick={() => appendStatus({ localStatus: "COMPLETED" })}
                    variant="outlined"
                    color="info"
                    size="small"
                  >
                    Add Status Map
                  </SoftButton>
                ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={1}>
          <IconButton onClick={() => removeSheet(nestedIndex)} sx={{ mt: 2 }}>
            <Icon>delete_forever</Icon>
          </IconButton>
        </Grid>
      </Grid>
    </SoftBox>
  );
};

Sheet.propTypes = {
  nestedIndex: PropTypes.number,
  removeSheet: PropTypes.func,
  sheetOptions: PropTypes.array,
};

export default Sheet;
