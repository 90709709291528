import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDisclosure } from "hooks/useDisclosure";
import { Dialog, DialogContent, DialogTitle, DialogActions, Grid } from "@mui/material";
import FormProvider from "components/FormProviders";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import DataTable from "examples/Tables/DataTable";
import { useTransactionStore } from "stores/transactions";
import { SelectInputField } from "components/FormField";
import { StatusEnum } from "constants/status";
import { generateOptionsFromEnum } from "utils/options";
import { useUpdateBatchStatus } from "../hooks";
import { set } from "lodash";

const UpdateTransactionStatusModal = () => {
  const [failedTransactions, setFailedTransactions] = React.useState([]);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { selectedTransactions, clearSelectedTransactions } = useTransactionStore();
  const methods = useForm();
  const { handleSubmit } = methods;
  //options
  const statusOptions = generateOptionsFromEnum(StatusEnum, false);

  // hooks
  const { mutate: updateBatchStatus, isLoading, isError, error } = useUpdateBatchStatus();

  const handleSubmitForm = ({ status }) => {
    updateBatchStatus(
      { status, transactionIds: selectedTransactions.map((item) => item.id) },
      {
        onSuccess: () => {
          clearSelectedTransactions();
          onClose();
        },
      }
    );
  };
  const getRows = () => {
    return _.map(selectedTransactions, (item) => {
      return {
        status: failedTransactions.includes(item.id) ? (
          <b style={{ color: "red" }}>Failed</b>
        ) : null,
        country: item.country,
        currency: item.currency,
        merchant: item.merchant,
        brand: item.brand,
        amount: item.paymentAmount ? item.paymentAmount : item.amount,
        id: item.id,
      };
    });
  };

  useEffect(() => {
    if (isError && error) {
      const failed = _.get(error, "response.data.data", []);
      setFailedTransactions(failed);
    }
  }, [isError, error]);

  return (
    <>
      <SoftButton
        variant="gradient"
        color="success"
        type="button"
        sx={{ mt: 2, mr: 2 }}
        onClick={onOpen}
      >
        Update Transaction Status
      </SoftButton>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={"lg"}>
        <DialogTitle
          sx={{
            background: (theme) => theme.palette.grey[500],
            fontWeight: (theme) => theme.typography.h6,
            color: (theme) => theme.palette.common.white,
            px: 4,
            py: 1,
          }}
        >
          Update Transaction Status
        </DialogTitle>
        <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
          <DialogContent>
            <SoftBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <SelectInputField label="Status" name="status" options={statusOptions} />
                </Grid>
              </Grid>
              <DataTable
                table={{
                  columns: [
                    { Header: "Update Status", accessor: "status" },
                    { Header: "id", accessor: "id" },
                    { Header: "merchant", accessor: "merchant" },
                    { Header: "brand", accessor: "brand" },
                    { Header: "country", accessor: "country" },
                    { Header: "currency", accessor: "currency" },
                    { Header: "amount", accessor: "amount" },
                  ],
                  rows: getRows(),
                }}
                showTotalEntries={false}
              />
            </SoftBox>
          </DialogContent>

          <DialogActions>
            <SoftButton onClick={onClose}>{"Cancel"}</SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              disabled={isLoading}
              isLoading={isLoading}
            >
              {"Update Status"}
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default UpdateTransactionStatusModal;
