import _ from "lodash";
import { Card, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import ActiveStatusChip from "components/ActiveStatusChip";
import SoftAvatar from "components/SoftAvatar";
import { countries } from "constants/countries";

const GeneralSettings = ({ method }) => {
  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Payment Options Details
        </SoftTypography>
      </SoftBox>
      <SoftBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={1}>
            <SoftAvatar
              src={method?.logo || ""}
              alt={method?.name}
              variant="rounded"
              size="lg"
              shadow="sm"
            />
          </Grid>
          <Grid item xs={11}>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {method?.name}
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={1}></Grid>
          <Grid item xs={11}>
            <SoftBox display="flex" mb={2}>
              <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize" mt={1}>
                Active: &nbsp;
              </SoftTypography>
              <ActiveStatusChip status={method?.active} />
            </SoftBox>
            <SoftBox display="flex" mb={2}>
              <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Refundable: &nbsp;
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                {`${method?.refundable}`}
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" mb={2}>
              <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Restricted Countries: &nbsp;
              </SoftTypography>
              <SoftBox mt={-1}>
                {method?.restrictedCountries.map((country, index) => {
                  const countryName = _.find(countries, (c) => c.code === country);
                  return (
                    <SoftBox key={country}>
                      <SoftTypography
                        key={index}
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        <b>{country}</b>&nbsp;&nbsp;{countryName ? `(${countryName.label})` : ""}
                      </SoftTypography>
                    </SoftBox>
                  );
                })}
              </SoftBox>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
};
GeneralSettings.propTypes = {
  method: PropTypes.object.isRequired,
};
export default GeneralSettings;
