import { ErrorCategory, ErrorAction } from "constants/errorCodes";

export const getErrorCategoryName = (category) => {
  switch (category) {
    case ErrorCategory.Internal:
      return "Internal error";
    case ErrorCategory.User:
      return "User error";
    case ErrorCategory.Provider:
      return "Provider error";
    default:
      return "Unknown";
  }
};

export const getErrorActionName = (action) => {
  switch (action) {
    case ErrorAction.BLACKLIST_IP:
      return "Blacklist IP";
    case ErrorAction.BLACKLIST_CARD:
      return "Blacklist Card";
    case ErrorAction.BLACKLIST_EMAIL:
      return "Blacklist Email";
    default:
      return "Unknown";
  }
};
export const getErrorActionsNames = (actions) => {
  return actions.map((action) => getErrorActionName(action)).join(", ");
};
