import { useQuery, useMutation } from "react-query";
import request from "utils/request";

const TEMPLATES = "/reconciliation-templates";

const getTemplates = (pspId) => {
  if (pspId) {
    return request.get(`${TEMPLATES}?pspId=${pspId}`);
  } else {
    return request.get(TEMPLATES);
  }
};

export const useGetTemplates = (pspId) => {
  return useQuery([TEMPLATES, pspId], () => getTemplates(pspId), { refetchOnWindowFocus: false });
};

const getTemplate = (id) => request.get(`${TEMPLATES}/${id}`);
export const useMutateGetTemplate = () => {
  return useMutation(getTemplate);
};
