import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import borders from "assets/theme/base/borders";
import { useEffect, useState } from "react";

function CollapseWrapper({ title, children, openDefault, additionalActionNode, ...rest }) {
  const [open, setOpen] = useState(false);
  const { borderWidth, borderColor } = borders;

  const handleCollapse = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (openDefault) {
      setOpen(openDefault);
    }
  }, [openDefault]);

  return (
    <SoftBox mb={0.5}>
      <SoftBox
        {...rest}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={0.3}
        borderBottom={`${borderWidth[1]} solid ${borderColor}`}
        sx={{ cursor: "pointer" }}
        onClick={handleCollapse}
      >
        <SoftTypography variant="h6" color={open ? "dark" : "text"} sx={{ userSelect: "none" }}>
          {title}
        </SoftTypography>
        <SoftBox color={open ? "dark" : "text"} display="flex" flexDirection="row">
          <IconButton sx={{ mt: 2 }}>
            <Icon sx={{ fontWeight: "bold" }} fontSize="small">
              {open ? "remove" : "add"}
            </Icon>
          </IconButton>

          {additionalActionNode && additionalActionNode}
        </SoftBox>
      </SoftBox>
      <Collapse timeout={400} in={open}>
        <SoftBox p={1} lineHeight={1}>
          {children}
        </SoftBox>
      </Collapse>
    </SoftBox>
  );
}
CollapseWrapper.defaultProps = {
  openDefault: false,
};
CollapseWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  openDefault: PropTypes.bool,
  additionalActionNode: PropTypes.node,
};

export default CollapseWrapper;
