import { toDate } from "date-fns";
import moment from "moment";

export const generateDateRange = (option) => {
  let fromDate, toDate;

  switch (option) {
    case "Today":
      fromDate = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
      toDate = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
      break;
    case "Yesterday":
      fromDate = moment().subtract(1, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss");
      toDate = moment().subtract(1, "days").endOf("day").format("YYYY-MM-DD HH:mm:ss");
      break;
    case "This week":
      fromDate = moment().startOf("isoWeek").format("YYYY-MM-DD HH:mm:ss");
      toDate = moment().endOf("isoWeek").format("YYYY-MM-DD HH:mm:ss");
      break;
    case "Last week":
      fromDate = moment().subtract(1, "weeks").startOf("isoWeek").format("YYYY-MM-DD HH:mm:ss");
      toDate = moment().subtract(1, "weeks").endOf("isoWeek").format("YYYY-MM-DD HH:mm:ss");
      break;
    case "This month":
      fromDate = moment().startOf("month").format("YYYY-MM-DD HH:mm:ss");
      toDate = moment().endOf("month").format("YYYY-MM-DD HH:mm:ss");
      break;
    case "Last month":
      fromDate = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD HH:mm:ss");
      toDate = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD HH:mm:ss");
      break;
    default:
      throw new Error("Invalid option");
  }

  return { fromDate, toDate };
};

export const generateISODateRange = (option) => {
  const dateRange = generateDateRange(option);
  return {
    fromDate: new Date(dateRange.fromDate).toISOString(),
    toDate: new Date(dateRange.toDate).toISOString(),
  };
};
