import { forwardRef } from "react";
import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// react-flatpickr components
import Flatpickr from "react-flatpickr";
// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import SoftInput from "components/SoftInput";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

const DatePickerField = forwardRef(
  ({ options = {}, input = {}, name, label = "", helperText = null, ...rest }, ref) => {
    const { control } = useFormContext();

    return (
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        height="100%"
        mb={1.5}
      >
        {label && (
          <SoftBox mb={1} ml={0.5} lineHeight={0} display="flex" alignItems="center">
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {label}
            </SoftTypography>
            {helperText && (
              <Tooltip title={helperText}>
                <Icon sx={{ fontSize: 10, ml: 0.5 }}>help</Icon>
              </Tooltip>
            )}
          </SoftBox>
        )}
        <SoftBox mb={1} lineHeight={0}>
          <Controller
            name={name}
            control={control}
            render={({ field: { ref: formRef, ...formRest }, fieldState: { error } }) => (
              <>
                <Flatpickr
                  {...formRest}
                  required
                  options={options}
                  onChange={(selectedDates) => {
                    if (rest.onChange) {
                      rest.onChange(selectedDates[0]);
                    }
                    formRest.onChange(selectedDates[0]);
                  }}
                  render={({ defaultValue }, ref) => (
                    <SoftInput {...input} defaultValue={null} inputRef={ref} />
                  )}
                />
                {error && (
                  <SoftBox mt={0.75}>
                    <SoftTypography component="div" variant="caption" color="error">
                      {error?.message}
                    </SoftTypography>
                  </SoftBox>
                )}
              </>
            )}
          />
        </SoftBox>
      </SoftBox>
    );
  }
);

// Typechecking props for the SoftInput
DatePickerField.propTypes = {
  label: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  input: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  helperText: PropTypes.string,
};

export default DatePickerField;
