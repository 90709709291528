import _ from "lodash";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import WrapperItem from "components/ActionCell/WrapperItem";

import { actionIcons } from "constants/actions";

function ActionCell({
  actions,
  onVisibilityClick,
  onEditClick,
  onDeleteClick,
  onResetClick,
  onNotificationClick,
  onWithdrawalClick,
}) {
  const handleClick = (type) => {
    switch (type) {
      case "visibility":
        onVisibilityClick();
        break;
      case "edit":
        onEditClick();
        break;
      case "delete":
        onDeleteClick();
        break;
      case "reset":
        onResetClick();
        break;
      case "notification":
        onNotificationClick();
        break;
      case "withdrawal":
        onWithdrawalClick();
        break;
      default:
        break;
    }
  };

  return (
    <SoftBox display="flex" alignItems="center">
      {_.map(actions, (action, key) => {
        return (
          <WrapperItem index={key} key={key} actionsLength={actions.length}>
            <SoftTypography
              variant="body1"
              color="secondary"
              sx={{ cursor: "pointer", lineHeight: 0 }}
            >
              <Tooltip
                title={action.title}
                placement="top"
                onClick={() => handleClick(action.type)}
              >
                <Icon>{actionIcons[action.type]}</Icon>
              </Tooltip>
            </SoftTypography>
          </WrapperItem>
        );
      })}
    </SoftBox>
  );
}

// Setting default values for the props of ActionCell
ActionCell.defaultProps = {
  actions: [],
  onVisibilityClick: () => {},
  onEditClick: () => {},
  onDeleteClick: () => {},
  onResetClick: () => {},
  onWithdrawalClick: () => {},
  onNotificationClick: () => {},
};

// Typechecking props of the ActionCell
ActionCell.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  onVisibilityClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onResetClick: PropTypes.func,
  onWithdrawalClick: PropTypes.func,
  onNotificationClick: PropTypes.func,
};

export default ActionCell;
