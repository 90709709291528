import { create } from "zustand";

type Brand = {
  _id: string | null;
  name: string | null;
  pspMids: PspMid[];
  domains: string[];
  ipWhitelists: IpWhitelist[];
  paymentFac: boolean;
};

type PspMid = {
  _id: string;
  pspMidId: string;
  payinEnabled: boolean;
  payoutEnabled: boolean;
  paymentMethods: any[];
};

type IpWhitelist = {
  startingIp: string;
  endingIp: string;
};

type BrandStore = {
  brand: Brand;
  setBrand: (brand: Brand) => void;
};

const initialState: BrandStore = {
  brand: {
    _id: null,
    name: null,
    pspMids: [],
    domains: [],
    ipWhitelists: [],
    paymentFac: false,
  },
  setBrand: () => null,
};

export const useBrandStore = create((set) => ({
  ...initialState,
  setBrand: (values: Brand) => {
    return set(({ brand }) => ({ brand: { ...brand, ...values } }));
  },
}));
