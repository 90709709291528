import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useGetFilterWhitelist } from "../hooks";
import { get, set } from "../../../utils/localStorage";
import _ from "lodash";
import DataTable from "../../../examples/Tables/DataTable";
import { fDateTimeSuffix } from "../../../utils/formatTime";
import FormProvider from "../../../components/FormProviders";
import CustomersFilter from "./CustomersFilter";
import { useForm } from "react-hook-form";
import SoftBadge from "components/SoftBadge";
import AddCustomerToWhitelistModal from "./AddCustomerToWhitelistModal";
import DeleteCustomerFromWhitelist from "./DeleteCustomerFromWhitelist";

const LC_PAYLOAD_WHITE = "customerwhitelistPayload";

const Whitelist = (props) => {
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const whitelistPayload = get(LC_PAYLOAD_WHITE) || {};
  const { mutate: getWhitelist, isLoading: isLoading, data: whitelist } = useGetFilterWhitelist();

  const methods = useForm({ whitelistPayload });
  const { handleSubmit } = methods;
  useEffect(() => {
    getWhitelist({
      page: 1,
      email: "",
      ip: "",
      limit: pageSize,
    });
  }, []);

  const onSubmit = (values) => {
    const payload = {
      ...values,
      limit: pageSize,
      page: 1,
    };
    set(LC_PAYLOAD_WHITE, payload);
    getWhitelist(payload);
  };

  const updateData = () => {
    setCurrentPage(1);
    getWhitelist({
      page: 1,
      email: "",
      ip: "",
      limit: pageSize,
    });
  };

  const getRows = () => {
    return _.map(_.get(whitelist, "docs", []), (item) => {
      return {
        _id: item._id,
        email: item.email,
        ip: item.ip,
        createdAt: fDateTimeSuffix(item.createdAt),
        updatedAt: fDateTimeSuffix(item.updatedAt),
        tags: (
          <>
            {_.map(item.tags, (tag) => (
              <SoftBadge
                color={"secondary"}
                size="xs"
                badgeContent={tag}
                container
                sx={{ ml: 1 }}
              />
            ))}
          </>
        ),
        reason: item.reason,
        actions: <DeleteCustomerFromWhitelist id={item._id} updateData={updateData} />,
      };
    });
  };

  // create a function that handles the page change
  const handlePageChange = (page) => {
    setCurrentPage(page + 1);
    whitelistPayload.page = page + 1;
    set(LC_PAYLOAD_WHITE, whitelistPayload);
    getWhitelist(whitelistPayload);
  };
  // create a function that handles the page size change
  const handlePageSizeChange = (pageSizes) => {
    if (pageSizes !== pageSize) {
      setCurrentPage(1);
      setPageSize(pageSizes);
      whitelistPayload.page = 1;
      whitelistPayload.limit = pageSizes;
      set(LC_PAYLOAD_WHITE, whitelistPayload);
      getWhitelist(whitelistPayload);
    }
  };

  return (
    <>
      <Grid container mt={3} display={"inline-block"}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <CustomersFilter onSubmit={handleSubmit(onSubmit)} whitelist={true} />
        </FormProvider>
      </Grid>

      <Grid container display="flex" justifyContent="end" alignItems="center" mt={3}>
        <AddCustomerToWhitelistModal updateData={updateData} />
      </Grid>
      <DataTable
        table={{
          columns: [
            { Header: "Id", accessor: "_id" },
            { Header: "Email", accessor: "email" },
            { Header: "ip", accessor: "ip" },
            { Header: "tags", accessor: "tags" },
            { Header: "reason", accessor: "reason" },
            { Header: "Created At", accessor: "createdAt" },
            { Header: "Updated At", accessor: "updatedAt" },
            { Header: "Actions", accessor: "actions" },
          ],
          rows: getRows(),
        }}
        manualPagination={true}
        nextPage={_.get(whitelist, "nextPage", 0)}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        pageCount={_.get(whitelist, "totalPages", 0)}
        rowCount={_.get(whitelist, "totalDocs", 0)}
        isLoading={isLoading}
        currentPage={currentPage}
      />
    </>
  );
};

export default Whitelist;
