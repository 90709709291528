import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import SoftBox from "components/SoftBox";

import DataTable from "examples/Tables/DataTable";
import { useGetBrands } from "pages/merchant-management-detail/hooks";
import CreateBrand from "./CreateBrand";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";
const Brands = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  if (!hasPermission(Permissions.BRAND.LIST)) {
    return <Error403 />;
  }

  const { data } = useGetBrands(id);

  const getRows = () => {
    return _.map(data, (item) => ({
      id: item._id,
      name: item.name,
      payFac: item.paymentFac ? "Yes" : "No",
      ipWhitelists: item.ipWhitelists,
    }));
  };

  return (
    <SoftBox>
      <SoftBox display="flex" justifyContent="flex-end">
        <CreateBrand />
      </SoftBox>
      <SoftBox mt={2}>
        <DataTable
          table={{
            columns: [
              { Header: "id", accessor: "id" },
              { Header: "name", accessor: "name" },
              { Header: "payfac", accessor: "payFac" },
            ],
            rows: getRows(),
          }}
          canSearch={true}
          onCellClick={(row) => {
            navigate(`/merchants/${id}/brands/${row?.original?.id}`);
          }}
          isClickable={true}
        />
      </SoftBox>
    </SoftBox>
  );
};

export default Brands;
