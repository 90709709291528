import React, { useState } from "react";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";

import Transactions from "./components/Transactions";
import SoftBox from "components/SoftBox";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { Settings } from "@mui/icons-material";
import TabPanel, { a11yProps } from "components/TabPanel";
import ReconciliationOverview from "./components/Overview";

const ReconciliationDetail = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MainLayout>
      <MainNavbar />
      <SoftBox sx={{ maxWidth: 400 }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ backgroundColor: "transparent" }}
            variant="standard"
          >
            <Tab icon={<Settings />} label="Overview" {...a11yProps(0)} />
            <Tab icon={<TableRowsIcon />} label="Transactions" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
      </SoftBox>
      <TabPanel value={value} index={0}>
        <ReconciliationOverview />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Transactions />
      </TabPanel>
    </MainLayout>
  );
};

export default ReconciliationDetail;
