import { useMutation, useQuery, useQueryClient } from "react-query";
import request from "utils/request";
import { toastSuccess } from "../../utils/toast";
import { getQueryParam, getTemplatePath } from "utils/string";

const GET_SETTINGS = "/settings";
const UPDATE_SETTINGS = "/settings";

const getSettings = () => request.get(GET_SETTINGS);
const updateSettings = (data) => request.put(UPDATE_SETTINGS, data);
export const useGetSettings = () => {
  return useQuery([GET_SETTINGS], getSettings);
};

export const useUpdateSettings = () => {
  const queryClient = useQueryClient();
  return useMutation(updateSettings, {
    onSuccess: () => {
      toastSuccess({ description: "Update settings has been success" });
      queryClient.invalidateQueries([GET_SETTINGS]);
    },
  });
};

// templates
const TEMPLATES = "psp/:pspId/templates";
const TEMPLATE = "psp/:pspId/templates/:templateId";
const getTemplates = ({ pspId, ...queries }) => {
  let URL = getTemplatePath(TEMPLATES, { pspId });
  const params = getQueryParam(queries);
  if (params) {
    URL = `${URL}?${params}`;
  }
  return request.get(URL);
};
export const useGetTemplates = () => {
  return useMutation(getTemplates);
};

const createTemplate = ({ pspId, ...payload }) => {
  const URL = getTemplatePath(TEMPLATES, { pspId });
  return request.post(URL, payload);
};
export const useCreateTemplate = () => {
  return useMutation(createTemplate);
};

const updateTemplate = ({ pspId, templateId, ...payload }) => {
  const URL = getTemplatePath(TEMPLATE, { pspId, templateId });
  return request.put(URL, payload);
};
export const useUpdateTemplate = () => {
  return useMutation(updateTemplate);
};

const deleteTemplate = ({ pspId, templateId }) => {
  const URL = getTemplatePath(TEMPLATE, { pspId, templateId });
  return request.delete(URL);
};
export const useDeleteTemplate = () => {
  return useMutation(deleteTemplate);
};
