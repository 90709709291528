import { Box, Divider, Grid } from "@mui/material";
import React from "react";

import { SelectInputField, TextInputField } from "../../../components/FormField";
import { useGetMerchants } from "../../merchant-management/hooks";
import _ from "lodash";
import PropTypes from "prop-types";
const WhitelistForm = ({ tab }) => {
  const { data } = useGetMerchants();
  const options = [{ value: null, label: "All" }].concat(
    _.map(data, (item) => ({ value: item._id, label: item.name }))
  );
  return (
    <Box>
      <Grid container spacing={3}>
        {tab === 0 && (
          <Grid item xs={12} sm={12} mb="-25px">
            <SelectInputField
              label="Merchants"
              name="merchantId"
              options={options}
              isClearable
              placeholder="Select an option"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={tab === 0 ? 3 : 6}>
          <TextInputField label="Bin" name="bin" />
        </Grid>
        <Grid item xs={12} sm={tab === 0 ? 3 : 6}>
          <TextInputField label="Last 4" name="last4" />
        </Grid>

        {tab === 0 && (
          <Grid item xs={12} sm={6}>
            <TextInputField label="Score Override" name="scoreOverride" />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Expiry Month" name="expiryMonth" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Expiry Year" name="expiryYear" />
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};

export default WhitelistForm;

// Typechecking props of the WhitelistForm
WhitelistForm.propTypes = {
  tab: PropTypes.number.isRequired,
};
