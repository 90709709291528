import { useState, useEffect } from "react";

import _ from "lodash";
import { useParams, useNavigate } from "react-router-dom";

import Error403 from "components/Error403";
import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import SoftBox from "components/SoftBox";
import TabPanel, { a11yProps } from "components/TabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SegmentIcon from "@mui/icons-material/Segment";
import CircularProgress from "@mui/material/CircularProgress";
import { Card, Icon } from "@mui/material";
import SoftButton from "components/SoftButton";

import { usePspsStore } from "stores/psps";
import { useGetPaymentOption } from "./hooks";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";

import GeneralSettings from "./components/GeneralSettings";
import MethodRoutes from "./components/MethodRoutes";
import SubOptions from "./components/SubOptions";

export const METHOD_TABS = {
  GENERAL: 0,
  PAYIN_ROUTES: 1,
  PAYOUT_ROUTES: 2,
  SUBOPTIONS: 3,
};

const PspMethods = () => {
  const { id: pspId, methodId } = useParams();
  const navigate = useNavigate();
  if (!hasPermission(Permissions.PSP_METHOD.GET)) {
    return (
      <MainLayout>
        <MainNavbar
          customRoutes={[
            { label: "psps", route: "psps" },
            { label: pspId, route: pspId },
            { label: "methods", route: "methods" },
            { label: methodId, route: methodId },
          ]}
        />
        <Error403 />
      </MainLayout>
    );
  }

  const { psps } = usePspsStore();
  const [tab, setTab] = useState(METHOD_TABS.GENERAL);
  const [psp, setPsp] = useState({});
  const { data, isLoading } = useGetPaymentOption({ pspId, methodId });

  const onTabChange = (event, newValue) => {
    setTab(newValue);
  };
  const onBackBtnClick = () => {
    navigate(`/psps/${pspId}`);
  };
  useEffect(() => {
    const psp = _.find(psps, (item) => _.get(item, "_id") === pspId);
    setPsp(psp);
  }, [psps]);

  return (
    <MainLayout>
      <MainNavbar
        customRoutes={[
          { label: "psps", route: "psps" },
          { label: _.get(psp, "name", pspId), route: `psps/${pspId}` },
          { label: "methods", route: `psps/${pspId}` },
          { label: _.get(data, "name", methodId), route: methodId },
        ]}
      />

      <SoftBox display="flex" justifyContent="space-between" alignItems="center" py={2} px={3}>
        <SoftButton
          variant="outlined"
          color="dark"
          sx={{ border: "none", "&:hover": { border: "none" } }}
          onClick={onBackBtnClick}
        >
          <Icon>arrow_back</Icon> &nbsp;&nbsp;&nbsp;BACK TO PSP
        </SoftButton>
      </SoftBox>

      <SoftBox sx={{ maxWidth: 1000, marginTop: 2, marginBottom: 2 }}>
        <AppBar position="static">
          <Tabs
            value={tab}
            onChange={onTabChange}
            aria-label="basic tabs example"
            sx={{ backgroundColor: "transparent" }}
            variant="standard"
          >
            <Tab
              icon={<FormatListBulletedIcon />}
              label="General Settings"
              {...a11yProps(METHOD_TABS.GENERAL)}
            />
            <Tab
              icon={<ArrowDownwardIcon />}
              label="Payin Routes"
              {...a11yProps(METHOD_TABS.PAYIN_ROUTES)}
            />
            <Tab
              icon={<ArrowUpwardIcon />}
              label="Payout Routes"
              {...a11yProps(METHOD_TABS.PAYOUT_ROUTES)}
            />
            <Tab icon={<SegmentIcon />} label="SubOptions" {...a11yProps(METHOD_TABS.SUBOPTIONS)} />
          </Tabs>
        </AppBar>
      </SoftBox>
      <TabPanel value={tab} index={METHOD_TABS.GENERAL}>
        {isLoading && (
          <SoftBox sx={{ display: "flex" }}>
            <CircularProgress color="black" />
          </SoftBox>
        )}
        {!isLoading && <GeneralSettings method={data} />}
      </TabPanel>
      <TabPanel value={tab} index={METHOD_TABS.PAYIN_ROUTES}>
        <MethodRoutes
          title="Payin Routes"
          routes={_.get(data, "payinRoutes", [])}
          isLoading={isLoading}
        />
      </TabPanel>
      <TabPanel value={tab} index={METHOD_TABS.PAYOUT_ROUTES}>
        <MethodRoutes
          title="Payout Routes"
          routes={_.get(data, "payoutRoutes", [])}
          isLoading={isLoading}
        />
      </TabPanel>
      <TabPanel value={tab} index={METHOD_TABS.SUBOPTIONS}>
        <SubOptions subOptions={_.get(data, "suboptions", [])} isLoading={isLoading} />
      </TabPanel>
    </MainLayout>
  );
};

export default PspMethods;
