import React, { useEffect, useState } from "react";

import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useForm } from "react-hook-form";

import SoftButton from "../../../components/SoftButton";
import FormProvider from "../../../components/FormProviders";
import SoftBox from "../../../components/SoftBox";
import { useAddCSVToBlacklist, useAddCSVToWhitelist } from "../hooks";

import PropTypes from "prop-types";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Grid from "@mui/material/Grid";
import SoftTypography from "../../../components/SoftTypography";

const UploadCSV = ({ updateData, place }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [file, setFile] = useState();
  //form provider
  const methods = useForm();
  const { handleSubmit, getValues, setValue } = methods;
  useEffect(() => {
    if (!isOpenModal) {
      Object.keys(getValues()).map((val) => {
        setValue(val, null);
      });
    }
  }, [isOpenModal, place]);

  //hooks
  const { isLoading, mutate } = useAddCSVToBlacklist();
  const { isLoading: isWhiteLoading, mutate: whiteMutate } = useAddCSVToWhitelist();
  const currentMutate = place === 0 ? whiteMutate : mutate;
  const currentLoading = place === 0 ? isWhiteLoading : isLoading;
  // create a function that handles the form submission
  const onSubmit = async () => {
    const formData = new FormData();
    formData.append("file", file);
    currentMutate(
      { payload: formData },
      {
        onSuccess: () => {
          updateData();
          setFile(null);
          handleCloseModal();
        },
      }
    );
  };
  const handleOpenModal = () => {
    setIsOpenModal(true);
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
  };
  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };
  return (
    <>
      <SoftBox pt={2} px={2} mb={3}>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <SoftButton variant="gradient" color="info" size="small" onClick={handleOpenModal}>
            Upload CSV
          </SoftButton>
        </Stack>
      </SoftBox>
      <Dialog open={isOpenModal} onClose={handleCloseModal} fullWidth maxWidth="lg">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Upload CSV to Blacklist
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              position={"relative"}
              gap={2}
              pt={3}
            >
              <label htmlFor="upload-photo">
                <UploadFileIcon fill={"#344767!important"} fontSize={"large"} />
              </label>
              <SoftBox>
                <SoftTypography>{file?.name ? file?.name : "Upload file"}</SoftTypography>
                {file == null && (
                  <SoftTypography variant="caption">Drop file here to upload</SoftTypography>
                )}
              </SoftBox>
              <input
                style={{ opacity: "0", position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
                type="file"
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleOnChange}
                name="file"
              />
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px" }}
          >
            <SoftButton variant="gradient" sx={{ mt: 2 }} onClick={handleCloseModal}>
              Cancel
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              sx={{ mt: 2 }}
              disabled={currentLoading || file == null}
              isLoading={currentLoading}
            >
              Upload CSV
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default UploadCSV;

// Typechecking props of the UploadCSV
UploadCSV.propTypes = {
  place: PropTypes.number.isRequired,
  updateData: PropTypes.func.isRequired,
};
