import React, { useEffect, useState } from "react";

import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useForm } from "react-hook-form";

import SoftButton from "../../../components/SoftButton";
import FormProvider from "../../../components/FormProviders";
import SoftBox from "../../../components/SoftBox";
import { useAddCustomerToBlacklist } from "../hooks";

import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { blackDefaultValues, blackSchema } from "../schemas/blacklist";
import Grid from "@mui/material/Grid";
import { TextInputField, TagsInput } from "../../../components/FormField";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import TabPanel, { a11yProps } from "../../../components/TabPanel";
import ReceiptIcon from "@mui/icons-material/Receipt";

const CommonFields = () => {
  return (
    <>
      <Grid item xs={12} sm={12}>
        <TagsInput label="tags" name="tags" />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInputField label="reason" name="reason" />
      </Grid>
    </>
  );
};

const AddCustomerToBlacklistModal = ({ updateData }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [tab, setTab] = useState(0);

  //hooks
  const { isLoading: isLoadingBlack, mutate: mutateBlacklist } = useAddCustomerToBlacklist();

  //form provider
  const methods = useForm({
    resolver: yupResolver(blackSchema),
    defaultValues: blackDefaultValues,
  });
  const { handleSubmit, getValues, setValue } = methods;
  useEffect(() => {
    if (!isOpenModal) {
      Object.keys(getValues()).map((val) => {
        setValue(val, null);
      });
    }
  }, [isOpenModal]);
  useEffect(() => {
    setValue("ip", null);
    setValue("email", null);
  }, [tab]);

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    mutateBlacklist(
      { payload: values },
      {
        onSuccess: () => {
          updateData();
          handleCloseModal();
        },
      }
    );
  };
  const handleOpenModal = () => {
    setIsOpenModal(true);
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <SoftBox mb={3}>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <SoftButton variant="gradient" color="info" size="small" onClick={handleOpenModal}>
            Add Customer to Blacklist
          </SoftButton>
        </Stack>
      </SoftBox>
      <Dialog open={isOpenModal} onClose={handleCloseModal} fullWidth maxWidth="lg">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Add Customer to blacklist
          </DialogTitle>

          <DialogContent sx={{ pt: 0 }}>
            <Grid>
              <SoftBox sx={{ maxWidth: 700, marginTop: 2 }}>
                <AppBar position="static">
                  <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                    sx={{ backgroundColor: "transparent" }}
                    variant="standard"
                  >
                    <Tab
                      icon={<CreditCardIcon />}
                      label="By IP"
                      {...a11yProps(0)}
                      sx={{ marginRight: 2 }}
                    />
                    <Tab
                      icon={<ReceiptIcon />}
                      label="By Email"
                      {...a11yProps(1)}
                      sx={{ marginRight: 2 }}
                    />
                  </Tabs>
                </AppBar>
              </SoftBox>
            </Grid>
            <TabPanel value={tab} index={0}>
              <SoftBox>
                <Grid item xs={12} sm={12}>
                  <TextInputField label="Ip" name="ip" />
                  <CommonFields />
                </Grid>
              </SoftBox>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <SoftBox>
                <Grid item xs={12} sm={12}>
                  <TextInputField label="Email" name="email" />
                  <CommonFields />
                </Grid>
              </SoftBox>
            </TabPanel>
          </DialogContent>

          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mt: "-30px",
            }}
          >
            <SoftButton variant="gradient" onClick={handleCloseModal}>
              Cancel
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              disabled={isLoadingBlack}
              isLoading={isLoadingBlack}
            >
              Add Customer
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default AddCustomerToBlacklistModal;

// Typechecking props of the AddCustomerToBlacklistModal
AddCustomerToBlacklistModal.propTypes = {
  updateData: PropTypes.func.isRequired,
};
