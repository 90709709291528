import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import _ from "lodash";
import PropTypes from "prop-types";

const SimpleTimeLineItem = ({ label, value, dark }) => {
  return (
    <SoftBox display="flex">
      <SoftBox display="flex" flexDirection="column" justifyContent="center">
        <SoftTypography variant="caption" color={dark ? "white" : "text"}>
          {label}:&nbsp;&nbsp;
        </SoftTypography>
      </SoftBox>

      <SoftBox display="flex" flexDirection="column" justifyContent="center">
        <SoftTypography
          component="div"
          textTransform="capitalize"
          variant="caption"
          fontWeight="medium"
          color={dark ? "white" : "text"}
        >
          {value}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};
SimpleTimeLineItem.defaultProps = {
  dark: false,
};
SimpleTimeLineItem.propTypes = {
  dark: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default SimpleTimeLineItem;
