import TimelineItem from "examples/Timeline/TimelineItem";
import TimelineList from "examples/Timeline/TimelineList";
import { transactionSingleTimeLine } from "./transactionSingleTimeLine";
import PropTypes from "prop-types";

const TimeLineView = ({ events }) => {
  const generageTimeLineData = () => {
    if (events) {
      let timelineData = [];
      for (let i = 0; i < events.length; i++) {
        const isLast = i == events.length - 1;
        timelineData.push(transactionSingleTimeLine(events[i], isLast));
      }
      return timelineData;
    } else return [];
  };

  const timelineData = generageTimeLineData();
  const renderTimelineItems = timelineData.map(
    ({ color, icon, title, dateTime, description, badges, lastItem }) => (
      <TimelineItem
        key={title + color}
        color={color}
        icon={icon}
        title={title}
        dateTime={dateTime}
        description={description}
        badges={badges}
        lastItem={lastItem}
      />
    )
  );
  return <TimelineList title="Transaction Progress">{renderTimelineItems}</TimelineList>;
};

TimeLineView.defaultProps = {
  events: [],
};
TimeLineView.propTypes = {
  events: PropTypes.array,
};

export default TimeLineView;
