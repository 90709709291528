import React, { useState } from "react";

import MainNavbar from "../../examples/Navbars/MainNavbar";
import MainLayout from "../../examples/LayoutContainers/MainLayout";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import TabPanel, { a11yProps } from "components/TabPanel";
import SoftBox from "components/SoftBox";

import { CardActions, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { useGetSettings } from "./hooks";
import PropTypes from "prop-types";
import CardContent from "@mui/material/CardContent";
import EnabledChip from "../../components/EnabledChip";
import EditSettings from "./components/EditSettings";
import ManageUsers from "./components/ManageUsers";
import ManageRoles from "./components/ManageRoles";
import PaymentMethodsTemplates from "./components/PaymentMethodsTemplates";
import DescriptionIcon from "@mui/icons-material/Description";
import Templates from "./components/Templates";

const StatusLabel = ({ label, value }) => (
  <Stack direction="row" spacing={3} justifyContent="space-between" mb={3}>
    <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
      {label}:
    </Typography>
    <EnabledChip label={value} size="medium" />
  </Stack>
);
const TextLabel = ({ label, value }) => (
  <Stack direction="row" spacing={3} justifyContent="space-between" mb={3}>
    <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
      {label}:
    </Typography>
    <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
      {value}
    </Typography>
  </Stack>
);
const Settings = () => {
  const { data } = useGetSettings();
  const [tab, setTab] = useState(0);
  const onTabChange = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <MainLayout>
      <MainNavbar />
      <Card sx={{ p: 3, mb: 4, mt: 3 }}>
        <CardContent sx={{ p: 3 }}>
          <StatusLabel label="Card Score Routing" value={data?.enableCardScoreRouting} />
          <StatusLabel label="Bin Country Check" value={data?.enableBinCountryCheck} />
          <StatusLabel label="Customer Check" value={data?.enableCustomerCheck} />
          <TextLabel label="Min Customer Risk" value={data?.minCustomerRisk} />
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <EditSettings data={data} />
        </CardActions>
      </Card>
      <SoftBox sx={{ maxWidth: 800, marginTop: 2, marginBottom: 2 }}>
        <AppBar position="static">
          <Tabs
            value={tab}
            onChange={onTabChange}
            aria-label="basic tabs example"
            sx={{ backgroundColor: "transparent" }}
            variant="standard"
          >
            <Tab icon={<PersonIcon />} label="Users" {...a11yProps(0)} />
            <Tab icon={<GroupIcon />} label="Roles" {...a11yProps(1)} />
            <Tab icon={<LibraryBooksIcon />} label="PM Templates" {...a11yProps(2)} />
            <Tab icon={<DescriptionIcon />} label="Templates" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
      </SoftBox>
      <TabPanel value={tab} index={0}>
        <ManageUsers />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <ManageRoles />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <PaymentMethodsTemplates />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <Templates />
      </TabPanel>
    </MainLayout>
  );
};

export default Settings;

StatusLabel.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
};
TextLabel.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
};
