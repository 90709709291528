import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { useDisclosure } from "hooks/useDisclosure";

import FormProvider from "components/FormProviders";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import {
  useUpdatePaymentMethod,
  useGetPaymentMethod,
  GET_PAYMENT_METHOD,
} from "pages/brand-detail/hooks";
import PaymentMethodForm from "./PaymentMethodForm";

import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema, sanitizeRequest } from "pages/brand-detail/schemas/paymentMethod";
import { useQueryClient } from "react-query";
import { findDefaultCurrency, isDefaultCurrency } from "utils/country";

const updateGroup = (groups, country) => {
  let _groups = groups;
  for (let g of _groups) {
    if (_.isEqual(g.currencies, country.currencies)) {
      g.code = [country.code, ...g.code];
      return _groups;
    }
  }
  _groups.push({ code: [country.code], currencies: country.currencies });
  return _groups;
};

export const groupCountryBySetting = (countries) => {
  let groups = [];
  countries?.forEach((c) => {
    groups = updateGroup(groups, c);
  });
  return groups;
};
const replaceDefaultCurrency = (country, currencies) => {
  return _.map(currencies, ({ code, fxCurrency }) => {
    const _code = isDefaultCurrency(country, code) ? "0" : code;
    const _fx = fxCurrency && isDefaultCurrency(country, fxCurrency) ? "0" : fxCurrency;
    return {
      code: _code,
      fxCurrency: _fx,
    };
  });
};
export const sanitizeDefaultCurrency = (countries) => {
  return _.map(countries, ({ code, currencies }) => ({
    code,
    currencies: replaceDefaultCurrency(code, currencies),
  }));
};

export const extractDefaultCurrency = (group) => {
  let newGroup = group;
  newGroup?.map((gr) => {
    const defaultCurrencyFromCountry = _.uniq(_.map(gr.code, (c) => findDefaultCurrency(c)));
    if (defaultCurrencyFromCountry.length == 1) {
      const newCurrencies = _.map(gr.currencies, ({ code, fxCurrency }) => {
        const _code = code == "0" ? defaultCurrencyFromCountry[0] : code;
        const _fx = fxCurrency == "0" ? defaultCurrencyFromCountry[0] : fxCurrency;
        return { code: _code, fxCurrency: _fx };
      });
      gr.currencies = newCurrencies;
    }
  });
  return newGroup;
};

const EditPaymentMethod = ({ pspMidId, methodId, excludeOptions }) => {
  const queryClient = useQueryClient();
  const { id: merchantId, brandId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();

  // query
  const { data } = useGetPaymentMethod({ merchantId, brandId, pspMidId, methodId });
  const { mutate, isLoading } = useUpdatePaymentMethod({ merchantId, brandId, pspMidId });

  // hook form
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = methods;

  // function
  const handleSubmitForm = (values) => {
    mutate(
      sanitizeRequest({
        methodId,
        merchantId,
        brandId,
        pspMidId,
        ...values,
      }),
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  const handleOpen = () => {
    onOpen();
    queryClient.invalidateQueries([GET_PAYMENT_METHOD, merchantId, brandId, pspMidId, methodId]);
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (data) {
      const payinGroup = groupCountryBySetting(sanitizeDefaultCurrency(data?.payinCountries));
      const payoutGroup = groupCountryBySetting(sanitizeDefaultCurrency(data?.payoutCountries));
      const payinCountries = extractDefaultCurrency(payinGroup);
      const payoutCountries = extractDefaultCurrency(payoutGroup);
      const gData = {
        ...data,
        payinCountries,
        payoutCountries,
      };
      reset(gData);
    }
  }, [data]);

  return (
    <>
      <SoftButton variant="gradient" color="secondary" onClick={handleOpen} sx={{ width: "220px" }}>
        Update Payment Method
      </SoftButton>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        sx={{ overflow: "visible" }}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>Update Payment Method</DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
            <SoftBox py={2}>
              <PaymentMethodForm excludeOptions={[]} showPaymentOption={true} />
              <Stack direction="row" spacing={1} justifyContent="center" mt={4}>
                <SoftButton onClick={handleClose}>Cancel</SoftButton>
                <SoftButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Update
                </SoftButton>
              </Stack>
            </SoftBox>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

EditPaymentMethod.propTypes = {
  pspMidId: PropTypes.string.isRequired,
  methodId: PropTypes.string.isRequired,
  excludeOptions: PropTypes.array,
};

export default EditPaymentMethod;
