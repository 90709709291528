import { useParams } from "react-router-dom";
import _ from "lodash";

import { Card, DialogContent } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import borders from "assets/theme/base/borders";
import { useGetBrands } from "../hooks";
import DataTable from "examples/Tables/DataTable";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";
import SoftBadgeDot from "components/SoftBadgeDot";

const MidBrands = () => {
  if (!hasPermission(Permissions.PSP_MID.LIST_BRANDS)) {
    return <Error403 />;
  }
  const { id: pspId, midId } = useParams();
  const { borderWidth, borderColor } = borders;

  const { data, isLoading } = useGetBrands({ pspId, midId });

  const getRows = () => {
    return _.map(data, (item) => {
      return {
        merchantId: item.merchantId,
        merchantName: item.merchantName,
        brandId: item.brandId,
        brandName: item.brandName,
        payinEnabled: <SoftBadgeDot color={item.payinEnabled ? "success" : "light"} size="md" />,
        payoutEnabled: <SoftBadgeDot color={item.payoutEnabled ? "success" : "light"} size="md" />,
      };
    });
  };

  const onCellClick = (row) => {
    const { merchantId, brandId } = row.values;
    const path = `merchants/${merchantId}/brands/${brandId}`;
    window.location.replace(`${window.location.origin}/${path}`);
  };
  return (
    <Card sx={{ mt: 2 }}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ xs: "column", sm: "row" }}
        borderBottom={`${borderWidth[1]} solid ${borderColor}`}
        py={2}
        px={3}
      >
        <SoftBox mx={2} lineHeight={1}>
          <SoftTypography component="a" href="#" variant="button" fontWeight="regular">
            Usage
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <DialogContent>
        <DataTable
          table={{
            columns: [
              { Header: "Merchant ID", accessor: "merchantId" },
              { Header: "Merchant Name", accessor: "merchantName" },
              { Header: "Brand Id", accessor: "brandId" },
              { Header: "Brand Name", accessor: "brandName" },
              { Header: "PayIn Enabled", accessor: "payinEnabled" },
              { Header: "PayOut Enabled", accessor: "payoutEnabled" },
            ],
            rows: getRows(),
          }}
          entriesPerPage={false}
          isLoading={isLoading}
          onCellClick={onCellClick}
          onCellAuxClick={onCellClick}
          isClickable={true}
        />
      </DialogContent>
    </Card>
  );
};

export default MidBrands;
