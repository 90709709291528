import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import DataTable from "examples/Tables/DataTable";

import { useGetTemplates } from "./hooks";
import FormProvider from "components/FormProviders";
import { useForm } from "react-hook-form";
import { Card, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import { SelectInputField } from "components/FormField";
import { generateOptions } from "utils/options";
import { useGetPsps } from "pages/psp-management/hooks";
import { Stack } from "@mui/material";
import SoftButton from "components/SoftButton";

const ReconciliationTemplates = () => {
  const navigate = useNavigate();
  // forms
  const methods = useForm();
  const { watch } = methods;
  const pspId = watch("pspId");

  // hooks
  const { data: templates, isLoading, refetch } = useGetTemplates(pspId);
  const { data: psps } = useGetPsps();

  // options
  const pspOptions = useMemo(() => {
    if (psps) {
      return generateOptions(psps);
    } else {
      return [];
    }
  }, [psps]);

  //  functions
  const getRows = () => {
    return _.map(templates, (item) => ({
      id: item._id,
      name: item.name,
    }));
  };

  return (
    <MainLayout>
      <MainNavbar />

      <FormProvider methods={methods}>
        <Card sx={{ overflow: "visible", mb: 2 }}>
          <SoftBox p={3}>
            <Grid container spacing={2}>
              <Grid item xs={7} sm={7}>
                <SelectInputField
                  name="pspId"
                  label="Psp"
                  options={pspOptions}
                  placeholder="Select an psp"
                />
              </Grid>
              <Grid item xs={5} sm={5}>
                <SoftBox pt={2} px={2} mb={3}>
                  <Stack spacing={1} direction="row" justifyContent="flex-end">
                    <SoftButton
                      variant="gradient"
                      color="info"
                      size="small"
                      onClick={() => {
                        navigate("/new-reconciliation-template");
                      }}
                    >
                      Create Template
                    </SoftButton>
                  </Stack>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </FormProvider>
      <DataTable
        table={{
          columns: [
            { Header: "ID", accessor: "id" },
            { Header: "name", accessor: "name" },
          ],
          rows: getRows() || [],
        }}
        canSearch={true}
        columnsFilter={true}
        onCellClick={(row) => {
          navigate(`/reconciliation-templates/${row?.original?.id}`);
        }}
        isClickable={true}
        isLoading={isLoading}
      />
    </MainLayout>
  );
};

export default ReconciliationTemplates;
