import * as Yup from "yup";

export const defaultValues = {
  name: null,
};

export const schema = Yup.object().shape({
  name: Yup.string()
    .required("Organization name is required")
    .max(100, "Organization name is too long, should be less than 100 characters"),
});

export const sanitizeRequest = ({ name }) => {
  return { name };
};
