import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Card } from "@mui/material";

import SoftBox from "components/SoftBox";

import { useGetPspMidMethods } from "pages/brand-detail/hooks";
import AddPaymentMethod from "./AddPaymentMethod";
import DeletePaymentMethod from "./DeletePaymentMethod";
import EditPaymentMethod from "./EditPaymentMethod";
import PaymentOptionSelect from "./PaymentOptionSelect";
import PaymentMethodTypeNav from "./PaymentMethodTypeNav";
import { PaymentMethodMapping, PaymentMethodTypeEnum } from "constants/tabs";
import ContinentsView from "./ContinentsView";
import { usePaymentMethodStore } from "stores/paymentMethod";
import _ from "lodash";

const PaymentMethod = ({ pspMidId }) => {
  const { id: merchantId, brandId } = useParams();
  const [selectedOption, setSelectedOption] = useState(null);
  const [type, setType] = useState(0);
  const [typeStr, setTypeStr] = useState(PaymentMethodMapping.Payin);

  const { setPaymentMethod } = usePaymentMethodStore();

  const { data } = useGetPspMidMethods({ merchantId, brandId, pspMidId });
  const excludeOptions = _.map(data, (d) => d.paymentOptionId);

  const onPaymentOptionChange = (value) => {
    setSelectedOption(value);
    setPaymentMethod({ pspMidId, ...value });
  };

  const onPaymentMethodTypeChange = (value) => {
    setType(value);
    setTypeStr(PaymentMethodMapping[Object.keys(PaymentMethodTypeEnum)[value]]);
  };

  return (
    <Card sx={{ mt: 2 }}>
      <SoftBox>
        <SoftBox display="flex" justifyContent="space-between" m={2}>
          <SoftBox sx={{ width: "35vw" }}>
            <PaymentOptionSelect data={data} onChange={onPaymentOptionChange} />
          </SoftBox>
          <SoftBox>
            <AddPaymentMethod pspMidId={pspMidId} excludeOptions={excludeOptions} />
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" justifyContent="space-between" m={2}>
          <SoftBox></SoftBox>
          <SoftBox>
            {selectedOption && (
              <DeletePaymentMethod pspMidId={pspMidId} methodId={selectedOption?._id} />
            )}
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" justifyContent="space-between" m={2}>
          <SoftBox sx={{ width: "35vw" }}>
            <PaymentMethodTypeNav onChange={onPaymentMethodTypeChange} />
          </SoftBox>
          <SoftBox>
            {selectedOption && (
              <EditPaymentMethod
                pspMidId={pspMidId}
                methodId={selectedOption?._id}
                excludeOptions={excludeOptions}
              />
            )}
          </SoftBox>
        </SoftBox>
        {useMemo(() => {
          return <ContinentsView countries={_.get(selectedOption, typeStr, {})} type={type} />;
        }, [selectedOption, type])}
      </SoftBox>
    </Card>
  );
};

PaymentMethod.propTypes = {
  pspMidId: PropTypes.string.isRequired,
};
export default React.memo(PaymentMethod);
