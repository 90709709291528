import { useMutation, useQuery, useQueryClient } from "react-query";
import { getTemplatePath } from "utils/string";
import { toastSuccess } from "utils/toast";
import { useBrandStore } from "stores/brand";

const { default: request } = require("utils/request");

export const GET_BRANDS = "/merchants/:merchantId/brands";
const CREATE_BRAND = "/merchants/:merchantId/brands";
const DELETE_BRAND = "/merchants/:merchantId/brands/:brandId";
const EDIT_BRAND = "/merchants/:merchantId/brands/:brandId";
const GET_BRAND = "/merchants/:merchantId/brands/:brandId";
const GET_WALLETS = "/merchants/:merchantId/brands/:brandId/wallets";
const GET_API_KEYS = "/merchants/:merchantId/brands/:brandId/api-keys";
const GENERATE_API_KEY = "/merchants/:merchantId/brands/:brandId/api-keys";
const DELETE_API_KEY = "/merchants/:merchantId/brands/:brandId/api-keys/:apiKeyId";
const GET_PSP_MIDS = "/merchants/:merchantId/brands/:brandId/psp-mids";
const WITHDRAW = "/merchants/:merchantId/brands/:brandId/wallets/:walletId/withdraw";
const TOPUP = "/merchants/:merchantId/brands/:brandId/wallets/:walletId/topup";
// psp mid
const ASSIGN_PSP_MID_TO_BRAND = "/merchants/:merchantId/brands/:brandId/psp-mids";
const DELETE_PSP_MID = "/merchants/:merchantId/brands/:brandId/psp-mids/:pspMidId";
const EDIT_PSP_MID = "/merchants/:merchantId/brands/:brandId/psp-mids/:pspMidId";
const GET_PSP_MID = "/merchants/:merchantId/brands/:brandId/psp-mids/:pspMidId";
//virtual accounts
const GET_VIRTUAL_ACCOUNTS = "/merchants/:merchantId/brands/:brandId/virtual-accounts";
const CREATE_VIRTUAL_ACCOUNT = "/merchants/:merchantId/brands/:brandId/virtual-accounts";
const DELETE_VIRTUAL_ACCOUNT = "/merchants/:merchantId/brands/:brandId/virtual-accounts/:id";
const UPDATE_VIRTUAL_ACCOUNT = "/merchants/:merchantId/brands/:brandId/virtual-accounts/:id";
const CHECK_VIRTUAL_ACCOUNT_STATUS =
  "/merchants/:merchantId/brands/:brandId/virtual-accounts/:id/check-status";

// psp mid payment method
const GET_PAYMENT_METHODS = "/merchants/:merchantId/brands/:brandId/psp-mids/:pspMidId/methods";
const CREATE_PAYMENT_METHOD = "/merchants/:merchantId/brands/:brandId/psp-mids/:pspMidId/methods";
const DELETE_PAYMENT_METHOD =
  "/merchants/:merchantId/brands/:brandId/psp-mids/:pspMidId/methods/:methodId";
export const GET_PAYMENT_METHOD =
  "/merchants/:merchantId/brands/:brandId/psp-mids/:pspMidId/methods/:methodId";
const UPDATE_PAYMENT_METHOD =
  "/merchants/:merchantId/brands/:brandId/psp-mids/:pspMidId/methods/:methodId";
// payment options
const GET_PAYMENT_OPTIONS = "/payment-options";

const MERCHAT_PAYMENT_METHOD_TEMPLATE = "/merchants/payment-methods-template";

// brands
const getBrands = (merchantId) => {
  const URL = getTemplatePath(GET_BRANDS, { merchantId });
  return request.get(URL);
};

export const getBrand = ({ merchantId, brandId }) => {
  const URL = getTemplatePath(GET_BRAND, { merchantId, brandId });
  return request.get(URL);
};

const createBrand = ({ merchantId, ...data }) => {
  const URL = getTemplatePath(CREATE_BRAND, { merchantId });
  return request.post(URL, data);
};

const deleteBrand = ({ merchantId, brandId }) => {
  const URL = getTemplatePath(DELETE_BRAND, { merchantId, brandId });
  return request.delete(URL);
};

const editBrand = ({ merchantId, brandId, ...data }) => {
  const URL = getTemplatePath(EDIT_BRAND, { merchantId, brandId });
  return request.put(URL, data);
};

// wallets
const getWallets = (merchantId, brandId) => {
  const URL = getTemplatePath(GET_WALLETS, { merchantId, brandId });
  return request.get(URL);
};

const withdraw = ({ merchantId, brandId, walletId, amount }) => {
  const URL = getTemplatePath(WITHDRAW, { merchantId, brandId, walletId });
  return request.put(URL, { amount });
};

const topup = ({ merchantId, brandId, walletId, amount }) => {
  const URL = getTemplatePath(TOPUP, { merchantId, brandId, walletId });
  return request.put(URL, { amount });
};

// api keys
const getApiKeys = (merchantId, brandId) => {
  const URL = getTemplatePath(GET_API_KEYS, { merchantId, brandId });
  return request.get(URL);
};

const generateApiKey = ({ merchantId, brandId }) => {
  const URL = getTemplatePath(GENERATE_API_KEY, { merchantId, brandId });
  return request.post(URL);
};

const deleteApiKey = ({ merchantId, brandId, apiKeyId }) => {
  const URL = getTemplatePath(DELETE_API_KEY, { merchantId, brandId, apiKeyId });
  return request.delete(URL);
};

// psp mid
const getPspMids = ({ merchantId, brandId }) => {
  const URL = getTemplatePath(GET_PSP_MIDS, { merchantId, brandId });
  return request.get(URL);
};

const getPspMid = ({ merchantId, brandId, pspMidId }) => {
  const URL = getTemplatePath(GET_PSP_MID, { merchantId, brandId, pspMidId });
  return request.get(URL);
};

const assignPspMidToBrand = ({ merchantId, brandId, ...data }) => {
  const URL = getTemplatePath(ASSIGN_PSP_MID_TO_BRAND, { merchantId, brandId });
  return request.post(URL, data);
};

const deletePspMid = ({ merchantId, brandId, pspMidId }) => {
  const URL = getTemplatePath(DELETE_PSP_MID, { merchantId, brandId, pspMidId });
  return request.delete(URL);
};

const editPspMid = ({ merchantId, brandId, pspMidId, ...data }) => {
  const URL = getTemplatePath(EDIT_PSP_MID, { merchantId, brandId, pspMidId });
  return request.put(URL, data);
};
//virtual accounts
const getVirtualAccounts = ({ merchantId, brandId }) => {
  const URL = getTemplatePath(GET_VIRTUAL_ACCOUNTS, { merchantId, brandId });
  return request.get(URL);
};

const createVirtualAccount = ({ merchantId, brandId, country, ...data }) => {
  const URL = getTemplatePath(CREATE_VIRTUAL_ACCOUNT, { merchantId, brandId });
  return request.post(URL, { merchantId, brandId, ...data });
};

const deleteVirtualAccount = ({ merchantId, brandId, id }) => {
  const URL = getTemplatePath(DELETE_VIRTUAL_ACCOUNT, { merchantId, brandId, id });
  return request.delete(URL);
};

const updateVirtualAccount = ({ merchantId, brandId, id, ...data }) => {
  const URL = getTemplatePath(UPDATE_VIRTUAL_ACCOUNT, { merchantId, brandId, id });
  return request.put(URL, data);
};

export const getVirtualAccountStatus = ({ merchantId, brandId, id }) => {
  const URL = getTemplatePath(CHECK_VIRTUAL_ACCOUNT_STATUS, { merchantId, brandId, id });
  return request.get(URL);
};
// psp mid methods
const getPspMidMethods = ({ merchantId, brandId, pspMidId }) => {
  const URL = getTemplatePath(GET_PAYMENT_METHODS, { merchantId, brandId, pspMidId });
  return request.get(URL);
};
const createPspMidMethod = ({ merchantId, brandId, pspMidId, ...data }) => {
  const URL = getTemplatePath(CREATE_PAYMENT_METHOD, { merchantId, brandId, pspMidId });
  return request.post(URL, data);
};
const deletePaymentMethod = ({ merchantId, brandId, pspMidId, methodId }) => {
  const URL = getTemplatePath(DELETE_PAYMENT_METHOD, { merchantId, brandId, pspMidId, methodId });
  return request.delete(URL);
};
const getPspMidMethod = ({ merchantId, brandId, pspMidId, methodId }) => {
  const URL = getTemplatePath(GET_PAYMENT_METHOD, { merchantId, brandId, pspMidId, methodId });
  return request.get(URL);
};
const updatePspMidMethod = ({ merchantId, brandId, pspMidId, methodId, ...data }) => {
  const URL = getTemplatePath(UPDATE_PAYMENT_METHOD, { merchantId, brandId, pspMidId, methodId });
  return request.put(URL, data);
};
// const getPspMidMethod = ({ merchantId, brandId, pspMidId, methodId }) => {}

// payment options
const getPaymentOptions = () => {
  return request.get(GET_PAYMENT_OPTIONS);
};

// hooks
// brands hooks
export const useGetBrands = (merchantId) => {
  return useQuery([GET_BRANDS, merchantId], () => getBrands(merchantId), {
    enabled: !!merchantId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetBrand = ({ merchantId, brandId }) => {
  const { setBrand } = useBrandStore();
  return useQuery([GET_BRAND, merchantId, brandId], () => getBrand({ merchantId, brandId }), {
    enabled: !!merchantId,
    onSuccess: (data) => {
      setBrand(data);
    },
  });
};

export const useCreateBrand = () => {
  const queryClient = useQueryClient();
  return useMutation(createBrand, {
    onSuccess: () => {
      toastSuccess({ description: "Create brand has been success" });
      queryClient.invalidateQueries([GET_BRANDS]);
    },
  });
};

export const useDeleteBrand = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteBrand, {
    onSuccess: () => {
      toastSuccess({ description: "Delete brand has been success" });
      queryClient.invalidateQueries([GET_BRANDS]);
    },
  });
};

export const useEditBrand = ({ merchantId, brandId }) => {
  const queryClient = useQueryClient();
  return useMutation(editBrand, {
    onSuccess: () => {
      toastSuccess({ description: "Update brand has been success" });
      queryClient.invalidateQueries([GET_BRAND, merchantId, brandId]);
    },
  });
};

// wallets hooks
export const useGetWallets = (merchantId, brandId) => {
  return useQuery([GET_WALLETS, merchantId, brandId], () => getWallets(merchantId, brandId), {
    enabled: !!(merchantId && brandId),
  });
};

export const useWithdraw = () => {
  const queryClient = useQueryClient();
  return useMutation(withdraw, {
    onSuccess: () => {
      toastSuccess({ description: "Withdraw has been success" });
      queryClient.invalidateQueries([GET_WALLETS]);
    },
  });
};

export const useTopup = () => {
  const queryClient = useQueryClient();
  return useMutation(topup, {
    onSuccess: () => {
      toastSuccess({ description: "Topup has been success" });
      queryClient.invalidateQueries([GET_WALLETS]);
    },
  });
};

// api keys hooks
export const useGetApiKeys = (merchantId, brandId) => {
  return useQuery([GET_API_KEYS, merchantId, brandId], () => getApiKeys(merchantId, brandId), {
    enabled: !!(merchantId && brandId),
  });
};

export const useGenerateApiKey = () => {
  const queryClient = useQueryClient();
  return useMutation(generateApiKey, {
    onSuccess: () => {
      toastSuccess({ description: "Generate API key has been success" });
      queryClient.invalidateQueries([GET_API_KEYS]);
    },
  });
};

export const useDeleteApiKey = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteApiKey, {
    onSuccess: () => {
      toastSuccess({ description: "Delete API key has been success" });
      queryClient.invalidateQueries([GET_API_KEYS]);
    },
  });
};

//psp mids hooks
export const useGetPspMid = ({ merchantId, brandId, pspMidId }) => {
  return useQuery(
    [GET_PSP_MID, merchantId, brandId, pspMidId],
    () => getPspMid({ merchantId, brandId, pspMidId }),
    {
      enabled: !!(merchantId && brandId && pspMidId),
    }
  );
};
export const useGetPspMids = ({ merchantId, brandId }) => {
  return useQuery([GET_PSP_MIDS, merchantId, brandId], () => getPspMids({ merchantId, brandId }), {
    enabled: !!(merchantId && brandId),
  });
};

export const useAssignPspMidToBrand = () => {
  const queryClient = useQueryClient();
  return useMutation(assignPspMidToBrand, {
    onSuccess: () => {
      toastSuccess({ description: "Assign psp mid to brand has been success" });
      queryClient.invalidateQueries([GET_PSP_MIDS]);
    },
  });
};

export const useDeletePspMid = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePspMid, {
    onSuccess: () => {
      toastSuccess({ description: "Delete psp mid has been success" });
      queryClient.invalidateQueries([GET_PSP_MIDS]);
    },
  });
};

export const useEditPspMid = () => {
  const queryClient = useQueryClient();
  return useMutation(editPspMid, {
    onSuccess: () => {
      toastSuccess({ description: "Update psp mid has been success" });
      queryClient.invalidateQueries([GET_PSP_MIDS]);
    },
  });
};
//virtual accounts hook

export const useVirtualAccounts = ({ merchantId, brandId }) => {
  return useQuery(
    [GET_VIRTUAL_ACCOUNTS, merchantId, brandId],
    () => getVirtualAccounts({ merchantId, brandId }),
    {
      enabled: !!merchantId && !!brandId,
    }
  );
};

export const useCreateVirtualAccount = ({ merchantId, brandId }) => {
  const queryClient = useQueryClient();
  return useMutation(createVirtualAccount, {
    onSuccess: () => {
      toastSuccess({ description: "Create Virtual Account has been success" });
      queryClient.invalidateQueries([GET_VIRTUAL_ACCOUNTS, merchantId, brandId]);
    },
  });
};

export const useDeleteVirtualAccount = ({ merchantId, brandId }) => {
  const queryClient = useQueryClient();
  return useMutation(deleteVirtualAccount, {
    onSuccess: () => {
      toastSuccess({ description: "Delete Virtual Account has been success" });
      queryClient.invalidateQueries([GET_VIRTUAL_ACCOUNTS, merchantId, brandId]);
    },
  });
};

export const useCheckVirtualAccountStatus = ({ merchantId, brandId, id }) => {
  return useQuery(
    [CHECK_VIRTUAL_ACCOUNT_STATUS, merchantId, brandId, id],
    () => getVirtualAccountStatus({ merchantId, brandId, id }),
    {
      enabled: !!merchantId && !!brandId && !!id,
    }
  );
};

export const useUpdateVirtualAccount = ({ merchantId, brandId }) => {
  const queryClient = useQueryClient();
  return useMutation(updateVirtualAccount, {
    onSuccess: () => {
      toastSuccess({ description: "Update Virtual Account has been success" });
      queryClient.invalidateQueries([GET_VIRTUAL_ACCOUNTS, merchantId, brandId]);
    },
  });
};

// psp mid methods hook

export const useGetPspMidMethods = ({ merchantId, brandId, pspMidId }) => {
  return useQuery(
    [GET_PAYMENT_METHODS, merchantId, brandId, pspMidId],
    () => getPspMidMethods({ merchantId, brandId, pspMidId }),
    {
      enabled: !!merchantId && !!brandId && !!pspMidId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};
export const useCreatePspMidMethod = ({ merchantId, brandId, pspMidId }) => {
  const queryClient = useQueryClient();
  return useMutation(createPspMidMethod, {
    onSuccess: () => {
      toastSuccess({ description: "Create psp mid method has been success" });
      queryClient.invalidateQueries([GET_PAYMENT_METHODS, merchantId, brandId, pspMidId]);
    },
  });
};
export const useDeletePaymentMethod = ({ merchantId, brandId, pspMidId }) => {
  const queryClient = useQueryClient();
  return useMutation(deletePaymentMethod, {
    onSuccess: () => {
      toastSuccess({ description: "Delete payment method has been success" });
      queryClient.invalidateQueries([GET_PAYMENT_METHODS, merchantId, brandId, pspMidId]);
    },
  });
};

export const useGetPaymentMethod = ({ merchantId, brandId, pspMidId, methodId }) => {
  return useQuery(
    [GET_PAYMENT_METHOD, merchantId, brandId, pspMidId, methodId],
    () => getPspMidMethod({ merchantId, brandId, pspMidId, methodId }),
    {
      enabled: !!merchantId && !!brandId && !!pspMidId && !!methodId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};
export const useUpdatePaymentMethod = ({ merchantId, brandId, pspMidId }) => {
  const queryClient = useQueryClient();
  return useMutation(updatePspMidMethod, {
    onSuccess: () => {
      toastSuccess({ description: "Update payment method has been success" });
      queryClient.invalidateQueries([GET_PAYMENT_METHODS, merchantId, brandId, pspMidId]);
    },
  });
};

// payment options
export const useGetPaymentOptions = () => {
  return useQuery(GET_PAYMENT_OPTIONS, getPaymentOptions, { refetchOnWindowFocus: false });
};

// template
const createTemplate = (payload) => {
  return request.post(MERCHAT_PAYMENT_METHOD_TEMPLATE, payload);
};
export const useCreateTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation(createTemplate, {
    onSuccess: () => {
      toastSuccess({ description: "Create payment methods template has been success" });
      queryClient.invalidateQueries([MERCHAT_PAYMENT_METHOD_TEMPLATE]);
    },
  });
};

const getTemplates = () => {
  return request.get(`${MERCHAT_PAYMENT_METHOD_TEMPLATE}/all`);
};
export const useGetTemplates = () => {
  return useQuery(MERCHAT_PAYMENT_METHOD_TEMPLATE, getTemplates, { refetchOnWindowFocus: false });
};

const updateTemplate = ({ id, ...payload }) => {
  return request.put(`${MERCHAT_PAYMENT_METHOD_TEMPLATE}/${id}`, payload);
};
export const useUpdateTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation(updateTemplate, {
    onSuccess: () => {
      toastSuccess({ description: "Update payment methods template has been success" });
      queryClient.invalidateQueries([MERCHAT_PAYMENT_METHOD_TEMPLATE]);
    },
  });
};

const deleteTemplate = ({ id }) => {
  return request.delete(`${MERCHAT_PAYMENT_METHOD_TEMPLATE}/${id}`);
};
export const useDeleteTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteTemplate, {
    onSuccess: () => {
      toastSuccess({ description: "Delete payment methods template has been success" });
      queryClient.invalidateQueries([MERCHAT_PAYMENT_METHOD_TEMPLATE]);
    },
  });
};
