import { useEffect, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";

import { Card, Stack } from "@mui/material";
import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useGetPsps } from "pages/psp-management/hooks";
import AmountLimit from "pages/psp-mid-details/components/AmountLimit";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";
import TabPanel, { a11yProps } from "components/TabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PaymentsIcon from "@mui/icons-material/Payments";
import GeneralSettings from "./components/GeneralSettings";
import Mids from "./components/Mids";
import PaymentOptions from "./components/PaymentOptions";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Fees from "./components/Fees";

export const PSP_TABS = {
  GENERAL: 0,
  MIDS: 1,
  AMOUNT_LIMITS: 2,
  PAYMENT_OPTIONS: 3,
  FEES: 4,
};

const PspDetails = () => {
  const { id } = useParams();
  if (!hasPermission(Permissions.PSP_MID.LIST)) {
    return (
      <MainLayout>
        <MainNavbar
          customRoutes={[
            { label: "psps", route: "psps" },
            { label: id, route: id },
          ]}
        />
        <Error403 />
      </MainLayout>
    );
  }
  const [tab, setTab] = useState(PSP_TABS.MIDS);
  const [psp, setPsp] = useState({});

  const { data } = useGetPsps();

  //hooks

  useEffect(() => {
    const psp = _.find(data, (item) => _.get(item, "_id") === id);
    setPsp(psp);
  }, [data]);

  const onTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <MainLayout>
      <MainNavbar
        customRoutes={[
          { label: "psps", route: "psps" },
          { label: _.get(psp, "name", id), route: id },
        ]}
      />
      <SoftBox sx={{ maxWidth: 1000, marginTop: 2, marginBottom: 2 }}>
        <AppBar position="static">
          <Tabs
            value={tab}
            onChange={onTabChange}
            aria-label="basic tabs example"
            sx={{ backgroundColor: "transparent" }}
            variant="standard"
          >
            <Tab
              icon={<FormatListBulletedIcon />}
              label="General Settings"
              {...a11yProps(PSP_TABS.GENERAL)}
            />
            <Tab icon={<AssessmentIcon />} label="Mids" {...a11yProps(PSP_TABS.MIDS)} />
            <Tab
              icon={<HourglassFullIcon />}
              label="Amount Limits"
              {...a11yProps(PSP_TABS.AMOUNT_LIMITS)}
            />
            <Tab
              icon={<PaymentsIcon />}
              label="Payment Options"
              {...a11yProps(PSP_TABS.PAYMENT_OPTIONS)}
            />
            <Tab icon={<AttachMoneyIcon />} label="Fees" {...a11yProps(PSP_TABS.FEES)} />
          </Tabs>
        </AppBar>
      </SoftBox>
      {/* General */}
      <TabPanel value={tab} index={PSP_TABS.GENERAL}>
        <GeneralSettings />
      </TabPanel>
      <TabPanel value={tab} index={PSP_TABS.MIDS}>
        <Mids />
      </TabPanel>
      <TabPanel value={tab} index={PSP_TABS.AMOUNT_LIMITS}>
        {hasPermission(Permissions.AMOUNT_LIMIT.LIST) && (
          <Card sx={{ height: "100%" }}>
            <SoftBox pt={2} px={2}>
              <Stack spacing={1} direction="row" justifyContent="space-between">
                <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Amount Limits
                </SoftTypography>
              </Stack>
            </SoftBox>
            <AmountLimit />
          </Card>
        )}
        {!hasPermission(Permissions.AMOUNT_LIMIT.LIST) && <Error403 />}
      </TabPanel>
      <TabPanel value={tab} index={PSP_TABS.PAYMENT_OPTIONS}>
        <PaymentOptions />
      </TabPanel>
      <TabPanel value={tab} index={PSP_TABS.FEES}>
        <Fees />
      </TabPanel>
    </MainLayout>
  );
};

export default PspDetails;
