import { DialogActions, Grid } from "@mui/material";
import SoftButton from "../SoftButton";
import PropTypes from "prop-types";

export default function ButtonSet({ onClose, isLoading, title }) {
  return (
    <DialogActions>
      <Grid
        container
        spacing={3}
        gap={3}
        ml={0}
        mt={-3}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid>
          <SoftButton variant="gradient" onClick={onClose}>
            Cancel
          </SoftButton>
        </Grid>
        <Grid>
          <SoftButton
            variant="gradient"
            color="info"
            type="submit"
            disabled={isLoading}
            isLoading={isLoading}
          >
            {title}
          </SoftButton>
        </Grid>
      </Grid>
    </DialogActions>
  );
}

ButtonSet.propTypes = {
  isLoading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};
