import { Chip } from "@mui/material";
import { forwardRef } from "react";
import PropTypes from "prop-types";
import { EnabledColorEnum } from "constants/status";

const EnabledChip = forwardRef(({ label, ...rest }, ref) => {
  const color = EnabledColorEnum[label];
  return <Chip label={label ? "Enabled" : "Disabled"} {...rest} ref={ref} color={color} />;
});
// Setting default values for the props of SoftPagination
EnabledChip.defaultProps = {
  label: null,
};

// Typechecking props for the SoftPagination
EnabledChip.propTypes = {
  label: PropTypes.bool,
};
export default EnabledChip;
