import * as Yup from "yup";
export const sanitizeRequest = (payload) => {
  const { payoutCountries, payinCountries, name } = payload;
  return {
    payoutCountries,
    payinCountries,
    name,
  };
};
export const schema = Yup.object().shape({
  payinCountries: Yup.array()
    .of(
      Yup.object().shape({
        code: Yup.array().required("Pay-In country is required").nullable(),
        currencies: Yup.array().of(
          Yup.object().shape({
            code: Yup.string().required("Pay-In currency code is required").nullable(),
            fxCurrency: Yup.string().nullable(),
          })
        ),
      })
    )
    .required("Pay-In country is required"),
  payoutCountries: Yup.array()
    .of(
      Yup.object().shape({
        code: Yup.array().required("Pay-Out country is required").nullable(),
        currencies: Yup.array().of(
          Yup.object().shape({
            code: Yup.string().required("Pay-Out currency code is required").nullable(),
            fxCurrency: Yup.string().nullable(),
          })
        ),
      })
    )
    .nullable(),
});
