// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

function MiniStatisticsCard({ bgColor, title, icon, direction, image, onClick, selected = false }) {
  return (
    <Card
      onClick={onClick}
      sx={{
        border: selected ? "2px solid" : "none",
        borderColor: "secondary.main",
      }}
    >
      <SoftBox bgColor={bgColor} variant="gradient">
        <SoftBox p={2}>
          <Grid container alignItems="center">
            {direction === "left" ? (
              <Grid item>
                {image && (
                  <SoftBox mr={1}>
                    <SoftAvatar src={image} size="sm" alt="title" />
                  </SoftBox>
                )}
                {!image && (
                  <SoftBox
                    variant="gradient"
                    bgColor={bgColor === "white" ? icon?.color : "white"}
                    color={bgColor === "white" ? "white" : "dark"}
                    width="3rem"
                    height="3rem"
                    borderRadius="md"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    shadow="md"
                  >
                    <Icon fontSize="small" color="inherit">
                      {icon.component}
                    </Icon>
                  </SoftBox>
                )}
              </Grid>
            ) : null}
            <Grid item xs={8} minHeight="100%" height="100%">
              <SoftBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                <SoftTypography
                  variant="button"
                  color={bgColor === "white" ? "text" : "white"}
                  opacity={bgColor === "white" ? 1 : 0.7}
                  textTransform="capitalize"
                  fontWeight={title.fontWeight}
                >
                  {title.text}
                </SoftTypography>
              </SoftBox>
            </Grid>
            {direction === "right" ? (
              <Grid item xs={4}>
                {image && (
                  <SoftBox mr={1}>
                    <SoftAvatar src={image} size="sm" alt="title" />
                  </SoftBox>
                )}
                {!image && (
                  <SoftBox
                    variant="gradient"
                    bgColor={bgColor === "white" ? icon?.color : "white"}
                    color={bgColor === "white" ? "white" : "dark"}
                    width="3rem"
                    height="3rem"
                    marginLeft="auto"
                    borderRadius="md"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    shadow="md"
                  >
                    <Icon fontSize="small" color="inherit">
                      {icon?.component}
                    </Icon>
                  </SoftBox>
                )}
              </Grid>
            ) : null}
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of MiniStatisticsCard
MiniStatisticsCard.defaultProps = {
  bgColor: "white",
  title: {
    fontWeight: "medium",
    text: "",
  },
  direction: "right",
};

// Typechecking props for the MiniStatisticsCard
MiniStatisticsCard.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    component: PropTypes.node.isRequired,
  }),
  direction: PropTypes.oneOf(["right", "left"]),
  image: PropTypes.string,
};

export default MiniStatisticsCard;
