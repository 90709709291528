import { useEffect, useState } from "react";

import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";

import Error403 from "components/Error403";

import { isSuperAdmin } from "utils/roles";
import { useGetPsps } from "pages/psp-management/hooks";
import SoftBox from "components/SoftBox";
import PspFeeForm from "pages/psp-details/components/PspFeeForm";
import FormProvider from "components/FormProviders";
import { useForm } from "react-hook-form";
import { Card, DialogActions } from "@mui/material";
import SoftButton from "components/SoftButton";
import { useGetPspsFees } from "pages/psp-details/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema, sanitizeRequest } from "pages/psp-details/schemas/fees";
import { useDeleteFee, useUpdatePspsFee } from "./hooks";
import { DeleteModal } from "components/Modal";

const PspFeeDetail = () => {
  const { id, feeId } = useParams();
  if (!isSuperAdmin()) {
    return (
      <MainLayout>
        <MainNavbar
          customRoutes={[
            { label: "psps", route: "psps" },
            { label: id, route: id },
          ]}
        />
        <Error403 />
      </MainLayout>
    );
  }

  // state
  const [psp, setPsp] = useState({});
  const [selectedFee, setSelectedFee] = useState(null);
  const navigate = useNavigate();

  // hooks
  const { data: fees } = useGetPspsFees(id);
  const { data } = useGetPsps();
  const { mutate, isLoading } = useUpdatePspsFee(id);
  const { mutateAsync: deleteFee, isLoading: isDeleting } = useDeleteFee(id);

  //forms
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = methods;

  // functions
  const onSubmit = (values) => {
    mutate({ pspId: id, feeId, ...sanitizeRequest(values) });
  };

  useEffect(() => {
    const psp = _.find(data, (item) => _.get(item, "_id") === id);
    setPsp(psp);
  }, [data]);

  useEffect(() => {
    if (feeId) {
      const fee = _.find(fees, (item) => _.get(item, "_id") === feeId);
      setSelectedFee(fee);
      methods.reset(fee);
    }
  }, [fees]);
  return (
    <MainLayout>
      <MainNavbar
        customRoutes={[
          { label: "psps", route: "psps" },
          { label: _.get(psp, "name", id), route: `psps/${id}` },
          { label: feeId, route: feeId },
        ]}
      />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} key={"deleteModal"}>
        <Card>
          <SoftBox p={2}>
            <PspFeeForm fee={selectedFee} />
          </SoftBox>
          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "20px",
              paddingLeft: 6,
            }}
          >
            <SoftButton
              variant="gradient"
              color="info"
              sx={{ mt: 2 }}
              disabled={isLoading}
              isLoading={isLoading}
              type="submit"
            >
              Update
            </SoftButton>
            <SoftBox display="flex" gap={1}>
              <DeleteModal
                type="button"
                title="Delete Psp Fee"
                message="Are you sure want to delete this Psp Fee?"
                isLoading={isDeleting}
                onDelete={async () => {
                  await deleteFee({ pspId: id, feeId });
                }}
                onClose={() => navigate(`/psps/${id}`)}
              />
            </SoftBox>
          </DialogActions>
        </Card>
      </FormProvider>
    </MainLayout>
  );
};

export default PspFeeDetail;
