import { useMutation } from "react-query";
import request from "utils/request";
import { toastSuccess } from "utils/toast";

const UPDATE_TRANSACTION = "transactions";

// define the function to update merchant info
const updateTransaction = (data) => {
  return request.put(`${UPDATE_TRANSACTION}/${data.transactionId}`, data);
};

// update transaction hook
export const useUpdateTransaction = () => {
  return useMutation(updateTransaction, {
    onSuccess: () => {
      toastSuccess({ description: "Update transaction has been success" });
    },
  });
};
