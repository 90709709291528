import React, { useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";
import SoftBox from "../../../../components/SoftBox";
import Card from "@mui/material/Card";
import { countriesFlags } from "../../../../constants/countriesFlags";
import { getLabelsStat } from "../../../../utils/analytic";
import Grid from "@mui/material/Grid";
import MiniStatisticsCard from "../../../Cards/StatisticsCards/MiniStatisticsCard";
import { getSymbolFromCurrency } from "../../../../utils/string";
import SoftTypography from "../../../../components/SoftTypography";
import SoftButton from "../../../../components/SoftButton";
import SoftInput from "../../../../components/SoftInput";
import { Switch } from "@mui/material";
import { useGetFilterApprovalTransactions } from "../../../../pages/transactions/useTransactions";
import { get, set } from "../../../../utils/localStorage";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const footer = (tooltipItems) => {
  let sum = 0;
  tooltipItems.forEach(function (tooltipItem) {
    sum += tooltipItem.parsed.y;
  });
  return "All transactions: " + sum;
};
export const options = {
  elements: {
    point: {
      borderColor: "transparent",
    },
  },
  plugins: {
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        footer: footer,
      },
    },
  },
  redraw: false,
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  animation: false,
  scales: {
    x: {
      stacked: true,
      // beginAtZero: true,
    },
    y: {
      stacked: true,
      beginAtZero: true,
    },
  },
};

const LC_PAYLOAD = "transactionReportingPayload";

export const GroupedBarChart = ({ color, sum, approvalData, approvalMutate }) => {
  if (!hasPermission(Permissions.TRANSACTION.GET_APPROVAL_RATE)) {
    return <Error403 />;
  }
  const [selected, setSelected] = useState([]);
  const [reportDataStat, setReportDataStat] = useState({});
  const [checked, setChecked] = useState(false);
  const [effectiveArChecked, setEffectiveArChecked] = useState(false);
  const [search, setSearch] = useState("");

  const transactionReportingPayload = get(LC_PAYLOAD) || {};

  useEffect(() => {
    setSelected([]);
    setChecked(false);
    setEffectiveArChecked(true);
    setSearch("");
  }, [sum]);

  useEffect(() => {
    if (approvalData) {
      const filteredData = (data, selected) =>
        sortCountry(
          selected.length > 0
            ? data?.byCountry?.filter((item) => selected?.includes(item.code))
            : data?.byCountry
        );
      const filteredAllData = filteredData(approvalData, selected);
      const reportDataStat = getLabelsStat(filteredAllData);
      setReportDataStat(reportDataStat);
    }
  }, [selected, approvalData, sum]);

  function getObjectByCode(code) {
    return countriesFlags.find((country) => country.code === code);
  }
  function sortCountry(arr) {
    return arr
      ?.map((i) => {
        return getObjectByCode(i.code)
          ? {
              ...i,
              name: getObjectByCode(i.code)?.name,
            }
          : i?.txCompleted > 0
          ? {
              ...i,
              name: `${i.code}`,
            }
          : null;
      })
      .filter((i) => i != null)
      .sort((a, b) => {
        if (!a?.name) return 1;
        if (!b?.name) return -1;
        return a?.name.localeCompare(b.name);
      });
  }
  const renderStatisticsCards = () => {
    const byCountry = search.trim()
      ? approvalData?.byCountry.filter((i) =>
          getObjectByCode(i.code)?.name?.toLowerCase().includes(search.trim()?.toLowerCase())
        )
      : approvalData?.byCountry;
    const keys = sortCountry(byCountry)?.map((i) => i.code);
    return _.map(keys, (item, index) => {
      return (
        <Grid item xs={6} sm={4} lg={3} key={index + item}>
          <MiniStatisticsCard
            key={item}
            selected={selected?.includes(item)}
            title={{
              text: getObjectByCode(item)?.name || item,
              fontWeight: "medium",
            }}
            percentage={{ color: "main", text: getSymbolFromCurrency(item) }}
            image={getObjectByCode(item)?.image}
            onClick={() => {
              setSelected((prev) =>
                prev?.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
              );
              setSearch("");
            }}
          />
        </Grid>
      );
    });
  };
  const onUserAbandonedChange = () => {
    const _checked = !checked;
    setChecked(!checked);
    if (transactionReportingPayload) {
      const payload = {
        ...get(LC_PAYLOAD),
        excludeAbandoned: _checked,
        effectiveAr: effectiveArChecked,
      };
      set(LC_PAYLOAD, payload);
      approvalMutate(payload);
    } else {
      approvalMutate({ excludeAbandoned: _checked, effectiveAr: effectiveArChecked });
    }
  };

  const onEffectiveArChange = () => {
    const _effectiveArChecked = !effectiveArChecked;
    setEffectiveArChecked(!effectiveArChecked);
    if (transactionReportingPayload) {
      const payload = {
        ...get(LC_PAYLOAD),
        effectiveAr: _effectiveArChecked,
        excludeAbandoned: checked,
      };
      set(LC_PAYLOAD, payload);
      approvalMutate(payload);
    } else {
      approvalMutate({ excludeAbandoned: checked, effectiveAr: _effectiveArChecked });
    }
  };

  return (
    <>
      <Card sx={{ height: "auto", padding: 2, marginBottom: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SoftTypography variant="h6">Total Reporting</SoftTypography>
          </Grid>
          <Grid item xs={12} lg={3.5} md={6} sx={{ paddingTop: "0!important" }}>
            <SoftTypography variant="h6" sx={{ fontWeight: 400 }}>
              Amount of transaction:
              {approvalData?.overall
                ? approvalData?.overall?.txCompleted + approvalData?.overall?.txOthers
                : "0"}
            </SoftTypography>
          </Grid>
          <Grid item xs={12} lg={5} md={6} sx={{ paddingTop: "0!important" }}>
            <SoftTypography variant="h6" sx={{ fontWeight: 400 }}>
              Amount of complete transactions: {approvalData?.overall?.txCompleted || "0"}
            </SoftTypography>
          </Grid>
          <Grid item xs={12} lg={3.5} md={6} sx={{ paddingTop: "0!important" }}>
            <SoftTypography variant="h6" sx={{ fontWeight: 400 }}>
              Approval rate: {approvalData?.overall?.approvalRate || "0"}%
            </SoftTypography>
          </Grid>
        </Grid>
      </Card>

      <Grid container display="flex" justifyContent="end">
        <SoftBox fontSize={16} fontWeight="bold">
          <Grid
            display="flex"
            alignItems="center"
            sx={{ width: 260, marginTop: 2, mb: 2 }}
            fontSize={16}
            onClick={onEffectiveArChange}
          >
            <Switch color="info" checked={effectiveArChecked} />
            <SoftBox sx={{ ml: 2 }}>Effective Ar</SoftBox>
          </Grid>
          <Grid
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: 260, marginTop: 2, mb: 2 }}
            fontSize={16}
            onClick={onUserAbandonedChange}
          >
            <Switch color="info" checked={checked} />
            <SoftBox>Exclude USER_ABANDONED</SoftBox>
          </Grid>
        </SoftBox>
      </Grid>
      <Card
        sx={{
          height: "100%",
          overflowX: "scroll",
          padding: "0px!important",
          ml: "-24px",
          mr: "-24px",
        }}
      >
        <SoftBox padding="0.7rem">
          {reportDataStat && Object.keys(reportDataStat).length > 0 && (
            <SoftBox
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              py={2}
              pr={0.5}
              mb={2}
              minWidth={"700px"}
              overflowX={"scroll"}
            >
              <Bar data={reportDataStat} options={options} px={0} />
            </SoftBox>
          )}
        </SoftBox>
      </Card>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        mt={2}
      >
        <Grid item>
          <SoftBox width="15rem" ml="auto">
            <SoftInput
              placeholder="Search..."
              value={search}
              onChange={({ currentTarget }) => {
                setSearch(currentTarget.value);
              }}
            />
          </SoftBox>
        </Grid>
        <Grid item>
          <SoftButton
            variant="gradient"
            color="info"
            type="button"
            onClick={() => {
              setSelected([]);
              setSearch("");
            }}
          >
            show all
          </SoftButton>
        </Grid>
      </Grid>
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          {useMemo(() => renderStatisticsCards(), [search, selected, approvalData])}
        </Grid>
      </SoftBox>
    </>
  );
};

GroupedBarChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  sum: PropTypes.object,
  approvalData: PropTypes.object,
  approvalMutate: PropTypes.func,
};
