export const sanitizeRequest = ({
  merchantId,
  fromDate,
  toDate,
  limit,
  email,
  page,
  paymentOptionId,
  merchantReference,
  transactionId,
  status,
  country,
  sortBy,
  sortDirection,
}) => {
  const identityObj = _.pickBy(
    {
      country,
      merchantReference,
      transactionId,
      status,
      email,
      merchantId,
      fromDate,
      toDate,
      limit,
      page,
      paymentOptionId,
      sortBy,
      sortDirection,
    },
    _.identity
  );
  const removeAll = _.pickBy(identityObj, (value) => value !== "All");
  if (!_.isUndefined(email)) {
    return {
      ...removeAll,
      email: encodeURIComponent(email),
    };
  }
  return removeAll;
};
