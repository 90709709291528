import { useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { Card, DialogContent, Grid } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import borders from "assets/theme/base/borders";
import { useGetPaginationBins, useDeleteBin } from "../hooks";
import { useEffect } from "react";
import DataTable from "examples/Tables/DataTable";
import StatusChip from "components/StatusChip";
import { BinColorEnum } from "constants/bins";
import { fDateTimeSuffix } from "utils/formatTime";
import AddBinModal from "./AddBinModal";
import SoftButton from "components/SoftButton";
import { TextInputField } from "components/FormField";
import FormProvider from "components/FormProviders";
import { useForm } from "react-hook-form";
import { set, get } from "utils/localStorage";
import { useDataTable } from "hooks/useDataTable";
import { DeleteModal } from "components/Modal";

const LC_PAYLOAD = "bins";

const BinCard = () => {
  const { id: pspId, midId: pspMidId } = useParams();
  const { borderWidth, borderColor } = borders;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const sortColumns = ["createdAt", "action", "reason", "bin"];
  const { onSortChange, sortDirection, sortBy } = useDataTable();

  const binsPayload = get(LC_PAYLOAD) || {};

  //hooks
  const { mutateAsync: deleteBin, isLoading: isDeleting } = useDeleteBin();
  const { data, mutate: getBins, isLoading } = useGetPaginationBins();

  // forms
  const methods = useForm();
  const { handleSubmit } = methods;

  useEffect(() => {
    getBins({ page: 1, limit: pageSize, sortDirection, pspId, pspMidId, sortBy });
    set(LC_PAYLOAD, { ...binsPayload, pspId, pspMidId });
  }, []);

  const refetchBins = () => {
    getBins({ page: 1, limit: pageSize, sortDirection, pspId, pspMidId, sortBy });
  };

  const getRows = () => {
    return _.map(_.get(data, "docs", []), (item) => {
      return {
        bin: item.bin,
        reason: item.reason,
        action: (
          <StatusChip
            label={item.action}
            size="small"
            tooltip={item.reason}
            colorEnum={BinColorEnum}
          />
        ),
        createdAt: fDateTimeSuffix(item.createdAt),
        delete: (
          <SoftBox display="flex" gap={1}>
            <DeleteModal
              type="icon"
              iconColor="error"
              title="Delete Bin"
              message={
                <>
                  Are you sure want to delete this Bin <b>{item._id}</b>?
                </>
              }
              onDelete={async () => {
                await deleteBin({ binId: item._id, pspId, pspMidId });
              }}
              onClose={refetchBins}
              isLoading={isDeleting}
            />
          </SoftBox>
        ),
        brand: item.details?.cardBrand,
        type: item.details?.cardType,
        country: item.details?.country,
      };
    });
  };
  const handlePageChange = (page) => {
    const payload = binsPayload;
    payload.page = page + 1;
    payload.limit = pageSize;
    payload.sortBy = sortBy;
    set(LC_PAYLOAD, payload);
    getBins({ ...payload, pspId, pspMidId, sortDirection });
    setCurrentPage(page + 1);
  };
  const handlePageSizeChange = (newPageSize) => {
    if (newPageSize !== pageSize) {
      setCurrentPage(1);
      setPageSize(newPageSize);
      const payload = binsPayload;
      payload.page = 1;
      payload.limit = newPageSize;
      payload.sortBy = sortBy;
      set(LC_PAYLOAD, payload);
      getBins({ ...payload, pspId, pspMidId, sortDirection });
    }
  };
  const onSubmit = (values) => {
    const payload = { ...values, limit: pageSize, page: 1, sortBy, pspId, pspMidId, sortDirection };
    set(LC_PAYLOAD, payload);
    getBins(payload);
  };
  const handleSortChange = (column) => {
    onSortChange({
      column,
      sortColumns,
      lcPayload: LC_PAYLOAD,
      mutate: getBins,
      setCurrentPage,
      sanitizeRequest: (value) => value,
    });
  };
  return (
    <Card sx={{ mt: 2 }}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ xs: "column", sm: "row" }}
        borderBottom={`${borderWidth[1]} solid ${borderColor}`}
        py={2}
        px={3}
      >
        {/* <SoftAvatar src={team4} alt="profile-image" variant="rounded" /> */}
        <SoftBox mx={2} lineHeight={1}>
          <SoftTypography component="a" href="#" variant="button" fontWeight="regular">
            BIN Whitelist/Blacklist
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <DialogContent>
        <AddBinModal onClose={refetchBins} />
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={12}>
              <Card sx={{ overflow: "visible" }}>
                <SoftBox pr={3} pl={3} pb={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={6}>
                      <TextInputField label="Search" name="search" placeholder="Search for Bin" />
                    </Grid>
                  </Grid>

                  <Grid container display="flex" justifyContent="space-between">
                    <SoftButton variant="gradient" color="info" type="submit" sx={{ mt: 1 }}>
                      Search Bins
                    </SoftButton>
                  </Grid>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
        <DataTable
          table={{
            columns: [
              { Header: "BIN", accessor: "bin" },
              { Header: "reason", accessor: "reason" },
              { Header: "action", accessor: "action" },
              { Header: "brand", accessor: "brand" },
              { Header: "type", accessor: "type" },
              { Header: "country", accessor: "country" },
              { Header: "created at", accessor: "createdAt" },
              { Header: "Delete", accessor: "delete" },
            ],
            rows: getRows(),
          }}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          pageCount={_.get(data, "totalPages", 0)}
          rowCount={_.get(data, "totalDocs", 0)}
          manualPagination={true}
          isLoading={isLoading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          nextPage={_.get(data, "nextPage", 0)}
          sortDirection={sortDirection}
          columnsFilter={true}
          sortColumns={sortColumns}
          onHeaderCellClick={handleSortChange}
        />
      </DialogContent>
    </Card>
  );
};

export default BinCard;
