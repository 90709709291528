import * as Yup from "yup";

export const defaultValues = {
  pspIds: [],
  startDatetime: null,
  endDatetime: null,
  selectedSheets: [],
};
export const schema = Yup.object().shape({
  pspIds: Yup.array().of(Yup.string()).required("Psp is required").min(1, "Psp is required"),
  startDatetime: Yup.date().required("Start datetime is required"),
  endDatetime: Yup.date().required("End datetime is required"),
  selectedSheets: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Sheet name is required"),
        headerLine: Yup.number().required("Header line is required"),
        startFrom: Yup.number().required("Start from is required"),
        fieldsOfInterest: Yup.array()
          .of(
            Yup.object().shape({
              field: Yup.string().required("Field is required"),
              localField: Yup.string().required("Local field is required"),
            })
          )
          .required("Fields of interest are required"),
        statusMap: Yup.array()
          .of(
            Yup.object().shape({
              status: Yup.string().required("status is required"),
              localStatus: Yup.string().required("local status is required"),
            })
          )
          .required("Status map is required"),
      })
    )
    .required("Sheets are required")
    .min(1, "Sheets are required"),
});

const sanitizePspIds = (pspIds) => {
  if (_.isArray(pspIds) && pspIds.includes("")) {
    return null;
  }
  return pspIds;
};

export const sanitizeRequest = (data) => {
  return { ...data, pspIds: sanitizePspIds(data.pspIds) };
};
