import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";

import { MultipleSelectField, SelectInputField } from "components/FormField";

import { countriesFlags } from "constants/countriesFlags";
import { countries } from "constants/countries";

const CountrySelect = ({
  name,
  label = "",
  required = false,
  isMulti = false,
  placeholder = "Select country",
  useAll = false,
  ...rest
}) => {
  let countriesOptions = countries.slice(1, -1).map((item) => {
    const countryWithFlag = _.find(countriesFlags, { code: item.code });
    const flag = _.get(countryWithFlag, "image", "");
    const size = isMulti ? "xs" : "md";
    const py = isMulti ? 0 : 1.5;
    const px = isMulti ? 0 : 2;
    return {
      name: item.label,
      value: item.code,
      label: (
        <SoftBox display="flex" px={px} py={py}>
          <SoftBox mr={1}>
            <SoftAvatar src={flag} size={size} alt="title" />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column" justifyContent="center">
            <SoftTypography
              component="div"
              variant="button"
              textTransform="capitalize"
              fontWeight="medium"
            >
              {item.label}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ),
    };
  });
  if (useAll) {
    countriesOptions = [{ label: "All countries", value: null }].concat(countriesOptions);
  }
  const customFilter = (option, inputValue) => {
    const { value, data } = option;
    if (!inputValue || !value || !data?.name) return true;
    return (
      data?.name.toLowerCase().includes(inputValue.toLowerCase()) ||
      value.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  if (isMulti) {
    return (
      <MultipleSelectField
        {...rest}
        placeholder={placeholder}
        name={name}
        label={label}
        options={countriesOptions}
        required={required}
        isMulti
        multiLine
        filterOption={customFilter}
      />
    );
  }
  return (
    <SelectInputField
      {...rest}
      placeholder={placeholder}
      name={name}
      label={label}
      options={countriesOptions}
      required={required}
      filterOption={customFilter}
    />
  );
};

CountrySelect.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  useAll: PropTypes.bool,
};

export default CountrySelect;
