import * as Yup from "yup";

export const defaultValues = {
  paymentOptionId: null,
  type: "PAYIN",
  country: null,
  parentPaymentOptionId: null,
  authFeeFTDAmount: null,
  authFeeAmount: null,
  ftd: null,
  nftd: null,
  creditCard: null,
  debitCard: null,
  payin: null,
  payout: null,
  settlement: null,
  fxFee: null,
};
export const schema = Yup.object().shape({
  paymentOptionId: Yup.string().required("Payment option is required"),
  type: Yup.string().required("Type is required"),
  country: Yup.string().nullable(),
  parentPaymentOptionId: Yup.string().nullable(),
  authFeeFTDAmount: Yup.number().nullable(),
  authFeeAmount: Yup.number().nullable(),
  ftd: Yup.number().nullable(),
  nftd: Yup.number().nullable(),
  creditCard: Yup.number().nullable(),
  debitCard: Yup.number().nullable(),
  payin: Yup.number().nullable(),
  payout: Yup.number().nullable(),
  settlement: Yup.number().nullable(),
  fxFee: Yup.number().nullable(),
});

export const sanitizeRequest = (values) => {
  return {
    ..._.pickBy(values, _.identity),
    country: values.country,
    parentPaymentOptionId: values.parentPaymentOptionId,
  };
};
