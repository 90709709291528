import { useState } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import TableRowsIcon from "@mui/icons-material/TableRows";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SoftBox from "components/SoftBox";
import { a11yProps } from "components/TabPanel";
import Grid from "@mui/material/Grid";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import PropTypes from "prop-types";

const TransactionsTabs = ({ value, onChange }) => {
  //tab handle
  const handleTabChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Grid>
      <SoftBox sx={{ maxWidth: 750, marginTop: 2, ml: 2 }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            sx={{ backgroundColor: "transparent", display: { xs: "none", sm: "flex" } }}
            variant="standard"
          >
            <Tab
              icon={<TableRowsIcon />}
              label="Transaction Grid"
              {...a11yProps(0)}
              sx={{ marginRight: 2 }}
            />

            <Tab
              icon={<MonetizationOnOutlinedIcon />}
              label="Summary Report"
              {...a11yProps(2)}
              sx={{ marginRight: 2 }}
            />
            <Tab
              icon={<CheckCircleOutlineOutlinedIcon />}
              label="Approval rate"
              {...a11yProps(3)}
            />
          </Tabs>
        </AppBar>
      </SoftBox>
    </Grid>
  );
};
TransactionsTabs.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default TransactionsTabs;
