import * as Yup from "yup";

export const defaultValues = {
  password: null,
};
export const schema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must have at least 8 symbols")
    .required("Password is required"),
});
