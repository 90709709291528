import * as Yup from "yup";

export const defaultValues = {
  name: null,
  mid: null,
  dailyUsdLimit: null,
  monthlyUsdLimit: null,
  minSuccessTx: null,
  hsfst: null,
  default: false,
};
export const schema = Yup.object().shape({
  name: Yup.string().required("Psp name is required"),
  mid: Yup.string().required("Psp mid is required"),
  dailyUsdLimit: Yup.number().nullable(),
  monthlyUsdLimit: Yup.number().nullable(),
  minSuccessTx: Yup.number().nullable(),
  hsfst: Yup.number().nullable(),
  default: Yup.boolean().nullable(),
});
