import { toastSuccess } from "utils/toast";
import { useQuery, useMutation, useQueryClient } from "react-query";
import request from "utils/request";
import { getTemplatePath } from "utils/string";
import { useMidStore } from "stores/mid";
import { getQueryParam } from "utils/string";

const GET_PSP_MID = "psp/:pspId/mids/:midId";
const EDIT_MID_PSP = "psp/:pspId/mids/:midId";
const GET_CRED = "psp/:pspId/mids/:midId/credentials";
const UPDATE_CRED = "psp/:pspId/mids/:midId/credentials";
const DELETE_CRED = "psp/:pspId/mids/:midId/credentials";
const BINS = "psp/:pspId/mids/:pspMidId/bin-routes";
const BINS_CSV = "psp/:pspId/mids/:pspMidId/bin-routes/csv";
const GET_PSP_MID_BRANDS = `${GET_PSP_MID}/brands`;

const getPspMid = ({ pspId, midId }) => request.get(getTemplatePath(GET_PSP_MID, { pspId, midId }));
const editPspMid = ({
  pspId,
  midId,
  name,
  mid,
  allowCardTypes,
  disallowCardTypes,
  dailyUsdLimit,
  monthlyUsdLimit,
  minSuccessTx,
  velocities,
  active,
  hsfst,
  isDefault,
}) => {
  const URL = getTemplatePath(EDIT_MID_PSP, { pspId, midId });
  const dailyLimit = Number(dailyUsdLimit) === 0 ? null : Number(dailyUsdLimit);
  const monthlyLimit = Number(monthlyUsdLimit) === 0 ? null : Number(monthlyUsdLimit);
  const minSuccess = Number(minSuccessTx) === 0 ? null : Number(minSuccessTx);
  return request.put(URL, {
    name,
    mid,
    dailyUsdLimit: dailyLimit,
    monthlyUsdLimit: monthlyLimit,
    allowCardTypes: allowCardTypes,
    disallowCardTypes: disallowCardTypes,
    minSuccessTx: minSuccess,
    velocities: velocities?.map((i) => ({
      per: i.per,
      count: Number(i.count),
      hour: Number(i.hour),
    })),
    active,
    hsfst: Number(hsfst),
    default: isDefault,
  });
};
const getCredentials = ({ pspId, midId }) =>
  request.get(getTemplatePath(GET_CRED, { pspId, midId }));

const updateCredentials = ({ pspId, midId, ...data }) => {
  const URL = getTemplatePath(UPDATE_CRED, { pspId, midId });
  const payload = {
    pspId,
    data,
  };
  return request.put(URL, payload);
};

const deleteCredential = ({ pspId, midId }) => {
  const URL = getTemplatePath(DELETE_CRED, { pspId, midId });
  return request.delete(URL);
};

export const useGetPspMid = ({ pspId, midId }) => {
  const { setMid } = useMidStore();
  return useQuery({
    queryKey: [GET_PSP_MID],
    queryFn: () => getPspMid({ pspId, midId }),
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setMid(data);
    },
  });
};

export const useUpdatePspMid = () => {
  const queryClient = useQueryClient();
  return useMutation(editPspMid, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_PSP_MID]);
      toastSuccess({ description: "Psp Mid edited successfully" });
    },
  });
};

export const useGetCredentials = ({ pspId, midId }) => {
  return useQuery({
    queryKey: [GET_CRED],
    queryFn: () => getCredentials({ pspId, midId }),
    refetchOnWindowFocus: false,
    retry: false,
  });
};

export const useUpdateCredential = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCredentials, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_CRED]);
      toastSuccess({ description: "Credential edited successfully" });
    },
  });
};
export const useDeleteCredential = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteCredential, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_CRED]);
      toastSuccess({ description: "Credential deleted successfully" });
    },
  });
};

// Bin

const getBins = (filteredPayload) => {
  const { pspId, pspMidId, ...payload } = filteredPayload;
  const queryParams = getQueryParam(payload);
  const URL = getTemplatePath(BINS, { pspId, pspMidId });
  return request.get(`${URL}?${queryParams}`);
};

export const useGetPaginationBins = () => {
  return useMutation(getBins);
};

const createBin = (payload) => {
  const { pspId, pspMidId, ...sanPayload } = payload;
  const URL = getTemplatePath(BINS, { pspId, pspMidId });
  return request.post(URL, sanPayload);
};
export const useCreateBin = () => {
  return useMutation(createBin, {
    onSuccess: () => {
      toastSuccess({ description: "Bin created successfully" });
    },
  });
};

const createBinsFromCsv = ({ pspId, pspMidId, formData }) => {
  const URL = getTemplatePath(BINS_CSV, { pspId, pspMidId });
  return request.post(URL, formData);
};
export const useCreateBinFromCSV = () => {
  return useMutation(createBinsFromCsv, {
    onSuccess: (data) => {
      toastSuccess({ description: data });
    },
  });
};

const deleteBin = ({ binId, pspId, pspMidId }) => {
  const URL = getTemplatePath(BINS, { pspId, pspMidId });
  return request.delete(`${URL}/${binId}`);
};
export const useDeleteBin = () => {
  return useMutation(deleteBin, {
    onSuccess: () => {
      toastSuccess({ description: "Bins deleted successfully" });
    },
  });
};

const getBrands = ({ pspId, midId }) =>
  request.get(getTemplatePath(GET_PSP_MID_BRANDS, { pspId, midId }));
export const useGetBrands = ({ pspId, midId }) => {
  return useQuery({
    queryKey: [GET_PSP_MID_BRANDS],
    queryFn: () => getBrands({ pspId, midId }),
    refetchOnWindowFocus: false,
  });
};
