import { useState, useEffect } from "react";

import _ from "lodash";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import DataTable from "examples/Tables/DataTable";
import StatusChip from "components/StatusChip";
import TransactionManagementFilters from "./components/TransactionManagementFilters";
import { useForm } from "react-hook-form";
import FormProvider from "components/FormProviders";
import { set, get } from "utils/localStorage";
import ActionCell from "components/ActionCell";
import { countries } from "constants/countries";
import Customer from "./components/Customer";
import NotificationModal from "./components/NotificationModal";
import RefundModal from "./components/RefundModal";
import { Chip, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetFilterTransactions } from "../transactions/useTransactions";
import moment from "moment/moment";
import Info from "./components/Info";
import { generateDateRange } from "utils/time";
import { sanitizeRequest } from "./schemas";
import { useGetMerchants } from "pages/merchant-management/hooks";
import { useGetPaymentOptions } from "../brand-detail/hooks";
import { useDataTable } from "hooks/useDataTable";
import { updateSelectedFilters } from "utils/filters";

const LC_PAYLOAD = "merchantReportingPayload";
const ACTION_CELL = [
  {
    type: "notification",
    title: "Send Notification",
  },
  {
    type: "withdrawal",
    title: "Refund",
  },
  {
    type: "edit",
    title: "Edit Transaction",
  },
];

const TransactionManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [pageSize, setPageSize] = useState(10);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [refundedModalOpen, setRefundedModalOpen] = useState(false);
  const [currentSelectedTransaction, setCurrentSelectedTransaction] = useState({});
  const [firstRender, setFirstRender] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const { onSortChange, sortDirection, setSortDirection } = useDataTable();

  const [selectedFilters, setSelectedFilters] = useState({});
  // Destructuring the object of all selected filters to obtain only the necessary
  const filters = _.omit(selectedFilters, ["page", "limit", "brandId"]);
  const { mutate, isLoading, data } = useGetFilterTransactions();
  const { data: merchants } = useGetMerchants();
  const { data: paymentOptions } = useGetPaymentOptions();
  const merchantReportingPayload = get(LC_PAYLOAD) || {};

  const defaultValues = merchantReportingPayload;
  const methods = useForm({ defaultValues });
  const { handleSubmit } = methods;
  const queryParams = new URLSearchParams(location.search);
  const pageParameter = queryParams.get("page");
  useEffect(() => {
    if (pageParameter) {
      handlePageChange(pageParameter - 1);
    } else if (merchantReportingPayload) {
      mutate(sanitizeRequest(merchantReportingPayload));
      if (merchantReportingPayload.sortDirection)
        setSortDirection(merchantReportingPayload.sortDirection);
    } else {
      mutate(
        sanitizeRequest({
          page: 1,
          merchantId: "All",
          limit: pageSize,
          sortBy: "createdAt",
          sortDirection: sortDirection,
        })
      );
    }
  }, []);

  useEffect(() => {
    updateSelectedFilters(merchantReportingPayload, setSelectedFilters, {
      merchants,
      paymentOptions,
    });
  }, [merchants, paymentOptions]);

  const getSelectedTransaction = (id) => {
    const transaction = _.find(_.get(data, "docs", []), { id });
    setCurrentSelectedTransaction(transaction);
  };

  // create a function that navigates to the detail page for a given transaction
  const handleNotificationClick = (id) => {
    getSelectedTransaction(id);
    setNotificationModalOpen(true);
  };
  const handleNotificationClose = () => {
    setNotificationModalOpen(false);
  };
  // create a function that navigates to the edit page for a given transaction
  const handleRefundClick = (id) => {
    getSelectedTransaction(id);
    setRefundedModalOpen(true);
  };
  const setRefundedModalClose = () => {
    setRefundedModalOpen(false);
    mutate(sanitizeRequest(merchantReportingPayload));
  };

  // create a function that navigates to the edit page for a given transaction
  const handleEditClick = (id) => {
    navigate(`/transactions/edit/${id}?edit=true`);
  };

  // create a function that returns the rows for the table
  const getRows = () => {
    return _.map(_.get(data, "docs", []), (item) => ({
      country: _.get(_.find(countries, { code: item.country }), "label", "N/A"),
      currency: item.currency,
      amount: item.paymentAmount ? item.paymentAmount : item.amount,
      paymentOption: item.paymentOption,
      status: <StatusChip label={item.status} size="small" tooltip={item.errorReason} />,
      createdAt: item.createdAt,
      details: <Info item={item} />,
      id: item.id,
      customer: <Customer customer={item.customer} />,
      actions: (
        <ActionCell
          actions={ACTION_CELL}
          onNotificationClick={() => handleNotificationClick(item.id)}
          onWithdrawalClick={() => handleRefundClick(item.id)}
          onEditClick={() => handleEditClick(item.id)}
        />
      ),
    }));
  };

  // create a function that handles the form submission
  const onSubmit = (values) => {
    if (pageParameter) removeQueryParam();
    let fromDate = values.fromDate;
    let toDate = values.toDate;
    if (values.last) {
      const { last } = values;
      const dateRange = generateDateRange(last);
      fromDate = dateRange.fromDate;
      toDate = dateRange.toDate;
    }
    const payload = {
      ...values,
      fromDate: fromDate && moment(new Date(fromDate)).format("YYYY-MM-DD HH:mm:ss"),
      toDate: toDate && moment(new Date(toDate)).format("YYYY-MM-DD HH:mm:ss"),
      limit: pageSize,
      page: 1,
      sortBy: "createdAt",
      sortDirection: sortDirection,
    };
    setCurrentPage(1);
    set(LC_PAYLOAD, payload);
    mutate(sanitizeRequest(payload));
    updateSelectedFilters(values, setSelectedFilters, {
      merchants,
      paymentOptions,
    });
  };

  const removeQueryParam = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("page");
    navigate({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  };

  // create a function that handles the page change
  const handlePageChange = (page) => {
    if (pageParameter) removeQueryParam();
    setCurrentPage(page + 1);
    const payload = merchantReportingPayload;
    if (payload.sortDirection) setSortDirection(payload.sortDirection);
    payload.page = page + 1;
    set(LC_PAYLOAD, payload);
    mutate(sanitizeRequest(payload));
  };
  // create a function that handles the page size change
  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    if (!firstRender) setCurrentPage(1);
    const payload = merchantReportingPayload;
    payload.page = 1;
    payload.limit = pageSize;
    set(LC_PAYLOAD, payload);
    firstRender ? setFirstRender(false) : mutate(sanitizeRequest(payload));
  };
  const handleSortChange = (column) => {
    onSortChange({
      column,
      sortColumns: ["createdAt"],
      lcPayload: LC_PAYLOAD,
      mutate,
      setCurrentPage,
      sanitizeRequest,
    });
  };
  return (
    <MainLayout>
      <MainNavbar />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <TransactionManagementFilters
          onSubmit={handleSubmit(onSubmit)}
          merchants={merchants}
          paymentOptions={paymentOptions}
        />
      </FormProvider>
      <Stack
        direction="row"
        spacing={1}
        sx={{ mt: 2, mb: 2 }}
        display="flex"
        flexWrap="wrap"
        gap={1}
      >
        {Object.values(filters)?.map((i, index) =>
          i ? <Chip key={i + index} label={i} color="secondary" textColor="white" /> : null
        )}
      </Stack>
      <DataTable
        table={{
          columns: [
            { Header: "country", accessor: "country" },
            { Header: "currency", accessor: "currency" },
            { Header: "amount", accessor: "amount" },
            { Header: "option", accessor: "paymentOption" },
            { Header: "status", accessor: "status" },
            { Header: "details", accessor: "details" },
            { Header: "customer", accessor: "customer" },
            { Header: "created time", accessor: "createdAt" },
            { Header: "actions", accessor: "actions" },
          ],
          rows: getRows(),
        }}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        pageCount={_.get(data, "pagination.totalPages", 0)}
        manualPagination={true}
        rowCount={_.get(data, "pagination.totalDocs", 0)}
        isLoading={isLoading}
        currentPage={currentPage}
        sortDirection={sortDirection}
        onCellClick={(row) => {
          navigate(`/transactions/${row?.original?.id}`);
        }}
        onHeaderCellClick={handleSortChange}
        isClickable={true}
      />
      <NotificationModal
        transaction={currentSelectedTransaction}
        onClose={handleNotificationClose}
        isOpen={notificationModalOpen}
      />
      <RefundModal
        transaction={currentSelectedTransaction}
        onClose={setRefundedModalClose}
        isOpen={refundedModalOpen}
      />
    </MainLayout>
  );
};

export default TransactionManagement;
