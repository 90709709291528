import * as Yup from "yup";

export const assignMidDefaultValues = {
  psp: null,
  pspMid: null,
};
export const assignMidSchema = Yup.object().shape({
  psp: Yup.string().required("PSP is required").nullable(),
  pspMid: Yup.string().required("PSP MID is required").nullable(),
});

export const pspMidDetail = Yup.object().shape({
  payinEnabled: Yup.boolean(),
  payoutEnabled: Yup.boolean(),
  weight: Yup.number().min(0).max(100),
});
