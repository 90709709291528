import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import request from "utils/request";
import { getTemplatePath } from "utils/string";
import { toastSuccess } from "utils/toast";

const INTERNAL_ERRORS = "/error/codes";
const PROVIDER_UNRESOLVED = "/error/unresolved";
const PROVIDER_RESOLVED = "/error/resolved";
const PROVIDER_RESOLVE = "/error/resolve";
const INTERNAL_ERROR = `${INTERNAL_ERRORS}/:id`;

//get internal error codes
const getInternalErrorCodes = async () => {
  return request.get(INTERNAL_ERRORS);
};
export const useGetInternalErrorCodes = () => {
  return useQuery([INTERNAL_ERRORS], getInternalErrorCodes, {
    refetchOnWindowFocus: false,
  });
};

// get list of unresolved provider errors.
const getUnresolvedErrorCodes = async () => {
  return request.get(PROVIDER_UNRESOLVED);
};
export const useGetUnresolvedErrorCodes = () => {
  return useQuery([PROVIDER_UNRESOLVED], getUnresolvedErrorCodes);
};

// get list of resolved provider errors.
const getResolvedErrorCodes = async () => {
  return request.get(PROVIDER_RESOLVED);
};
export const useGetResolvedErrorCodes = () => {
  return useQuery([PROVIDER_RESOLVED], getResolvedErrorCodes);
};

// create new internal error code
const addError = (payload) => {
  return request.post(INTERNAL_ERRORS, payload);
};
export const useAddError = () => {
  const queryClient = useQueryClient();
  return useMutation(addError, {
    onSuccess: () => {
      toastSuccess({ description: "Error added successfully" });
      queryClient.invalidateQueries([INTERNAL_ERRORS]);
    },
  });
};
// create new internal error code
const editError = ({ id, ...payload }) => {
  const URL = getTemplatePath(INTERNAL_ERROR, { id });
  return request.put(URL, payload);
};
export const useEditError = () => {
  const queryClient = useQueryClient();
  return useMutation(editError, {
    onSuccess: () => {
      toastSuccess({ description: "Error updated successfully" });
      queryClient.invalidateQueries([INTERNAL_ERRORS]);
    },
  });
};

// resolve provider error
const resolveError = (payload) => {
  return request.post(PROVIDER_RESOLVE, payload);
};
export const useResolveError = () => {
  const queryClient = useQueryClient();
  return useMutation(resolveError, {
    onSuccess: () => {
      toastSuccess({ description: "Resolved successfully" });
      queryClient.invalidateQueries([PROVIDER_UNRESOLVED]);
      queryClient.invalidateQueries([PROVIDER_RESOLVED]);
    },
  });
};

// delete error map
const deleteErrorMap = ({ id }) => {
  return request.delete(`${PROVIDER_RESOLVE}/${id}`);
};
export const useDeleteErrorMap = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteErrorMap, {
    onSuccess: () => {
      toastSuccess({ description: "Error map deleted successfully" });
      queryClient.invalidateQueries([PROVIDER_UNRESOLVED]);
      queryClient.invalidateQueries([PROVIDER_RESOLVED]);
    },
  });
};

// delete internal error
const deleteInternalError = ({ id }) => {
  return request.delete(`${INTERNAL_ERRORS}/${id}`);
};
export const useDeleteInternalError = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteInternalError, {
    onSuccess: () => {
      toastSuccess({ description: "Error deleted successfully" });
      queryClient.invalidateQueries([INTERNAL_ERRORS]);
    },
  });
};
