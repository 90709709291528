import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import pxToRem from "assets/theme/functions/pxToRem";
import FormProvider from "components/FormProviders";

import _ from "lodash";
import { DeleteForever } from "@mui/icons-material";
import { useDisclosure } from "hooks/useDisclosure";

const DeleteModal = ({
  message,
  title,
  onDelete,
  type,
  isLoading,
  size,
  onClose: onCloseCallback,
  iconColor,
  iconText,
  buttonText,
  buttonColor,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  //form provider
  const methods = useForm();
  const { handleSubmit } = methods;
  //hooks

  // create a function that handles the form submission

  const onSubmit = async (values) => {
    await onDelete(values);
    onClose();
    if (onCloseCallback) onCloseCallback();
  };

  const renderButton = () => {
    if (type === "button")
      return (
        <SoftButton
          variant="gradient"
          color={buttonColor || "error"}
          sx={{ mt: 2 }}
          onClick={onOpen}
        >
          {buttonText || "Delete"}
        </SoftButton>
      );
    if (type === "icon") {
      return (
        <Tooltip title="Delete">
          <IconButton onClick={onOpen} disabled={isLoading}>
            <DeleteForever color={iconColor} />{" "}
            {iconText && (
              <SoftTypography variant="caption" color={iconColor}>
                {iconText}
              </SoftTypography>
            )}
          </IconButton>
        </Tooltip>
      );
    }
  };

  return (
    <>
      {renderButton()}
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={size || "xs"}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} key={"deleteModal"}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            {title}
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3}>
              <SoftBox>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <DeleteForeverIcon
                          sx={{
                            width: pxToRem(48),
                            height: pxToRem(48),
                            color: (theme) => theme.palette.error.main,
                          }}
                        />
                        <SoftBox ml={2} lineHeight={0}>
                          <SoftTypography variant="h6" fontWeight="medium">
                            {title}
                          </SoftTypography>
                          <SoftTypography variant="button" color="text" fontWeight="regular">
                            {message}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px" }}
          >
            <SoftButton
              variant="gradient"
              color="error"
              onClick={handleSubmit(onSubmit)}
              sx={{ mt: 2 }}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Delete
            </SoftButton>
            <SoftButton variant="gradient" sx={{ mt: 2 }} onClick={onClose}>
              Cancel
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

// Typechecking props for the DeleteMerchantModal
DeleteModal.propTypes = {
  message: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  size: PropTypes.string,
  onClose: PropTypes.func,
  iconColor: PropTypes.string,
  iconText: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonText: PropTypes.string,
};

export default DeleteModal;
