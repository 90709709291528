import { useState } from "react";
import { useParams } from "react-router-dom";

import SoftBox from "components/SoftBox";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";

// Settings page components
import BranchNavBar from "./components/BranchNavBar";
import BrandOverview from "./components/Overview";
import Wallets from "./components/Wallets";
import ApiKeys from "./components/ApiKeys";
import PspMids from "./components/PspMids";
import Domains from "./components/Domains";
import TabPanel from "components/TabPanel";

import { useGetBrand } from "./hooks";
import { useMerchantStore } from "stores/merchant";

function BrandDetail() {
  const { id: merchantId, brandId } = useParams();
  const [value, setValue] = useState(0);

  const { data } = useGetBrand({ merchantId, brandId });
  const { merchant } = useMerchantStore();

  const merchantName = _.get(merchant, "name", null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <MainLayout>
      <MainNavbar
        customRoutes={[
          { label: "Merchants", route: "merchants" },
          { label: merchantName ? merchantName : merchantId, route: `merchants/${merchantId}` },
          { label: "brands", route: `merchants/${merchantId}?tab=brands` },
          {
            label: _.get(data, "name", brandId),
            route: `merchants/${merchantId}/brands/${brandId}`,
          },
        ]}
      />

      <SoftBox mt={4}>
        <BranchNavBar onChange={handleChange} value={value} />
        <TabPanel value={value} index={0}>
          <BrandOverview />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Wallets />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ApiKeys />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <PspMids />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Domains />
        </TabPanel>
      </SoftBox>
    </MainLayout>
  );
}

export default BrandDetail;
