import React from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import SoftButton from "components/SoftButton";
import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import DataTable from "examples/Tables/DataTable";

import { useGetOrganizations } from "./hooks";
import { useForm } from "react-hook-form";
import { fDateTimeSuffix } from "utils/formatTime";
import AddOrganizationModal from "./components/AddOrganizationModal";

const Organizations = () => {
  const navigate = useNavigate();
  // forms
  const methods = useForm();

  // hooks
  const { data: organizations, isLoading } = useGetOrganizations();

  //  functions
  const getRows = () => {
    return _.map(organizations, (item) => ({
      id: item._id,
      name: item.name,
      createdAt: fDateTimeSuffix(item.createdAt),
      updatedAt: fDateTimeSuffix(item.updatedAt),
    }));
  };

  return (
    <MainLayout>
      <MainNavbar />
      <AddOrganizationModal />
      <DataTable
        table={{
          columns: [
            { Header: "ID", accessor: "id" },
            { Header: "name", accessor: "name" },
            { Header: "updatedAt", accessor: "updatedAt" },
            { Header: "createdAt", accessor: "createdAt" },
          ],
          rows: getRows() || [],
        }}
        canSearch={true}
        columnsFilter={true}
        onCellClick={(row) => {
          navigate(`/organizations/${row?.original?.id}`);
        }}
        isClickable={true}
        isLoading={isLoading}
      />
    </MainLayout>
  );
};

export default Organizations;
