import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Card, Grid, DialogActions } from "@mui/material";
import SoftBox from "components/SoftBox";
import { useUpdateOrganization } from "../../hooks";
import FormProvider from "components/FormProviders";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema, defaultValues, sanitizeRequest } from "../../schemas";
import { TextInputField } from "components/FormField";
import SoftButton from "components/SoftButton";
import PropTypes from "prop-types";

const OrgDetails = ({ org }) => {
  const { id: organizationId } = useParams();

  // forms
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = methods;

  // hooks
  const { mutate: updateOrganization, isLoading } = useUpdateOrganization(organizationId);
  // const { mutateAsync: deleteOrganization, isLoading: isDeleting } = useDeleteOrganization();

  //functions
  const onSubmit = (values) => {
    updateOrganization({ id: organizationId, ...sanitizeRequest(values) });
  };

  useEffect(() => {
    if (org) {
      reset(org);
    }
  }, [org]);

  return (
    <Card>
      <SoftBox p={3}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInputField name="name" label="Name" />
            </Grid>
            <Grid item xs={6}>
              <TextInputField name="createdAt" label="Created At" disabled />
            </Grid>
            <Grid item xs={6}>
              <TextInputField name="updatedAt" label="Updated At" disabled />
            </Grid>
          </Grid>
          <DialogActions style={{ justifyContent: "flex-start" }}>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              sx={{ mt: 2 }}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Update
            </SoftButton>
            {/* <DeleteModal
                type="button"
                title="Delete Organization"
                message={
                  <>
                    Are you sure you want to delete organization <b>{_.get(data, "name")}</b>? you
                    will not be able to recover this organization?
                  </>
                }
                isLoading={isDeleting}
                onDelete={async () => {
                  await deleteOrganization({ id: organizationId });
                }}
                onClose={() => {
                  navigate("/organizations");
                }}
              /> */}
          </DialogActions>
        </FormProvider>
      </SoftBox>
    </Card>
  );
};
OrgDetails.propTypes = {
  org: PropTypes.object,
};
export default OrgDetails;
