import _ from "lodash";
import { useMutation, useQueryClient } from "react-query";
import request from "utils/request";
import { toastError, toastSuccess } from "utils/toast";
import { getQueryParam, getTemplatePath } from "utils/string";

const GET_BLACK_LIST = "/customer/blacklist";
const ADD_CUSTOMER_TO_BLACK_LIST = "/customer/blacklist";
const DELETE_BLACK_LIST = "/customer/blacklist/:id";

const WHITE_LISTS = "/customer/whitelist-ip";
const WHITE_LIST = "/customer/whitelist-ip/:id";

//get blacklist
const getFilterBlacklist = async (filterBlacklistProps) => {
  const filteredPayload = _.pickBy(filterBlacklistProps, _.identity);
  const queryParams = getQueryParam(filteredPayload);
  return request.get(`${GET_BLACK_LIST}?${queryParams}`);
};
export const useGetFilterBlacklist = () => {
  return useMutation(getFilterBlacklist, {
    onError: (error) => {
      const message = _.get(error, "response.data.message[0]");
      toastError({ description: message });
    },
  });
};

// add Card To Blacklist
const addCustomerToBlacklist = ({ payload }) => {
  return request.post(ADD_CUSTOMER_TO_BLACK_LIST, payload);
};
export const useAddCustomerToBlacklist = () => {
  const queryClient = useQueryClient();
  return useMutation(addCustomerToBlacklist, {
    onSuccess: () => {
      toastSuccess({ description: "Customer added successfully" });
      queryClient.invalidateQueries([GET_BLACK_LIST]);
    },
  });
};

// Delete Card From Blacklist
const deleteCustomerFromBlacklist = ({ id }) => {
  return request.delete(getTemplatePath(DELETE_BLACK_LIST, { id }));
};
export const useDeleteCardFromBlacklist = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteCustomerFromBlacklist, {
    onSuccess: () => {
      toastSuccess({ description: "Customer deleted successfully" });
      queryClient.invalidateQueries([GET_BLACK_LIST]);
    },
  });
};

// add Card To whitelist
const addCustomerToWhitelist = ({ payload }) => {
  return request.post(WHITE_LISTS, payload);
};
export const useAddCustomerToWhitelist = () => {
  const queryClient = useQueryClient();
  return useMutation(addCustomerToWhitelist, {
    onSuccess: () => {
      toastSuccess({ description: "Customer added successfully" });
      queryClient.invalidateQueries([WHITE_LISTS]);
    },
  });
};

//get whitelist
const getFilterWhitelist = async (props) => {
  const filteredPayload = _.pickBy(props, _.identity);
  const queryParams = getQueryParam(filteredPayload);
  return request.get(`${WHITE_LISTS}?${queryParams}`);
};
export const useGetFilterWhitelist = () => {
  return useMutation(getFilterWhitelist, {
    onError: (error) => {
      const message = _.get(error, "response.data.message[0]");
      toastError({ description: message });
    },
  });
};

// Delete Card From white
const deleteCustomerFromWhitelist = ({ id }) => {
  return request.delete(getTemplatePath(WHITE_LIST, { id }));
};
export const useDeleteCustomerFromWhitelist = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteCustomerFromWhitelist, {
    onSuccess: () => {
      toastSuccess({ description: "Customer deleted successfully" });
      queryClient.invalidateQueries([WHITE_LISTS]);
    },
  });
};
