import _ from "lodash";
import { toastSuccess } from "utils/toast";
import { useQuery, useMutation, useQueryClient } from "react-query";
import request from "utils/request";
import { getTemplatePath } from "utils/string";
import { usePspsStore } from "stores/psps";

export const GET_PSP = "psps";
const UPDATE_PSP = "psps";
const GET_MID_PSP = "psp/:pspId/mids";

const getPsps = () => request.get(GET_PSP);
const getMidPsp = (pspId) => request.get(getTemplatePath(GET_MID_PSP, { pspId }));
// update psp
const updatePsp = (data) => {
  const pspId = data.pspId;
  const payload = _.omit(data, ["pspId"]);
  return request.put(`${UPDATE_PSP}/${pspId}`, payload);
};
export const useGetPsps = () => {
  const { setPsps } = usePspsStore();
  return useQuery({
    queryKey: [GET_PSP],
    queryFn: getPsps,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setPsps(data);
    },
  });
};
export const useGetPspMid = () => {
  return useMutation(getMidPsp);
};

// update psp hook
export const useUpdatePsp = () => {
  const queryClient = useQueryClient();
  return useMutation(updatePsp, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_PSP]);
      toastSuccess({ description: "Update psp has been success" });
    },
  });
};
