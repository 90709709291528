import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";

import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { useDisclosure } from "hooks/useDisclosure";

import FormProvider from "components/FormProviders";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import {
  useCreatePspMidMethod,
  useCreateTemplate,
  useGetTemplates,
} from "pages/brand-detail/hooks";
import PaymentMethodForm from "./PaymentMethodForm";

import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema, sanitizeRequest } from "pages/brand-detail/schemas/paymentMethod";
import { sanitizeRequest as sanitizeTemplate } from "pages/brand-detail/schemas/paymentMethodsTemplate";
import SoftInput from "components/SoftInput";
import { toastError } from "utils/toast";
import { SelectInputField } from "components/FormField";
import { generateOptions } from "utils/options";
import {
  extractDefaultCurrency,
  groupCountryBySetting,
  sanitizeDefaultCurrency,
} from "pages/brand-detail/components/PspMids/components/EditPaymentMethod";

const AddPaymentMethod = ({ pspMidId, excludeOptions }) => {
  const { id: merchantId, brandId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [templateName, setTemplateName] = useState("");

  // query
  const { mutate, isLoading } = useCreatePspMidMethod({ merchantId, brandId, pspMidId });
  const { mutate: createTemplate } = useCreateTemplate();
  const { data: templates } = useGetTemplates();

  const templateOptions = generateOptions(templates, false);

  // hook form
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, setValue, reset, watch, getValues } = methods;
  const selectedTemplate = watch("template");

  // function
  const handleSubmitForm = async (values) => {
    if (saveAsTemplate && !templateName) {
      toastError({ description: "Template Name must not be empty" });
    } else {
      const payload = sanitizeRequest({
        merchantId,
        brandId,
        pspMidId,
        ...values,
      });
      if (saveAsTemplate) {
        const templatePayload = sanitizeTemplate({ name: templateName, ...payload });
        createTemplate(templatePayload);
      }

      mutate(payload, {
        onSuccess: () => {
          handleClose();
        },
      });
    }
  };

  const handleClose = () => {
    setValue("payinCountries", []);
    setValue("payoutCountries", []);
    reset();
    onClose();
  };

  const templateSaveOnChange = (e) => {
    setSaveAsTemplate(e.target.checked);
  };

  const templateNameOnChange = (e) => {
    setTemplateName(e.target.value);
  };

  useEffect(() => {
    if (selectedTemplate) {
      const templateValue = _.find(templates, (t) => t._id == selectedTemplate);
      const payinGroup = groupCountryBySetting(
        sanitizeDefaultCurrency(templateValue?.payinCountries)
      );
      const payoutGroup = groupCountryBySetting(
        sanitizeDefaultCurrency(templateValue?.payoutCountries)
      );
      const payinCountries = extractDefaultCurrency(payinGroup);
      const payoutCountries = extractDefaultCurrency(payoutGroup);
      setValue("payoutCountries", payoutCountries);
      setValue("payinCountries", payinCountries);
    } else {
      setValue("payoutCountries", []);
      setValue("payinCountries", []);
    }
  }, [selectedTemplate]);

  return (
    <>
      <SoftButton variant="gradient" color="info" onClick={onOpen} sx={{ width: "220px" }}>
        Add Payment Method
      </SoftButton>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        sx={{ overflow: "visible" }}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>Add Payment Method</DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
            <SoftBox py={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <SelectInputField
                    name="template"
                    options={templateOptions}
                    label="Template"
                    isClearable
                  />
                </Grid>
              </Grid>
              <PaymentMethodForm excludeOptions={excludeOptions} showPaymentOption={true} />
              <Stack direction="row" spacing={1} justifyContent="center" mt={4}>
                <FormControlLabel
                  control={<Checkbox checked={saveAsTemplate} onChange={templateSaveOnChange} />}
                  label="Save As Template"
                />
              </Stack>
              {saveAsTemplate && (
                <Stack direction="row" spacing={1} justifyContent="center" mt={1}>
                  <SoftInput
                    label="Template Name"
                    sx={{ maxWidth: "300px" }}
                    mt={1}
                    placeholder="Template Name"
                    value={templateName}
                    onChange={templateNameOnChange}
                  />
                </Stack>
              )}
              <Stack direction="row" spacing={1} justifyContent="center" mt={2}>
                <SoftButton onClick={handleClose}>Cancel</SoftButton>
                <SoftButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Add
                </SoftButton>
              </Stack>
            </SoftBox>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

AddPaymentMethod.propTypes = {
  pspMidId: PropTypes.string.isRequired,
  excludeOptions: PropTypes.array,
};

export default AddPaymentMethod;
