import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useForm } from "react-hook-form";

import SoftButton from "components/SoftButton";
import FormProvider from "components/FormProviders";
import SoftBox from "components/SoftBox";

import { yupResolver } from "@hookform/resolvers/yup";
import { useDisclosure } from "hooks/useDisclosure";

import { defaultValues, schema, sanitizeRequest } from "../schemas";
import { useUpdateAmountLimit } from "../hooks";
import { TextInputField } from "components/FormField";
import EditIcon from "@mui/icons-material/Edit";

const UpdateAmountLimitModal = ({ onClose: onCloseCallback, amountLimit }) => {
  const { id: pspId, midId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();

  // hooks
  const { mutate: updateAmountLimit, isLoading } = useUpdateAmountLimit();

  // form
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { handleSubmit, reset } = methods;

  const onSubmit = (values) => {
    const payload = {
      id: amountLimit._id,
      ...sanitizeRequest({ pspId, midId, ...values }),
    };
    updateAmountLimit(payload, {
      onSuccess: () => {
        onClose();
        onCloseCallback();
      },
    });
  };
  useEffect(() => {
    if (amountLimit) {
      reset(amountLimit);
    }
  }, [amountLimit]);

  return (
    <>
      <Tooltip title="Delete">
        <IconButton onClick={onOpen} disabled={isLoading} color="text">
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Update Amount Limit
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3}>
              <Grid container spacing={3} mb={3}>
                <Grid item xs={12} sm={6}>
                  <TextInputField label="Min Amount" name="min" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInputField label="Max Amount" name="max" />
                </Grid>
              </Grid>
            </SoftBox>
          </DialogContent>
          <DialogActions>
            <SoftButton onClick={onClose}>Cancel</SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              disabled={isLoading}
              isLoading={isLoading}
            >
              Update Amount Limit
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};
UpdateAmountLimitModal.propTypes = {
  onClose: PropTypes.func,
  amountLimit: PropTypes.object.isRequired,
};
export default UpdateAmountLimitModal;
