import { useState } from "react";
import { set, get } from "utils/localStorage";

export const useDataTable = () => {
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("createdAt");

  const onSortChange = ({
    column,
    sortColumns,
    lcPayload,
    mutate,
    setCurrentPage,
    sanitizeRequest,
  }) => {
    if (sortColumns.includes(column.id)) {
      const payload = {
        ...get(lcPayload),
        sortBy: column.id,
        sortDirection: sortDirection === "desc" || sortDirection === "" ? "asc" : "desc",
        page: 1,
      };
      setSortBy(column.id);
      mutate(sanitizeRequest(payload));
      set(lcPayload, payload);
      setSortDirection((prev) => (prev === "desc" ? "asc" : "desc"));
      setCurrentPage(0);
    }
  };

  return {
    onSortChange,
    sortDirection,
    sortBy,
    setSortDirection,
  };
};
