import _ from "lodash";
import { useMutation } from "react-query";
import { removeToken } from "utils/cache";

import { setToken, setMe } from "utils/cache";
import request from "utils/request";
const SIGN_IN_PATH = "/auth/login";
const FORGOT_PASSWORD = "/auth/forgot-password";
const CHANGE_PASSWORD = "/auth/change-password";
const ME = "/auth/me";

const signIn = ({ email, password }) => {
  return request.post(SIGN_IN_PATH, { email, password, type: "ADMIN" });
};

const forgotPassword = ({ email }) => {
  return request.post(FORGOT_PASSWORD, { email, type: "ADMIN" });
};

const changePassword = ({ password, resetPasswordToken }) => {
  return request.put(CHANGE_PASSWORD, { password, resetPasswordToken, type: "ADMIN" });
};
export const useChangePassword = () => {
  return useMutation(changePassword);
};

export const useForgotPassword = () => {
  return useMutation(forgotPassword);
};
export const useSignIn = () => {
  return useMutation(signIn, {
    onSuccess: async (res) => {
      const accessToken = _.get(res, "accessToken");
      setToken(accessToken);
      const meRsp = await request.get(ME);
      setMe(meRsp);
      window.location.href = "/";
    },
  });
};

export const useLogout = () => {
  const handleLogout = () => {
    removeToken();
    window.location.href = "/sign-in";
  };

  return {
    logout: handleLogout,
  };
};
