import { countriesCode } from "constants/countriesCode";
import { countriesByRegion } from "constants/countriesByRegion";
import { countriesFlags } from "constants/countriesFlags";
import _ from "lodash";

export const getContinent = (countryCode) => {
  const country = _.find(countriesCode, { code: countryCode });
  return country ? country.continent : null;
};

export const getCountriesByRegion = (region) => {
  return _.filter(countriesByRegion, { continent: region });
};

export const isFromRegion = (country, region) => {
  const countries = getCountriesByRegion(region);
  const countriesCode = countries.map((c) => c.code);
  return countriesCode.includes(country);
};

export const getRegion = (countryCode) => {
  const country = _.find(countriesByRegion, { code: countryCode });
  return country ? country.continent : null;
};

export const getCountry = (code) => {
  const country = _.find(countriesFlags, { code });
  return country;
};

export const getCountryObjects = (codes) => {
  return _.filter(countriesByRegion, (item) => codes.includes(item.code));
};

export const findDefaultCurrency = (code) => {
  const currencyObj = _.find(countriesByRegion, { code });
  return currencyObj.currency;
};

export const isDefaultCurrency = (country, currency) => {
  const defaultCurrency = findDefaultCurrency(country);
  return defaultCurrency == currency;
};
