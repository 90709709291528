import _ from "lodash";
import { useMutation } from "react-query";
import request from "utils/request";
import { toastError } from "utils/toast";
import { getQueryParam } from "utils/string";

const ERROR_SUMMARY = "/transactions/errors";

const getErrorSummary = (payload) => {
  const sanPayload = _.pickBy(payload, _.identity);
  const queryParams = getQueryParam(sanPayload);
  return request.get(`${ERROR_SUMMARY}?${queryParams}`);
};

export const useGetErrorSummary = () => {
  return useMutation(getErrorSummary, {
    onError: (error) => {
      const message = _.get(error, "response.data.message[0]");
      toastError({ description: message });
    },
  });
};
