import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Analytics application components
import SummaryItem from "./SummaryItem";
import { getStatusReport } from "utils/analytic";
import { statusIcon, StatusColorEnum } from "constants/status";

function StatusSummary({ data, currencyReport = false }) {
  const [statusReportData, setStatusReportData] = useState({});
  const [totalStatusCount, setTotalStatusCount] = useState(0);
  useEffect(() => {
    if (!currencyReport) {
      const reportData = getStatusReport(data);
      setTotalStatusCount(_.sum(Object.values(reportData)));
      setStatusReportData(reportData);
    } else {
      setTotalStatusCount(_.sum(Object.values(createStatusReportData(data))));
      setStatusReportData(createStatusReportData(data));
    }
  }, [data]);
  const createStatusReportData = (data) => {
    const transformedObject = {};
    data?.forEach((item) => {
      transformedObject[item.status] = item.txCount;
    });
    return transformedObject;
  };
  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SoftTypography variant="h6">Status</SoftTypography>
        <Tooltip title="Status count" placement="bottom">
          <SoftButton variant="outlined" color="secondary" size="small" circular iconOnly>
            <Icon>priority_high</Icon>
          </SoftButton>
        </Tooltip>
      </SoftBox>
      <SoftBox p={2}>
        {_.map(Object.keys(statusReportData), (item, key) => {
          return (
            <SummaryItem
              key={key}
              icon={{ color: StatusColorEnum[item], component: <Icon>{statusIcon[item]}</Icon> }}
              title={item}
              amount={statusReportData[item]}
              total={totalStatusCount}
            />
          );
        })}
      </SoftBox>
    </Card>
  );
}
StatusSummary.propTypes = {
  data: PropTypes.array.isRequired,
  currencyReport: PropTypes.bool,
};
export default StatusSummary;
