export const saveDataToFile = (data, fileName, fileType) => {
  const element = document.createElement("a");
  const file = new Blob([data], {
    type: fileType,
  });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};
