import * as Yup from "yup";

export const defaultValues = {
  name: null,
  mid: null,
  dailyUsdLimit: null,
  monthlyUsdLimit: null,
  minSuccessTx: null,
  velocities: null,
  allowCardTypes: [],
  disallowCardTypes: [],
  active: false,
  default: false,
};
export const schema = Yup.object().shape({
  name: Yup.string().required("Psp name is required"),
  mid: Yup.string().required("Psp mid is required"),
  dailyUsdLimit: Yup.string().nullable(),
  monthlyUsdLimit: Yup.string().nullable(),
  minSuccessTx: Yup.string().nullable(),
  allowCardTypes: Yup.array().of(Yup.string()),
  disallowCardTypes: Yup.array().of(Yup.string()),
  active: Yup.boolean(),
  velocities: Yup.array().of(
    Yup.object().shape({
      per: Yup.string().required("Per is required"),
      hour: Yup.string().nullable().required("Hour is required"),
      count: Yup.string().required("Count is required"),
    })
  ),
  default: Yup.boolean().nullable(),
});
