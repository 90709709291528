import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import SoftButton from "components/SoftButton";
import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import DataTable from "examples/Tables/DataTable";

import { useMutateGetMerchants } from "./hooks";
import { useGetOrganizations } from "pages/organizations/hooks";
import AddMerchantModal from "./components/AddMerchantModal";
import FormProvider from "components/FormProviders";
import { useForm } from "react-hook-form";
import { Card, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import { SelectInputField } from "components/FormField";
import { generateOptions } from "utils/options";

const MerchantManagement = () => {
  const navigate = useNavigate();
  // forms
  const methods = useForm();

  // hooks
  const { data: organizations } = useGetOrganizations();
  const organizationOptions = useMemo(() => {
    return generateOptions(organizations, true, null);
  }, [organizations]);
  const { data, isLoading, mutate: getMerchants } = useMutateGetMerchants();

  //  functions
  const getRows = () => {
    return _.map(data, (item) => ({
      id: item._id,
      name: item.name,
      legalName: item.legalName,
      website: item.website,
      companyPhone: item.companyPhone,
      companyEmail: item.companyEmail,
      companyAddress: item.companyAddress,
      country: item.country,
      currency: item.currency,
      actions: (
        <SoftButton
          variant="gradient"
          color="info"
          size="small"
          onClick={() => navigate(`/merchants/${item._id}?tab=brands`)}
        >
          Edit
        </SoftButton>
      ),
    }));
  };

  const onOgChange = (value) => {
    getMerchants(value?.value);
  };
  const onCreateMerchantClose = () => {
    getMerchants(null);
  };

  useEffect(() => {
    getMerchants(null);
  }, []);

  return (
    <MainLayout>
      <MainNavbar />

      <FormProvider methods={methods}>
        <Card sx={{ overflow: "visible", mb: 2 }}>
          <SoftBox p={3}>
            <Grid container spacing={2}>
              <Grid item xs={7} sm={7}>
                <SelectInputField
                  name="organizationId"
                  label="Organization"
                  options={organizationOptions}
                  placeholder="Select an organization"
                  onSelect={onOgChange}
                />
              </Grid>
              <Grid item xs={5} sm={5}>
                <AddMerchantModal onClose={onCreateMerchantClose} />
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </FormProvider>
      <DataTable
        table={{
          columns: [
            { Header: "name", accessor: "name" },
            { Header: "legalName", accessor: "legalName" },
            { Header: "website", accessor: "website" },
            { Header: "companyPhone", accessor: "companyPhone" },
            { Header: "companyEmail", accessor: "companyEmail" },
            { Header: "companyAddress", accessor: "companyAddress" },
            { Header: "country", accessor: "country" },
            { Header: "currency", accessor: "currency" },
          ],
          rows: getRows() || [],
        }}
        canSearch={true}
        columnsFilter={true}
        onCellClick={(row) => {
          navigate(`/merchants/${row?.original?.id}?tab=brands`);
        }}
        isClickable={true}
        isLoading={isLoading}
      />
    </MainLayout>
  );
};

export default MerchantManagement;
