import { Card, Grid } from "@mui/material";
import _ from "lodash";

import { DatePickerField, TextInputField, TimeRangeInput } from "components/FormField";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { SelectInputField } from "components/FormField";

import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import React from "react";
import { dateTimePickerOptions } from "constants/dateFilter";
import { requestMethodsOptions } from "constants/common";
import { useGetManageUsers } from "pages/settings/users/hooks";
import { generateUserOptions } from "utils/options";

const UserLogsFilters = (props) => {
  const { onSubmit } = props;
  const { setValue, getValues } = useFormContext();
  const { data: users } = useGetManageUsers();
  const userOptions = generateUserOptions(users);

  const handleClickCleanFilters = async () => {
    const currentFilters = getValues();
    Object.keys(_.omit(currentFilters, ["limit", "page"])).forEach((key) => {
      setValue(key, "");
    }); // Reset the values of all current filters
    await onSubmit();
  };

  const clearDateSelect = () => {
    setValue("fromDate", null);
    setValue("toDate", null);
  };
  const clearLast = () => {
    setValue("last", "");
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} lg={12}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <SelectInputField label="User" name="userId" options={userOptions} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextInputField label="Request ID" name="requestId" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectInputField
                  name="method"
                  label="Method"
                  options={requestMethodsOptions}
                  placeholder="Select a method"
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <DatePickerField
                  name="fromDate"
                  options={dateTimePickerOptions()}
                  label="From Date"
                  onChange={clearLast}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DatePickerField
                  name="toDate"
                  options={dateTimePickerOptions()}
                  label="To Date"
                  onChange={clearLast}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TimeRangeInput name="last" label="Timeframe" onChange={clearDateSelect} />
              </Grid>
            </Grid>

            <Grid container display="flex" justifyContent="space-between">
              <SoftButton variant="gradient" color="info" type="submit" sx={{ mt: 2 }}>
                Search
              </SoftButton>
              <SoftButton
                variant="gradient"
                color="info"
                type="button"
                sx={{ mt: 2 }}
                onClick={handleClickCleanFilters}
              >
                Clear filters
              </SoftButton>
            </Grid>
          </SoftBox>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UserLogsFilters;

// Typechecking props of the UserLogsFilters
UserLogsFilters.propTypes = {
  onSubmit: PropTypes.func,
};
