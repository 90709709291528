import { useQuery, useQueryClient, useMutation } from "react-query";
import { toastSuccess } from "utils/toast";
import request from "utils/request";

const ORGANIZATIONS = "/organizations";

const createOrganization = (payload) => request.post(ORGANIZATIONS, payload);

export const useCreateOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation(createOrganization, {
    onSuccess: () => {
      toastSuccess({ description: "Create organization has been success" });
      queryClient.invalidateQueries([ORGANIZATIONS]);
    },
  });
};

export const useGetOrganizations = () => {
  return useQuery([ORGANIZATIONS], () => request.get(ORGANIZATIONS), {
    retry: false,
  });
};
