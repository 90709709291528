import React from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { Dialog, DialogContent, DialogTitle, Stack, DialogActions } from "@mui/material";
import { useForm } from "react-hook-form";

import SoftButton from "components/SoftButton";
import FormProvider from "components/FormProviders";
import SoftBox from "components/SoftBox";

import { yupResolver } from "@hookform/resolvers/yup";
import { useDisclosure } from "hooks/useDisclosure";

import { defaultValues, schema, sanitizeRequest } from "../schemas";
import { useAddAmountLimit } from "../hooks";
import AmountLimitForm from "./AmountLimitForm";

const CreateAmountLimitModal = ({ onClose: onCloseCallback, paymentOptions }) => {
  const { id: pspId, midId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();

  // hooks
  const { mutate: addAmountLimit, isLoading } = useAddAmountLimit();

  // form
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { handleSubmit } = methods;

  const onSubmit = (values) => {
    addAmountLimit(sanitizeRequest({ pspId, midId, ...values }), {
      onSuccess: () => {
        onClose();
        onCloseCallback();
      },
    });
  };

  return (
    <>
      <SoftBox pt={2} px={2} mb={3}>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <SoftButton variant="gradient" color="info" size="small" onClick={onOpen}>
            Add Amount Limit
          </SoftButton>
        </Stack>
      </SoftBox>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Add Amount Limit
          </DialogTitle>
          <DialogContent>
            <AmountLimitForm paymentOptions={paymentOptions} />
          </DialogContent>
          <DialogActions>
            <SoftButton onClick={onClose}>Cancel</SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              disabled={isLoading}
              isLoading={isLoading}
            >
              Add Amount Limit
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};
CreateAmountLimitModal.propTypes = {
  onClose: PropTypes.func,
  paymentOptions: PropTypes.array,
};
export default CreateAmountLimitModal;
