import React from "react";

import {
  Dialog,
  IconButton,
  DialogContent,
  DialogTitle,
  Tooltip,
  DialogActions,
  Icon,
} from "@mui/material";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import SoftButton from "components/SoftButton";
import FormProvider from "components/FormProviders";

const ModalWrapper = ({
  children,
  title,
  submitButtonText,
  cancelButtonText,
  tooltipTitle,
  modalState,
  isLoading,
  type,
  methods,
  onSubmit,
  maxWidth,
  minHeight,
  buttonColor,
  iconColor,
}) => {
  const { isOpen, onClose, onOpen } = modalState;

  const renderButton = () => {
    if (type === "hidden") return null;
    if (type === "button")
      return (
        <SoftButton onClick={onOpen} color={buttonColor || "info"}>
          {title}
        </SoftButton>
      );
    if (type === "iconButton")
      return (
        <SoftButton variant="gradient" color={iconColor || "info"} onClick={onOpen}>
          <Icon>edit</Icon>&nbsp;{submitButtonText || "Update"}
        </SoftButton>
      );
    return (
      <Tooltip title={tooltipTitle || title}>
        <IconButton onClick={onOpen} disabled={isLoading} color="secondary">
          <EditIcon sx={{ ml: 2 }} />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <>
      {renderButton()}
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={maxWidth || "xs"}>
        <DialogTitle
          sx={{
            background: (theme) => theme.palette.grey[500],
            fontWeight: (theme) => theme.typography.h6,
            color: (theme) => theme.palette.common.white,
            px: 4,
            py: 1,
          }}
        >
          {title}
        </DialogTitle>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <DialogContent sx={{ minHeight: minHeight || "0" }}>{children}</DialogContent>

          <DialogActions>
            <SoftButton onClick={onClose}>{cancelButtonText || "Cancel"}</SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              disabled={isLoading}
              isLoading={isLoading}
            >
              {submitButtonText || "Update"}
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};
ModalWrapper.propTypes = {
  submitButtonText: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string,
  tooltipTitle: PropTypes.string,
  modalState: PropTypes.object.isRequired,
  type: PropTypes.string,
  methods: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  minHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  buttonColor: PropTypes.string,
  iconColor: PropTypes.string,
};
export default ModalWrapper;
