import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema, sanitizeRequest } from "../schemas/fees";
import { useDisclosure } from "hooks/useDisclosure";
import { ModalWrapper } from "components/Modal";
import { useCreateFee } from "../hooks";
import PspFeeForm from "./PspFeeForm";

const CreateFeeModal = () => {
  const { id: pspId } = useParams();
  const modalState = useDisclosure();
  const { onClose } = modalState;

  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit, reset } = methods;

  //hooks
  const { isLoading, mutate: createFee } = useCreateFee(pspId);

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    createFee(
      { pspId, ...sanitizeRequest(values) },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  useEffect(() => {
    reset(defaultValues);
  }, []);

  return (
    <ModalWrapper
      title="Create Fee"
      modalState={modalState}
      isLoading={isLoading}
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      minHeight="350px"
      maxWidth="md"
      type="button"
      submitButtonText={"Create"}
    >
      <PspFeeForm />
    </ModalWrapper>
  );
};
export default CreateFeeModal;
