import { useQuery } from "react-query";
import request from "utils/request";

const GET_MISMATCHED_TRANSACTIONS = "/transactions/get-missmatched";

const getMismatchedTransactions = () => request.get(GET_MISMATCHED_TRANSACTIONS);

export const useGetMismatchedTransactions = () => {
  return useQuery([GET_MISMATCHED_TRANSACTIONS], getMismatchedTransactions);
};
