import _ from "lodash";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";

import SoftMultipleCheckBox from "components/SoftMultipleCheckBox";
import { RegionEnum } from "constants/tabs";
import { useFormContext } from "react-hook-form";
import ContinentCollapse from "./CollapseWrapper";
import { getCountriesByRegion, getCountryObjects, getRegion } from "utils/country";
import SoftButton from "components/SoftButton";
import { Icon } from "@mui/material";
import SideCountries from "./SideCountries";
import SoftTypography from "components/SoftTypography";

const MultipleCountriesCollapse = ({ name, label, required, ...rest }) => {
  const [openDefault, setOpenDefault] = useState([]);
  const [leftValue, setLeftValue] = useState([]);
  const [rightCountries, setRightCountries] = useState([]);
  const [rightCountryCode, setRightCountryCodes] = useState([]);
  const [toRemoveValue, setToRemoveValue] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const { watch, setValue, getFieldState, formState } = useFormContext();
  const { error } = getFieldState(name, formState);
  const valueWatch = watch(name);

  const moveToRight = () => {
    if (leftValue.length > 0) {
      const countries = getCountryObjects([...rightCountryCode, ...leftValue]);
      setRightCountries(countries);
      setRightCountryCodes([...rightCountryCode, ...leftValue]);
      setValue(name, _.uniq([...rightCountryCode, ...leftValue]));
      setRefresh(!refresh);
      setLeftValue([]);
    }
  };
  const moveToLeft = () => {
    let newRightValue = [];
    rightCountryCode.forEach((v) => {
      if (!toRemoveValue.includes(v)) {
        newRightValue.push(v);
      }
    });
    setRightCountryCodes(newRightValue);
    const countries = getCountryObjects(newRightValue);
    setRightCountries(countries);
    setToRemoveValue([]);
    setValue(name, newRightValue);
  };
  const onLeftChange = (value) => {
    setLeftValue(_.uniq(value));
  };
  const onRightChange = (value) => {
    setToRemoveValue(_.uniq(value));
  };

  // set collapse state
  useEffect(() => {
    if (valueWatch && valueWatch.length > 0) {
      setLeftValue(valueWatch);
      // const initContinents = _.uniq(_.map(valueWatch, (v) => getRegion(v)));
      // setOpenDefault(initContinents);
      const countries = getCountryObjects(valueWatch);
      const countryCode = _.map(countries, (c) => c.code);
      setRightCountries(countries);
      setRightCountryCodes(countryCode);
    }
  }, [valueWatch]);

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={5.5} sm={5.5}>
        <SoftMultipleCheckBox>
          <CardContent sx={{ overflow: "auto" }}>
            {Object.keys(RegionEnum).map((r) => (
              <ContinentCollapse title={r} key={r} openDefault={openDefault.includes(r)}>
                <SideCountries
                  initCountries={getCountriesByRegion(r)}
                  notDisplayCountries={rightCountryCode}
                  onChange={onLeftChange}
                  value={leftValue}
                />
              </ContinentCollapse>
            ))}
          </CardContent>
        </SoftMultipleCheckBox>
      </Grid>
      <Grid item xs={1} sm={1}>
        <SoftBox>
          <SoftButton
            variant="outlined"
            size="small"
            sx={{ ml: -1.5, mt: 1 }}
            onClick={moveToRight}
            color="success"
          >
            <Icon>arrow_forward</Icon>
          </SoftButton>
          <SoftButton
            variant="outlined"
            onClick={moveToLeft}
            size="small"
            sx={{ ml: -1.5, mt: 1 }}
            color="error"
          >
            <Icon>arrow_backward</Icon>
          </SoftButton>
        </SoftBox>
      </Grid>
      <Grid item xs={5.5} sm={5.5}>
        <SoftMultipleCheckBox>
          <CardContent sx={{ overflow: "auto" }}>
            <SoftBox component="ul" display="flex" flexDirection="column">
              <SideCountries
                initCountries={rightCountries}
                notDisplayCountries={[]}
                onChange={onRightChange}
                value={toRemoveValue}
              />
            </SoftBox>
            {error && (
              <SoftBox mt={0.75}>
                <SoftTypography component="div" variant="caption" color="error">
                  {_.get(error, "message", "Unknown error")}
                </SoftTypography>
              </SoftBox>
            )}
          </CardContent>
        </SoftMultipleCheckBox>
      </Grid>
    </Grid>
  );
};
MultipleCountriesCollapse.defaultValues = {
  required: false,
  label: "",
};
MultipleCountriesCollapse.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
};

export default MultipleCountriesCollapse;
