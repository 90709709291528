import TransactionReportingPage, {
  defaultTableColumns,
} from "pages/transactions/components/TransactionReportingPage";
import StatusChip from "components/StatusChip";
import TableSelectBox from "./components/TableSelectBox";
import TransactionFilters from "./components/TransactionFilters";
import { useGetFilterTransactions } from "./hooks";
import { useEffect, useState } from "react";
import { useTransactionStore } from "stores/transactions";
import { useGlobalStateStore } from "stores/globalStateStore";

const TransactionsBatches = () => {
  const [checked, setChecked] = useState({});
  const {
    appendSelectedTransaction,
    removeSelectedTransaction,
    transactions,
    selectedTransactions,
  } = useTransactionStore();
  const { dataTableAllChecked, setDataTableAllChecked } = useGlobalStateStore();

  const tableColumns = [
    {
      Header: "select",
      accessor: "select",
      type: "select",
      onCheckChange: (e) => {
        setDataTableAllChecked(e.target.checked);
      },
    },
    ...defaultTableColumns,
  ];

  const onCheckChange = (state, transaction) => {
    setChecked({ ...checked, [transaction.id]: state });
    if (state) {
      appendSelectedTransaction(transaction);
    } else {
      removeSelectedTransaction(transaction.id);
    }
  };
  const getRows = (data) => {
    return _.map(_.get(data, "docs", []), (item) => {
      return {
        select: (
          <TableSelectBox
            transaction={item}
            checked={_.get(checked, item.id, false)}
            onChange={onCheckChange}
          />
        ),
        country: item.country,
        currency: item.currency,
        merchant: item.merchant,
        brand: item.brand,
        amount: item.paymentAmount ? item.paymentAmount : item.amount,
        usdAmount: item.usdAmount,
        mid: item.mid,
        psp: item.psp,
        id: item.id,
        externalId: item.externalId,
        errorReason: item.errorReason,
        option: item.paymentOption,
        status: <StatusChip label={item.status} size="small" tooltip={item.errorReason} />,
        createdAt: item.createdAt,
        domain: item.merchantDomain ?? "N/A",
        customerName: item.customer?.name,
        customerEmail: item.customer?.email,
      };
    });
  };

  useEffect(() => {
    if (transactions) {
      if (dataTableAllChecked) {
        let _check = {};
        _.map(transactions, (item) => {
          _check[item.id] = true;
        });

        setChecked(_check);

        _.map(transactions, (item) => {
          appendSelectedTransaction(item);
        });
      } else {
        setChecked({});
        _.map(transactions, (item) => {
          removeSelectedTransaction(item.id);
        });
      }
    }
  }, [dataTableAllChecked]);

  useEffect(() => {
    if (selectedTransactions && selectedTransactions.length == 0) {
      setChecked({});
      setDataTableAllChecked(false);
    }
  }, [selectedTransactions]);

  return (
    <TransactionReportingPage
      showTabs={false}
      tableColumns={tableColumns}
      getRowsFn={getRows}
      Filters={TransactionFilters}
      filterTransactionQueryFn={useGetFilterTransactions}
      original="transactions-batch"
    />
  );
};

export default TransactionsBatches;
