import { useNavigate } from "react-router-dom";

import _ from "lodash";
import { useParams } from "react-router-dom";

import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useGetPaymentOptions } from "../hooks";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";
import DataTable from "examples/Tables/DataTable";
import SoftBadgeDot from "components/SoftBadgeDot";

const PaymentOptions = () => {
  const { id } = useParams();
  if (!hasPermission(Permissions.PSP_METHOD.LIST)) {
    return <Error403 />;
  }
  const navigate = useNavigate();
  const { data: paymentOptions, isLoading, isFetching } = useGetPaymentOptions(id);

  const getRows = () => {
    return _.map(paymentOptions, (item) => {
      return {
        id: item.id,
        psp: item.psp,
        name: item.name,
        logo: (
          <SoftBox mx={2} width="2.75rem">
            <SoftBox component="img" src={item.logo} alt={item.name} width="100%" />
          </SoftBox>
        ),
        active: <SoftBadgeDot color={item.active ? "success" : "light"} size="md" />,
      };
    });
  };

  const onCellClick = (row) => {
    navigate(`/psps/${id}/methods/${row.original.id}`);
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox pt={2} px={2}>
        <Stack spacing={1} direction="row" justifyContent="space-between">
          <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Payment Options
          </SoftTypography>
        </Stack>
      </SoftBox>
      <SoftBox p={2}>
        <DataTable
          table={{
            columns: [
              { Header: "ID", accessor: "id" },
              { Header: "Name", accessor: "name" },
              { Header: "Logo", accessor: "logo" },
              { Header: "Active", accessor: "active" },
            ],
            rows: getRows(),
          }}
          entriesPerPage={{
            defaultValue: 25,
            entries: [25, 50, 200, 500],
          }}
          onCellClick={onCellClick}
          onCellAuxClick={onCellClick}
          canSearch={true}
          columnsFilter={true}
          isClickable={true}
          isLoading={isLoading || isFetching}
        />
      </SoftBox>
    </Card>
  );
};

export default PaymentOptions;
