import _ from "lodash";
import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";

import Checkbox from "@mui/material/Checkbox";

import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";

import { countriesFlags } from "constants/countriesFlags";
import FormControlLabel from "@mui/material/FormControlLabel";

const SideCountries = forwardRef(
  ({ initCountries, onChange, value, notDisplayCountries, ...rest }, ref) => {
    const [refresh, setRefresh] = useState(false);

    const allSelectOnChange = (e) => {
      if (e.target.checked == true) {
        const countryCodes = _.map(initCountries, (c) => c.code);
        onChange(countryCodes);
      } else {
        onChange([]);
      }

      setRefresh(!refresh);
    };
    const isChecked = (countryCode) => {
      return value.includes(countryCode);
    };
    const onCountryCheckChange = (e, countryCode) => {
      if (e.target.checked == true) {
        onChange([...value, countryCode]);
      } else {
        onChange(_.filter(value, (c) => c != countryCode));
      }
      setRefresh(!refresh);
    };

    const allChecked = () => {
      if (initCountries.length == 0) return false;
      return value.length == initCountries.length;
    };

    return (
      <SoftBox component="ul" display="flex" flexDirection="column">
        <SoftBox display="flex" alignItems="center" px={1} ml={1.5}>
          <FormControlLabel
            value={"All"}
            onChange={(e) => {
              allSelectOnChange(e);
            }}
            control={
              <FormControlLabel value={true} control={<Checkbox checked={allChecked()} />} />
            }
          />

          <SoftBox display="flex" flexDirection="column" justifyContent="center">
            <SoftTypography
              component="div"
              variant="button"
              textTransform="capitalize"
              fontWeight="medium"
              ml={-1}
            >
              All
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        {initCountries
          ?.filter((item) => !notDisplayCountries.includes(item.code))
          .map((item, index) => {
            const countryWithFlag = _.find(countriesFlags, { code: item.code });
            const flag = _.get(countryWithFlag, "image", "");
            return (
              <SoftBox display="flex" alignItems="center" px={1} key={index}>
                <FormControlLabel
                  value={item.code}
                  onChange={(e) => {
                    onCountryCheckChange(e, item.code);
                  }}
                  control={<Checkbox checked={isChecked(item.code)} />}
                />
                <SoftBox mr={1}>
                  <SoftAvatar src={flag} size="sm" alt="title" />
                </SoftBox>
                <SoftBox display="flex" flexDirection="column" justifyContent="center">
                  <SoftTypography
                    component="div"
                    variant="button"
                    textTransform="capitalize"
                    fontWeight="medium"
                  >
                    {item.label}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            );
          })}
      </SoftBox>
    );
  }
);
SideCountries.defaultValues = {
  initCountries: [],
  onChange: () => {},
  value: [],
  notDisplayCountries: [],
};
SideCountries.propTypes = {
  initCountries: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.array,
  notDisplayCountries: PropTypes.array,
};
export default SideCountries;
