import _ from "lodash";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import DataTable from "examples/Tables/DataTable";
import PropTypes from "prop-types";
import { countries } from "constants/countries";

const MethodRoutes = ({ routes, title, isLoading }) => {
  const getRows = () => {
    return _.map(routes, (item) => {
      const country = countries.find((country) => country.code === item.country);
      return {
        id: item.id,
        country: (
          <>
            <b>{item.country}</b> &nbsp;&nbsp;{country ? `(${country.label})` : ""}
          </>
        ),
        currencies: <SoftBox maxWidth="820px">{_.get(item, "currencies", []).join(", ")}</SoftBox>,
        currenciesHidden: _.get(item, "currencies", []).join(", "),
        countryHidden: item.country,
      };
    });
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox pt={2} px={2}>
        <Stack spacing={1} direction="row" justifyContent="space-between">
          <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </SoftTypography>
        </Stack>
      </SoftBox>
      <SoftBox p={2}>
        <DataTable
          table={{
            columns: [
              { Header: "ID", accessor: "id" },
              { Header: "Country", accessor: "country" },
              { Header: "Currencies", accessor: "currencies" },
              { Header: "CurrenciesHidden", accessor: "currenciesHidden", hide: true },
              { Header: "CountryHidden", accessor: "countryHidden", hide: true },
            ],
            rows: getRows(),
          }}
          entriesPerPage={{
            defaultValue: 25,
            entries: [25, 50, 200, 500],
          }}
          canSearch={true}
          columnsFilter={false}
          isLoading={isLoading}
          isClickable={true}
        />
      </SoftBox>
    </Card>
  );
};
MethodRoutes.propTypes = {
  routes: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};
export default MethodRoutes;
