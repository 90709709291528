import { useState, useEffect } from "react";

import _ from "lodash";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import DataTable from "examples/Tables/DataTable";
import TransactionManagementFilters from "./components/TransactionManagementFilters";
import { useForm } from "react-hook-form";
import FormProvider from "components/FormProviders";
import { set, get } from "utils/localStorage";
import { Chip, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetTransactionAttempts, useGetBrands } from "./hooks";
import moment from "moment/moment";
import { generateDateRange } from "utils/time";
import { sanitizeRequest } from "./schemas";
import { useGetMerchants } from "pages/merchant-management/hooks";
import { useDataTable } from "hooks/useDataTable";
import { updateSelectedFilters } from "utils/filters";
import { generateOptions } from "utils/options";
import DetailsModal from "./components/DetailsModal";

const LC_PAYLOAD = "transaction-attempts-payload";

const TransactionAttempts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [pageSize, setPageSize] = useState(10);
  const [firstRender, setFirstRender] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [brandOptions, setBrandOptions] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedAttempt, setSelectedAttempt] = useState(null);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const { onSortChange, sortDirection, setSortDirection } = useDataTable();

  const [selectedFilters, setSelectedFilters] = useState({});
  // Destructuring the object of all selected filters to obtain only the necessary
  const filters = _.omit(selectedFilters, ["page", "limit"]);
  const { mutate, isLoading, data } = useGetTransactionAttempts();
  const { mutate: getBrands, isLoading: isFetchingBrand } = useGetBrands();
  const { data: merchants } = useGetMerchants();
  const merchantReportingPayload = get(LC_PAYLOAD) || {};

  const defaultValues = merchantReportingPayload;
  const methods = useForm({ defaultValues });
  const { handleSubmit, watch, setValue } = methods;
  const watchMerchant = watch("merchantId");
  const queryParams = new URLSearchParams(location.search);
  const pageParameter = queryParams.get("page");
  useEffect(() => {
    if (pageParameter) {
      handlePageChange(pageParameter - 1);
    } else if (merchantReportingPayload) {
      mutate(sanitizeRequest(merchantReportingPayload));
      if (merchantReportingPayload.sortDirection)
        setSortDirection(merchantReportingPayload.sortDirection);
    } else {
      mutate(
        sanitizeRequest({
          page: 1,
          merchantId: "All",
          limit: pageSize,
          sortBy: "createdAt",
          sortDirection: sortDirection,
        })
      );
    }
  }, []);

  useEffect(() => {
    updateSelectedFilters(merchantReportingPayload, setSelectedFilters, {
      merchants,
      brands,
    });
  }, [merchants]);

  // create a function that returns the rows for the table
  const getRows = () => {
    return _.map(_.get(data, "docs", []), (item) => ({
      id: item.id,
      merchant: item.merchant,
      brand: item.brand,
      endpoint: item.endpoint,
      country: _.get(item, "payload.country", ""),
      currency: _.get(item, "payload.currency", ""),
      amount: _.get(item, "payload.amount", ""),
      error: _.get(item, "error.statusCode", ""),
      createdAt: item.createdAt,
    }));
  };

  // create a function that handles the form submission
  const onSubmit = (values) => {
    if (pageParameter) removeQueryParam();
    let fromDate = values.fromDate;
    let toDate = values.toDate;
    if (values.last) {
      const { last } = values;
      const dateRange = generateDateRange(last);
      fromDate = dateRange.fromDate;
      toDate = dateRange.toDate;
    }
    const payload = {
      ...values,
      fromDate: fromDate && moment(new Date(fromDate)).format("YYYY-MM-DD HH:mm:ss"),
      toDate: toDate && moment(new Date(toDate)).format("YYYY-MM-DD HH:mm:ss"),
      limit: pageSize,
      page: 1,
      sortBy: "createdAt",
      sortDirection: sortDirection,
    };
    setCurrentPage(1);
    set(LC_PAYLOAD, payload);
    mutate(sanitizeRequest(payload));
    updateSelectedFilters(values, setSelectedFilters, {
      merchants,
      brands,
    });
  };

  const removeQueryParam = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("page");
    navigate({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  };

  // create a function that handles the page change
  const handlePageChange = (page) => {
    if (pageParameter) removeQueryParam();
    setCurrentPage(page + 1);
    const payload = merchantReportingPayload;
    if (payload.sortDirection) setSortDirection(payload.sortDirection);
    payload.page = page + 1;
    set(LC_PAYLOAD, payload);
    mutate(sanitizeRequest(payload));
  };
  // create a function that handles the page size change
  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    if (!firstRender) setCurrentPage(1);
    const payload = merchantReportingPayload;
    payload.page = 1;
    payload.limit = pageSize;
    set(LC_PAYLOAD, payload);
    firstRender ? setFirstRender(false) : mutate(sanitizeRequest(payload));
  };
  const handleSortChange = (column) => {
    onSortChange({
      column,
      sortColumns: ["createdAt"],
      lcPayload: LC_PAYLOAD,
      mutate,
      setCurrentPage,
      sanitizeRequest,
    });
  };

  const openDetails = (id) => {
    const selected = _.find(_.get(data, "docs", []), { id: id });
    setSelectedAttempt(selected);
    setDetailModalOpen(true);
  };

  useEffect(() => {
    if (watchMerchant === "" || watchMerchant === null || watchMerchant === undefined) {
      setBrandOptions([{ value: "", label: "All" }]);
      setValue("brandId", "");
      return;
    } else {
      getBrands(
        { merchantId: watchMerchant },
        {
          onSuccess: (data) => {
            setBrands(data);
            setBrandOptions(generateOptions(data, true));
          },
        }
      );
    }
  }, [watchMerchant]);
  return (
    <MainLayout>
      <MainNavbar />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <TransactionManagementFilters
          onSubmit={handleSubmit(onSubmit)}
          merchants={merchants}
          brandOptions={brandOptions}
          isFetchingBrand={isFetchingBrand}
        />
      </FormProvider>
      <Stack
        direction="row"
        spacing={1}
        sx={{ mt: 2, mb: 2 }}
        display="flex"
        flexWrap="wrap"
        gap={1}
      >
        {Object.values(filters)?.map((i, index) =>
          i ? <Chip key={i + index} label={i} color="secondary" textColor="white" /> : null
        )}
      </Stack>
      <DataTable
        table={{
          columns: [
            { Header: "Merchant", accessor: "merchant" },
            { Header: "brand", accessor: "brand" },
            { Header: "endpoint", accessor: "endpoint" },
            { Header: "country", accessor: "country" },
            { Header: "currency", accessor: "currency" },
            { Header: "amount", accessor: "amount" },
            { Header: "error", accessor: "error" },
            { Header: "created time", accessor: "createdAt" },
          ],
          rows: getRows(),
        }}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        pageCount={_.get(data, "pagination.totalPages", 0)}
        manualPagination={true}
        rowCount={_.get(data, "pagination.totalDocs", 0)}
        isLoading={isLoading}
        currentPage={currentPage}
        sortDirection={sortDirection}
        onHeaderCellClick={handleSortChange}
        isClickable={true}
        onCellClick={(row) => {
          openDetails(row.original.id);
        }}
      />
      <DetailsModal
        isOpen={detailModalOpen}
        onClose={() => setDetailModalOpen(false)}
        attempt={selectedAttempt}
      />
    </MainLayout>
  );
};

export default TransactionAttempts;
