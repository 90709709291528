import React, { useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import SoftBox from "components/SoftBox";

import DataTable from "examples/Tables/DataTable";
import { useGetApiKeys, useDeleteApiKey } from "pages/brand-detail/hooks";
import SoftBadgeDot from "components/SoftBadgeDot";
import GenerateApiKey from "./GenerateApiKey";
import { Card, Typography } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";
import { DeleteModal } from "components/Modal";

const ApiKeys = () => {
  if (!hasPermission(Permissions.API_KEY.LIST)) {
    return <Error403 />;
  }

  const { id: merchantId, brandId } = useParams();
  const [apiKey, setApikey] = useState("");

  // hooks
  const { data: apiKeys } = useGetApiKeys(merchantId, brandId);
  const { mutate: deleteAPIKey, isLoading: isDeleting } = useDeleteApiKey();

  const getRows = () => {
    return _.map(apiKeys, (item) => ({
      id: item.id,
      prefix: item.prefix,
      active: <SoftBadgeDot color={item.active ? "success" : "light"} size="md" />,
      actions: (
        <SoftBox display="flex" gap={1}>
          <DeleteModal
            type="icon"
            title="Delete API Key"
            message="Are you sure want to delete this API Key?"
            isLoading={isDeleting}
            onDelete={async () => {
              await deleteAPIKey({ merchantId, brandId, apiKeyId: item.id });
            }}
          />
        </SoftBox>
      ),
    }));
  };

  return (
    <SoftBox>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center">
        <SoftBox maxWidth={260}></SoftBox>
        <GenerateApiKey merchantId={merchantId} brandId={brandId} onSuccess={setApikey} />
      </SoftBox>
      {apiKeys?.length ? (
        <Card sx={{ mt: 2 }}>
          <SoftBox p={3}>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              API Key
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              {apiKey}
            </SoftTypography>
          </SoftBox>
        </Card>
      ) : null}
      <SoftBox mt={2}>
        {apiKeys?.length ? (
          <DataTable
            table={{
              columns: [
                { Header: "id", accessor: "id" },
                { Header: "prefix", accessor: "prefix" },
                { Header: "active", accessor: "active" },
                { Header: "actions", accessor: "actions" },
              ],
              rows: getRows(),
            }}
          />
        ) : (
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              my: 3,
              color: "#67748e",
            }}
          >
            There are no API Keys
          </Typography>
        )}
      </SoftBox>
    </SoftBox>
  );
};

export default ApiKeys;
