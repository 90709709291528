import { useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { Card, DialogContent, Grid } from "@mui/material";
import DataTable from "examples/Tables/DataTable";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import borders from "assets/theme/base/borders";
import { useEffect } from "react";
import { fDateTimeSuffix } from "utils/formatTime";
import SoftButton from "components/SoftButton";
import { PaymentOptionSelect } from "components/FormField";
import FormProvider from "components/FormProviders";
import { useForm } from "react-hook-form";
import { set, get } from "utils/localStorage";
import { useGetAmountLimits } from "./hooks";
import { useDataTable } from "hooks/useDataTable";
import CreateAmountLimitModal from "./components/CreateAmountLimitModal";
import { useGetPaymentOptions } from "pages/brand-detail/hooks";
import UpdateAmountLimitModal from "./components/UpdateAmountLimitModal";
import { paymentOptionName } from "utils/paymentOption";
import PropTypes from "prop-types";
import { DeleteModal } from "components/Modal";
import { useDeleteAmountLimit } from "./hooks";

const LC_PAYLOAD = "amountLimits";
const sanitizeRequest = ({ sortDirection, pspId, midId, paymentOptionId, limit, sortBy, page }) => {
  return _.pickBy(
    { sortDirection, pspId, midId, paymentOptionId, limit, sortBy, page },
    _.identity
  );
};

const AmountLimit = ({ showHeader }) => {
  const { id: pspId, midId } = useParams();
  const { borderWidth, borderColor } = borders;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [paymentOptionId, setPaymentOptionId] = useState("");
  const sortColumns = ["createdAt", "min", "max", "category", "currency", "country"];
  const { onSortChange, sortDirection, sortBy } = useDataTable();
  const amountLimitPayload = get(LC_PAYLOAD) || {};

  // forms
  const methods = useForm();
  const { handleSubmit } = methods;

  // hooks
  const { data: paymentOptions } = useGetPaymentOptions();
  const { data, isLoading, mutate: getAmountLimits } = useGetAmountLimits();
  const { mutateAsync: deleteAmountLimit, isLoading: isDeleting } = useDeleteAmountLimit();

  const handlePageChange = (page) => {
    const payload = amountLimitPayload;
    payload.page = page + 1;
    payload.limit = pageSize;
    payload.sortBy = sortBy;
    set(LC_PAYLOAD, payload);
    getAmountLimits(sanitizeRequest({ ...payload, pspId, midId, sortDirection }));
    setCurrentPage(page + 1);
  };
  const handlePageSizeChange = (newPageSize) => {
    if (newPageSize !== pageSize) {
      setCurrentPage(1);
      setPageSize(newPageSize);
      const payload = amountLimitPayload;
      payload.page = 1;
      payload.limit = newPageSize;
      payload.sortBy = sortBy;
      set(LC_PAYLOAD, payload);
      getAmountLimits(sanitizeRequest({ ...payload, pspId, midId, sortDirection }));
    }
  };
  const getRows = () => {
    return _.map(_.get(data, "docs", []), (item) => {
      return {
        paymentOption: paymentOptionName(item.paymentOptionId, paymentOptions),
        country: item.country,
        currency: item.currency,
        category: item.category,
        min: item.min,
        max: item.max,
        createdAt: fDateTimeSuffix(item.createdAt),
        action: (
          <>
            <UpdateAmountLimitModal amountLimit={item} onClose={refetch} />
            <DeleteModal
              size="md"
              title="Delete Amount Limit"
              type="icon"
              message={
                <>
                  Are you sure you want to delete amount limit <b>{_.get(item, "_id", "")}</b>? you
                  will not be able to recover this amount limit?
                </>
              }
              onClose={refetch}
              onDelete={async () => {
                await deleteAmountLimit({ pspId, id: item._id });
              }}
              isLoading={isDeleting}
            />
          </>
        ),
      };
    });
  };
  const handleSortChange = (column) => {
    onSortChange({
      column,
      sortColumns,
      lcPayload: LC_PAYLOAD,
      mutate: getAmountLimits,
      setCurrentPage,
      sanitizeRequest,
    });
  };

  const refetch = () => {
    getAmountLimits(
      sanitizeRequest({
        sortDirection,
        pspId,
        midId,
        paymentOptionId,
        limit: pageSize,
        page: currentPage,
        sortBy,
      })
    );
  };

  useEffect(() => {
    const payload = sanitizeRequest({ pspId, midId, limit: pageSize, page: currentPage, sortBy });
    set(LC_PAYLOAD, payload);
    getAmountLimits(payload);
  }, []);

  const onSubmit = (values) => {
    setPaymentOptionId(values.paymentOptionId);
    const payload = sanitizeRequest({ pspId, midId, ...values });
    set(LC_PAYLOAD, payload);
    getAmountLimits(payload);
  };

  return (
    <Card sx={{ mt: 2 }}>
      {showHeader && (
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{ xs: "column", sm: "row" }}
          borderBottom={`${borderWidth[1]} solid ${borderColor}`}
          py={2}
          px={3}
        >
          <SoftBox mx={2} lineHeight={1}>
            <SoftTypography component="a" href="#" variant="button" fontWeight="regular">
              Min/Max Amount
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      )}
      <DialogContent>
        <CreateAmountLimitModal paymentOptions={paymentOptions} onClose={refetch} />
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={12}>
              <Card sx={{ overflow: "visible" }}>
                <SoftBox pr={3} pl={3} pb={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={6}>
                      <PaymentOptionSelect
                        label="Payment option"
                        name="paymentOptionId"
                        paymentOptions={paymentOptions}
                        removeSelectAll={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container display="flex" justifyContent="space-between">
                    <SoftButton variant="gradient" color="info" type="submit" sx={{ mt: 1 }}>
                      Search Amount Limits
                    </SoftButton>
                  </Grid>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
        <DataTable
          table={{
            columns: [
              { Header: "Payment Option", accessor: "paymentOption" },
              { Header: "country", accessor: "country" },
              { Header: "currency", accessor: "currency" },
              { Header: "category", accessor: "category" },
              { Header: "min", accessor: "min" },
              { Header: "max", accessor: "max" },
              { Header: "createdAt", accessor: "createdAt" },
              { Header: "action", accessor: "action" },
            ],
            rows: getRows(),
          }}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          pageCount={_.get(data, "totalPages", 0)}
          rowCount={_.get(data, "totalDocs", 0)}
          manualPagination={true}
          isLoading={isLoading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          nextPage={_.get(data, "nextPage", 0)}
          sortDirection={sortDirection}
          columnsFilter={true}
          sortColumns={sortColumns}
          onHeaderCellClick={handleSortChange}
        />
      </DialogContent>
    </Card>
  );
};
AmountLimit.propTypes = {
  showHeader: PropTypes.bool,
};
export default AmountLimit;
