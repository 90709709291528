import * as Yup from "yup";

export const defaultValues = {
  paymentOptionId: null,
  category: null,
  country: null,
  currency: null,
  min: null,
  max: null,
};
export const schema = Yup.object().shape({
  paymentOptionId: Yup.string().required("Payment Option is required"),
  category: Yup.string().required("Category is required"),
  currency: Yup.string().required("Currency is required"),
  min: Yup.number().required("Min amount is required"),
  max: Yup.number().required("Max amount is required"),
  country: Yup.string().nullable(),
});

export const sanitizeRequest = ({
  paymentOptionId,
  category,
  currency,
  min,
  max,
  country,
  pspId,
  midId,
}) => {
  const payload = _.pickBy(
    {
      paymentOptionId,
      category,
      currency,

      country,
      pspId,
      midId,
    },
    _.identity
  );
  return {
    ...payload,
    min,
    max,
  };
};
