import * as Yup from "yup";

export const defaultValues = {
  amount: 0,
};

export const topUpSchema = Yup.object().shape({
  amount: Yup.number().required("Topup amount is required"),
});

export const withdrawSchema = Yup.object().shape({
  amount: Yup.number().required("Withdraw amount is required"),
});
