import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Grid,
  Divider,
  Tooltip,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import _ from "lodash";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { TextInputField } from "components/FormField";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { useDisclosure } from "hooks/useDisclosure";
import FormProvider from "components/FormProviders";
import PaymentMethodForm from "../../../brand-detail/components/PspMids/components/PaymentMethodForm";
import { defaultValues, sanitizeRequest } from "pages/brand-detail/schemas/paymentMethod";
import {
  sanitizeRequest as sanitizeTemplate,
  schema,
} from "pages/brand-detail/schemas/paymentMethodsTemplate";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUpdateTemplate } from "pages/brand-detail/hooks";
import PropTypes from "prop-types";
import {
  extractDefaultCurrency,
  groupCountryBySetting,
  sanitizeDefaultCurrency,
} from "pages/brand-detail/components/PspMids/components/EditPaymentMethod";

const UpdateTemplate = ({ template }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  //form
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, control, reset } = methods;

  //hooks
  const { mutate, isLoading } = useUpdateTemplate();

  const handleSubmitForm = (values) => {
    const payload = sanitizeRequest(values);
    const templatePayload = sanitizeTemplate({ ...payload, name: values.name });
    mutate(
      { id: template._id, ...templatePayload },
      {
        onSuccess: async () => {
          handleClose();
        },
      }
    );
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  useEffect(() => {
    if (template) {
      const payinGroup = groupCountryBySetting(sanitizeDefaultCurrency(template?.payinCountries));
      const payoutGroup = groupCountryBySetting(sanitizeDefaultCurrency(template?.payoutCountries));
      const payinCountries = extractDefaultCurrency(payinGroup);
      const payoutCountries = extractDefaultCurrency(payoutGroup);
      const gData = {
        ...template,
        payinCountries,
        payoutCountries,
      };
      reset(gData);
    }
  }, [template]);

  return (
    <>
      <Tooltip title="Delete">
        <IconButton onClick={onOpen} disabled={isLoading}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        sx={{ overflow: "visible" }}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>Update Template</DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
            <SoftBox py={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextInputField name="name" label="Template Name" />
                </Grid>
              </Grid>
              <Divider />
              <PaymentMethodForm showPaymentOption={false} />
              <Stack direction="row" spacing={1} justifyContent="center" mt={4}>
                <SoftButton onClick={handleClose}>Cancel</SoftButton>
                <SoftButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Update Template
                </SoftButton>
              </Stack>
            </SoftBox>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};
UpdateTemplate.propTypes = {
  template: PropTypes.object.isRequired,
};
export default UpdateTemplate;
