import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

const NoData = ({ message }) => {
  return (
    <Typography
      sx={{
        width: "100%",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        my: 3,
        color: "#67748e",
      }}
    >
      {message}
    </Typography>
  );
};

NoData.propTypes = {
  message: PropTypes.string.isRequired,
};
export default NoData;
