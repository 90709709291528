import * as Yup from "yup";

export const defaultValues = {
  code: null,
  name: null,
  legalName: null,
  registrationNumber: null,
  website: null,
  companyPhone: null,
  companyEmail: null,
  companyAddress: null,
  country: null,
  currency: null,
  mccCode: null,
  vat: null,
  enableCashier: true,
  enableDirect: true,
  enableHybrid: true,
  excludeFromReports: false,
};

export const schema = Yup.object().shape({
  name: Yup.string()
    .required("Merchant name is required")
    .max(100, "Merchant name is too long, should be less than 100 characters"),
  legalName: Yup.string()
    .required("Legal name is required")
    .max(100, "Legal name is too long, should be under 100 characters"),
  registrationNumber: Yup.string().required("Registration number is required"),
  organizationId: Yup.string().required("Organization is required"),
});
