import { toastSuccess } from "utils/toast";
import { useMutation, useQueryClient } from "react-query";
import request from "utils/request";
import { getTemplatePath } from "utils/string";

const FEE = "psps/:pspId/fees/:feeId";
const FEES = "psps/:pspId/fees";

const updateFee = ({ pspId, feeId, ...payload }) => {
  const URL = getTemplatePath(FEE, { pspId, feeId });
  return request.put(URL, payload);
};
export const useUpdatePspsFee = (id) => {
  const queryClient = useQueryClient();
  return useMutation(updateFee, {
    onSuccess: () => {
      queryClient.invalidateQueries([FEES, id]);
      toastSuccess({ description: "Fee updated successfully" });
    },
  });
};

export const deleteFee = ({ pspId, feeId }) => {
  const URL = getTemplatePath(FEE, { pspId, feeId });
  return request.delete(URL);
};

export const useDeleteFee = (id) => {
  const queryClient = useQueryClient();
  return useMutation(deleteFee, {
    onSuccess: () => {
      queryClient.invalidateQueries([FEES, id]);
      toastSuccess({ description: "Fee deleted successfully" });
    },
  });
};
