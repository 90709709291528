// create a currency mock of all the currencies in the world

export const currencies = [
  {
    code: "",
    name: "All",
    symbol: "",
    flag: "https://flagcdn.com/are.svg",
  },
  {
    code: "AED",
    name: "United Arab Emirates Dirham",
    symbol: "د.إ",
    flag: "https://flagcdn.com/are.svg",
  },
  {
    code: "AFN",
    name: "Afghan Afghani",
    symbol: "؋",
    flag: "https://flagcdn.com/afg.svg",
  },
  {
    code: "ALL",
    name: "Albanian Lek",
    symbol: "Lek",
    flag: "https://flagcdn.com/alb.svg",
  },
  {
    code: "AMD",
    name: "Armenian Dram",
    symbol: "դր.",
    flag: "https://flagcdn.com/arm.svg",
  },
  {
    code: "ANG",
    name: "Netherlands Antillean Guilder",
    symbol: "ƒ",
    flag: "https://flagcdn.com/ant.svg",
  },
  {
    code: "AOA",
    name: "Angolan Kwanza",
    symbol: "Kz",
    flag: "https://flagcdn.com/ago.svg",
  },
  {
    code: "ARS",

    name: "Argentine Peso",
    symbol: "$",
    flag: "https://flagcdn.com/arg.svg",
  },
  {
    code: "AUD",
    name: "Australian Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/aus.svg",
  },
  {
    code: "AWG",
    name: "Aruban Florin",
    symbol: "ƒ",
    flag: "https://flagcdn.com/abw.svg",
  },
  {
    code: "AZN",
    name: "Azerbaijani Manat",
    symbol: "ман",
    flag: "https://flagcdn.com/aze.svg",
  },
  {
    code: "BAM",
    name: "Bosnia-Herzegovina Convertible Mark",
    symbol: "KM",
    flag: "https://flagcdn.com/bih.svg",
  },
  {
    code: "BBD",
    name: "Barbadian Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/brb.svg",
  },
  {
    code: "BDT",
    name: "Bangladeshi Taka",
    symbol: "৳",
    flag: "https://flagcdn.com/bgd.svg",
  },
  {
    code: "BGN",
    name: "Bulgarian Lev",
    symbol: "лв",
    flag: "https://flagcdn.com/bgr.svg",
  },
  {
    code: "BHD",
    name: "Bahraini Dinar",
    symbol: "ب.د",

    flag: "https://flagcdn.com/bhr.svg",
  },
  {
    code: "BIF",
    name: "Burundian Franc",
    symbol: "Fr",
    flag: "https://flagcdn.com/bdi.svg",
  },
  {
    code: "BMD",
    name: "Bermudian Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/bmu.svg",
  },
  {
    code: "BND",
    name: "Brunei Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/brn.svg",
  },
  {
    code: "BOB",
    name: "Bolivian Boliviano",
    symbol: "Bs",
    flag: "https://flagcdn.com/bol.svg",
  },
  {
    code: "BRL",
    name: "Brazilian Real",
    symbol: "R$",
    flag: "https://flagcdn.com/bra.svg",
  },
  {
    code: "BSD",
    name: "Bahamian Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/bhs.svg",
  },
  {
    code: "BTC",
    name: "Bitcoin",
    symbol: "฿",
    flag: "https://flagcdn.com/btc.svg",
  },
  {
    code: "BTN",
    name: "Bhutanese Ngultrum",
    symbol: "Nu",
    flag: "https://flagcdn.com/btn.svg",
  },
  {
    code: "BWP",
    name: "Botswanan Pula",
    symbol: "P",
    flag: "https://flagcdn.com/bwa.svg",
  },
  {
    code: "BYN",
    name: "Belarusian Ruble",
    symbol: "Br",
    flag: "https://flagcdn.com/blr.svg",
  },
  {
    code: "BZD",
    name: "Belize Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/blz.svg",
  },
  {
    code: "CAD",
    name: "Canadian Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/can.svg",
  },
  {
    code: "CDF",
    name: "Congolese Franc",
    symbol: "Fr",
    flag: "https://flagcdn.com/cod.svg",
  },
  {
    code: "CHF",
    name: "Swiss Franc",
    symbol: "Fr",
    flag: "https://flagcdn.com/che.svg",
  },
  {
    code: "CLF",
    name: "Chilean Unit of Account (UF)",
    symbol: "UF",
    flag: "https://flagcdn.com/chl.svg",
  },
  {
    code: "CLP",
    name: "Chilean Peso",
    symbol: "$",
    flag: "https://flagcdn.com/chl.svg",
  },
  {
    code: "CNH",
    name: "Chinese Yuan (Offshore)",
    symbol: "¥",
    flag: "https://flagcdn.com/chn.svg",
  },
  {
    code: "CNY",
    name: "Chinese Yuan",
    symbol: "¥",
    flag: "https://flagcdn.com/chn.svg",
  },

  {
    code: "COP",

    name: "Colombian Peso",
    symbol: "$",
    flag: "https://flagcdn.com/col.svg",
  },
  {
    code: "CRC",
    name: "Costa Rican Colón",
    symbol: "₡",
    flag: "https://flagcdn.com/cri.svg",
  },
  {
    code: "CUC",
    name: "Cuban Convertible Peso",
    symbol: "$",
    flag: "https://flagcdn.com/cub.svg",
  },
  {
    code: "CUP",
    name: "Cuban Peso",
    symbol: "$",
    flag: "https://flagcdn.com/cub.svg",
  },
  {
    code: "CVE",
    name: "Cape Verdean Escudo",
    symbol: "Esc",
    flag: "https://flagcdn.com/cpv.svg",
  },
  {
    code: "CZK",
    name: "Czech Republic Koruna",
    symbol: "Kč",
    flag: "https://flagcdn.com/cze.svg",
  },
  {
    code: "DJF",
    name: "Djiboutian Franc",
    symbol: "Fr",
    flag: "https://flagcdn.com/dji.svg",
  },
  {
    code: "DKK",
    name: "Danish Krone",
    symbol: "kr",
    flag: "https://flagcdn.com/dnk.svg",
  },
  {
    code: "DOP",
    name: "Dominican Peso",
    symbol: "$",

    flag: "https://flagcdn.com/dom.svg",
  },
  {
    code: "DZD",
    name: "Algerian Dinar",
    symbol: "د.ج",
    flag: "https://flagcdn.com/dza.svg",
  },
  {
    code: "EGP",
    name: "Egyptian Pound",
    symbol: "£",
    flag: "https://flagcdn.com/eg.svg",
  },
  {
    code: "ERN",
    name: "Eritrean Nakfa",
    symbol: "Nfk",

    flag: "https://flagcdn.com/eri.svg",
  },
  {
    code: "ETB",
    name: "Ethiopian Birr",
    symbol: "Br",
    flag: "https://flagcdn.com/eth.svg",
  },
  {
    code: "EUR",
    name: "Euro",
    symbol: "€",
    flag: "https://flagcdn.com/eur.svg",
  },
  {
    code: "FJD",
    name: "Fijian Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/fji.svg",
  },
  {
    code: "FKP",
    name: "Falkland Islands Pound",
    symbol: "£",
    flag: "https://flagcdn.com/flk.svg",
  },
  {
    code: "GBP",
    name: "British Pound Sterling",
    symbol: "£",
    flag: "https://flagcdn.com/gbr.svg",
  },
  {
    code: "GEL",
    name: "Georgian Lari",
    symbol: "ლ",
    flag: "https://flagcdn.com/geo.svg",
  },
  {
    code: "GHS",
    name: "Ghanaian Cedi",
    symbol: "₵",
    flag: "https://flagcdn.com/gha.svg",
  },
  {
    code: "GIP",
    name: "Gibraltar Pound",
    symbol: "£",
    flag: "https://flagcdn.com/gib.svg",
  },
  {
    code: "GMD",
    name: "Gambian Dalasi",
    symbol: "D",
    flag: "https://flagcdn.com/gmb.svg",
  },
  {
    code: "GNF",
    name: "Guinean Franc",
    symbol: "Fr",
    flag: "https://flagcdn.com/gin.svg",
  },
  {
    code: "GTQ",
    name: "Guatemalan Quetzal",
    symbol: "Q",
    flag: "https://flagcdn.com/gtm.svg",
  },
  {
    code: "GYD",
    name: "Guyanaese Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/guy.svg",
  },
  {
    code: "HKD",
    name: "Hong Kong Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/hkg.svg",
  },
  {
    code: "HNL",
    name: "Honduran Lempira",
    symbol: "L",
    flag: "https://flagcdn.com/hnd.svg",
  },
  {
    code: "HRK",
    name: "Croatian Kuna",
    symbol: "kn",
    flag: "https://flagcdn.com/hrv.svg",
  },
  {
    code: "HTG",
    name: "Haitian Gourde",
    symbol: "G",
    flag: "https://flagcdn.com/hti.svg",
  },
  {
    code: "HUF",
    name: "Hungarian Forint",
    symbol: "Ft",
    flag: "https://flagcdn.com/hun.svg",
  },
  {
    code: "IDR",
    name: "Indonesian Rupiah",
    symbol: "Rp",
    flag: "https://flagcdn.com/idn.svg",
  },
  {
    code: "ILS",
    name: "Israeli New Sheqel",
    symbol: "₪",
    flag: "https://flagcdn.com/isr.svg",
  },
  {
    code: "INR",
    name: "Indian Rupee",
    symbol: "₹",
    flag: "https://flagcdn.com/ind.svg",
  },
  {
    code: "IQD",
    name: "Iraqi Dinar",
    symbol: "ع.د",
    flag: "https://flagcdn.com/irq.svg",
  },
  {
    code: "IRR",
    name: "Iranian Rial",
    symbol: "﷼",
    flag: "https://flagcdn.com/irn.svg",
  },
  {
    code: "ISK",
    name: "Icelandic Króna",
    symbol: "kr",
    flag: "https://flagcdn.com/isl.svg",
  },
  {
    code: "JMD",
    name: "Jamaican Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/jam.svg",
  },
  {
    code: "JOD",
    name: "Jordanian Dinar",
    symbol: "د.ا",
    flag: "https://flagcdn.com/jor.svg",
  },
  {
    code: "JPY",
    name: "Japanese Yen",
    symbol: "¥",
    flag: "https://flagcdn.com/jpn.svg",
  },
  {
    code: "KES",
    name: "Kenyan Shilling",
    symbol: "Sh",
    flag: "https://flagcdn.com/ken.svg",
  },
  {
    code: "KGS",
    name: "Kyrgystani Som",
    symbol: "сом",
    flag: "https://flagcdn.com/kgz.svg",
  },
  {
    code: "KHR",
    name: "Cambodian Riel",
    symbol: "៛",
    flag: "https://flagcdn.com/khm.svg",
  },
  {
    code: "KMF",
    name: "Comorian Franc",
    symbol: "Fr",
    flag: "https://flagcdn.com/com.svg",
  },
  {
    code: "KPW",
    name: "North Korean Won",
    symbol: "₩",
    flag: "https://flagcdn.com/prk.svg",
  },
  {
    code: "KRW",
    name: "South Korean Won",
    symbol: "₩",
    flag: "https://flagcdn.com/kor.svg",
  },
  {
    code: "KWD",
    name: "Kuwaiti Dinar",
    symbol: "د.ك",
    flag: "https://flagcdn.com/kwt.svg",
  },
  {
    code: "KYD",
    name: "Cayman Islands Dollar",
    symbol: "$",

    flag: "https://flagcdn.com/cym.svg",
  },
  {
    code: "KZT",
    name: "Kazakhstani Tenge",
    symbol: "₸",
    flag: "https://flagcdn.com/kaz.svg",
  },
  {
    code: "LAK",
    name: "Laotian Kip",
    symbol: "₭",
    flag: "https://flagcdn.com/lao.svg",
  },
  {
    code: "LBP",
    name: "Lebanese Pound",
    symbol: "ل.ل",
    flag: "https://flagcdn.com/lbn.svg",
  },
  {
    code: "LKR",
    name: "Sri Lankan Rupee",
    symbol: "Rs",
    flag: "https://flagcdn.com/lka.svg",
  },
  {
    code: "LRD",
    name: "Liberian Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/lbr.svg",
  },
  {
    code: "LSL",
    name: "Lesotho Loti",
    symbol: "L",
    flag: "https://flagcdn.com/lso.svg",
  },
  {
    code: "LTL",
    name: "Lithuanian Litas",
    symbol: "Lt",

    flag: "https://flagcdn.com/ltu.svg",
  },
  {
    code: "LVL",
    name: "Latvian Lats",
    symbol: "Ls",
    flag: "https://flagcdn.com/lva.svg",
  },
  {
    code: "LYD",
    name: "Libyan Dinar",
    symbol: "ل.د",
    flag: "https://flagcdn.com/lby.svg",
  },
  {
    code: "MAD",
    name: "Moroccan Dirham",
    symbol: "د.م.",
    flag: "https://flagcdn.com/mar.svg",
  },
  {
    code: "MDL",
    name: "Moldovan Leu",
    symbol: "L",
    flag: "https://flagcdn.com/mda.svg",
  },
  {
    code: "MGA",
    name: "Malagasy Ariary",
    symbol: "Ar",
    flag: "https://flagcdn.com/mdg.svg",
  },
  {
    code: "MKD",
    name: "Macedonian Denar",
    symbol: "ден",
    flag: "https://flagcdn.com/mkd.svg",
  },
  {
    code: "MMK",
    name: "Myanma Kyat",
    symbol: "K",
    flag: "https://flagcdn.com/mmr.svg",
  },
  {
    code: "MNT",
    name: "Mongolian Tugrik",
    symbol: "₮",

    flag: "https://flagcdn.com/mng.svg",
  },
  {
    code: "MOP",
    name: "Macanese Pataca",
    symbol: "P",
    flag: "https://flagcdn.com/mac.svg",
  },
  {
    code: "MRO",
    name: "Mauritanian Ouguiya",
    symbol: "UM",
    flag: "https://flagcdn.com/mrt.svg",
  },
  {
    code: "MUR",
    name: "Mauritian Rupee",
    symbol: "₨",
    flag: "https://flagcdn.com/mus.svg",
  },
  {
    code: "MVR",
    name: "Maldivian Rufiyaa",
    symbol: "ރ.",
    flag: "https://flagcdn.com/mdv.svg",
  },
  {
    code: "MWK",
    name: "Malawian Kwacha",
    symbol: "MK",
    flag: "https://flagcdn.com/mwi.svg",
  },
  {
    code: "MXN",
    name: "Mexican Peso",
    symbol: "$",
    flag: "https://flagcdn.com/mex.svg",
  },
  {
    code: "MYR",
    name: "Malaysian Ringgit",
    symbol: "RM",
    flag: "https://flagcdn.com/mys.svg",
  },
  {
    code: "MZN",
    name: "Mozambican Metical",
    symbol: "MT",
    flag: "https://flagcdn.com/moz.svg",
  },
  {
    code: "NAD",
    name: "Namibian Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/nam.svg",
  },
  {
    code: "NGN",
    name: "Nigerian Naira",
    symbol: "₦",
    flag: "https://flagcdn.com/nga.svg",
  },
  {
    code: "NIO",
    name: "Nicaraguan Córdoba",
    symbol: "C$",
    flag: "https://flagcdn.com/nic.svg",
  },
  {
    code: "NOK",
    name: "Norwegian Krone",
    symbol: "kr",
    flag: "https://flagcdn.com/nor.svg",
  },
  {
    code: "NPR",
    name: "Nepalese Rupee",
    symbol: "₨",
    flag: "https://flagcdn.com/npl.svg",
  },
  {
    code: "NZD",
    name: "New Zealand Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/nzl.svg",
  },
  {
    code: "OMR",
    name: "Omani Rial",
    symbol: "ر.ع.",
    flag: "https://flagcdn.com/omn.svg",
  },
  {
    code: "PAB",
    name: "Panamanian Balboa",
    symbol: "B/.",
    flag: "https://flagcdn.com/pan.svg",
  },
  {
    code: "PEN",
    name: "Peruvian Nuevo Sol",
    symbol: "S/.",
    flag: "https://flagcdn.com/per.svg",
  },
  {
    code: "PGK",
    name: "Papua New Guinean Kina",
    symbol: "K",
    flag: "https://flagcdn.com/png.svg",
  },
  {
    code: "PHP",
    name: "Philippine Peso",
    symbol: "₱",
    flag: "https://flagcdn.com/phl.svg",
  },
  {
    code: "PKR",
    name: "Pakistani Rupee",
    symbol: "₨",

    flag: "https://flagcdn.com/pak.svg",
  },
  {
    code: "PLN",
    name: "Polish Zloty",
    symbol: "zł",
    flag: "https://flagcdn.com/pol.svg",
  },
  {
    code: "PYG",
    name: "Paraguayan Guaraní",
    symbol: "₲",
    flag: "https://flagcdn.com/pry.svg",
  },
  {
    code: "QAR",
    name: "Qatari Rial",
    symbol: "ر.ق",
    flag: "https://flagcdn.com/qat.svg",
  },
  {
    code: "RON",
    name: "Romanian Leu",
    symbol: "L",
    flag: "https://flagcdn.com/rou.svg",
  },
  {
    code: "RSD",
    name: "Serbian Dinar",
    symbol: "Дин.",
    flag: "https://flagcdn.com/srb.svg",
  },
  {
    code: "RUB",
    name: "Russian Ruble",
    symbol: "₽",
    flag: "https://flagcdn.com/rus.svg",
  },

  {
    code: "RWF",
    name: "Rwandan Franc",
    symbol: "Fr",
    flag: "https://flagcdn.com/rwa.svg",
  },
  {
    code: "SAR",
    name: "Saudi Riyal",
    symbol: "ر.س",
    flag: "https://flagcdn.com/sau.svg",
  },
  {
    code: "SBD",
    name: "Solomon Islands Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/slb.svg",
  },
  {
    code: "SCR",
    name: "Seychellois Rupee",
    symbol: "₨",
    flag: "https://flagcdn.com/syc.svg",
  },
  {
    code: "SDG",
    name: "Sudanese Pound",
    symbol: "ج.س.",
    flag: "https://flagcdn.com/sdn.svg",
  },
  {
    code: "SEK",
    name: "Swedish Krona",
    symbol: "kr",
    flag: "https://flagcdn.com/swe.svg",
  },
  {
    code: "SGD",
    name: "Singapore Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/sgp.svg",
  },
  {
    code: "SHP",
    name: "Saint Helena Pound",
    symbol: "£",
    flag: "https://flagcdn.com/shn.svg",
  },
  {
    code: "SLL",
    name: "Sierra Leonean Leone",
    symbol: "Le",
    flag: "https://flagcdn.com/sle.svg",
  },
  {
    code: "SOS",
    name: "Somali Shilling",
    symbol: "Sh",
    flag: "https://flagcdn.com/som.svg",
  },
  {
    code: "SRD",
    name: "Surinamese Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/sur.svg",
  },
  {
    code: "SSP",
    name: "South Sudanese Pound",
    symbol: "£",
    flag: "https://flagcdn.com/ssd.svg",
  },
  {
    code: "STD",
    name: "São Tomé and Príncipe Dobra",
    symbol: "Db",
    flag: "https://flagcdn.com/stp.svg",
  },
  {
    code: "SYP",
    name: "Syrian Pound",
    symbol: "ل.س",
    flag: "https://flagcdn.com/syr.svg",
  },
  {
    code: "SZL",
    name: "Swazi Lilangeni",
    symbol: "E",
    flag: "https://flagcdn.com/swz.svg",
  },
  {
    code: "THB",
    name: "Thai Baht",
    symbol: "฿",
    flag: "https://flagcdn.com/tha.svg",
  },
  {
    code: "TJS",
    name: "Tajikistani Somoni",
    symbol: "ЅМ",
    flag: "https://flagcdn.com/tjk.svg",
  },
  {
    code: "TMT",
  },
  ,
  {
    code: "TND",
    name: "Tunisian Dinar",
    symbol: "د.ت",
    flag: "https://flagcdn.com/tun.svg",
  },
  {
    code: "TOP",
    name: "Tongan Paʻanga",
    symbol: "T$",
    flag: "https://flagcdn.com/ton.svg",
  },
  {
    code: "TRY",
    name: "Turkish Lira",
    symbol: "₺",

    flag: "https://flagcdn.com/tur.svg",
  },
  {
    code: "TTD",
    name: "Trinidad and Tobago Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/tto.svg",
  },
  {
    code: "TWD",
    name: "New Taiwan Dollar",
    symbol: "NT$",
    flag: "https://flagcdn.com/twn.svg",
  },
  {
    code: "TZS",
    name: "Tanzanian Shilling",
    symbol: "TSh",
    flag: "https://flagcdn.com/tza.svg",
  },
  {
    code: "UAH",
    name: "Ukrainian Hryvnia",
    symbol: "₴",
    flag: "https://flagcdn.com/ukr.svg",
  },
  {
    code: "UGX",
    name: "Ugandan Shilling",
    symbol: "USh",
    flag: "https://flagcdn.com/uga.svg",
  },
  {
    code: "USD",
    name: "United States Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/us.svg",
  },
  {
    code: "USN",
    name: "United States Dollar (Next day)",
    symbol: "$",
    flag: "https://flagcdn.com/usa.svg",
  },
  {
    code: "UYI",
    name: "Uruguayan Peso (Indexed Units)",
    symbol: "$",

    flag: "https://flagcdn.com/ury.svg",
  },

  {
    code: "UYU",
    name: "Uruguayan Peso",
    symbol: "$",
    flag: "https://flagcdn.com/ury.svg",
  },
  {
    code: "UZS",
    name: "Uzbekistan Som",
    symbol: "UZS",
    flag: "https://flagcdn.com/uzb.svg",
  },
  {
    code: "VEF",
    name: "Venezuelan Bolívar",
    symbol: "Bs",
    flag: "https://flagcdn.com/ven.svg",
  },
  {
    code: "VND",

    name: "Vietnamese Đồng",
    symbol: "₫",
    flag: "https://flagcdn.com/vnm.svg",
  },
  {
    code: "VUV",
    name: "Vanuatu Vatu",

    symbol: "Vt",
    flag: "https://flagcdn.com/vut.svg",
  },
  {
    code: "WST",
    name: "Samoan Tala",
    symbol: "T",
    flag: "https://flagcdn.com/wsm.svg",
  },
  {
    code: "XAF",
    name: "CFA Franc BEAC",
    symbol: "FCFA",
    flag: "https://flagcdn.com/cmr.svg",
  },
  {
    code: "XAG",
    name: "Silver (troy ounce)",
    symbol: "oz t",
    flag: "https://flagcdn.com/xxx.svg",
  },
  {
    code: "XAU",
    name: "Gold (troy ounce)",
    symbol: "oz t",
    flag: "https://flagcdn.com/xxx.svg",
  },
  {
    code: "XCD",
    name: "East Caribbean Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/jam.svg",
  },
  {
    code: "XDR",
    name: "Special Drawing Rights",
    symbol: "SDR",
    flag: "https://flagcdn.com/xxx.svg",
  },
  {
    code: "XOF",
    name: "CFA Franc BCEAO",
    symbol: "CFA",
    flag: "https://flagcdn.com/cmr.svg",
  },
  {
    code: "XPD",
    name: "Palladium (troy ounce)",

    symbol: "oz t",
    flag: "https://flagcdn.com/xxx.svg",
  },
  {
    code: "XPF",
    name: "CFP Franc",
    symbol: "FCFP",
    flag: "https://flagcdn.com/cmr.svg",
  },
  {
    code: "XPT",
    name: "Platinum (troy ounce)",
    symbol: "oz t",
    flag: "https://flagcdn.com/xxx.svg",
  },
  {
    code: "XTS",
    name: "Testing Currency Code",
    symbol: "TS",
    flag: "https://flagcdn.com/xxx.svg",
  },
  {
    code: "XXX",
    name: "Unknown Currency",
    symbol: "XXX",
    flag: "https://flagcdn.com/xxx.svg",
  },
  {
    code: "YER",
    name: "Yemeni Rial",
    symbol: "﷼",
    flag: "https://flagcdn.com/yem.svg",
  },
  {
    code: "ZAR",
    name: "South African Rand",
    symbol: "R",
    flag: "https://flagcdn.com/zaf.svg",
  },
  {
    code: "ZMW",
    name: "Zambian Kwacha",
    symbol: "ZK",
    flag: "https://flagcdn.com/zmb.svg",
  },
  {
    code: "ZWL",
    name: "Zimbabwean Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/zwe.svg",
  },
];
