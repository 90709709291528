import { Dialog, DialogContent, DialogTitle, Icon, IconButton, Tooltip } from "@mui/material";
import { TextInputField } from "components/FormField";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { useDisclosure } from "hooks/useDisclosure";
import { useWithdraw } from "pages/brand-detail/hooks";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import FormProvider from "components/FormProviders";

import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, withdrawSchema } from "pages/brand-detail/schemas/wallet";

const Withdraw = ({ walletId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id: merchantId, brandId } = useParams();

  // form
  const methods = useForm({ defaultValues, resolver: yupResolver(withdrawSchema) });
  const { handleSubmit, reset } = methods;

  // hooks
  const { mutate, isLoading } = useWithdraw();

  // function
  const handleSubmitForm = ({ amount }) => {
    mutate(
      { merchantId, brandId, walletId, amount: Number(amount) },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <>
      <Tooltip title="Withdraw">
        <IconButton onClick={onOpen}>
          <Icon>exit_to_app</Icon>
        </IconButton>
      </Tooltip>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>Withdraw</DialogTitle>
        <DialogContent>
          <SoftBox p={2}>
            <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
              <TextInputField label="Amount" name="amount" />
              <SoftBox display="flex" justifyContent="center" gap={2}>
                <SoftButton onClick={handleClose}>Cancel</SoftButton>
                <SoftButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Withdraw
                </SoftButton>
              </SoftBox>
            </FormProvider>
          </SoftBox>
        </DialogContent>
      </Dialog>
    </>
  );
};

Withdraw.defaultProps = {
  brandId: "",
  walletId: "",
};

Withdraw.propTypes = {
  brandId: PropTypes.string,
  walletId: PropTypes.string,
};

export default Withdraw;
