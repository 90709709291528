import { useParams } from "react-router-dom";
import _ from "lodash";

import SoftBox from "components/SoftBox";

import DataTable from "examples/Tables/DataTable";
import { useGetWallets } from "pages/brand-detail/hooks";
import Withdraw from "./Withdraw";
import Topup from "./Topup";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";

const Wallets = () => {
  if (!hasPermission(Permissions.BRAND_WALLET.LIST)) {
    return <Error403 />;
  }
  const { id: merchantId, brandId } = useParams();

  const { data: wallets } = useGetWallets(merchantId, brandId);

  const getRows = () => {
    return _.map(wallets, (item) => ({
      id: item.id,
      currency: item.currency,
      balance: item.balance,
      psp: item.psp,
      actions: (
        <SoftBox display="flex">
          <Topup walletId={item.id} />
          <Withdraw walletId={item.id} />
        </SoftBox>
      ),
    }));
  };

  return (
    <SoftBox>
      <SoftBox mt={2}>
        <DataTable
          table={{
            columns: [
              { Header: "currency", accessor: "currency" },
              { Header: "balance", accessor: "balance" },
              { Header: "psp", accessor: "psp" },
              { Header: "actions", accessor: "actions" },
            ],
            rows: getRows(),
          }}
        />
      </SoftBox>
    </SoftBox>
  );
};

export default Wallets;
