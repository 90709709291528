import _ from "lodash";
import { useMutation } from "react-query";
import request from "utils/request";

const RECONCILIATION = "/reconciliation";
const SHEETS = `${RECONCILIATION}/sheets`;

const getSheets = ({ formData }) => {
  return request.post(SHEETS, formData);
};
export const useGetSheets = () => {
  return useMutation({
    mutationFn: getSheets,
  });
};

const createReconciliation = ({ formData }) => request.post(RECONCILIATION, formData);
export const useCreateReconciliation = () => {
  return useMutation({
    mutationFn: createReconciliation,
  });
};
