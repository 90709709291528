export const countriesByRegion = [
  { code: "AD", label: "Andorra", currency: "EUR", phone: "376", continent: "Non EEA" },
  {
    code: "AE",
    label: "United Arab Emirates",
    currency: "AED",
    phone: "971",
    continent: "Middle east",
  },
  { code: "AF", label: "Afghanistan", currency: "AFN", phone: "93", continent: "Asia & Pacific" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    currency: "XCD",
    phone: "1-268",
    continent: "North America",
  },
  { code: "AI", label: "Anguilla", currency: "XCD", phone: "1-264", continent: "North America" },
  { code: "AL", label: "Albania", currency: "ALL", phone: "355", continent: "Non EEA" },
  { code: "AM", label: "Armenia", currency: "AMD", phone: "374", continent: "Non EEA" },
  { code: "AO", label: "Angola", currency: "AOA", phone: "244", continent: "Africa" },
  { code: "AQ", label: "Antarctica", currency: "FSA", phone: "672", continent: "Antarctica" },
  {
    code: "AR",
    label: "Argentina",
    currency: "ARS",
    phone: "54",
    continent: "South/Latin America",
  },
  {
    code: "AS",
    label: "American Samoa",
    currency: "USD",
    phone: "1-684",
    continent: "Asia & Pacific",
  },
  { code: "AT", label: "Austria", currency: "EUR", phone: "43", continent: "Europe" },
  { code: "AU", label: "Australia", currency: "AUD", phone: "61", continent: "Asia & Pacific" },
  { code: "AW", label: "Aruba", currency: "AWG", phone: "297", continent: "North America" },
  { code: "AX", label: "Alland Islands", currency: "EUR", phone: "358", continent: "Europe" },
  { code: "AZ", label: "Azerbaijan", currency: "AZN", phone: "994", continent: "Asia & Pacific" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    currency: "BAM",
    phone: "387",
    continent: "Non EEA",
  },
  {
    code: "BB",
    label: "Barbados",
    currency: "BBD",
    phone: "1-246",
    continent: "South/Latin America",
  },
  { code: "BD", label: "Bangladesh", currency: "BDT", phone: "880", continent: "Asia & Pacific" },
  { code: "BE", label: "Belgium", currency: "EUR", phone: "32", continent: "Europe" },
  { code: "BF", label: "Burkina Faso", currency: "XOF", phone: "226", continent: "Africa" },
  { code: "BG", label: "Bulgaria", currency: "BGN", phone: "359", continent: "Europe" },
  { code: "BH", label: "Bahrain", currency: "BHD", phone: "973", continent: "Arab States" },
  { code: "BI", label: "Burundi", currency: "BIF", phone: "257", continent: "Africa" },
  { code: "BJ", label: "Benin", currency: "XOF", phone: "229", continent: "Africa" },
  {
    code: "BL",
    label: "Saint Barthelemy",
    currency: "EUR",
    phone: "590",
    continent: "North America",
  },
  {
    code: "BM",
    label: "Bermuda",
    currency: "BMD",
    phone: "1-441",
    continent: "South/Latin America",
  },
  {
    code: "BN",
    label: "Brunei Darussalam",
    currency: "BND",
    phone: "673",
    continent: "Asia & Pacific",
  },
  { code: "BO", label: "Bolivia", currency: "BOB", phone: "591", continent: "South/Latin America" },
  { code: "BR", label: "Brazil", currency: "BRL", phone: "55", continent: "South/Latin America" },
  {
    code: "BS",
    label: "Bahamas",
    currency: "BSD",
    phone: "1-242",
    continent: "South/Latin America",
  },
  { code: "BT", label: "Bhutan", currency: "BTN", phone: "975", continent: "Asia & Pacific" },
  { code: "BV", label: "Bouvet Island", currency: "NOK", phone: "47", continent: "Antarctica" },
  { code: "BW", label: "Botswana", currency: "BWP", phone: "267", continent: "Africa" },
  { code: "BY", label: "Belarus", currency: "BYN", phone: "375", continent: "Europe" },
  { code: "BZ", label: "Belize", currency: "BZD", phone: "501", continent: "South/Latin America" },
  { code: "CA", label: "Canada", currency: "CAD", phone: "1", continent: "North America" },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    currency: "AUD",
    phone: "61",
    continent: "Asia & Pacific",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    currency: "CDF",
    phone: "243",
    continent: "Africa",
  },
  {
    code: "CF",
    label: "Central African Republic",
    currency: "XAF",
    phone: "236",
    continent: "Africa",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    currency: "XAF",
    phone: "242",
    continent: "Africa",
  },
  { code: "CH", label: "Switzerland", currency: "CHF", phone: "41", continent: "Europe" },
  { code: "CI", label: "Cote d'Ivoire", currency: "XOF", phone: "225", continent: "Africa" },
  { code: "CK", label: "Cook Islands", currency: "NZD", phone: "682", continent: "Asia & Pacific" },
  { code: "CL", label: "Chile", currency: "CLP", phone: "56", continent: "South/Latin America" },
  { code: "CM", label: "Cameroon", currency: "XAF", phone: "237", continent: "Africa" },
  { code: "CN", label: "China", currency: "CNY", phone: "86", continent: "Asia & Pacific" },
  { code: "CO", label: "Colombia", currency: "COP", phone: "57", continent: "South/Latin America" },
  {
    code: "CR",
    label: "Costa Rica",
    currency: "CRC",
    phone: "506",
    continent: "South/Latin America",
  },
  { code: "CU", label: "Cuba", currency: "CUP", phone: "53", continent: "South/Latin America" },
  { code: "CV", label: "Cabo Verde", currency: "CVE", phone: "238", continent: "Africa" },
  { code: "CW", label: "Curacao", currency: "ANG", phone: "599", continent: "North America" },
  {
    code: "CX",
    label: "Christmas Island",
    currency: "AUD",
    phone: "61",
    continent: "Asia & Pacific",
  },
  { code: "CY", label: "Cyprus", currency: "EUR", phone: "357", continent: "Europe" },
  { code: "CZ", label: "Czech Republic", currency: "CZK", phone: "420", continent: "Europe" },
  { code: "DE", label: "Germany", currency: "EUR", phone: "49", continent: "Europe" },
  { code: "DJ", label: "Djibouti", currency: "DJF", phone: "253", continent: "Africa" },
  { code: "DK", label: "Denmark", currency: "DKK", phone: "45", continent: "Europe" },
  {
    code: "DM",
    label: "Dominica",
    currency: "XCD",
    phone: "1-767",
    continent: "South/Latin America",
  },
  {
    code: "DO",
    label: "Dominican Republic",
    currency: "DOP",
    phone: "1-809",
    continent: "South/Latin America",
  },
  { code: "DZ", label: "Algeria", currency: "DZD", phone: "213", continent: "Arab States" },
  { code: "EC", label: "Ecuador", currency: "USD", phone: "593", continent: "South/Latin America" },
  { code: "EE", label: "Estonia", currency: "EUR", phone: "372", continent: "Europe" },
  { code: "EG", label: "Egypt", currency: "EGP", phone: "20", continent: "Arab States" },
  { code: "EH", label: "Western Sahara", currency: "MAD", phone: "212", continent: "Africa" },
  { code: "ER", label: "Eritrea", currency: "ERN", phone: "291", continent: "Africa" },
  { code: "ES", label: "Spain", currency: "EUR", phone: "34", continent: "Europe" },
  { code: "ET", label: "Ethiopia", currency: "ETB", phone: "251", continent: "Africa" },
  { code: "FI", label: "Finland", currency: "EUR", phone: "358", continent: "Europe" },
  { code: "FJ", label: "Fiji", currency: "FJD", phone: "679", continent: "Asia & Pacific" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    currency: "FKP",
    phone: "500",
    continent: "South/Latin America",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    currency: "USD",
    phone: "691",
    continent: "Asia & Pacific",
  },
  { code: "FO", label: "Faroe Islands", currency: "DKK", phone: "298", continent: "Europe" },
  { code: "FR", label: "France", currency: "EUR", phone: "33", continent: "Europe" },
  { code: "GA", label: "Gabon", currency: "XAF", phone: "241", continent: "Africa" },
  { code: "GB", label: "United Kingdom", currency: "GBP", phone: "44", continent: "Europe" },
  {
    code: "GD",
    label: "Grenada",
    currency: "XCD",
    phone: "1-473",
    continent: "South/Latin America",
  },
  { code: "GE", label: "Georgia", currency: "GEL", phone: "995", continent: "Non EEA" },
  {
    code: "GF",
    label: "French Guiana",
    currency: "EUR",
    phone: "594",
    continent: "South/Latin America",
  },
  { code: "GG", label: "Guernsey", currency: "GBP", phone: "44", continent: "Europe" },
  { code: "GH", label: "Ghana", currency: "GHS", phone: "233", continent: "Africa" },
  { code: "GI", label: "Gibraltar", currency: "GIP", phone: "350", continent: "Europe" },
  { code: "GL", label: "Greenland", currency: "DKK", phone: "299", continent: "North America" },
  { code: "GM", label: "Gambia", currency: "GMD", phone: "220", continent: "Africa" },
  { code: "GN", label: "Guinea", currency: "GNF", phone: "224", continent: "Africa" },
  {
    code: "GP",
    label: "Guadeloupe",
    currency: "EUR",
    phone: "590",
    continent: "South/Latin America",
  },
  { code: "GQ", label: "Equatorial Guinea", currency: "XAF", phone: "240", continent: "Africa" },
  { code: "GR", label: "Greece", currency: "EUR", phone: "30", continent: "Europe" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    currency: "GBP",
    phone: "500",
    continent: "Antarctica",
  },
  {
    code: "GT",
    label: "Guatemala",
    currency: "GTQ",
    phone: "502",
    continent: "South/Latin America",
  },
  { code: "GU", label: "Guam", currency: "USD", phone: "1-671", continent: "Asia & Pacific" },
  { code: "GW", label: "Guinea-Bissau", currency: "XOF", phone: "245", continent: "Africa" },
  { code: "GY", label: "Guyana", currency: "GYD", phone: "592", continent: "South/Latin America" },
  { code: "HK", label: "Hong Kong", currency: "HKD", phone: "852", continent: "Asia & Pacific" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    currency: "AUD",
    phone: "672",
    continent: "Antarctica",
  },
  {
    code: "HN",
    label: "Honduras",
    currency: "HNL",
    phone: "504",
    continent: "South/Latin America",
  },
  { code: "HR", label: "Croatia", currency: "HRK", phone: "385", continent: "Europe" },
  { code: "HT", label: "Haiti", currency: "HTG", phone: "509", continent: "South/Latin America" },
  { code: "HU", label: "Hungary", currency: "HUF", phone: "36", continent: "Europe" },
  { code: "ID", label: "Indonesia", currency: "IDR", phone: "62", continent: "Asia & Pacific" },
  { code: "IE", label: "Ireland", currency: "EUR", phone: "353", continent: "Europe" },
  { code: "IL", label: "Israel", currency: "ILS", phone: "972", continent: "Arab States" },
  { code: "IM", label: "Isle of Man", currency: "GBP", phone: "44", continent: "Europe" },
  { code: "IN", label: "India", currency: "INR", phone: "91", continent: "Asia & Pacific" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    currency: "USD",
    phone: "246",
    continent: "Asia & Pacific",
  },
  { code: "IQ", label: "Iraq", currency: "IQD", phone: "964", continent: "Middle east" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    currency: "IRR",
    phone: "98",
    continent: "Middle east",
  },
  { code: "IS", label: "Iceland", currency: "ISK", phone: "354", continent: "Europe" },
  { code: "IT", label: "Italy", currency: "EUR", phone: "39", continent: "Europe" },
  { code: "JE", label: "Jersey", currency: "GBP", phone: "44", continent: "Europe" },
  {
    code: "JM",
    label: "Jamaica",
    currency: "JMD",
    phone: "1-876",
    continent: "South/Latin America",
  },
  { code: "JO", label: "Jordan", currency: "JOD", phone: "962", continent: "Middle east" },
  { code: "JP", label: "Japan", currency: "JPY", phone: "81", continent: "Asia & Pacific" },
  { code: "KE", label: "Kenya", currency: "KES", phone: "254", continent: "Africa" },
  { code: "KG", label: "Kyrgyzstan", currency: "KGS", phone: "996", continent: "Asia & Pacific" },
  { code: "KH", label: "Cambodia", currency: "KHR", phone: "855", continent: "Asia & Pacific" },
  { code: "KI", label: "Kiribati", currency: "AUD", phone: "686", continent: "Asia & Pacific" },
  { code: "KM", label: "Comoros", currency: "KMF", phone: "269", continent: "Africa" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    currency: "XCD",
    phone: "1-869",
    continent: "North America",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    currency: "KPW",
    phone: "850",
    continent: "Asia & Pacific",
  },
  {
    code: "KR",
    label: "Korea, Republic of",
    currency: "KRW",
    phone: "82",
    continent: "Asia & Pacific",
  },
  { code: "KW", label: "Kuwait", currency: "KWD", phone: "965", continent: "Middle east" },
  {
    code: "KY",
    label: "Cayman Islands",
    currency: "KYD",
    phone: "1-345",
    continent: "South/Latin America",
  },
  { code: "KZ", label: "Kazakhstan", currency: "KZT", phone: "7", continent: "Asia & Pacific" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    currency: "LAK",
    phone: "856",
    continent: "Asia & Pacific",
  },
  { code: "LB", label: "Lebanon", currency: "LBP", phone: "961", continent: "Middle east" },
  { code: "LC", label: "Saint Lucia", currency: "XCD", phone: "1-758", continent: "North America" },
  { code: "LI", label: "Liechtenstein", currency: "CHF", phone: "423", continent: "Europe" },
  { code: "LK", label: "Sri Lanka", currency: "LKR", phone: "94", continent: "Asia & Pacific" },
  { code: "LR", label: "Liberia", currency: "LRD", phone: "231", continent: "Africa" },
  { code: "LS", label: "Lesotho", currency: "LSL", phone: "266", continent: "Africa" },
  { code: "LT", label: "Lithuania", currency: "EUR", phone: "370", continent: "Europe" },
  { code: "LU", label: "Luxembourg", currency: "EUR", phone: "352", continent: "Europe" },
  { code: "LV", label: "Latvia", currency: "EUR", phone: "371", continent: "Europe" },
  { code: "LY", label: "Libya", currency: "LYD", phone: "218", continent: "Arab States" },
  { code: "MA", label: "Morocco", currency: "MAD", phone: "212", continent: "Africa" },
  { code: "MC", label: "Monaco", currency: "EUR", phone: "377", continent: "Europe" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    currency: "MDL",
    phone: "373",
    continent: "Non EEA",
  },
  { code: "ME", label: "Montenegro", currency: "EUR", phone: "382", continent: "Non EEA" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    currency: "EUR",
    phone: "590",
    continent: "North America",
  },
  { code: "MG", label: "Madagascar", currency: "MGA", phone: "261", continent: "Africa" },
  {
    code: "MH",
    label: "Marshall Islands",
    currency: "USD",
    phone: "692",
    continent: "Asia & Pacific",
  },
  { code: "MK", label: "North Macedonia", currency: "MKD", phone: "389", continent: "Non EEA" },
  { code: "ML", label: "Mali", currency: "XOF", phone: "223", continent: "Africa" },
  { code: "MM", label: "Myanmar", currency: "MMK", phone: "95", continent: "Asia & Pacific" },
  { code: "MN", label: "Mongolia", currency: "MNT", phone: "976", continent: "Asia & Pacific" },
  { code: "MO", label: "Macao", currency: "MOP", phone: "853", continent: "Asia & Pacific" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    currency: "USD",
    phone: "1-670",
    continent: "Asia & Pacific",
  },
  {
    code: "MQ",
    label: "Martinique",
    currency: "EUR",
    phone: "596",
    continent: "South/Latin America",
  },
  { code: "MR", label: "Mauritania", currency: "MRU", phone: "222", continent: "Africa" },
  {
    code: "MS",
    label: "Montserrat",
    currency: "XCD",
    phone: "1-664",
    continent: "South/Latin America",
  },
  { code: "MT", label: "Malta", currency: "EUR", phone: "356", continent: "Europe" },
  { code: "MU", label: "Mauritius", currency: "MUR", phone: "230", continent: "Africa" },
  { code: "MV", label: "Maldives", currency: "MVR", phone: "960", continent: "Asia & Pacific" },
  { code: "MW", label: "Malawi", currency: "MWK", phone: "265", continent: "Africa" },
  { code: "MX", label: "Mexico", currency: "MXN", phone: "52", continent: "South/Latin America" },
  { code: "MY", label: "Malaysia", currency: "MYR", phone: "60", continent: "Asia & Pacific" },
  { code: "MZ", label: "Mozambique", currency: "MZN", phone: "258", continent: "Africa" },
  { code: "NA", label: "Namibia", currency: "NAD", phone: "264", continent: "Africa" },
  {
    code: "NC",
    label: "New Caledonia",
    currency: "XPF",
    phone: "687",
    continent: "Asia & Pacific",
  },
  { code: "NE", label: "Niger", currency: "XOF", phone: "227", continent: "Africa" },
  {
    code: "NF",
    label: "Norfolk Island",
    currency: "AUD",
    phone: "672",
    continent: "Asia & Pacific",
  },
  { code: "NG", label: "Nigeria", currency: "NGN", phone: "234", continent: "Africa" },
  {
    code: "NI",
    label: "Nicaragua",
    currency: "NIO",
    phone: "505",
    continent: "South/Latin America",
  },
  { code: "NL", label: "Netherlands", currency: "EUR", phone: "31", continent: "Europe" },
  { code: "NO", label: "Norway", currency: "NOK", phone: "47", continent: "Europe" },
  { code: "NP", label: "Nepal", currency: "NPR", phone: "977", continent: "Asia & Pacific" },
  { code: "NR", label: "Nauru", currency: "AUD", phone: "674", continent: "Asia & Pacific" },
  { code: "NU", label: "Niue", currency: "NZD", phone: "683", continent: "Asia & Pacific" },
  { code: "NZ", label: "New Zealand", currency: "NZD", phone: "64", continent: "Asia & Pacific" },
  { code: "OM", label: "Oman", currency: "OMR", phone: "968", continent: "Middle east" },
  { code: "PA", label: "Panama", currency: "PAB", phone: "507", continent: "South/Latin America" },
  { code: "PE", label: "Peru", currency: "PEN", phone: "51", continent: "South/Latin America" },
  {
    code: "PF",
    label: "French Polynesia",
    currency: "XPF",
    phone: "689",
    continent: "Asia & Pacific",
  },
  {
    code: "PG",
    label: "Papua New Guinea",
    currency: "PGK",
    phone: "675",
    continent: "Asia & Pacific",
  },
  { code: "PH", label: "Philippines", currency: "PHP", phone: "63", continent: "Asia & Pacific" },
  { code: "PK", label: "Pakistan", currency: "PKR", phone: "92", continent: "Asia & Pacific" },
  { code: "PL", label: "Poland", currency: "PLN", phone: "48", continent: "Europe" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    currency: "EUR",
    phone: "508",
    continent: "North America",
  },
  { code: "PN", label: "Pitcairn", currency: "NZD", phone: "870", continent: "Asia & Pacific" },
  {
    code: "PR",
    label: "Puerto Rico",
    currency: "USD",
    phone: "1",
    continent: "South/Latin America",
  },
  {
    code: "PS",
    label: "Palestine, State of",
    currency: "ILS",
    phone: "970",
    continent: "Arab States",
  },
  { code: "PT", label: "Portugal", currency: "EUR", phone: "351", continent: "Europe" },
  { code: "PW", label: "Palau", currency: "USD", phone: "680", continent: "Asia & Pacific" },
  {
    code: "PY",
    label: "Paraguay",
    currency: "PYG",
    phone: "595",
    continent: "South/Latin America",
  },
  { code: "QA", label: "Qatar", currency: "QAR", phone: "974", continent: "Middle east" },
  { code: "RE", label: "Reunion", currency: "EUR", phone: "262", continent: "Africa" },
  { code: "RO", label: "Romania", currency: "RON", phone: "40", continent: "Europe" },
  { code: "RS", label: "Serbia", currency: "RSD", phone: "381", continent: "Non EEA" },
  { code: "RU", label: "Russian Federation", currency: "RUB", phone: "7", continent: "Non EEA" },
  { code: "RW", label: "Rwanda", currency: "RWF", phone: "250", continent: "Africa" },
  { code: "SA", label: "Saudi Arabia", currency: "SAR", phone: "966", continent: "Middle east" },
  {
    code: "SB",
    label: "Solomon Islands",
    currency: "SBD",
    phone: "677",
    continent: "Asia & Pacific",
  },
  { code: "SC", label: "Seychelles", currency: "SCR", phone: "248", continent: "Africa" },
  { code: "SD", label: "Sudan", currency: "SDG", phone: "249", continent: "Africa" },
  { code: "SE", label: "Sweden", currency: "SEK", phone: "46", continent: "Europe" },
  { code: "SG", label: "Singapore", currency: "SGD", phone: "65", continent: "Asia & Pacific" },
  { code: "SH", label: "Saint Helena", currency: "SHP", phone: "290", continent: "Africa" },
  { code: "SI", label: "Slovenia", currency: "EUR", phone: "386", continent: "Europe" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    currency: "NOK",
    phone: "47",
    continent: "Europe",
  },
  { code: "SK", label: "Slovakia", currency: "EUR", phone: "421", continent: "Europe" },
  { code: "SL", label: "Sierra Leone", currency: "SLL", phone: "232", continent: "Africa" },
  { code: "SM", label: "San Marino", currency: "EUR", phone: "378", continent: "Europe" },
  { code: "SN", label: "Senegal", currency: "XOF", phone: "221", continent: "Africa" },
  { code: "SO", label: "Somalia", currency: "SOS", phone: "252", continent: "Africa" },
  {
    code: "SR",
    label: "Suriname",
    currency: "SRD",
    phone: "597",
    continent: "South/Latin America",
  },
  { code: "SS", label: "South Sudan", currency: "SSP", phone: "211", continent: "Africa" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    currency: "STN",
    phone: "239",
    continent: "Africa",
  },
  {
    code: "SV",
    label: "El Salvador",
    currency: "USD",
    phone: "503",
    continent: "South/Latin America",
  },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    currency: "ANG",
    phone: "1-721",
    continent: "North America",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    currency: "SYP",
    phone: "963",
    continent: "Middle east",
  },
  { code: "SZ", label: "Eswatini", currency: "SZL", phone: "268", continent: "Africa" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    currency: "USD",
    phone: "1-649",
    continent: "South/Latin America",
  },
  { code: "TD", label: "Chad", currency: "XAF", phone: "235", continent: "Africa" },
  {
    code: "TF",
    label: "French Southern Territories",
    currency: "EUR",
    phone: "262",
    continent: "Antarctica",
  },
  { code: "TG", label: "Togo", currency: "XOF", phone: "228", continent: "Africa" },
  { code: "TH", label: "Thailand", currency: "THB", phone: "66", continent: "Asia & Pacific" },
  { code: "TJ", label: "Tajikistan", currency: "TJS", phone: "992", continent: "Non EEA" },
  { code: "TK", label: "Tokelau", currency: "NZD", phone: "690", continent: "Asia & Pacific" },
  { code: "TL", label: "Timor-Leste", currency: "USD", phone: "670", continent: "Asia & Pacific" },
  { code: "TM", label: "Turkmenistan", currency: "TMT", phone: "993", continent: "Non EEA" },
  { code: "TN", label: "Tunisia", currency: "TND", phone: "216", continent: "Arab States" },
  { code: "TO", label: "Tonga", currency: "TOP", phone: "676", continent: "Asia & Pacific" },
  { code: "TR", label: "Turkey", currency: "TRY", phone: "90", continent: "Non EEA" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    currency: "TTD",
    phone: "1-868",
    continent: "South/Latin America",
  },
  { code: "TV", label: "Tuvalu", currency: "AUD", phone: "688", continent: "Asia & Pacific" },
  {
    code: "TW",
    label: "Taiwan, Province of China",
    currency: "TWD",
    phone: "886",
    continent: "Asia & Pacific",
  },
  {
    code: "TZ",
    label: "Tanzania, United Republic of",
    currency: "TZS",
    phone: "255",
    continent: "Africa",
  },
  { code: "UA", label: "Ukraine", currency: "UAH", phone: "380", continent: "Non EEA" },
  { code: "UG", label: "Uganda", currency: "UGX", phone: "256", continent: "Africa" },
  {
    code: "UM",
    label: "United States Minor Outlying Islands",
    currency: "USD",
    phone: "1",
    continent: "Asia & Pacific",
  },
  { code: "US", label: "United States", currency: "USD", phone: "1", continent: "North America" },
  { code: "UY", label: "Uruguay", currency: "UYU", phone: "598", continent: "South/Latin America" },
  { code: "UZ", label: "Uzbekistan", currency: "UZS", phone: "998", continent: "Non EEA" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    currency: "EUR",
    phone: "379",
    continent: "Europe",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    currency: "XCD",
    phone: "1-784",
    continent: "North America",
  },
  {
    code: "VE",
    label: "Venezuela, Bolivarian Republic of",
    currency: "VEF",
    phone: "58",
    continent: "South/Latin America",
  },
  {
    code: "VG",
    label: "Virgin Islands, British",
    currency: "USD",
    phone: "1-284",
    continent: "South/Latin America",
  },
  {
    code: "VI",
    label: "Virgin Islands, U.S.",
    currency: "USD",
    phone: "1-340",
    continent: "South/Latin America",
  },
  { code: "VN", label: "Viet Nam", currency: "VND", phone: "84", continent: "Asia & Pacific" },
  { code: "VU", label: "Vanuatu", currency: "VUV", phone: "678", continent: "Asia & Pacific" },
  {
    code: "WF",
    label: "Wallis and Futuna",
    currency: "XPF",
    phone: "681",
    continent: "Asia & Pacific",
  },
  { code: "WS", label: "Samoa", currency: "WST", phone: "685", continent: "Asia & Pacific" },
  { code: "YE", label: "Yemen", currency: "YER", phone: "967", continent: "Middle east" },
  { code: "YT", label: "Mayotte", currency: "EUR", phone: "262", continent: "Africa" },
  { code: "ZA", label: "South Africa", currency: "ZAR", phone: "27", continent: "Africa" },
  { code: "ZM", label: "Zambia", currency: "ZMW", phone: "260", continent: "Africa" },
  { code: "ZW", label: "Zimbabwe", currency: "ZWL", phone: "263", continent: "Africa" },
];
