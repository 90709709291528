import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import DeleteForever from "@mui/icons-material/DeleteForever";
import { Dialog, DialogTitle, IconButton, Tooltip, DialogContent } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { useEditBrand } from "pages/brand-detail/hooks";
import { useBrandStore } from "stores/brand";

const deleteDomainFromArray = (domains, domain) => {
  const idx = domains.findIndex((d) => d == domain);
  if (idx >= 0) {
    domains.splice(idx, 1);
  }
  return domains;
};

const sanitizeRequest = ({ merchantId, brandId, brand, domain }) => {
  return {
    ...brand,
    merchantId,
    domains: deleteDomainFromArray(_.get(brand, "domains", []), domain),
    brandId,
  };
};
const DeleteDomain = ({ domain }) => {
  const { id: merchantId, brandId } = useParams();
  const { mutate, isLoading } = useEditBrand({ merchantId, brandId });
  const [isOpen, setIsOpen] = useState(false);

  const { brand } = useBrandStore();

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  const handleDelete = () => {
    mutate(sanitizeRequest({ merchantId, brandId, brand, domain }), {
      onSuccess: () => {
        handleClose();
      },
    });
  };

  return (
    <>
      <Tooltip title="Delete">
        <IconButton onClick={handleOpen} disabled={isLoading}>
          <DeleteForever />
        </IconButton>
      </Tooltip>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xs">
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <SoftBox p={3} minWidth="300px">
            <SoftTypography variant="h6" textAlign="center">
              Are you sure want to delete domain {domain}?
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" gap={2} justifyContent="center">
            <SoftButton
              variant="gradient"
              color="error"
              onClick={handleDelete}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Delete
            </SoftButton>
            <SoftButton type="submit" onClick={handleClose}>
              Cancel
            </SoftButton>
          </SoftBox>
        </DialogContent>
      </Dialog>
    </>
  );
};

DeleteDomain.propTypes = {
  domain: PropTypes.string.isRequired,
};

export default DeleteDomain;
