import { create } from "zustand";

type Merchant = {
  _id: string | null;
  name: string | null;
  legalName: string | null;
  registrationNumber: string | null;
  companyEmail: string | null;
  companyPhone: string | null;
  companyAddress: string | null;
  website: string | null;
  country: string | null;
  currency: string | null;
  vat: string | null;
  mccCode: string | null;
  bankAccounts: BankAccount[];
  providerCustomerId: string | null;
  active: boolean | null;
  stores: any[];
  createdAt: string | null;
  updatedAt: string | null;
  logo: string | null;
  notificationUrl: string | null;
  signKey: string | null;
  brands: Brand[];
  settlementCurrency: string | null;
  paymentFac: boolean;
  cashierColor: string | null;
  cashierTheme: string | null;
  ipWhitelists: IpWhitelist2[];
};

type BankAccount = {
  _id: string;
  type: string;
  currency: string;
  accountId: string;
  name: string;
  sortCode: string;
  accountNumber: string;
  iban: any;
  bic: any;
};

type Brand = {
  _id: string;
  name: string;
  pspMids: PspMid[];
  domains: string[];
  ipWhitelists: IpWhitelist[];
  paymentFac: boolean;
};

type PspMid = {
  _id: string;
  pspMidId: string;
  payinEnabled: boolean;
  payoutEnabled: boolean;
  paymentMethods: any[];
};

type IpWhitelist = {
  startingIp: string;
  endingIp: string;
};

type IpWhitelist2 = {
  startingIp: string;
  endingIp: string;
};

type MerchantStore = {
  merchant: Merchant;
  setMerchant: (merchant: Merchant) => void;
};

const initialState: MerchantStore = {
  merchant: {
    _id: null,
    name: null,
    legalName: null,
    registrationNumber: null,
    companyEmail: null,
    companyPhone: null,
    companyAddress: null,
    website: null,
    country: null,
    currency: null,
    vat: null,
    mccCode: null,
    bankAccounts: [],
    providerCustomerId: null,
    active: false,
    stores: [],
    createdAt: null,
    updatedAt: null,
    logo: null,
    notificationUrl: null,
    signKey: null,
    brands: [],
    settlementCurrency: null,
    paymentFac: false,
    cashierColor: null,
    cashierTheme: null,
    ipWhitelists: [],
  },
  setMerchant: () => null,
};

export const useMerchantStore = create((set) => ({
  ...initialState,
  setMerchant: (values: Merchant) => {
    return set(({ merchant }) => ({ merchant: { ...merchant, ...values } }));
  },
}));
