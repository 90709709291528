import Card from "@mui/material/Card";

import { styled } from "@mui/material/styles";

export default styled(Card)(({ theme, ownerState }) => {
  return {
    height: "23rem",
    borderRadius: "0.5rem",
    borderColor: "#d2d6da",
    border: "0.0625rem solid #d2d6da",
    marginLeft: 2,
    marginBottom: "1rem",
  };
});
