import { useNavigate } from "react-router-dom";
import _ from "lodash";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import DataTable from "examples/Tables/DataTable";
import ActiveStatusChip from "components/ActiveStatusChip";

import { useGetPsps } from "./hooks";

const PspManagement = () => {
  const navigate = useNavigate();
  const { isLoading, data } = useGetPsps();

  const onCellClick = (row) => {
    navigate(`/psps/${row.original.id}`);
  };

  // create a function that returns the rows for the table
  const getRows = () => {
    return _.map(data, (item) => ({
      id: _.get(item, "_id"),
      name: item.name,
      minScore: item.minScore,
      maxScore: item.maxScore,
      active: <ActiveStatusChip status={item.active} size="small" />,
    }));
  };

  return (
    <MainLayout>
      <MainNavbar />
      <DataTable
        table={{
          columns: [
            { Header: "id", accessor: "id" },
            { Header: "name", accessor: "name" },
            { Header: "min score", accessor: "minScore" },
            { Header: "max score", accessor: "maxScore" },
            { Header: "status", accessor: "active" },
          ],
          rows: getRows(),
        }}
        isLoading={isLoading}
        onCellClick={onCellClick}
        onCellAuxClick={onCellClick}
        isClickable={true}
        canSearch={true}
      />
    </MainLayout>
  );
};

export default PspManagement;
