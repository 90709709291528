import SoftBox from "components/SoftBox";

import DataTable from "examples/Tables/DataTable";
import AddDomain from "./AddDomain";
import DeleteDomain from "./DeleteDomain";
import { useBrandStore } from "stores/brand";
import { Typography } from "@mui/material";
import React from "react";
const Domains = () => {
  const { brand: data } = useBrandStore();

  const getRows = () => {
    return _.map(_.get(data, "domains", []), (item) => ({
      domain: item,
      actions: (
        <SoftBox display="flex" gap={1}>
          <DeleteDomain domain={item} />
        </SoftBox>
      ),
    }));
  };

  return (
    <SoftBox>
      <SoftBox display="flex" justifyContent="space-between">
        <SoftBox></SoftBox>
        <SoftBox>
          <AddDomain />
        </SoftBox>
      </SoftBox>
      <SoftBox mt={2}>
        {data?.domains?.length ? (
          <DataTable
            table={{
              columns: [
                { Header: "Domain Name", accessor: "domain" },
                { Header: "Actions", accessor: "actions", align: "right" },
              ],
              rows: getRows(),
            }}
          />
        ) : (
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              my: 3,
              color: "#67748e",
            }}
          >
            There are no Domains
          </Typography>
        )}
      </SoftBox>
    </SoftBox>
  );
};

export default Domains;
