const compareNumber = (numA, numB, desc) => {
  let a = numA;
  let b = numB;
  if (Number.isNaN(a)) {
    // Blanks and non-numeric strings to bottom
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

export const compareFormattedNumber = (rowA, rowB, id, desc) => {
  let a = Number.parseFloat(rowA.values[id].replaceAll(",", ""));
  let b = Number.parseFloat(rowB.values[id].replaceAll(",", ""));
  return compareNumber(a, b, desc);
};

export const compareFormattedCurrency = (rowA, rowB, id, desc) => {
  let a = Number.parseFloat(rowA.values[id].replaceAll(",", "").replaceAll("$", ""));
  let b = Number.parseFloat(rowB.values[id].replaceAll(",", "").replaceAll("$", ""));
  return compareNumber(a, b, desc);
};
