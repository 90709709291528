import _ from "lodash";
import { useMutation } from "react-query";
import request from "utils/request";
import { getQueryParam } from "utils/string";

const RECONCILIATION = "/reconciliation";

const getReconciliation = (queries) => {
  const params = getQueryParam(_.pickBy(queries, _.identity));
  return request.get(`${RECONCILIATION}?${params}`);
};

export const useGetReconciliation = () => {
  return useMutation(getReconciliation);
};
