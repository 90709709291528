import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import ListAltIcon from "@mui/icons-material/ListAlt";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import SoftBox from "components/SoftBox";
import Profile from "./components/Profile";
import Brands from "./components/Brands";
import TabPanel, { a11yProps } from "components/TabPanel";
import { useGetMerchantInfo } from "./hooks";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const MerchantManagementDetail = () => {
  const query = useQuery();
  const tab = query.get("tab");
  const { id: merchantId } = useParams();
  const tabIndex = tab === "brands" ? 1 : tab === "users" ? 2 : 0;
  const [value, setValue] = useState(tabIndex);
  const navigate = useNavigate();
  useEffect(() => {
    tab === "brands" ? setValue(1) : tab === "users" ? setValue(2) : setValue(0);
  }, [tab]);
  const { data } = useGetMerchantInfo(merchantId);

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        navigate(`/merchants/${merchantId}?tab=profile`);
        break;
      case 1:
        navigate(`/merchants/${merchantId}?tab=brands`);
        break;
      default:
        break;
    }
    setValue(newValue);
  };

  return (
    <MainLayout>
      <MainNavbar
        customRoutes={[
          { label: "Merchants", route: "merchants" },
          { label: _.get(data, "name", merchantId), route: `merchants/${merchantId}` },
        ]}
      />

      <SoftBox sx={{ maxWidth: 400 }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ backgroundColor: "transparent" }}
            variant="standard"
          >
            <Tab icon={<ContactPageIcon />} label="Profile" {...a11yProps(0)} />
            <Tab icon={<ListAltIcon />} label="Brands" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
      </SoftBox>
      <TabPanel value={value} index={0}>
        <Profile />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Brands />
      </TabPanel>
    </MainLayout>
  );
};

export default MerchantManagementDetail;
