import React, { useEffect } from "react";
import _ from "lodash";
import DataTable from "../../../../examples/Tables/DataTable";
import { useNavigate, useParams } from "react-router-dom";

import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";
import { Card } from "@mui/material";
import SoftBox from "components/SoftBox";
import { useMutateGetMerchants } from "pages/merchant-management/hooks";

const OrganizationMerchants = () => {
  const navigate = useNavigate();
  const { id: organizationId } = useParams();
  if (!hasPermission(Permissions.MERCHANT.LIST)) {
    return <Error403 />;
  }
  // hooks
  const { mutate, data, isLoading } = useMutateGetMerchants();

  const getRows = () => {
    return _.map(data, (item) => ({
      id: item._id,
      name: item.name,
      legalName: item.legalName,
      website: item.website,
      companyPhone: item.companyPhone,
      companyEmail: item.companyEmail,
      companyAddress: item.companyAddress,
      country: item.country,
      currency: item.currency,
    }));
  };

  useEffect(() => {
    if (organizationId) {
      // fetch data
      mutate(organizationId);
    }
  }, [organizationId]);
  return (
    <Card sx={{ mt: 2 }}>
      <SoftBox p={3}>
        <DataTable
          table={{
            columns: [
              { Header: "name", accessor: "name" },
              { Header: "legalName", accessor: "legalName" },
              { Header: "website", accessor: "website" },
              { Header: "companyPhone", accessor: "companyPhone" },
              { Header: "companyEmail", accessor: "companyEmail" },
              { Header: "companyAddress", accessor: "companyAddress" },
              { Header: "country", accessor: "country" },
              { Header: "currency", accessor: "currency" },
            ],
            rows: getRows() || [],
          }}
          canSearch={true}
          columnsFilter={true}
          onCellClick={(row) => {
            navigate(`/merchants/${row?.original?.id}?tab=brands`);
          }}
          isClickable={true}
          isLoading={isLoading}
        />
      </SoftBox>
    </Card>
  );
};

export default OrganizationMerchants;
