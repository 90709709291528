import { useMutation } from "react-query";
import { getQueryParam } from "utils/string";
import { toastError } from "utils/toast";

const { default: request } = require("utils/request");

const USER_LOGS = "/requests";

const getUserLogs = async (filters) => {
  const queryParams = getQueryParam(filters);
  return request.get(`${USER_LOGS}?${queryParams}`);
};

export const useGetUserLogs = () => {
  return useMutation(getUserLogs, {
    onError: (error) => {
      const message = _.get(error, "response.data.message[0]");
      toastError({ description: message });
    },
  });
};
