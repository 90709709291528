import { useState } from "react";
import _ from "lodash";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadgeDot from "components/SoftBadgeDot";

import DataTable from "examples/Tables/DataTable";
import PropTypes from "prop-types";
import CreateSubOption from "./CreateSubOption";
import UpdateSubOption from "./UpdateSubOption";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import { useDisclosure } from "hooks/useDisclosure";
import { countries } from "constants/countries";

const SubOptions = ({ subOptions, isLoading }) => {
  const [selectedSubOption, setSelectedSubOption] = useState({});
  const hasUpdatePermission = hasPermission(Permissions.PSP_METHOD.UPDATE);
  const updateModalState = useDisclosure();
  const { onOpen } = updateModalState;

  const getRows = () => {
    return _.map(subOptions, (item) => {
      const country = countries.find((country) => country.code === item.country);

      return {
        id: item.id,
        name: item.name,
        externalId: item.externalId,
        active: <SoftBadgeDot color={item.active ? "success" : "light"} size="md" />,
        payin: <SoftBadgeDot color={item.payin ? "success" : "light"} size="md" />,
        payout: <SoftBadgeDot color={item.payout ? "success" : "light"} size="md" />,
        country: (
          <p>
            {item.country && (
              <p>
                <b>{item.country}</b> &nbsp;&nbsp;{country ? `(${country.label})` : ""}
              </p>
            )}
            {!item.country && ""}
          </p>
        ),
        currency: _.get(item, "currency", ""),
        min: _.get(item, "min", ""),
        max: _.get(item, "max", ""),
        payinHidden: item.payin ? "payin" : "",
        payoutHidden: item.payout ? "payout" : "",
        activeHidden: item.active ? "active" : "",
        countryHidden: _.get(item, "country", ""),
        action: <UpdateSubOption data={item} />,
      };
    });
  };
  const onCellClick = (row) => {
    if (hasUpdatePermission) {
      const id = row.original.id;
      const selected = subOptions.find((subOption) => subOption.id === id);
      setSelectedSubOption(selected);
      onOpen();
    }
  };
  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox pt={2} px={2}>
        <Stack spacing={1} direction="row" justifyContent="space-between">
          <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            SubOptions
          </SoftTypography>
        </Stack>
      </SoftBox>

      <SoftBox p={2}>
        {hasPermission(Permissions.PSP_METHOD.CREATE) && (
          <SoftBox display="flex" justifyContent="flex-end">
            <CreateSubOption />
          </SoftBox>
        )}

        <DataTable
          table={{
            columns: [
              { Header: "ID", accessor: "id" },
              { Header: "Name", accessor: "name" },
              { Header: "External ID", accessor: "externalId" },
              { Header: "Active", accessor: "active" },
              { Header: "PayIn", accessor: "payin" },
              { Header: "PayOut", accessor: "payout" },
              { Header: "Country", accessor: "country" },
              { Header: "Currency", accessor: "currency" },
              { Header: "Min", accessor: "min" },
              { Header: "Max", accessor: "max" },
              { Header: "PayInHidden", accessor: "payinHidden", hide: true },
              { Header: "PayoutHidden", accessor: "payoutHidden", hide: true },
              { Header: "ActiveHidden", accessor: "activeHidden", hide: true },
              { Header: "CountryHidden", accessor: "countryHidden", hide: true },
            ],
            rows: getRows(),
          }}
          entriesPerPage={{
            defaultValue: 25,
            entries: [25, 50, 200, 500],
          }}
          canSearch={true}
          columnsFilter={false}
          isLoading={isLoading}
          onCellAuxClick={onCellClick}
          onCellClick={onCellClick}
          isClickable={hasUpdatePermission}
        />
      </SoftBox>
      <UpdateSubOption data={selectedSubOption} modalState={updateModalState} />
    </Card>
  );
};
SubOptions.propTypes = {
  subOptions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};
export default SubOptions;
