import colors from "assets/theme/base/colors";

const { dark } = colors;

const formLabel = {
  styleOverrides: {
    root: {
      color: dark.main,
      fontSize: "16px",
      top: "-8px!important",
    },
  },
};

export default formLabel;
