import _ from "lodash";
import { useQuery, useMutation, useQueryClient } from "react-query";
import request from "utils/request";
import { toastError, toastSuccess } from "utils/toast";
import { getQueryParam, getTemplatePath } from "utils/string";

const GET_WHITE_LIST = "/cards/whitelist";
const DELETE_WHITE_LIST = "/cards/whitelist/:id";
const ADD_CARD_TO_WHITE_LIST = "/cards/whitelist";
const GET_BLACK_LIST = "/cards/blacklist";
const ADD_CARD_TO_BLACK_LIST = "/cards/blacklist";
const DELETE_BLACK_LIST = "/cards/blacklist/:id";
const ADD_CSV_TO_BLACK_LIST = "/cards/blacklist/csv";
const ADD_CSV_TO_WHITE_LIST = "/cards/whitelist/csv";
const ADD_TRANSACTION_ID_TO_BLACK_LIST = "/cards/blacklist/tx";

// get whitelist
const getFilterWhitelist = async (filterWhitelistProps) => {
  const filteredPayload = _.pickBy(filterWhitelistProps, _.identity);
  const queryParams = getQueryParam(filteredPayload);
  return request.get(`${GET_WHITE_LIST}?${queryParams}`);
};
export const useGetFilterWhitelist = () => {
  return useMutation(getFilterWhitelist, {
    onError: (error) => {
      const message = Array.isArray(error.response.data.message)
        ? _.get(error, "response.data.message[0]")
        : error.response.data.message;
      toastError({ description: message });
    },
  });
};

//get blacklist
const getFilterBlacklist = async (filterBlacklistProps) => {
  const filteredPayload = _.pickBy(filterBlacklistProps, _.identity);
  const queryParams = getQueryParam(filteredPayload);
  return request.get(`${GET_BLACK_LIST}?${queryParams}`);
};
export const useGetFilterBlacklist = () => {
  return useMutation(getFilterBlacklist, {
    onError: (error) => {
      const message = _.get(error, "response.data.message[0]");
      toastError({ description: message });
    },
  });
};

// add Card To Whitelist
const addCardToWhitelist = ({ payload }) => {
  return request.post(ADD_CARD_TO_WHITE_LIST, payload);
};
export const useAddCardToWhitelist = () => {
  const queryClient = useQueryClient();
  return useMutation(addCardToWhitelist, {
    onSuccess: () => {
      toastSuccess({ description: "Card added successfully" });
      queryClient.invalidateQueries([GET_WHITE_LIST]);
    },
  });
};

// add Card To Blacklist
const addCardToBlacklist = ({ payload }) => {
  return request.post(ADD_CARD_TO_BLACK_LIST, payload);
};
export const useAddCardToBlacklist = () => {
  const queryClient = useQueryClient();
  return useMutation(addCardToBlacklist, {
    onSuccess: () => {
      toastSuccess({ description: "Card added successfully" });
      queryClient.invalidateQueries([GET_BLACK_LIST]);
    },
  });
};

// add transactionId To Blacklist
const addTransactionIdToBlacklist = ({ payload }) => {
  return request.post(ADD_TRANSACTION_ID_TO_BLACK_LIST, payload);
};
export const useAddTransactionIdToBlacklist = () => {
  const queryClient = useQueryClient();
  return useMutation(addTransactionIdToBlacklist, {
    onSuccess: () => {
      toastSuccess({ description: "Card added successfully" });
      queryClient.invalidateQueries([GET_BLACK_LIST]);
    },
  });
};

// add CSV To Blacklist
const addCSVToBlacklist = ({ payload }) => {
  return request.post(ADD_CSV_TO_BLACK_LIST, payload);
};
export const useAddCSVToBlacklist = () => {
  const queryClient = useQueryClient();
  return useMutation(addCSVToBlacklist, {
    onSuccess: (res) => {
      toastSuccess({ description: res });
      queryClient.invalidateQueries([GET_BLACK_LIST]);
    },
  });
};
// add CSV To Whitelist
const addCSVToWhitelist = ({ payload }) => {
  return request.post(ADD_CSV_TO_WHITE_LIST, payload);
};
export const useAddCSVToWhitelist = () => {
  const queryClient = useQueryClient();
  return useMutation(addCSVToWhitelist, {
    onSuccess: (res) => {
      toastSuccess({ description: res });
      queryClient.invalidateQueries([GET_WHITE_LIST]);
    },
  });
};
// Delete Card From Whitelist
const deleteCardFromWhitelist = ({ id }) => {
  return request.delete(getTemplatePath(DELETE_WHITE_LIST, { id }));
};
export const useDeleteCardFromWhitelist = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteCardFromWhitelist, {
    onSuccess: () => {
      toastSuccess({ description: "Card deleted successfully" });
      queryClient.invalidateQueries([GET_WHITE_LIST]);
    },
  });
};

// Delete Card From Blacklist
const deleteCardFromBlacklist = ({ id }) => {
  return request.delete(getTemplatePath(DELETE_BLACK_LIST, { id }));
};
export const useDeleteCardFromBlacklist = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteCardFromBlacklist, {
    onSuccess: () => {
      toastSuccess({ description: "Card deleted successfully" });
      queryClient.invalidateQueries([GET_BLACK_LIST]);
    },
  });
};
