import _ from "lodash";
import { useMutation, useQuery } from "react-query";
import request from "utils/request";
import { getQueryParam, getTemplatePath } from "utils/string";

const TRANSACTIONS = "/reconciliation/:id/transactions";
const RECONCILIATION = "/reconciliation/:id";

const getTransactions = (queries) => {
  const URL = getTemplatePath(TRANSACTIONS, { id: queries.id });
  const params = getQueryParam(_.pickBy(queries, _.identity));
  return request.get(`${URL}?${params}`);
};

export const useGetTransactions = () => {
  return useMutation(getTransactions);
};

const deleteReconciliation = (id) => {
  const URL = getTemplatePath(RECONCILIATION, { id });
  return request.delete(URL);
};
export const useDeleteReconciliation = () => {
  return useMutation(deleteReconciliation);
};

const getReconciliation = (id) => {
  const URL = getTemplatePath(RECONCILIATION, { id });
  return request.get(URL);
};

export const useGetReconciliation = (id) => {
  return useQuery([RECONCILIATION, id], () => getReconciliation(id), {
    enabled: !!id,
  });
};
