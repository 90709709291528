import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { Dialog, DialogTitle, Icon, DialogContent } from "@mui/material";

import { useDeleteVirtualAccount } from "pages/brand-detail/hooks";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

const DeleteVirtualAccount = ({ id }) => {
  const { id: merchantId, brandId } = useParams();
  const { mutate, isLoading } = useDeleteVirtualAccount({ merchantId, brandId, id });
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  const handleDelete = () => {
    mutate(
      { merchantId, brandId, id },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  return (
    <>
      <SoftBox mr={1}>
        <SoftButton variant="text" color="error" onClick={handleOpen}>
          <Icon>delete</Icon>&nbsp;delete
        </SoftButton>
      </SoftBox>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xs">
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <SoftBox p={3} minWidth="300px">
            <SoftTypography variant="h6" textAlign="center">
              Are you sure want to delete Virtual Account {id}?
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" gap={2} justifyContent="center">
            <SoftButton
              variant="gradient"
              color="error"
              onClick={handleDelete}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Delete
            </SoftButton>
            <SoftButton type="submit" onClick={handleClose}>
              Cancel
            </SoftButton>
          </SoftBox>
        </DialogContent>
      </Dialog>
    </>
  );
};

DeleteVirtualAccount.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DeleteVirtualAccount;
