import { Chip } from "@mui/material";
import { forwardRef } from "react";
import PropTypes from "prop-types";
import { StatusColorEnum } from "constants/status";
import Tooltip from "@mui/material/Tooltip";

const StatusChip = forwardRef(({ label, tooltip, colorEnum, ...rest }, ref) => {
  let color = StatusColorEnum[label];
  if (!_.isEmpty(colorEnum)) {
    color = colorEnum[label];
  }
  return tooltip ? (
    <Tooltip title={tooltip} placement="bottom" sx={{ cursor: "help" }}>
      <Chip label={label} {...rest} ref={ref} color={color} />
    </Tooltip>
  ) : (
    <Chip label={label} {...rest} ref={ref} color={color} />
  );
});
// Setting default values for the props of SoftPagination
StatusChip.defaultProps = {
  label: "",
  tooltip: "",
  colorEnum: {},
};

// Typechecking props for the SoftPagination
StatusChip.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.string,
  colorEnum: PropTypes.object,
};
export default StatusChip;
