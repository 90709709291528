import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import SoftSelect from "components/SoftSelect";

import { get, set } from "utils/localStorage";

const LC_SELECTED_METHOD = "selectedPaymentMethod";

const PaymentOptionSelect = ({ onChange, data }) => {
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const localSelectedMethod = get(LC_SELECTED_METHOD);

  const onPaymentOptionChange = (value) => {
    setSelectedOption(value);
    const _paymentOption = _.find(data, { _id: value.value });
    onChange(_paymentOption);
    set(LC_SELECTED_METHOD, _paymentOption);
  };

  useEffect(() => {
    if (data) {
      const _paymentOptions = _.map(data, (p) => ({ label: p.paymentOption, value: p._id }));
      const _lcpaymentOptions = _.find(data, { _id: localSelectedMethod?._id });
      if (localSelectedMethod != null && _lcpaymentOptions && _paymentOptions) {
        setPaymentOptions(_paymentOptions);
        setSelectedOption({ value: _lcpaymentOptions._id, label: _lcpaymentOptions.paymentOption });
        onChange(_lcpaymentOptions);
      } else {
        setPaymentOptions(_paymentOptions);
        setSelectedOption(_.get(_paymentOptions, "[0]", null));
        onChange(_.get(data, "[0]", null));
        set(LC_SELECTED_METHOD, _.get(data, "[0]", null));
      }
    }
  }, [data]);

  return (
    <SoftSelect
      name="paymentOptionId"
      label="Payment Option"
      options={paymentOptions}
      placeholder="Select payment option"
      value={selectedOption}
      onChange={onPaymentOptionChange}
    />
  );
};

PaymentOptionSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};
export default PaymentOptionSelect;
