import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Card, DialogContent, Grid, Icon } from "@mui/material";

import { useForm } from "react-hook-form";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import FormProvider from "components/FormProviders";
import SoftButton from "components/SoftButton";
import { SwitchField, TextInputField } from "components/FormField";

import DeletePspMid from "./DeletePspMid";
import borders from "assets/theme/base/borders";

import { useGetPspMid, useEditPspMid } from "pages/brand-detail/hooks";
import PaymentMethod from "./PaymentMethod";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { a11yProps } from "../../../../../components/TabPanel";
import VirtualAccounts from "./VirtualAccounts";
import RowOrderingGrid from "../../../../psp-mid-details/components/RowOrderingGrid";
import CreateFallback from "../../../../psp-mid-details/components/CreateFallback";
import { pspMidDetail } from "pages/brand-detail/schemas/pspMid";
import { yupResolver } from "@hookform/resolvers/yup";

const PspMidDetail = ({ pspMidId, onBackBtnClick, midName }) => {
  const [tab, setTab] = useState(0);
  const { borderWidth, borderColor } = borders;
  const { id: merchantId, brandId } = useParams();

  const { data: pspMid } = useGetPspMid({ merchantId, brandId, pspMidId });
  const [fallbackItems, setFallbacksItems] = useState([]);
  const { mutate, isLoading } = useEditPspMid();
  useEffect(() => {
    setFallbacksItems(pspMid?.fallbacks);
  }, [pspMid]);
  // hooks form
  const methods = useForm({
    defaultValues: { payinEnabled: false, payoutEnabled: false, weight: null },
    resolver: yupResolver(pspMidDetail),
  });
  const { handleSubmit, reset } = methods;

  const handleSubmitForm = ({ payinEnabled, payoutEnabled, weight }) => {
    mutate({
      brandId,
      merchantId,
      pspMidId,
      payinEnabled,
      payoutEnabled,
      weight,
      fallbacks: fallbackItems,
    });
  };

  // set form values
  useEffect(() => {
    reset(pspMid);
  }, [pspMid]);

  //tab handle
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <SoftBox>
      <Card>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" py={2} px={3}>
          <SoftButton
            variant="outlined"
            color="dark"
            sx={{ border: "none", "&:hover": { border: "none" } }}
            onClick={onBackBtnClick}
          >
            <Icon>arrow_back</Icon> &nbsp;&nbsp;&nbsp;BACK
          </SoftButton>
        </SoftBox>
      </Card>

      <Card sx={{ mt: 2 }}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{ xs: "column", sm: "row" }}
          borderBottom={`${borderWidth[1]} solid ${borderColor}`}
          py={2}
          px={3}
        >
          {/* <SoftAvatar src={team4} alt="profile-image" variant="rounded" /> */}
          <SoftBox mx={2} lineHeight={1}>
            <SoftTypography component="a" href="#" variant="button" fontWeight="regular">
              Psp Mid Detail
            </SoftTypography>
            {midName && (
              <SoftTypography component="div" variant="button" color="text" fontWeight="regular">
                Name: {midName}
              </SoftTypography>
            )}
            <SoftTypography component="div" variant="button" color="text" fontWeight="regular">
              ID: {_.get(pspMid, "pspMidId", "")}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox p={1}>
          <SoftBox mb={1}>
            <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
              <DialogContent>
                <SoftBox p={2}>
                  <Grid item xs={2}>
                    <TextInputField name="weight" label="Weight" sx={{ maxWidth: "300px" }} />
                  </Grid>
                  <Grid item xs={6}>
                    <SoftBox display="flex" gap={2} sx={{ ml: 2 }}>
                      <SwitchField label="Payin Enabled" name="payinEnabled" />
                      <SwitchField label="Payout Enabled" name="payoutEnabled" />
                    </SoftBox>
                  </Grid>

                  <SoftBox display="flex" justifyContent="flex-start" gap={2} mt={4}>
                    <SoftButton
                      variant="gradient"
                      color="info"
                      type="submit"
                      disabled={isLoading}
                      isLoading={isLoading}
                    >
                      Update
                    </SoftButton>
                    <DeletePspMid pspMidId={pspMidId} onSuccess={onBackBtnClick} />
                  </SoftBox>
                </SoftBox>
              </DialogContent>
            </FormProvider>
          </SoftBox>
        </SoftBox>
      </Card>
      {useMemo(
        () =>
          pspMid && (
            <Card sx={{ mt: 2 }}>
              <SoftBox display="flex" justifyContent="end" m={2}>
                <SoftBox>
                  <CreateFallback
                    pspMidInfo={pspMid}
                    fallbackItems={fallbackItems}
                    setFallbacksItems={setFallbacksItems}
                  />
                </SoftBox>
              </SoftBox>
              <RowOrderingGrid
                pspMid={pspMid}
                fallbackItems={fallbackItems}
                setFallbacksItems={setFallbacksItems}
              />
            </Card>
          ),
        [pspMid, fallbackItems]
      )}
      <Grid>
        {pspMid?.pspId === "0ea3bc80-eae0-4567-89fb-64ac7faee7f8" && (
          <SoftBox sx={{ maxWidth: 400, marginTop: 2, marginBottom: 2 }}>
            <AppBar position="relative">
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                sx={{ backgroundColor: "transparent" }}
                variant="standard"
              >
                <Tab
                  icon={<TableRowsIcon />}
                  label="Payment methods"
                  {...a11yProps(0)}
                  sx={{ marginRight: 2 }}
                />
                <Tab icon={<TableRowsIcon />} label="Virtual Accounts" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
          </SoftBox>
        )}
      </Grid>

      {tab === 0 && <PaymentMethod pspMidId={pspMidId} />}
      {tab === 1 && <VirtualAccounts pspMidId={_.get(pspMid, "pspMidId", "")} />}
    </SoftBox>
  );
};

PspMidDetail.propTypes = {
  midName: PropTypes.string.isRequired,
  pspMidId: PropTypes.string.isRequired,
  onBackBtnClick: PropTypes.func.isRequired,
};
export default PspMidDetail;
