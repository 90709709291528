import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";

import React, { useEffect, useMemo, useState } from "react";

import { CircularProgress, DialogContent, Grid, DialogActions, Card } from "@mui/material";
import { useForm, useFieldArray } from "react-hook-form";

import SoftButton from "components/SoftButton";
import FormProvider from "components/FormProviders";
import SoftBox from "components/SoftBox";
import { useCreateReconciliation, useGetSheets } from "./hooks";

import { yupResolver } from "@hookform/resolvers/yup";
import { schema, defaultValues, sanitizeRequest } from "./schemas";
import { DatePickerField, MultipleSelectField, SelectInputField } from "components/FormField";
import { generateOptions } from "utils/options";
import { dateTimePickerOptions } from "constants/dateFilter";
import Sheet from "./components/Sheet";
import CollapseWrapper from "components/FormField/CollapseWrapper";
import _ from "lodash";
import { toastError } from "utils/toast";
import Dropzone from "react-dropzone";
import SoftDropzoneRoot from "components/SoftDropzone/SoftDropzoneRoot";
import SoftTypography from "components/SoftTypography";
import Icon from "@mui/material/Icon";
import { shortText } from "utils/string";
import { useGetTemplates, useMutateGetTemplate } from "pages/reconciliation-templates/hooks";
import { useGetPsps } from "pages/psp-management/hooks";
import { useNavigate } from "react-router-dom";

const NewReconciliation = () => {
  const navigate = useNavigate();
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [sheetOptions, setSheetOptions] = useState([]);

  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = methods;
  const { selectedSheets: selectedSheetsError } = errors;
  const templateId = watch("templateId");

  // hooks
  const { data: templates } = useGetTemplates();
  const { mutate: getTemplate } = useMutateGetTemplate();
  const { data: psps } = useGetPsps();

  const templateOptions = useMemo(() => {
    if (templates) {
      return generateOptions(templates, false);
    } else {
      return [];
    }
  }, [templates]);

  const {
    fields: sheets,
    append: appendSheet,
    remove: removeSheet,
  } = useFieldArray({
    control,
    name: "selectedSheets",
  });

  // options
  const pspOptions = useMemo(() => {
    if (psps) {
      return generateOptions(psps, false);
    } else {
      return [];
    }
  }, [psps]);

  //hooks
  const { isLoading, mutate } = useCreateReconciliation();
  const { mutate: getSheets, isLoading: isGettingSheets } = useGetSheets();

  const handleClose = () => {
    navigate("/reconciliation");
  };

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    if (uploadedFiles) {
      const formData = new FormData();
      formData.append("file", uploadedFiles);
      formData.append("jsonPayload", JSON.stringify(sanitizeRequest(values)));
      mutate(
        { formData },
        {
          onSuccess: () => {
            handleClose();
          },
        }
      );
    } else {
      toastError({ description: "File is required" });
    }
  };
  const onFileUpload = (files) => {
    if (files && files.length > 0) {
      setUploadedFiles(files[0]);
    }
  };
  useEffect(() => {
    appendSheet({
      headerLine: 1,
      startFrom: 2,
      fieldsOfInterest: [],
      statusMap: [],
      type: "PAYIN",
    });
  }, []);

  useEffect(() => {
    if (uploadedFiles) {
      const formData = new FormData();
      formData.append("file", uploadedFiles);
      getSheets(
        { formData },
        {
          onSuccess: (data) => {
            setSheetOptions(
              _.map(data.sheets, (sheet) => ({
                label: sheet,
                value: sheet,
              }))
            );
          },
        }
      );
    }
  }, [uploadedFiles]);

  useEffect(() => {
    if (templateId) {
      getTemplate(templateId, {
        onSuccess: (data) => {
          const sheets = _.get(data, "selectedSheets", []);
          const _sheetOptions = _.uniqBy(sheets, "name").map((sheet) => ({
            label: sheet.name,
            value: sheet.name,
          }));
          setSheetOptions(_sheetOptions);
          reset(data);
        },
      });
    }
  }, [templateId]);
  return (
    <MainLayout>
      <MainNavbar />
      <Card>
        <SoftBox p={3}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Submit Reconciliation File
            </SoftTypography>
            <SoftBox mt={4}>
              <Grid container spacing={2} rowSpacing={1}>
                <Grid item xs={6}>
                  <SelectInputField
                    menuPosition={"fixed"}
                    name="templateId"
                    label="Template"
                    options={templateOptions}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MultipleSelectField
                    menuPosition={"fixed"}
                    name="pspIds"
                    label="Psps"
                    options={pspOptions}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePickerField
                    name="startDatetime"
                    label="Start Date"
                    options={dateTimePickerOptions()}
                    helperText="Process the transactions from this line"
                  />
                </Grid>
                <Grid item xs={6} sx={{ mt: 0.65 }}>
                  <DatePickerField
                    name="endDatetime"
                    label="End Date"
                    options={dateTimePickerOptions()}
                  />
                </Grid>
              </Grid>
              {_.map(sheets, (field, index) => (
                <CollapseWrapper title={`Sheet ${index + 1}`} key={field.id} openDefault={true}>
                  <SoftBox>
                    <Sheet
                      nestedIndex={index}
                      removeSheet={removeSheet}
                      sheetOptions={sheetOptions}
                    />
                  </SoftBox>
                </CollapseWrapper>
              ))}
              <SoftButton
                sx={{ mt: 2 }}
                onClick={() => appendSheet({ type: "PAYIN", headerLine: 1, startFrom: 2 })}
              >
                Add Sheet
              </SoftButton>
              {selectedSheetsError && (
                <SoftBox mt={0.75}>
                  <SoftTypography component="div" variant="caption" color="error">
                    {selectedSheetsError?.message}
                  </SoftTypography>
                </SoftBox>
              )}
              <SoftBox mt={2}>
                <Dropzone onDrop={(acceptedFiles) => onFileUpload(acceptedFiles)} maxFiles={1}>
                  {({ getRootProps, getInputProps }) => (
                    <SoftDropzoneRoot
                      {...getRootProps()}
                      sx={{ height: "5rem", cursor: "pointer" }}
                      display="flex"
                      flexDirection={"column"}
                      justifyContent={"center"}
                    >
                      {uploadedFiles && (
                        <SoftTypography variant="caption2">
                          {shortText(uploadedFiles.name, 30)}
                        </SoftTypography>
                      )}
                      {!uploadedFiles && (
                        <>
                          <Icon fontSize="large">upload</Icon>
                          <SoftTypography variant="caption">Drag document file here</SoftTypography>
                        </>
                      )}

                      <SoftBox
                        component="input"
                        name="file"
                        type="file"
                        multiple
                        {...getInputProps()}
                      />
                    </SoftDropzoneRoot>
                  )}
                </Dropzone>
              </SoftBox>
            </SoftBox>
            <DialogActions>
              <SoftButton variant="gradient" onClick={handleClose}>
                Cancel
              </SoftButton>

              <SoftButton
                variant="gradient"
                color="info"
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
              >
                Create
              </SoftButton>
            </DialogActions>
          </FormProvider>
          {isGettingSheets && (
            <SoftBox
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "#000",
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                opacity: 0.9,
                flexDirection: "column",
                gap: 2,
              }}
            >
              <SoftTypography variant="h6" sx={{ color: "#ffffff" }}>
                Loading Sheets...
              </SoftTypography>
              <CircularProgress sx={{ color: "#ffffff" }} />
            </SoftBox>
          )}
        </SoftBox>
      </Card>
    </MainLayout>
  );
};

export default NewReconciliation;
