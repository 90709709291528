import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Card, Stack } from "@mui/material";

import SoftBox from "components/SoftBox";
import { useForm } from "react-hook-form";

import SoftButton from "components/SoftButton";
import FormProvider from "components/FormProviders";
import BrandForm from "../../../merchant-management-detail/components/Brands/BrandForm";
import { useEditBrand } from "pages/brand-detail/hooks";
import { useBrandStore } from "stores/brand";

import { defaultValues, schema } from "pages/merchant-management-detail/schemas/brand";
import { yupResolver } from "@hookform/resolvers/yup";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";
import { DeleteModal } from "components/Modal";
import { useDeleteBrand } from "pages/merchant-management-detail/hooks";

const BrandOverview = () => {
  const { id: merchantId, brandId } = useParams();
  if (!hasPermission(Permissions.BRAND.GET)) {
    return <Error403 />;
  }

  const navigate = useNavigate();

  const { brand: data } = useBrandStore();
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset } = methods;

  // hooks
  const { mutateAsync: deleteBrand, isLoading: isDeleting } = useDeleteBrand();
  const { mutate, isLoading } = useEditBrand({ merchantId, brandId });

  const handleSubmitForm = (values) => {
    mutate(
      { merchantId, brandId: data._id, ...values },
      {
        onSuccess: () => {
          reset(values);
        },
      }
    );
  };

  useEffect(() => {
    reset(data);
  }, [data?.name]);

  return (
    <Card sx={{ p: 2 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
        <SoftBox py={2}>
          <BrandForm signKey={data?.signKey} />
          <SoftBox display="flex" justifyContent="flex-start" gap={2} mt={4}>
            <SoftBox pt={2} px={2}>
              <Stack spacing={1} direction="row" justifyContent="flex-end">
                <SoftButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Update
                </SoftButton>
              </Stack>
            </SoftBox>

            <DeleteModal
              type="button"
              title="Delete Brand"
              message={
                <>
                  Are you sure want to delete this brand <b>{data?.name}</b>?
                </>
              }
              isLoading={isDeleting}
              onDelete={async () => {
                await deleteBrand({ merchantId, brandId });
              }}
              onClose={() => {
                navigate(`/merchants/${merchantId}?tab=brands`);
              }}
            />
          </SoftBox>
        </SoftBox>
      </FormProvider>
    </Card>
  );
};

export default BrandOverview;
