import React, { useState } from "react";

import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useForm } from "react-hook-form";

import SoftButton from "components/SoftButton";
import FormProvider from "components/FormProviders";
import SoftBox from "components/SoftBox";
import MerchantForm, { sanitizeRequest } from "./MerchantForm";
import { useCreateMerchant } from "../hooks";

import { yupResolver } from "@hookform/resolvers/yup";
import { schema, defaultValues } from "../schemas/merchant";
import ButtonSet from "../../../components/ButtonSet";
import PropTypes from "prop-types";

const AddMerchantModal = ({ onClose }) => {
  const [isOpenAddMerchantModal, setIsOpenAddMerchantModal] = useState(false);
  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit } = methods;

  //hooks
  const { isLoading, mutate } = useCreateMerchant();

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    mutate(sanitizeRequest({ ...values }), {
      onSuccess: () => {
        onClose();
        handleCloseAddMerchantModal();
      },
    });
  };
  const handleOpenAddMerchantModal = () => {
    setIsOpenAddMerchantModal(true);
  };
  const handleCloseAddMerchantModal = () => {
    setIsOpenAddMerchantModal(false);
  };

  return (
    <>
      <SoftBox pt={2} px={2} mb={3}>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleOpenAddMerchantModal}
          >
            Create Merchant
          </SoftButton>
        </Stack>
      </SoftBox>
      <Dialog
        open={isOpenAddMerchantModal}
        onClose={handleCloseAddMerchantModal}
        fullWidth
        maxWidth="lg"
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Create New Merchant
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3}>
              <MerchantForm />
            </SoftBox>
          </DialogContent>
          <ButtonSet
            onClose={handleCloseAddMerchantModal}
            isLoading={isLoading}
            title={"Create Merchant"}
          />
        </FormProvider>
      </Dialog>
    </>
  );
};
AddMerchantModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default AddMerchantModal;
