import styled from "@emotion/styled";
import { Dialog, DialogContent, DialogTitle, Stack, Grid, IconButton, Icon } from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { TextInputField } from "components/FormField";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { useDisclosure } from "hooks/useDisclosure";
import FormProvider from "components/FormProviders";
import { useEditBrand } from "pages/brand-detail/hooks";
import { useBrandStore } from "stores/brand";

const sanitizeRequest = ({ merchantId, domains, brandId, brandData }) => {
  return {
    ...brandData,
    merchantId,
    domains: _.concat(_.get(brandData, "domains", []), domains),
    brandId,
  };
};
const AddDomain = () => {
  const { id: merchantId, brandId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { brand: brandData } = useBrandStore();

  //form
  const methods = useForm();
  const { handleSubmit, control, reset, register, setValue } = methods;
  const { fields: domains, append, remove } = useFieldArray({ control, name: "domains" });

  //hooks
  const { mutate, isLoading } = useEditBrand({ merchantId, brandId });

  useEffect(() => {
    append("");
  }, []);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSubmitForm = ({ domains }) => {
    mutate(
      sanitizeRequest({
        domains,
        merchantId,
        brandId,
        brandData,
      }),
      {
        onSuccess: async () => {
          await sleep(2000);
          setValue("domains", [""]);
          handleClose();
        },
      }
    );
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <>
      <SoftButton variant="gradient" color="info" onClick={onOpen}>
        Add New Domain
      </SoftButton>
      <StyleDialog
        open={isOpen}
        onClose={handleClose}
        sx={{ overflow: "visible" }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Add New Domain</DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
            <SoftBox py={2}>
              {_.map(domains, (field, index) => (
                <Grid container key={field.id} spacing={2}>
                  <Grid item xs={10.5}>
                    <TextInputField
                      required
                      label={`domain ${index + 1}`}
                      name={`domains[${index}]`}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <IconButton onClick={() => remove(index)} sx={{ mt: 2 }}>
                      <Icon>delete_forever</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <SoftButton sx={{ mt: 2 }} onClick={() => append("")}>
                Add domain
              </SoftButton>
              <Stack direction="row" spacing={1} justifyContent="center" mt={4}>
                <SoftButton onClick={handleClose}>Cancel</SoftButton>
                <SoftButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Add Domains
                </SoftButton>
              </Stack>
            </SoftBox>
          </FormProvider>
        </DialogContent>
      </StyleDialog>
    </>
  );
};

const StyleDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    overflowY: "visible",
  },
  "& .MuiDialogTitle-root": {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
}));

export default AddDomain;
