import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import { useEditPspMid } from "pages/brand-detail/hooks";
import { useGetPsps } from "pages/psp-management/hooks";
import { SelectInputField } from "components/FormField";
import { useGetPspMid } from "pages/psp-details/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "components/FormProviders";
import { useDisclosure } from "hooks/useDisclosure";
import SoftButton from "components/SoftButton";
import {
  assignMidDefaultValues as defaultValues,
  assignMidSchema,
} from "pages/brand-detail/schemas/pspMid";
import SoftBox from "components/SoftBox";

const CreateFallback = ({ pspMidInfo, fallbackItems, setFallbacksItems }) => {
  const { id: merchantId, brandId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();

  // form
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(assignMidSchema),
  });
  const { handleSubmit, watch, reset } = methods;

  const psp = watch("psp");

  // hooks
  const { data: psps } = useGetPsps();
  const { data: pspMids } = useGetPspMid(psp);
  const { mutate, isLoading } = useEditPspMid();

  // function
  const handleSubmitForm = ({ pspMid, psp }) => {
    mutate(
      {
        merchantId,
        brandId,
        pspMidId: pspMidInfo._id,
        payinEnabled: pspMidInfo.payinEnabled,
        payoutEnabled: pspMidInfo.payoutEnabled,
        fallbacks: [...fallbackItems, { pspMidId: pspMid, pspId: psp }],
      },
      {
        onSuccess: (res) => {
          setFallbacksItems(res?.fallbacks);
          handleClose();
        },
      }
    );
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <>
      <SoftButton variant="gradient" color="info" onClick={onOpen}>
        Create Fallback
      </SoftButton>
      <StyleDialog
        open={isOpen}
        onClose={handleClose}
        sx={{ overflow: "visible" }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Create Fallback</DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
            <SoftBox py={2}>
              <Stack spacing={1}>
                <SelectInputField
                  name="psp"
                  label="Psp"
                  options={_.map(psps, (item) => ({ label: item.name, value: item._id }))}
                  isClearable
                  placeholder="Select an option"
                />
                <SelectInputField
                  name="pspMid"
                  label="Psp mid"
                  options={_.map(pspMids, (item) => ({ label: item.name, value: item._id }))}
                  isClearable
                  placeholder="Select an option"
                />
              </Stack>
              <Stack direction="row" spacing={1} justifyContent="center" mt={4}>
                <SoftButton onClick={handleClose}>Cancel</SoftButton>
                <SoftButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Create
                </SoftButton>
              </Stack>
            </SoftBox>
          </FormProvider>
        </DialogContent>
      </StyleDialog>
    </>
  );
};

const StyleDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    overflowY: "visible",
  },
  "& .MuiDialogTitle-root": {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
}));

CreateFallback.propTypes = {
  pspMidInfo: PropTypes.object,
  fallbackItems: PropTypes.array,
  setFallbacksItems: PropTypes.func,
};

export default CreateFallback;
