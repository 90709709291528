import { useQueryClient } from "react-query";
import React, { useEffect, useState } from "react";
import { Card, Grid } from "@mui/material";
import {
  TextInputField,
  SelectInputField,
  DatePickerField,
  PaymentOptionSelect,
  CurrencySelect,
  TimeRangeInput,
  SwitchField,
} from "components/FormField";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { useFormContext } from "react-hook-form";
import { GET_BRANDS } from "pages/merchant-management-detail/hooks";
import { categoryOptions, statusOptions, typeOptions } from "constants/status";
import PropTypes from "prop-types";
import SortedCountrySelect from "../../../components/SortedCountrySelect";
import { set } from "utils/localStorage";
import { defaultValues } from "../schemas/index";
import { dateTimePickerOptions } from "constants/dateFilter";
import { generateOptions } from "utils/options";

const LC_PAYLOAD = "errorSummaryPayload";

const TransactionFilters = (props) => {
  const { onClearFilters, paymentOptions, pspsData, merchants, brands } = props;
  const [paymentOptionsData, setPaymentOptionsData] = useState([]);
  const [pspOptionsData, setPspsData] = useState([]);
  const [brandOptionsData, setBrandOptionsData] = useState([]);
  const [merchantOptionsData, setMerchantOptionsData] = useState([]);

  const { watch, setValue, reset } = useFormContext();
  const queryClient = useQueryClient();

  const watchPaymentOption = watch("paymentOptionId", null);
  const watchPsp = watch("pspId", null);

  useEffect(() => {
    if (pspsData) setPspsData(generateOptions(pspsData));
  }, [pspsData]);
  useEffect(() => {
    if (paymentOptions) setPaymentOptionsData(paymentOptions);
  }, [paymentOptions]);
  useEffect(() => {
    if (merchants) setMerchantOptionsData(generateOptions(merchants));
  }, [merchants]);
  useEffect(() => {
    if (brands) setBrandOptionsData(generateOptions(brands));
  }, [brands]);

  useEffect(() => {
    const paymentOption = paymentOptionsData?.find((i) => i.id === watchPaymentOption)?.name;
    setValue("paymentOptionName", paymentOption);
    if (watchPaymentOption === "") {
      setValue("paymentOptionName", "");
      setValue("paymentOptionId", "");
    }
  }, [watchPaymentOption, paymentOptionsData]);
  useEffect(() => {
    const psp = pspOptionsData?.find((i) => i.value === watchPsp)?.label;
    if (psp) setValue("pspName", psp);
    if (watchPsp === "") {
      setValue("pspName", "");
      setValue("pspId", "");
    }
  }, [watchPsp, pspOptionsData]);

  const clearDateSelect = () => {
    setValue("fromDate", null);
    setValue("toDate", null);
  };
  const clearLast = () => {
    setValue("last", "");
  };

  const handleClickCleanFilters = async () => {
    set(LC_PAYLOAD, {});
    reset(defaultValues);
    onClearFilters();
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} lg={12}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SelectInputField
                  label="Merchants"
                  name="merchantId"
                  message="Required"
                  options={merchantOptionsData}
                  placeholder="Select an option"
                  onSelect={(e) => {
                    queryClient.invalidateQueries([GET_BRANDS, e.value]);
                    setValue("merchantId", e.value);
                    setValue(
                      "merchantName",
                      merchantOptionsData.find((i) => i.value === e.value)?.label
                    );
                    setValue("brandId", "");
                    setValue("brandName", "");
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SelectInputField
                  name="brandId"
                  label="Brand"
                  message="Required"
                  options={brandOptionsData}
                  onSelect={(e) => {
                    setValue("brandId", e.value);
                    setValue("brandName", brandOptionsData.find((i) => i.value === e.value)?.label);
                  }}
                  placeholder="Select brand"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SortedCountrySelect name={"country"} label="Country" setValue={setValue} withAll />
              </Grid>
              <Grid item sm={6}>
                <CurrencySelect name={"currency"} label={"Currency"} withAll />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <PaymentOptionSelect
                  name="paymentOptionId"
                  label="Payment Option"
                  paymentOptions={paymentOptions}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextInputField
                  label="Min Amount"
                  name="minAmount"
                  type="number"
                  placeholder="Minimum amount"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextInputField
                  label="Max Amount"
                  name="maxAmount"
                  type="number"
                  placeholder="Maximum amount"
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <SelectInputField
                  name="pspId"
                  label="Psp"
                  options={pspOptionsData}
                  placeholder="Select a status"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectInputField
                  name="type"
                  label="Type"
                  options={typeOptions}
                  placeholder="Select a type"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectInputField
                  name="category"
                  label="Category"
                  options={categoryOptions}
                  placeholder="Select a category"
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <DatePickerField
                  name="fromDate"
                  options={dateTimePickerOptions()}
                  label="From Date"
                  onChange={clearLast}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DatePickerField
                  name="toDate"
                  options={dateTimePickerOptions()}
                  label="To Date"
                  onChange={clearLast}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TimeRangeInput name="last" label="Timeframe" onChange={clearDateSelect} />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item>
                <SoftBox sx={{ pl: 2 }}>
                  <SwitchField name="searchArchives" label="Search Archives" />
                </SoftBox>
              </Grid>
            </Grid>

            <Grid container display="flex" justifyContent="space-between">
              <SoftButton variant="gradient" color="info" type="submit" sx={{ mt: 2 }}>
                Search
              </SoftButton>
              <SoftButton
                variant="gradient"
                color="info"
                type="button"
                sx={{ mt: 2 }}
                onClick={handleClickCleanFilters}
              >
                Clear filters
              </SoftButton>
            </Grid>
          </SoftBox>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TransactionFilters;

// Typechecking props of the TransactionFilters
TransactionFilters.propTypes = {
  onSubmit: PropTypes.func,
  paymentOptions: PropTypes.object,
  pspsData: PropTypes.object,
  merchants: PropTypes.object,
  onClearFilters: PropTypes.func,
  brands: PropTypes.object,
  errorCodes: PropTypes.object,
};
