import _ from "lodash";
import { useMutation } from "react-query";
import request from "utils/request";
import { toastError } from "utils/toast";
import { getQueryParam } from "utils/string";
import { useTransactionStore } from "stores/transactions";

const GET_TRANSACTIONS_BY_IDS = "/transactions/get-by-ids";
const GET_TRANSACTION = "/transactions/find-one";
const TRANSACTION_STATUS = "/transactions/batch-status";

const sanitizeTransactionIds = (transactionIds) => {
  if (!transactionIds || transactionIds.trim() === "") return [];
  return transactionIds.split(",").map((id) => id.trim());
};

const getFilterTransactions = async ({ transactionIds, ...filterTransactionProps }) => {
  const filteredPayload = _.pickBy(filterTransactionProps, _.identity);
  const queryParams = getQueryParam(filteredPayload);

  if (!filterTransactionProps.transactionId || filterTransactionProps.transactionId === "") {
    return request.post(
      `${GET_TRANSACTIONS_BY_IDS}?${queryParams}`,
      sanitizeTransactionIds(transactionIds)
    );
  } else {
    const result = await request.get(`${GET_TRANSACTION}?${queryParams}`);
    let resArr = [];
    resArr.push(result);
    const res = {
      docs: resArr,
    };

    return res;
  }
};

export const useGetFilterTransactions = () => {
  const { setTransactions } = useTransactionStore();
  return useMutation(getFilterTransactions, {
    onSuccess: (data) => {
      setTransactions(_.get(data, "docs", []));
    },
    onError: (error) => {
      const message = _.get(error, "response.data.message[0]");
      toastError({ description: message });
    },
  });
};

const updateBatchStatus = ({ status, transactionIds }) => {
  return request.post(TRANSACTION_STATUS, { status, ids: transactionIds });
};
export const useUpdateBatchStatus = () => {
  return useMutation(updateBatchStatus);
};
