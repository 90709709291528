import * as React from "react";

import { forwardRef } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

// Custom styles for SoftSelect
import SelectInputField from "./SelectInputField";

const MultiplePaymentOptionSelect = forwardRef(({ paymentOptions, name, label, ...rest }, ref) => {
  const options = _.map(paymentOptions, (item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  return (
    <SelectInputField
      ref={ref}
      name={name}
      label={label}
      options={options}
      isMulti
      filterOption={(option, inputValue) =>
        option.value === "" ||
        paymentOptions
          .filter((i) => i.name.toLowerCase().includes(inputValue?.toLowerCase()))
          .some((i) => i.id === option.value)
      }
      {...rest}
    />
  );
});

// Setting default values for the props of SwitchField
MultiplePaymentOptionSelect.defaultProps = {
  name: "",
  label: "",
};

// Typechecking props for the SwitchField
MultiplePaymentOptionSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  paymentOptions: PropTypes.array,
};
export default MultiplePaymentOptionSelect;
