import * as React from "react";
import { groupedOptions } from "../../constants/sortedCountries";
import PropTypes from "prop-types";
import { SelectInputField } from "../FormField";
import { countriesCode } from "../../constants/countriesCode";

export default function SortedCountrySelect({ name, label, required, withAll, ...rest }) {
  const formatGroupLabel = (data) => (
    <div>
      <span style={{ fontWeight: 700, color: "#344767", fontSize: "14px" }}>{data.label}</span>
    </div>
  );
  return (
    <>
      <SelectInputField
        {...rest}
        name={name}
        label={label}
        options={
          withAll
            ? [
                {
                  label: "",
                  options: [
                    {
                      label: "All",
                      value: "",
                    },
                  ],
                },
                ...groupedOptions,
              ]
            : groupedOptions
        }
        required={required}
        formatGroupLabel={formatGroupLabel}
        placeholder="Select country"
        onSelect={(val) => {
          rest?.setValue && rest?.setValue(name, val?.value);
        }}
        grouped={true}
        filterOption={(option, inputValue) =>
          option.value === "" ||
          countriesCode
            .filter((i) => i.label.toLowerCase().includes(inputValue?.toLowerCase()))
            .some((i) => i.code === option.value) ||
          countriesCode
            .filter((i) => i.code.toLowerCase().includes(inputValue?.toLowerCase()))
            .some((i) => i.code === option.value)
        }
      />
    </>
  );
}

SortedCountrySelect.defaultValues = {
  required: false,
  withAll: false,
  label: "",
};
SortedCountrySelect.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  withAll: PropTypes.bool,
};
