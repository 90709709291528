import { Card, Grid } from "@mui/material";
import { TextInputField } from "components/FormField";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import React from "react";

const CustomersFilter = (props) => {
  const { onSubmit, whitelist } = props;
  const { setValue, getValues } = useFormContext();
  const handleClickCleanFilters = async () => {
    const { limit, page, ...currentFilters } = await getValues(); // Get the current filter values
    await Object.keys(currentFilters).forEach((key) => {
      setValue(key, "");
    }); // Reset the values of all current filters
    await onSubmit();
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} lg={12}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox p={3}>
            {!whitelist && (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextInputField label="Email" name="email" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInputField label="Ip" name="ip" />
                </Grid>
              </Grid>
            )}
            {whitelist && (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextInputField label="Ip" name="ip" />
                </Grid>
              </Grid>
            )}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextInputField
                  label="tags"
                  name="tags"
                  placeholder="Comma separated list of tags for searching."
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInputField label="reason" name="reason" />
              </Grid>
            </Grid>
            <Grid container display="flex" justifyContent="space-between">
              <SoftButton variant="gradient" color="info" type="submit" sx={{ mt: 2 }}>
                Search
              </SoftButton>
              <SoftButton
                variant="gradient"
                color="info"
                type="button"
                sx={{ mt: 2 }}
                onClick={handleClickCleanFilters}
              >
                Clean filter
              </SoftButton>
            </Grid>
          </SoftBox>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CustomersFilter;

// Typechecking props of the CustomersFilter
CustomersFilter.propTypes = {
  onSubmit: PropTypes.func,
  whitelist: PropTypes.bool,
};
