import React, { useEffect, useState } from "react";

import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useForm } from "react-hook-form";

import SoftButton from "../../../components/SoftButton";
import FormProvider from "../../../components/FormProviders";
import SoftBox from "../../../components/SoftBox";
import {
  useAddCardToBlacklist,
  useAddCardToWhitelist,
  useAddTransactionIdToBlacklist,
} from "../hooks";

import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema } from "../schemas/whitelist";
import WhitelistForm from "./whiteListForm";
import PropTypes from "prop-types";
import { blackDefaultValues, blackSchema } from "../schemas/blacklist";
import { txSchema, txDefaultValues } from "../schemas/byTx";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TabPanel, { a11yProps } from "../../../components/TabPanel";
import Grid from "@mui/material/Grid";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { TextInputField } from "../../../components/FormField";

const AddCardToWhitelistModal = ({ place, updateData }) => {
  const [isOpenAddCardToWhitelistModal, setIsOpenAddCardToWhitelistModal] = useState(false);
  const [tab, setTab] = useState(0);
  //hooks
  const { isLoading, mutate: mutateWhitelist } = useAddCardToWhitelist();
  const { isLoadingBlack, mutate: mutateBlacklist } = useAddCardToBlacklist();
  const { isLoadingTx, mutate: mutateTx } = useAddTransactionIdToBlacklist();
  const currentSchema = place === 0 ? schema : tab === 0 ? blackSchema : txSchema;
  const currentDefaultValues =
    place === 0 ? defaultValues : tab === 0 ? blackDefaultValues : txDefaultValues;
  const currentMutate = place === 0 ? mutateWhitelist : tab === 0 ? mutateBlacklist : mutateTx;
  const currentLoading = place === 0 ? isLoading : tab === 0 ? isLoadingBlack : isLoadingTx;

  //form provider
  const methods = useForm({
    resolver: yupResolver(currentSchema),
    defaultValues: currentDefaultValues,
  });
  const { handleSubmit, getValues, setValue } = methods;
  useEffect(() => {
    if (!isOpenAddCardToWhitelistModal) {
      Object.keys(getValues()).map((val) => {
        setValue(val, null);
      });
    }
  }, [isOpenAddCardToWhitelistModal]);

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    const { transactionId, ...data } = values;
    const payload = tab === 0 ? (place === 0 ? values : data) : { transactionId };
    currentMutate(
      { payload },
      {
        onSuccess: () => {
          updateData();
          handleCloseAddCardToWhitelistModal();
        },
      }
    );
  };
  const handleOpenCardToWhitelistModal = () => {
    setIsOpenAddCardToWhitelistModal(true);
  };
  const handleCloseAddCardToWhitelistModal = () => {
    setIsOpenAddCardToWhitelistModal(false);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <SoftBox mb={3}>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleOpenCardToWhitelistModal}
          >
            Add Card to {place === 0 ? "Whitelist" : "Blacklist"}
          </SoftButton>
        </Stack>
      </SoftBox>
      <Dialog
        open={isOpenAddCardToWhitelistModal}
        onClose={handleCloseAddCardToWhitelistModal}
        fullWidth
        maxWidth="lg"
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Add Card to {place === 0 ? "whitelist" : "blacklist"}
          </DialogTitle>
          {place === 1 && (
            <Grid
              container
              spacing={2}
              sx={{ flexGrow: 1, marginLeft: 3, marginTop: 2 }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="fit-content"
            >
              <Grid>
                <SoftBox sx={{ maxWidth: 700, marginTop: 2 }}>
                  <AppBar position="static">
                    <Tabs
                      value={tab}
                      onChange={handleTabChange}
                      aria-label="basic tabs example"
                      sx={{ backgroundColor: "transparent" }}
                      variant="standard"
                    >
                      <Tab
                        icon={<CreditCardIcon />}
                        label="By Card number"
                        {...a11yProps(0)}
                        sx={{ marginRight: 2 }}
                      />
                      <Tab
                        icon={<ReceiptIcon />}
                        label="By Transaction ID"
                        {...a11yProps(1)}
                        sx={{ marginRight: 2 }}
                      />
                    </Tabs>
                  </AppBar>
                </SoftBox>
              </Grid>
            </Grid>
          )}

          <TabPanel value={tab} index={0}>
            <DialogContent sx={{ pt: 0 }}>
              <SoftBox>
                <WhitelistForm tab={place} />
              </SoftBox>
            </DialogContent>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Grid item xs={12} sm={12}>
              <TextInputField label="Transaction ID" name="transactionId" />
            </Grid>
          </TabPanel>
          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mt: "-30px",
            }}
          >
            <SoftButton variant="gradient" onClick={handleCloseAddCardToWhitelistModal}>
              Cancel
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              disabled={currentLoading}
              isLoading={currentLoading}
            >
              Add Card
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default AddCardToWhitelistModal;

// Typechecking props of the AddCardToWhitelistModal
AddCardToWhitelistModal.propTypes = {
  place: PropTypes.number.isRequired,
  updateData: PropTypes.func.isRequired,
};
