import { useRef, useState } from "react";
import { Grid, Card } from "@mui/material";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import {
  useGetInternalErrorCodes,
  useGetResolvedErrorCodes,
  useGetUnresolvedErrorCodes,
  useResolveError,
  useDeleteErrorMap,
} from "./hooks";
import ErrorList from "./components/ErrorList";
import SoftButton from "components/SoftButton";
import ErrorStepper from "./components/ErrorStepper";
import DataTable from "examples/Tables/DataTable";
import { toastError } from "utils/toast";
import { useDisclosure } from "hooks/useDisclosure";
import AddInternalError from "./components/AddInternalError";

import ErrorItemCell from "./components/ErrorItemCell";
import SoftBox from "components/SoftBox";
import { DeleteModal } from "components/Modal";

const ErrorMapping = () => {
  const stepperRef = useRef();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedInternalCode, setSelectedInternalCode] = useState(null);
  const [selectedProviderCode, setSelectedProviderCode] = useState(null);
  const [isResolvable, setIsResolvable] = useState(false);

  const { data: internalErrorCodes } = useGetInternalErrorCodes();
  const { data: unresolvedErrorCodes } = useGetUnresolvedErrorCodes();
  const { data: pairs, isLoading } = useGetResolvedErrorCodes();
  const { mutate: resolveError, isLoading: isSubmitting } = useResolveError();
  const { mutateAsync: deleteErrorMap, isLoading: isDeleting } = useDeleteErrorMap();

  const handleInternalCodeChange = (id) => {
    const selected = _.find(internalErrorCodes, { _id: id });
    setSelectedInternalCode(selected);
    if (selectedProviderCode) {
      setIsResolvable(true);
    }
  };
  const handleProviderCodeChange = (id) => {
    const selected = _.find(unresolvedErrorCodes, { _id: id });
    setSelectedProviderCode(selected);
    if (selectedInternalCode != null) {
      setIsResolvable(true);
    }
  };

  const handlePair = () => {
    if (internalErrorCodes && unresolvedErrorCodes) {
      resolveError(
        {
          _id: selectedProviderCode._id,
          errorCodeId: selectedInternalCode._id,
        },
        {
          onSuccess: () => {
            setSelectedProviderCode(null);
            setIsResolvable(false);
            stepperRef.current.resetStep();
          },
        }
      );
    } else {
      toastError({
        description: "Unable to match errors, please select internal error and provider error",
      });
    }
  };

  const getRows = () => {
    return _.map(pairs, (item) => ({
      id: item._id,
      providerError: <ErrorItemCell code={item.code} message={item.message} psp={item.psp} />,
      internalError: (
        <ErrorItemCell
          code={item.internalError.code}
          message={item.internalError.message}
          status={item.internalError.status}
        />
      ),
      category: item.internalError.categoryName,
      actions: (
        <SoftBox display="flex" gap={1}>
          <DeleteModal
            size="xs"
            title="Delete Resolved Error"
            type={"icon"}
            iconColor={"error"}
            iconText={"DELETE"}
            message={"Are you sure want to delete this record?"}
            isLoading={isDeleting}
            onDelete={async () => {
              await deleteErrorMap({ id: item._id });
            }}
          />
        </SoftBox>
      ),
    }));
  };

  return (
    <MainLayout>
      <MainNavbar />
      <AddInternalError onClose={onClose} isOpen={isOpen} />
      <Grid container spacing={2} mt={0.5}>
        <Grid item xs={6} sm={6}>
          <ErrorList
            title="Internal Errors"
            group="internalCode"
            data={internalErrorCodes}
            onChange={handleInternalCodeChange}
            allowAdd={true}
            onAddClick={onOpen}
            allowCellDelete={true}
            allowCellEdit={true}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <ErrorList
            title="Provider Errors"
            group="providerCode"
            data={unresolvedErrorCodes}
            onChange={handleProviderCodeChange}
          />
        </Grid>
      </Grid>
      <Card sx={{ mt: 2, mb: 2 }}>
        <ErrorStepper
          ref={stepperRef}
          internalError={selectedInternalCode}
          providerError={selectedProviderCode}
        />
        <Grid container direction="column" alignItems="center" mt={-4}>
          <Grid item>
            <SoftButton
              variant="gradient"
              color="info"
              sx={{ width: "200px", mb: 2 }}
              disabled={!isResolvable}
              onClick={handlePair}
              isLoading={isSubmitting}
            >
              Match Error
            </SoftButton>
          </Grid>
        </Grid>
      </Card>
      <DataTable
        table={{
          columns: [
            { Header: "Internal Error", accessor: "internalError" },
            { Header: "Provider Error", accessor: "providerError" },
            { Header: "Category", accessor: "category" },
            { Header: "Action", accessor: "actions" },
          ],
          rows: getRows() || [],
        }}
        entriesPerPage={{
          defaultValue: 20,
          entries: [20, 50],
        }}
        isLoading={isLoading}
        isClickable={false}
      />
    </MainLayout>
  );
};

export default ErrorMapping;
