import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDisclosure } from "hooks/useDisclosure";
import SoftBox from "components/SoftBox";
import { yupResolver } from "@hookform/resolvers/yup";
import { SwitchField, TextInputField } from "../../../components/FormField";
import { useUpdateSettings } from "../hooks";
import { defaultValues, schema, sanitizeRequest } from "../schemas/editSettings";
import PropTypes from "prop-types";
import { ModalWrapper } from "components/Modal";

const EditSettings = ({ data }) => {
  const modalState = useDisclosure();
  const { onClose } = modalState;

  // query
  const { mutate, isLoading } = useUpdateSettings();

  // hook form
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = methods;
  useEffect(() => {
    if (data) reset(data);
  }, [data]);

  // function
  const handleSubmitForm = (values) => {
    mutate(sanitizeRequest(values), {
      onSuccess: () => {
        handleClose();
      },
    });
  };

  const handleClose = () => {
    onClose();
  };
  return (
    <ModalWrapper
      title="Edit Settings"
      modalState={modalState}
      submitButtonText="Edit Settings"
      type="iconButton"
      isLoading={isLoading}
      methods={methods}
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <SoftBox py={2}>
        <Grid
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          py={1}
        >
          <Grid item>
            <SwitchField label="Card Score Routing" name="enableCardScoreRouting" />
            <SwitchField label="Bin Country Check" name="enableBinCountryCheck" />
            <SwitchField label="Customer Check" name="enableCustomerCheck" sx={{ mb: 2 }} />
            <TextInputField label="Min Customer Risk" name="minCustomerRisk" type="number" />
          </Grid>
        </Grid>
      </SoftBox>
    </ModalWrapper>
  );
};

export default EditSettings;
EditSettings.propTypes = {
  data: PropTypes.object,
};
