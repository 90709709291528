import { create } from "zustand";

type Mid = {
  _id: string | null;
  pspId: string | null;
  name: string | undefined;
  mid: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  allowCardTypes?: string[];
  disallowCardTypes?: string[];
};

type MidStore = {
  mid: Mid;
  setMid: (mid: Mid) => void;
};

const initialState: MidStore = {
  mid: {
    _id: null,
    pspId: null,
    name: undefined,
    mid: null,
    createdAt: null,
    updatedAt: null,
  },
  setMid: () => null,
};

export const useMidStore = create((set) => ({
  ...initialState,
  setMid: (values: Mid) => {
    return set(({ mid }) => ({ mid: { ...mid, ...values } }));
  },
}));
