import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema, sanitizeRequest } from "../schemas/subOption";
import { ModalWrapper } from "components/Modal";

import { useUpdateSubOption } from "../hooks";
import SubOptionForm from "./SubOptionForm";

const UpdateSubOption = ({ data, modalState }) => {
  const { id: pspId, methodId } = useParams();
  const { onClose } = modalState;

  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: data || defaultValues,
  });
  const { handleSubmit, reset } = methods;

  //hooks
  const { isLoading, mutate: updateSubOption } = useUpdateSubOption({ pspId, methodId });

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    updateSubOption(
      { pspId, methodId, subOptionId: data?.id, ...sanitizeRequest(values) },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  return (
    <ModalWrapper
      title="Update SubOption"
      modalState={modalState}
      isLoading={isLoading}
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      minHeight="350px"
      maxWidth="md"
      type="hidden"
      submitButtonText={"Update SubOption"}
    >
      <SubOptionForm />
    </ModalWrapper>
  );
};
UpdateSubOption.propTypes = {
  data: PropTypes.object.isRequired,
  modalState: PropTypes.object.isRequired,
};
export default UpdateSubOption;
