import { Chip } from "@mui/material";
import { forwardRef } from "react";
import PropTypes from "prop-types";
import { ActiveStatusColorEnum, ActiveStatusEnum } from "constants/status";

const ActiveStatusChip = forwardRef(({ status, label, ...rest }, ref) => {
  const color = ActiveStatusColorEnum[status];
  const labelToRender = label ? label : ActiveStatusEnum[status];
  return <Chip label={labelToRender} {...rest} ref={ref} color={color} />;
});
// Setting default values for the props of SoftPagination
ActiveStatusChip.defaultProps = {
  status: false,
};

// Typechecking props for the SoftPagination
ActiveStatusChip.propTypes = {
  status: PropTypes.bool,
  label: PropTypes.string,
};
export default ActiveStatusChip;
