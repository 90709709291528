export const defaultValues = {
  search: "",
  paymentOptionId: "",
  merchantId: "",
  brandId: "",
  pspId: "",
  type: "",
  category: "",
  currency: "",
  country: "",
  status: "",
  minAmount: "",
  maxAmount: "",
  customerName: "",
  email: "",
  pan: "",
  fromDate: "",
  toDate: "",
  last: "",
  errorCode: "",
};
