import React from "react";
import SoftBox from "components/SoftBox";
import { Grid } from "@mui/material";
import { SwitchField, TextInputField, CountrySelect, CurrencySelect } from "components/FormField";

const SubOptionForm = () => {
  return (
    <SoftBox p={3} pb={1}>
      <SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextInputField label="Name" name="name" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputField label="External ID" name="externalId" />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <CountrySelect
              name="country"
              label="Country"
              placeholder="Select country"
              maxMenuHeight={120}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CurrencySelect
              name="currency"
              label="Currency"
              placeholder="Select currency"
              maxMenuHeight={120}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextInputField label="Min" name="min" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputField label="Max" name="max" />
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={0.5} ml={1}>
          <Grid item xs={12} sm={4}>
            <SwitchField label="Active" name="active" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SwitchField label="Enable PayIn" name="payin" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SwitchField label="Enable PayOut" name="payout" />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
};

export default SubOptionForm;
