import React, { useEffect } from "react";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import { useGetReconciliation } from "./hooks";
import FormProvider from "components/FormProviders";
import Filters from "./components/Filters";
import { useForm } from "react-hook-form";
import { useGetPsps } from "pages/psp-management/hooks";
import DataTable from "examples/Tables/DataTable";
import { fDateTimeSuffix } from "utils/formatTime";
import StatusChip from "components/StatusChip";
import { sanitizeFilter } from "./schemas/filter";
import { useNavigate } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import Stack from "@mui/material/Stack";

const Reconciliation = () => {
  const navigate = useNavigate();

  const { data, isLoading, mutate } = useGetReconciliation();
  const { data: psps } = useGetPsps();

  // forms
  const methods = useForm();
  const { handleSubmit } = methods;

  //functions
  const onSubmit = (data) => {
    mutate(sanitizeFilter(data));
  };
  const getRows = () => {
    if (data) {
      return data.map((item) => {
        const foundPsps = psps?.filter((psp) => item.pspIds.includes(psp._id));
        return {
          id: item._id,
          filename: item.filename,
          status: <StatusChip label={item.status} />,
          psps: foundPsps?.map((psp) => psp.name).join(", "),
          startDatetime: fDateTimeSuffix(item.startDatetime),
          endDatetime: fDateTimeSuffix(item.endDatetime),
          createdAt: fDateTimeSuffix(item.createdAt),
        };
      });
    }
    return [];
  };

  useEffect(() => {
    mutate({});
  }, []);

  return (
    <MainLayout>
      <MainNavbar />
      <SoftBox pt={2} px={2} mb={3}>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={() => navigate("/new-reconciliation")}
          >
            Submit Reconciliation File
          </SoftButton>
        </Stack>
      </SoftBox>
      <div style={{ paddingBottom: "20px" }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Filters psps={psps} />
        </FormProvider>
      </div>
      <DataTable
        table={{
          columns: [
            { Header: "File Name", accessor: "filename" },
            { Header: "Status", accessor: "status" },
            { Header: "Psps", accessor: "psps" },
            { Header: "Start Datetime", accessor: "startDatetime" },
            { Header: "End Datetime", accessor: "endDatetime" },
            { Header: "Created At", accessor: "createdAt", hide: true },
          ],
          rows: getRows() || [],
        }}
        columnsFilter={true}
        canSearch={true}
        isLoading={isLoading}
        onCellClick={(row) => {
          navigate(`/reconciliation/${row.original.id}`);
        }}
      />
    </MainLayout>
  );
};

export default Reconciliation;
