/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.0
 =========================================================

 * Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import IllustrationLayout from "examples/LayoutContainers/IllustrationLayout";

// Image
import chat from "assets/images/illustrations/chat.png";
import { useForm } from "react-hook-form";
import { useForgotPassword } from "./useAuth";
import React from "react";
import { toastSuccess } from "../../utils/toast";
import { useNavigate } from "react-router-dom";

function SignIn() {
  const { register, handleSubmit } = useForm({ defaultValues: { email: "" } });
  const navigate = useNavigate();

  const { mutate, isLoading } = useForgotPassword();

  const handleSubmitForm = (values) => {
    mutate(values, {
      onSuccess: () => {
        navigate(-1);
        toastSuccess({
          description: `Password reset link has been sent to ${values.email}, check email! 🙂`,
        });
      },
    });
  };

  return (
    <IllustrationLayout
      title="Forgot your password?"
      description="Please enter the email address associated with your account and We will email you a link to reset your password."
      illustration={{
        image: chat,
        title: '"Attention is the new currency"',
        description:
          "The more effortless the writing looks, the more effort the writer actually put into the process.",
      }}
    >
      <SoftBox component="form" role="form" onSubmit={handleSubmit(handleSubmitForm)}>
        <SoftBox mb={2}>
          <SoftInput type="email" placeholder="Email" size="large" {...register("email")} />
        </SoftBox>

        <SoftBox mt={2} mb={1}>
          <SoftButton
            variant="gradient"
            color="info"
            size="large"
            fullWidth
            type="submit"
            disabled={isLoading}
          >
            reset password
          </SoftButton>
        </SoftBox>
        <SoftBox mt={2} mb={1}>
          <SoftButton
            variant="gradient"
            color="secondary"
            size="large"
            fullWidth
            onClick={() => {
              navigate(-1);
            }}
          >
            back
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}

export default SignIn;
