import { create } from "zustand";

type StateStore = {
  dataTableAllChecked: boolean;
  setDataTableAllChecked: (checked: boolean) => void;
};

const initialState: StateStore = {
  dataTableAllChecked: false,
  setDataTableAllChecked: () => null,
};

export const useGlobalStateStore = create((set) => ({
  ...initialState,
  setDataTableAllChecked: (checked: boolean) => {
    set(({ dataTableAllChecked }) => ({ dataTableAllChecked: checked }));
  },
}));
