import { useQuery } from "react-query";
import request from "utils/request";

const GET_MISMATCHED_TRANSACTION_DETAIL = "/transactions/get-missmatched";

const getMismatchedTransactions = (id) => request.get(`${GET_MISMATCHED_TRANSACTION_DETAIL}/${id}`);

export const useGetMismatchedTransactionDetail = (id) => {
  return useQuery([GET_MISMATCHED_TRANSACTION_DETAIL, id], () => getMismatchedTransactions(id), {
    enabled: !!id,
  });
};
