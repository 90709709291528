import * as Yup from "yup";

export const txDefaultValues = {
  transactionId: null,
};

export const txSchema = Yup.object().shape({
  transactionId: Yup.string()
    .required("Transaction ID is required")
    .max(100, "Transaction ID is too long, should be less than 100 characters"),
});
