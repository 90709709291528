import { useNavigate } from "react-router-dom";

import _ from "lodash";
import { useParams } from "react-router-dom";

import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useGetPspsFees } from "../hooks";
import { isSuperAdmin } from "utils/roles";
import Error403 from "components/Error403";
import DataTable from "examples/Tables/DataTable";
import CreateFeeModal from "./CreateFeeModal";
import { useGetPaymentOptions } from "pages/brand-detail/hooks";
import { fDateTimeSuffix } from "utils/formatTime";
import { countries } from "constants/countries";

const Fees = () => {
  const { id } = useParams();
  if (!isSuperAdmin()) {
    return <Error403 />;
  }
  const navigate = useNavigate();

  // hooks
  const { data: paymentOptions } = useGetPaymentOptions();
  const { data: fees, isLoading, isFetching } = useGetPspsFees(id);

  const getRows = () => {
    return _.map(fees, (item) => {
      const country = countries.find((c) => c.code === item.country);

      return {
        ...item,
        id: item._id,
        createdAt: fDateTimeSuffix(item.createdAt),
        countryName: _.get(country, "label", item.country),
      };
    });
  };

  const onCellClick = (row) => {
    navigate(`/psps/${id}/fees/${row.original.id}`);
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox pt={2} px={2}>
        <Stack spacing={1} direction="row" justifyContent="space-between">
          <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Fees
          </SoftTypography>
          <CreateFeeModal paymentOptions={paymentOptions} />
        </Stack>
      </SoftBox>
      <SoftBox p={2}>
        <DataTable
          table={{
            columns: [
              { Header: "ID", accessor: "id" },
              { Header: "payment Option", accessor: "paymentOptionName" },
              { Header: "parent Name", accessor: "parentName" },
              { Header: "Type", accessor: "type" },
              { Header: "Country", accessor: "countryName" },
              { Header: "auth Fee FTD Amount", accessor: "authFeeFTDAmount" },
              { Header: "auth Fee Amount", accessor: "authFeeAmount" },
              { Header: "ftd", accessor: "ftd" },
              { Header: "nftd", accessor: "nftd" },
              { Header: "Credit Card", accessor: "creditCard" },
              { Header: "Debit Card", accessor: "debitCard" },
              { Header: "payin", accessor: "payin" },
              { Header: "Payout", accessor: "payout" },
              { Header: "Created At", accessor: "createdAt" },
            ],
            rows: getRows(),
          }}
          entriesPerPage={{
            defaultValue: 25,
            entries: [25, 50, 200, 500],
          }}
          onCellClick={onCellClick}
          onCellAuxClick={onCellClick}
          canSearch={true}
          columnsFilter={true}
          isClickable={true}
          isLoading={isLoading || isFetching}
        />
      </SoftBox>
    </Card>
  );
};

export default Fees;
