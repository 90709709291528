export const set = (key: string, value: Object) => {
  function filterParameters(params) {
    // Create a new object to store the filtered parameters
    let filteredParams = {};

    // Iterate over the keys of the params object
    for (let key in params) {
      // Check if the value is not an empty string, undefined, or null
      if (params[key] !== "" && params[key] !== undefined && params[key] !== null) {
        // Add the key-value pair to the filteredParams object
        filteredParams[key] = params[key];
      }
    }

    return filteredParams;
  }
  localStorage.setItem(key, JSON.stringify(filterParameters(value)));
};
export const get = (key: string) => {
  const response =
    typeof window !== "undefined" && localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key) ?? "")
      : null;
  return response;
};

export const decodePayload = (payload: any) => {
  if (payload?.email) {
    return { ...payload, email: decodeURIComponent(payload.email) };
  }
  return payload;
};
