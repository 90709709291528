import { useState, useRef } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import Swal from "sweetalert2";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

function TableDownloadCSV({
  data,
  headers,
  useRowsData,
  onDownloadClick,
  isServerDownload,
  filename,
}) {
  // state
  const [openMenu, setOpenMenu] = useState(false);
  const [dataOptions, setDataOptions] = useState(["All"]);
  // calculate data to download
  const objectToCell = (obj) => {
    let cell = "";
    _.forEach(obj, (value, key) => {
      cell += `${key}: ${value} \n`;
    });
    return cell;
  };
  const headersToDownload = _.map(headers, (header) => {
    if (header.accessor === "actions") return null;
    if (header.hide) return null;
    return {
      label: header.Header,
      key: header.accessor,
    };
  });

  //Optimized function for creating a data list for downloading
  const dataToDownload = data.map((row) => {
    const rowToDownload = {};
    for (const header of headersToDownload) {
      if (header) {
        let cell;
        const paymentAmount = _.get(row, "paymentAmount");
        const headerKey = header.key;
        cell =
          headerKey === "amount"
            ? paymentAmount || _.get(row, headerKey)
            : headerKey === "option"
            ? _.get(row, "paymentOption")
            : _.get(row, headerKey);
        const isObject = _.isObject(cell);
        if (isObject) {
          const isArrayOfObject = _.isArray(cell);
          if (isArrayOfObject) {
            rowToDownload[headerKey] = cell.map((item) => objectToCell(item));
          } else {
            rowToDownload[headerKey] = objectToCell(cell);
          }
        } else {
          rowToDownload[headerKey] = cell;
        }
      }
    }
    return rowToDownload;
  });

  //ref
  const downloadBtn = useRef();

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const showAlert = () =>
    Swal.fire({
      title: "Downloading...",
      timerProgressBar: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  const closeAlert = () => Swal.close();
  const handleDownloadCSV = async (amount) => {
    showAlert();
    await onDownloadClick({ amount, ref: downloadBtn }).then(() => {
      closeAlert();
      handleCloseMenu();
    });
  };
  return (
    <>
      <Icon fontSize="default" sx={{ cursor: "pointer", marginRight: 2 }} onClick={handleOpenMenu}>
        download_for_offline_outlined
      </Icon>
      {!isServerDownload && (
        <CSVLink
          data={dataToDownload && dataToDownload}
          filename={filename}
          className="hidden"
          ref={downloadBtn}
          target="_blank"
        />
      )}

      {!isServerDownload && (
        <Menu
          anchorEl={openMenu}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(openMenu)}
          onClose={handleCloseMenu}
          keepMounted
        >
          {dataOptions.map((item) => (
            <MenuItem onClick={() => handleDownloadCSV(item)} key={item}>
              <span style={{ fontWeight: "normal" }}>
                Download <b>{item}</b> records
              </span>
            </MenuItem>
          ))}
        </Menu>
      )}
      {isServerDownload && (
        <Menu
          anchorEl={openMenu}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(openMenu)}
          onClose={handleCloseMenu}
          keepMounted
        >
          <MenuItem onClick={() => handleDownloadCSV(-1)}>
            <span style={{ fontWeight: "normal" }}>Download as CSV</span>
          </MenuItem>
        </Menu>
      )}
    </>
  );
}

// Setting default values for the props of ActionMenu
TableDownloadCSV.defaultProps = {
  data: [],
  headers: [],
  useRowsData: true,
  isServerDownload: false,
  onDownloadClick: () => {},
};

// Typechecking props of the ActionMenu
TableDownloadCSV.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(PropTypes.object),
  useRowsData: PropTypes.bool,
  onDownloadClick: PropTypes.func.isRequired,
  isServerDownload: PropTypes.bool,
  filename: PropTypes.string,
};

export default TableDownloadCSV;
