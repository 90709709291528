import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema, sanitizeRequest } from "../schemas/templates";
import { useDisclosure } from "hooks/useDisclosure";
import { useUpdateTemplate } from "../hooks";

import SoftBox from "components/SoftBox";
import PropTypes from "prop-types";
import TemplateForm from "./TemplateForm";
import { ModalWrapper } from "components/Modal";

const EditTemplateModal = ({ paymentOptions, onCloseSuccess, pmTemplates, psps, template }) => {
  //modal state
  const modalState = useDisclosure();
  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit, reset } = methods;
  //hooks
  const { mutate: updateTemplate, isLoading: isUpdating } = useUpdateTemplate();

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    updateTemplate(
      { ...sanitizeRequest(values), templateId: template._id },
      {
        onSuccess: () => {
          onCloseSuccess();
          onClose();
        },
      }
    );
  };

  useEffect(() => {
    reset(template);
  }, [template]);

  return (
    <ModalWrapper
      title="Update Template"
      modalState={modalState}
      isLoading={isUpdating}
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      maxWidth="lg"
      minHeight={"300px"}
    >
      <SoftBox p={2}>
        <TemplateForm
          paymentOptions={paymentOptions}
          pmTemplates={pmTemplates}
          psps={psps}
          isUpdateForm={true}
        />
      </SoftBox>
    </ModalWrapper>
  );
};
EditTemplateModal.propTypes = {
  onCloseSuccess: PropTypes.func,
  paymentOptions: PropTypes.array,
  pmTemplates: PropTypes.array,
  psps: PropTypes.array,
  template: PropTypes.object,
};
export default EditTemplateModal;
