import React, { useState, useEffect } from "react";
import _ from "lodash";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import DataTable from "examples/Tables/DataTable";
import FormProvider from "components/FormProviders";
// import TransactionFilters from "./components/TransactionFilters";
import { useForm } from "react-hook-form";
import { useGetErrorSummary } from "./hooks";

import { set, get } from "utils/localStorage";
import moment from "moment";
import { generateDateRange } from "utils/time";
import { updateSelectedFilters } from "utils/filters";
import TransactionFilters from "./components/TransactionFilters";

// hooks
import { useGetMerchants } from "pages/merchant-management/hooks";
import { useGetPaymentOptions } from "pages/brand-detail/hooks";
import { useGetPsps } from "pages/psp-management/hooks";
import { useGetBrands } from "pages/merchant-management-detail/hooks";
import { useDataTable } from "hooks/useDataTable";
import { useGetInternalErrorCodes } from "pages/error-mapping/hooks";

const LC_PAYLOAD = "errorSummaryPayload";

const ErrorSummary = () => {
  const [selectedFilters, setSelectedFilters] = useState({});
  const merchantsSummaryPayload = get(LC_PAYLOAD) || {};
  // Destructuring the object of all selected filters to obtain only the necessary
  const { ...filters } = selectedFilters;

  // forms
  const methods = useForm({ defaultValues: merchantsSummaryPayload });
  const { handleSubmit, setValue, getValues, watch, reset } = methods;
  const watchMerchant = watch("merchantId", null);

  // hooks
  const { data, isLoading, mutate } = useGetErrorSummary();
  const { data: merchants } = useGetMerchants();
  const { data: paymentOptions } = useGetPaymentOptions();
  const { data: pspsData } = useGetPsps();
  const { data: brands } = useGetBrands(watchMerchant);

  const onSubmit = (values) => {
    let fromDate = values.fromDate;
    let toDate = values.toDate;
    if (values.last) {
      const { last } = values;
      const dateRange = generateDateRange(last);
      fromDate = dateRange.fromDate;
      toDate = dateRange.toDate;
    }
    const payload = {
      ...values,
      fromDate: fromDate && moment(new Date(fromDate)).format("YYYY-MM-DD HH:mm:ss"),
      toDate: toDate && moment(new Date(toDate)).format("YYYY-MM-DD HH:mm:ss"),
    };
    mutate(payload);
    updateFilters(values);
    set(LC_PAYLOAD, payload);
  };

  const getRows = () => {
    return _.map(data, (item) => ({
      count: item.count,
      errorReason: item.errorReason,
      errorCategory: item.errorCategory,
      errorCode: item.errorCode,
    }));
  };

  const handleClickCleanFilters = async () => {
    reset({
      toDate: "",
      country: "",
      fromDate: "",
      paymentOptionId: "",
      pspId: "",
      currency: "",
      last: "",
    });
    updateFilters({});
    set(LC_PAYLOAD, {});
  };

  useEffect(() => {
    mutate(merchantsSummaryPayload);
  }, []);

  useEffect(() => {
    updateFilters(merchantsSummaryPayload);
  }, [paymentOptions]);

  const updateFilters = (data) => {
    updateSelectedFilters(data, setSelectedFilters, {
      merchants,
      paymentOptions,
      brands,
      psps: pspsData,
    });
  };

  // create a function that update the list of selected filters
  const onClearFilters = () => {
    updateFilters({});
  };
  return (
    <MainLayout>
      <MainNavbar />
      <div style={{ paddingBottom: "20px" }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <TransactionFilters
            onSubmit={handleSubmit(onSubmit)}
            filters={filters}
            handleClickCleanFilters={handleClickCleanFilters}
            paymentOptions={paymentOptions}
            pspsData={pspsData}
            merchants={merchants}
            brands={brands}
            onClearFilters={onClearFilters}
          />
        </FormProvider>
      </div>

      <DataTable
        table={{
          columns: [
            { Header: "Error Code", accessor: "errorCode" },
            { Header: "Category", accessor: "errorCategory" },
            { Header: "Reason", accessor: "errorReason" },
            { Header: "Count", accessor: "count" },
          ],
          rows: getRows(),
        }}
        entriesPerPage={{
          defaultValue: 25,
          entries: [25, 50, 200, 500],
        }}
        isLoading={isLoading}
        isClickable={true}
      />
    </MainLayout>
  );
};

export default ErrorSummary;
