import React, { useEffect, useState } from "react";
import { Card, Grid, Stack, Chip } from "@mui/material";
import {
  SelectInputField,
  DatePickerField,
  PaymentOptionSelect,
  CurrencySelect,
  TimeRangeInput,
  SwitchField,
} from "components/FormField";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import _ from "lodash";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
// import RecentlyUsedFilters from "./ResentlyUsedFilters";
// import { useGetPsps } from "psp-management/hooks";
import SortedCountrySelect from "components/SortedCountrySelect";

import { dateTimePickerOptions } from "constants/dateFilter";

const TransactionFilters = ({ handleClickCleanFilters, paymentOptions, pspsData, filters }) => {
  const { setValue } = useFormContext();

  const [pspOptionsData, setPspsData] = useState([]);

  const clearLast = () => {
    setValue("last", "");
  };
  const clearDateSelect = () => {
    setValue("fromDate", null);
    setValue("toDate", null);
  };

  useEffect(() => {
    if (pspsData)
      setPspsData(
        [{ value: "", label: "All" }].concat(
          _.map(pspsData, (item) => ({ value: item._id, label: item.name }))
        )
      );
  }, [pspsData]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} lg={12}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox p={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <PaymentOptionSelect
                      name="paymentOptionId"
                      label="Payment Option"
                      paymentOptions={paymentOptions}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectInputField
                      name="pspId"
                      label="Psp"
                      options={pspOptionsData}
                      placeholder="Select a provider"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} sx={{ pt: "3px!important" }}>
                    <SortedCountrySelect name={"country"} label="Country" withAll />
                  </Grid>
                  <Grid item sm={6} sx={{ pt: "3px!important" }}>
                    <CurrencySelect
                      name={"currency"}
                      label={"Currency"}
                      withAll
                      placeholder="Select a currency"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <DatePickerField
                      name="fromDate"
                      options={dateTimePickerOptions()}
                      label="From Date"
                      onChange={clearLast}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DatePickerField
                      name="toDate"
                      options={dateTimePickerOptions()}
                      label="To Date"
                      onChange={clearLast}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TimeRangeInput name="last" label="Timeframe" onChange={clearDateSelect} />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item>
                    <SoftBox sx={{ pl: 2 }}>
                      <SwitchField name="includeDeleted" label="Include Cancelled" />
                    </SoftBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container display="flex" justifyContent="space-between">
              <SoftButton variant="gradient" color="info" type="submit" sx={{ mt: 2 }}>
                Search
              </SoftButton>
              <SoftButton
                variant="gradient"
                color="info"
                type="button"
                sx={{ mt: 2 }}
                onClick={handleClickCleanFilters}
              >
                Clear filters
              </SoftButton>
            </Grid>
          </SoftBox>
        </Card>
      </Grid>
      <Stack
        direction="row"
        spacing={1}
        sx={{ mt: 2, mb: 2 }}
        display="flex"
        flexWrap="wrap"
        gap={1}
      >
        {Object.values(filters)?.map((i, index) =>
          i ? <Chip key={i + index} label={i} color="secondary" textColor="white" /> : null
        )}
      </Stack>
    </Grid>
  );
};

export default TransactionFilters;

// Typechecking props of the TransactionFilters
TransactionFilters.propTypes = {
  handleClickCleanFilters: PropTypes.func,
  filters: PropTypes.any,
  paymentOptions: PropTypes.object,
  pspsData: PropTypes.object,
};
