import _ from "lodash";
import { countriesFlags } from "./countriesFlags";
import SoftBox from "../components/SoftBox";
import SoftAvatar from "../components/SoftAvatar";
import SoftTypography from "../components/SoftTypography";
import React from "react";

export const sortedCountries = {
  Europe: [
    { code: "AD", label: "Andorra" },
    { code: "AL", label: "Albania" },
    { code: "AM", label: "Armenia" },
    { code: "AT", label: "Austria" },
    { code: "AZ", label: "Azerbaijan" },
    { code: "BA", label: "Bosnia and Herzegovina" },
    { code: "BE", label: "Belgium" },
    { code: "BG", label: "Bulgaria" },
    { code: "BY", label: "Belarus" },
    { code: "CH", label: "Switzerland" },
    { code: "CY", label: "Cyprus" },
    { code: "CZ", label: "Czech Republic" },
    { code: "DE", label: "Germany" },
    { code: "DK", label: "Denmark" },
    { code: "EE", label: "Estonia" },
    { code: "ES", label: "Spain" },
    { code: "FI", label: "Finland" },
    { code: "FR", label: "France" },
    { code: "GB", label: "United Kingdom" },
    { code: "GE", label: "Georgia" },
    { code: "GR", label: "Greece" },
    { code: "HR", label: "Croatia" },
    { code: "HU", label: "Hungary" },
    { code: "IE", label: "Ireland" },
    { code: "IS", label: "Iceland" },
    { code: "IT", label: "Italy" },
    { code: "LT", label: "Lithuania" },
    { code: "LU", label: "Luxembourg" },
    { code: "LV", label: "Latvia" },
    { code: "MC", label: "Monaco" },
    { code: "MD", label: "Moldova, Republic of" },
    { code: "ME", label: "Montenegro" },
    { code: "MK", label: "Macedonia, the Former Yugoslav Republic of" },
    { code: "MT", label: "Malta" },
    { code: "NL", label: "Netherlands" },
    { code: "NO", label: "Norway" },
    { code: "PL", label: "Poland" },
    { code: "PT", label: "Portugal" },
    { code: "RO", label: "Romania" },
    { code: "RS", label: "Serbia" },
    { code: "RU", label: "Russian Federation" },
    { code: "SE", label: "Sweden" },
    { code: "SI", label: "Slovenia" },
    { code: "SK", label: "Slovakia" },
    { code: "SM", label: "San Marino" },
    { code: "UA", label: "Ukraine" },
    { code: "XK", label: "Kosovo" },
  ],
  Asia: [
    { code: "AE", label: "United Arab Emirates" },
    { code: "AF", label: "Afghanistan" },
    { code: "AM", label: "Armenia" },
    { code: "AZ", label: "Azerbaijan" },
    { code: "BD", label: "Bangladesh" },
    { code: "BH", label: "Bahrain" },
    { code: "BN", label: "Brunei Darussalam" },
    { code: "BT", label: "Bhutan" },
    { code: "CN", label: "China" },
    { code: "CY", label: "Cyprus" },
    { code: "GE", label: "Georgia" },
    { code: "ID", label: "Indonesia" },
    { code: "IL", label: "Israel" },
    { code: "IN", label: "India" },
    { code: "IQ", label: "Iraq" },
    { code: "IR", label: "Iran, Islamic Republic of" },
    { code: "JO", label: "Jordan" },
    { code: "JP", label: "Japan" },
    { code: "KG", label: "Kyrgyzstan" },
    { code: "KH", label: "Cambodia" },
    { code: "KP", label: "Korea, Democratic People's Republic of" },
    { code: "KR", label: "Korea, Republic of" },
    { code: "KW", label: "Kuwait" },
    { code: "KZ", label: "Kazakhstan" },
    { code: "LA", label: "Lao People's Democratic Republic" },
    { code: "LB", label: "Lebanon" },
    { code: "LK", label: "Sri Lanka" },
    { code: "MM", label: "Myanmar" },
    { code: "MN", label: "Mongolia" },
    { code: "MO", label: "Macao" },
    { code: "MV", label: "Maldives" },
    { code: "MY", label: "Malaysia" },
    { code: "NP", label: "Nepal" },
    { code: "OM", label: "Oman" },
    { code: "PH", label: "Philippines" },
    { code: "PK", label: "Pakistan" },
    { code: "PS", label: "Palestine, State of" },
    { code: "QA", label: "Qatar" },
    { code: "SA", label: "Saudi Arabia" },
    { code: "SG", label: "Singapore" },
    { code: "SY", label: "Syrian Arab Republic" },
    { code: "TH", label: "Thailand" },
    { code: "TJ", label: "Tajikistan" },
    { code: "TL", label: "Timor-Leste" },
    { code: "TM", label: "Turkmenistan" },
    { code: "TR", label: "Turkey" },
    { code: "TW", label: "Taiwan, Province of China" },
    { code: "UZ", label: "Uzbekistan" },
    { code: "VN", label: "Vietnam" },
    { code: "YE", label: "Yemen" },
  ],
  Africa: [
    { code: "AO", label: "Angola" },
    { code: "BF", label: "Burkina Faso" },
    { code: "BI", label: "Burundi" },
    { code: "BJ", label: "Benin" },
    { code: "BW", label: "Botswana" },
    { code: "CD", label: "Congo, Democratic Republic of the" },
    { code: "CF", label: "Central African Republic" },
    { code: "CG", label: "Congo, Republic of the" },
    { code: "CI", label: "Cote d'Ivoire" },
    { code: "CM", label: "Cameroon" },
    { code: "CV", label: "Cape Verde" },
    { code: "DJ", label: "Djibouti" },
    { code: "DZ", label: "Algeria" },
    { code: "EG", label: "Egypt" },
    { code: "EH", label: "Western Sahara" },
    { code: "ER", label: "Eritrea" },
    { code: "ET", label: "Ethiopia" },
    { code: "GA", label: "Gabon" },
    { code: "GH", label: "Ghana" },
    { code: "GM", label: "Gambia" },
    { code: "GN", label: "Guinea" },
    { code: "GQ", label: "Equatorial Guinea" },
    { code: "GW", label: "Guinea-Bissau" },
    { code: "KE", label: "Kenya" },
    { code: "KM", label: "Comoros" },
    { code: "LR", label: "Liberia" },
    { code: "LS", label: "Lesotho" },
    { code: "LY", label: "Libya" },
    { code: "MA", label: "Morocco" },
    { code: "MG", label: "Madagascar" },
    { code: "ML", label: "Mali" },
    { code: "MR", label: "Mauritania" },
    { code: "MU", label: "Mauritius" },
    { code: "MW", label: "Malawi" },
    { code: "MZ", label: "Mozambique" },
    { code: "NA", label: "Namibia" },
    { code: "NE", label: "Niger" },
    { code: "NG", label: "Nigeria" },
    { code: "RE", label: "Reunion" },
    { code: "RW", label: "Rwanda" },
    { code: "SC", label: "Seychelles" },
    { code: "SD", label: "Sudan" },
    { code: "SH", label: "Saint Helena" },
    { code: "SL", label: "Sierra Leone" },
    { code: "SN", label: "Senegal" },
    { code: "SO", label: "Somalia" },
    { code: "SS", label: "South Sudan" },
    { code: "ST", label: "Sao Tome and Principe" },
    { code: "SZ", label: "Swaziland" },
    { code: "TD", label: "Chad" },
    { code: "TG", label: "Togo" },
    { code: "TN", label: "Tunisia" },
    { code: "TZ", label: "Tanzania, United Republic of" },
    { code: "UG", label: "Uganda" },
    { code: "ZA", label: "South Africa" },
    { code: "ZM", label: "Zambia" },
    { code: "ZW", label: "Zimbabwe" },
  ],
  NorthAmerica: [
    { code: "AG", label: "Antigua and Barbuda" },
    { code: "AI", label: "Anguilla" },
    { code: "AW", label: "Aruba" },
    { code: "BB", label: "Barbados" },
    { code: "BM", label: "Bermuda" },
    { code: "BZ", label: "Belize" },
    { code: "CA", label: "Canada" },
    { code: "CR", label: "Costa Rica" },
    { code: "CU", label: "Cuba" },
    { code: "DM", label: "Dominica" },
    { code: "DO", label: "Dominican Republic" },
    { code: "GD", label: "Grenada" },
    { code: "GL", label: "Greenland" },
    { code: "GT", label: "Guatemala" },
    { code: "HN", label: "Honduras" },
    { code: "HT", label: "Haiti" },
    { code: "JM", label: "Jamaica" },
    { code: "KN", label: "Saint Kitts and Nevis" },
    { code: "KY", label: "Cayman Islands" },
    { code: "LC", label: "Saint Lucia" },
    { code: "MF", label: "Saint Martin (French part)" },
    { code: "MQ", label: "Martinique" },
    { code: "MS", label: "Montserrat" },
    { code: "MX", label: "Mexico" },
    { code: "NI", label: "Nicaragua" },
    { code: "PA", label: "Panama" },
    { code: "PR", label: "Puerto Rico" },
    { code: "SV", label: "El Salvador" },
    { code: "SX", label: "Sint Maarten (Dutch part)" },
    { code: "TC", label: "Turks and Caicos Islands" },
    { code: "TT", label: "Trinidad and Tobago" },
    { code: "US", label: "United States" },
    { code: "VC", label: "Saint Vincent and the Grenadines" },
    { code: "VG", label: "British Virgin Islands" },
    { code: "VI", label: "US Virgin Islands" },
  ],
  SouthAmerica: [
    { code: "AR", label: "Argentina" },
    { code: "BO", label: "Bolivia" },
    { code: "BR", label: "Brazil" },
    { code: "CL", label: "Chile" },
    { code: "CO", label: "Colombia" },
    { code: "EC", label: "Ecuador" },
    { code: "FK", label: "Falkland Islands (Malvinas)" },
    { code: "GF", label: "French Guiana" },
    { code: "GY", label: "Guyana" },
    { code: "PE", label: "Peru" },
    { code: "PY", label: "Paraguay" },
    { code: "SR", label: "Suriname" },
    { code: "UY", label: "Uruguay" },
    { code: "VE", label: "Venezuela" },
  ],
  Oceania: [
    { code: "AS", label: "American Samoa" },
    { code: "AU", label: "Australia" },
    { code: "CK", label: "Cook Islands" },
    { code: "FJ", label: "Fiji" },
    { code: "FM", label: "Micronesia, Federated States of" },
    { code: "GU", label: "Guam" },
    { code: "KI", label: "Kiribati" },
    { code: "MH", label: "Marshall Islands" },
    { code: "MP", label: "Northern Mariana Islands" },
    { code: "NC", label: "New Caledonia" },
    { code: "NF", label: "Norfolk Island" },
    { code: "NR", label: "Nauru" },
    { code: "NU", label: "Niue" },
    { code: "NZ", label: "New Zealand" },
    { code: "PF", label: "French Polynesia" },
    { code: "PG", label: "Papua New Guinea" },
    { code: "PN", label: "Pitcairn" },
    { code: "PW", label: "Palau" },
    { code: "SB", label: "Solomon Islands" },
    { code: "TK", label: "Tokelau" },
    { code: "TO", label: "Tonga" },
    { code: "TV", label: "Tuvalu" },
    { code: "UM", label: "United States Minor Outlying Islands" },
    { code: "VU", label: "Vanuatu" },
    { code: "WF", label: "Wallis and Futuna" },
    { code: "WS", label: "Samoa" },
  ],
  Antarctica: [
    { code: "AQ", label: "Antarctica" },
    { code: "BV", label: "Bouvet Island" },
    { code: "GS", label: "South Georgia and the South Sandwich Islands" },
    { code: "HM", label: "Heard Island and McDonald Islands" },
    { code: "TF", label: "French Southern Territories" },
  ],
};
const countriesOptions = (region) =>
  sortedCountries[region].map((item) => {
    const countryWithFlag = _.find(countriesFlags, { code: item.code });
    const flag = _.get(countryWithFlag, "image", "");
    return {
      value: item.code,
      label: (
        <SoftBox display="flex" py={1.5} px={2}>
          <SoftBox mr={1}>
            <SoftAvatar src={flag} size="sm" alt="title" />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column" justifyContent="center">
            <SoftTypography
              component="div"
              variant="button"
              textTransform="capitalize"
              fontWeight="medium"
            >
              {item.label}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ),
    };
  });
export const groupedOptions = [
  {
    label: "Europe",
    options: countriesOptions("Europe"),
  },
  {
    label: "Asia",
    options: countriesOptions("Asia"),
  },
  {
    label: "Africa",
    options: countriesOptions("Africa"),
  },
  {
    label: "North America",
    options: countriesOptions("NorthAmerica"),
  },
  {
    label: "South America",
    options: countriesOptions("SouthAmerica"),
  },
  {
    label: "Oceania",
    options: countriesOptions("Oceania"),
  },
  {
    label: "Antarctica",
    options: countriesOptions("Antarctica"),
  },
];
