import { useQuery, useQueryClient, useMutation } from "react-query";
import { toastSuccess } from "utils/toast";
import request from "utils/request";
import { getTemplatePath } from "utils/string";

const GET_MERCHANTS = "/merchants";
const CREATE_MERCHANTS = "/merchants";
const DELETE_MERCHANT = "/merchants/:merchantId";

const getMerchants = (ogId = null) => {
  const query = ogId && _.isString(ogId) ? "?organizationId=" + ogId : "";
  return request.get(`${GET_MERCHANTS}${query}`);
};
const createMerchant = (payload) => request.post(CREATE_MERCHANTS, payload);
const deleteMerchant = ({ merchantId }) =>
  request.delete(getTemplatePath(DELETE_MERCHANT, { merchantId }));

export const useGetMerchants = () => {
  return useQuery([GET_MERCHANTS], getMerchants, { refetchOnWindowFocus: false });
};

export const useGetMerchantsByOrg = (organizationId) => {
  return useQuery([GET_MERCHANTS, organizationId], () => getMerchants(organizationId), {
    refetchOnWindowFocus: false,
  });
};

export const useCreateMerchant = () => {
  const queryClient = useQueryClient();
  return useMutation(createMerchant, {
    onSuccess: () => {
      toastSuccess({ description: "Create merchant has been success" });
      queryClient.invalidateQueries([GET_MERCHANTS]);
    },
  });
};

export const useDeleteMerchant = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteMerchant, {
    onSuccess: () => {
      toastSuccess({ description: "Merchant deleted successfully" });
      queryClient.invalidateQueries([GET_MERCHANTS]);
    },
  });
};

export const useMutateGetMerchants = () => {
  return useMutation(getMerchants);
};
