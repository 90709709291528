import React, { useEffect, useState } from "react";

import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useForm } from "react-hook-form";

import SoftButton from "../../../components/SoftButton";
import FormProvider from "../../../components/FormProviders";
import SoftBox from "../../../components/SoftBox";
import { useAddCustomerToWhitelist } from "../hooks";

import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { defaultValues, schema } from "../schemas/whitelist";
import Grid from "@mui/material/Grid";
import { TextInputField, TagsInput } from "../../../components/FormField";

const AddCustomerToWhitelistModal = ({ updateData }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [tab, setTab] = useState(0);

  //hooks
  const { isLoading: isLoading, mutate: addWhitelist } = useAddCustomerToWhitelist();

  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const { handleSubmit, getValues, setValue } = methods;
  useEffect(() => {
    if (!isOpenModal) {
      Object.keys(getValues()).map((val) => {
        setValue(val, null);
      });
    }
  }, [isOpenModal]);
  useEffect(() => {
    setValue("ip", null);
  }, [tab]);

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    addWhitelist(
      { payload: values },
      {
        onSuccess: () => {
          updateData();
          handleCloseModal();
        },
      }
    );
  };
  const handleOpenModal = () => {
    setIsOpenModal(true);
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <SoftBox mb={3}>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <SoftButton variant="gradient" color="info" size="small" onClick={handleOpenModal}>
            Add Customer to Whitelist
          </SoftButton>
        </Stack>
      </SoftBox>
      <Dialog open={isOpenModal} onClose={handleCloseModal} fullWidth maxWidth="lg">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Add Customer to Whitelist
          </DialogTitle>

          <DialogContent sx={{ pt: 0 }}>
            <SoftBox p={3}>
              <TextInputField label="Ip" name="ip" />
              <Grid item xs={12} sm={12}>
                <TagsInput label="tags" name="tags" />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInputField label="reason" name="reason" />
              </Grid>
            </SoftBox>
          </DialogContent>

          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mt: "-30px",
            }}
          >
            <SoftButton variant="gradient" onClick={handleCloseModal}>
              Cancel
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              disabled={isLoading}
              isLoading={isLoading}
            >
              Add Customer
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default AddCustomerToWhitelistModal;

// Typechecking props of the AddCustomerToWhitelistModal
AddCustomerToWhitelistModal.propTypes = {
  updateData: PropTypes.func.isRequired,
};
