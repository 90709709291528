import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import FormProvider from "components/FormProviders";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import { yupResolver } from "@hookform/resolvers/yup";
import { addInternalErrorSchema } from "../schemas";
import { useAddError } from "../hooks";
import InternalErrorForm from "./InternalErrorForm";

const AddInternalError = ({ isOpen, onClose }) => {
  const methods = useForm({ resolver: yupResolver(addInternalErrorSchema) });
  const { handleSubmit } = methods;

  const { mutate: addError, isLoading } = useAddError();

  const handleSubmitForm = (values) => {
    addError(values, {
      onSuccess: () => {
        onClose();
      },
    });
  };
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
      <DialogTitle>Create new internal error </DialogTitle>
      <DialogContent maxWidth="lg">
        <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
          <SoftBox p={3}>
            <InternalErrorForm />
            <Stack direction="row" spacing={1} justifyContent="center" mt={3}>
              <SoftButton onClick={onClose}>Cancel</SoftButton>
              <SoftButton
                variant="gradient"
                color="info"
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
              >
                Create
              </SoftButton>
            </Stack>
          </SoftBox>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

AddInternalError.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddInternalError;
