import PropTypes from "prop-types";
import _ from "lodash";

import { Card } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

const Customer = ({ customer }) => {
  if (!customer) return null;
  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox component="li" display="flex" alignItems="center" p={1}>
        <SoftBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <SoftTypography variant="caption">
            <b>Email:</b> &nbsp;{_.get(customer, "email", "N/A")}
          </SoftTypography>
          <SoftTypography variant="caption">
            <b>Name:</b> &nbsp;{_.get(customer, "name", "N/A")}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </Card>
  );
};

Customer.defaultProps = {
  customer: {},
};

// Typechecking props of the Customer
Customer.propTypes = {
  customer: PropTypes.object,
};
export default Customer;
