import SoftBox from "components/SoftBox";
import ReactJson from "react-json-view";
import PropTypes from "prop-types";

const JsonView = ({ data, collapsed }) => {
  return (
    <SoftBox p={3} pt={0}>
      <ReactJson
        src={data}
        displayDataTypes={false}
        displayObjectSize={false}
        displayArrayKey={false}
        collapsed={collapsed}
        groupArraysAfterLength={20}
        enableClipboard={(text) => {
          if (text && text.src) {
            navigator.clipboard.writeText(JSON.stringify(text.src));
          }
        }}
      />
    </SoftBox>
  );
};
JsonView.propTypes = {
  data: PropTypes.object,
  collapsed: PropTypes.bool,
};

export default JsonView;
