import { omit } from "lodash";
import moment from "moment";
import { generateDateRange } from "utils/time";

export const defaultValues = {
  search: "",
  paymentOptionId: "",
  merchantId: "",
  brandId: "",
  pspId: "",
  type: "",
  category: "",
  currency: "",
  country: "",
  status: "",
  minAmount: "",
  maxAmount: "",
  customerName: "",
  reconciled: "",
  email: "",
  pan: "",
  fromDate: "",
  toDate: "",
  last: "",
  errorCode: "",
};

export const sanitizeSummaryReport = (data) =>
  omit(data, ["limit", "page", "brandName", "merchantName", "pspName", "paymentOptionName"]);

export const sanitizeSummaryApproval = (data) =>
  omit(data, ["brandName", "merchantName", "pspName", "paymentOptionName"]);

export const sanitizeTransaction = ({ pageSize, sortDirection, ...data }) => {
  let payload;
  if (!data.merchantId || data.merchantId === "All") {
    data.brandId = "";
    data.brandName = "";
  }
  if (data.last) {
    const { last, ...filValues } = data;
    const dateRange = generateDateRange(last);
    data.fromDate = dateRange.fromDate;
    data.toDate = dateRange.toDate;
    payload = {
      ...filValues,
      fromDate: dateRange.fromDate,
      toDate: dateRange.toDate,
    };
  } else {
    payload = {
      ...data,
      fromDate: data.fromDate && moment(new Date(data.fromDate)).format("YYYY-MM-DD HH:mm:ss"),
      toDate: data.toDate && moment(new Date(data.toDate)).format("YYYY-MM-DD HH:mm:ss"),
    };
  }
  payload = {
    ...payload,
    limit: pageSize,
    page: 1,
    sortBy: "createdAt",
    sortDirection: sortDirection,
  };
  if (data.email && !_.isUndefined(data.email)) {
    payload.email = encodeURIComponent(data.email);
  }
  return payload;
};
