import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";

import React from "react";

import { DialogActions, Card } from "@mui/material";
import { useForm } from "react-hook-form";

import SoftButton from "components/SoftButton";
import FormProvider from "components/FormProviders";
import SoftBox from "components/SoftBox";

import { yupResolver } from "@hookform/resolvers/yup";
import { schema, defaultValues, sanitizeRequest } from "./schemas";

import _ from "lodash";
import SoftTypography from "components/SoftTypography";
import { useNavigate } from "react-router-dom";
import ReconciliationForm from "pages/new-reconciliation/components/ReconciliationForm";
import { useCreateTemplate } from "./hooks";
import { useGetPsps } from "pages/psp-management/hooks";

const NewReconciliation = () => {
  const navigate = useNavigate();
  const { data: psps } = useGetPsps();
  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit } = methods;

  //hooks
  const { isLoading, mutate } = useCreateTemplate();

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    mutate(sanitizeRequest({ ...values }), {
      onSuccess: () => {
        navigate("/reconciliation-templates");
      },
    });
  };

  return (
    <MainLayout>
      <MainNavbar />
      <Card>
        <SoftBox p={3}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Create New Template
            </SoftTypography>
            <SoftBox mt={4}>
              <ReconciliationForm psps={psps} sheetOptions={[]} />
            </SoftBox>
            <DialogActions>
              <SoftButton
                variant="gradient"
                onClick={() => {
                  navigate("/reconciliation-templates");
                }}
              >
                Cancel
              </SoftButton>

              <SoftButton
                variant="gradient"
                color="info"
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
              >
                Create
              </SoftButton>
            </DialogActions>
          </FormProvider>
        </SoftBox>
      </Card>
    </MainLayout>
  );
};

export default NewReconciliation;
