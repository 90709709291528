import * as Yup from "yup";

export const blackDefaultValues = {
  bin: null,
  last4: null,
  expiryYear: null,
  expiryMonth: null,
};

export const blackSchema = Yup.object().shape({
  bin: Yup.string()
    .required("Bin is required")
    .max(6, "Bin is too long, should  consist of 6 digits")
    .min(6, "Bin is too short, should  consist of 6 digits"),
  last4: Yup.string()
    .required("Last 4 is required")
    .max(4, "Last 4 is too long, should  consist of 4 digits")
    .min(4, "Last 4 is too short, should  consist of 4 digits"),
  expiryYear: Yup.string()
    .required("Year is required")
    .max(4, "Year is too long, should consist of 4 digits")
    .min(4, "Year is too short, should consist of 4 digits"),
  expiryMonth: Yup.string()
    .required("Month is required")
    .max(2, "Month is too long, should consist of 2 digits")
    .min(2, "Month is too short, should consist of 2 digits"),
});
