import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import PropTypes from "prop-types";

import _ from "lodash";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import React from "react";
import JsonView from "pages/transaction-detail/components/JsonView";
import SoftButton from "components/SoftButton";

const DetailsModal = ({ isOpen = false, onClose, attempt }) => {
  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
        <DialogTitle
          sx={{
            background: (theme) => theme.palette.grey[500],
            fontWeight: (theme) => theme.typography.h6,
            color: (theme) => theme.palette.common.white,
            px: 4,
            py: 1,
          }}
        >
          Transaction Attempt
        </DialogTitle>
        <DialogContent>
          <SoftBox p={3} pb={0}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <SoftBox display="flex" alignItems="start" flexDirection="column">
                  <SoftTypography variant="text">
                    <b>ID:</b> &nbsp;{_.get(attempt, "id", "N/A")}
                  </SoftTypography>
                  <SoftTypography variant="text">
                    <b>Merchant:</b> &nbsp;{_.get(attempt, "merchant", "N/A")}
                  </SoftTypography>
                  <SoftTypography variant="text">
                    <b>Brand:</b> &nbsp;{_.get(attempt, "brand", "N/A")}
                  </SoftTypography>
                  <SoftTypography variant="text">
                    <b>Endpoint:</b> &nbsp;{_.get(attempt, "endpoint", "N/A")}
                  </SoftTypography>
                  <SoftTypography variant="text">
                    <b>Payload:</b> &nbsp;
                  </SoftTypography>
                  <JsonView data={_.get(attempt, "payload", {})} />
                  <SoftTypography variant="text">
                    <b>Error:</b> &nbsp;
                  </SoftTypography>
                  <JsonView data={_.get(attempt, "error", {})} />
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </DialogContent>
        <DialogActions>
          <SoftButton onClick={onClose} color="info">
            Close
          </SoftButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

// Typechecking props for the NotificationModal
DetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  attempt: PropTypes.object.isRequired,
};

export default DetailsModal;
