import MainNavbar from "../../examples/Navbars/MainNavbar";
import MainLayout from "../../examples/LayoutContainers/MainLayout";
import Grid from "@mui/material/Grid";
import SoftBox from "../../components/SoftBox";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, { a11yProps } from "../../components/TabPanel";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import React, { useEffect, useState } from "react";
import { useGetFilterBlacklist, useGetFilterWhitelist } from "./hooks";
import { get, set } from "../../utils/localStorage";
import _ from "lodash";
import DataTable from "../../examples/Tables/DataTable";
import { fDateTimeSuffix } from "../../utils/formatTime";
import AddCardToWhitelistModal from "./components/AddCardToWhitelistModal";
import DeleteCardFromWhitelist from "./components/DeleteCardFromWhitelist";
import FormProvider from "../../components/FormProviders";
import CardsFilter from "./components/CardsFilter";
import { useForm } from "react-hook-form";
import UploadCSV from "./components/UploadCSV";
const LC_PAYLOAD = "whitelistPayload";
const LC_PAYLOAD_BLACK = "blacklistPayload";

const CardsManagement = (props) => {
  const [pageSize, setPageSize] = useState(25);
  const [tab, setTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const whitelistPayload = get(LC_PAYLOAD) || {};
  const blacklistPayload = get(LC_PAYLOAD_BLACK) || {};

  const { mutate, isLoading, data: whitelist } = useGetFilterWhitelist();
  const {
    mutate: blacklistMutate,
    isLoading: blacklistLoading,
    data: blacklist,
  } = useGetFilterBlacklist();
  const defaultValues = tab === 0 ? whitelistPayload : blacklistPayload;
  const currentMutate = tab === 0 ? mutate : blacklistMutate;
  const methods = useForm({ defaultValues });
  const { handleSubmit } = methods;
  useEffect(() => {
    currentMutate({
      page: 1,
      cardnumber: "",
      limit: pageSize,
    });
  }, [tab]);

  const onSubmit = (values) => {
    const payload = {
      ...values,
      limit: pageSize,
      page: 1,
    };
    if (tab === 0) {
      set(LC_PAYLOAD, payload);
    } else {
      set(LC_PAYLOAD_BLACK, payload);
    }
    currentMutate(payload);
  };

  const updateData = () => {
    setCurrentPage(1);
    currentMutate({
      page: 1,
      cardnumber: "",
      limit: pageSize,
    });
  };

  const getRows = () => {
    return _.map(_.get(tab === 0 ? whitelist : blacklist, "docs", []), (item) => {
      return {
        _id: item._id,
        maskedPAN: item.maskedPAN,
        merchant: tab === 0 && item.merchant,
        scoreOverride: tab === 0 && item.scoreOverride,
        expiryMonth: item.expiryMonth,
        expiryYear: item.expiryYear,
        createdAt: fDateTimeSuffix(item.createdAt),
        updatedAt: fDateTimeSuffix(item.updatedAt),
        actions: (
          <DeleteCardFromWhitelist
            id={item._id}
            cardNumber={item.maskedPAN}
            tab={tab}
            updateData={updateData}
          />
        ),
      };
    });
  };

  // create a function that handles the page change
  const handlePageChange = (page) => {
    setCurrentPage(page + 1);
    defaultValues.page = page + 1;
    set(tab === 0 ? LC_PAYLOAD : LC_PAYLOAD_BLACK, defaultValues);
    currentMutate(defaultValues);
  };
  // create a function that handles the page size change
  const handlePageSizeChange = (pageSizes) => {
    if (pageSizes !== pageSize) {
      setCurrentPage(1);
      setPageSize(pageSizes);
      defaultValues.page = 1;
      defaultValues.limit = pageSizes;
      set(tab === 0 ? LC_PAYLOAD : LC_PAYLOAD_BLACK, defaultValues);
      currentMutate(defaultValues);
    }
  };
  //tab handle
  const handleTabChange = (event, newValue) => {
    setPageSize(25);
    setTab(newValue);
  };

  return (
    <MainLayout>
      <MainNavbar />
      {/* define tab */}
      <Grid
        container
        spacing={2}
        sx={{ flexGrow: 1, marginTop: 2 }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid>
          <SoftBox sx={{ maxWidth: 700, marginTop: 2 }}>
            <AppBar position="static">
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                sx={{ backgroundColor: "transparent" }}
                variant="standard"
              >
                <Tab
                  icon={<CreditScoreIcon />}
                  label="White list"
                  {...a11yProps(0)}
                  sx={{ marginRight: 2 }}
                />
                <Tab
                  icon={<CreditCardOffIcon />}
                  label="Black list"
                  {...a11yProps(1)}
                  sx={{ marginRight: 2 }}
                />
              </Tabs>
            </AppBar>
          </SoftBox>
        </Grid>
      </Grid>
      <Grid container mt={3} display={"inline-block"}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <CardsFilter onSubmit={handleSubmit(onSubmit)} tab={tab} />
        </FormProvider>
      </Grid>
      <TabPanel value={tab} index={0}>
        <Grid container display="flex" justifyContent="space-between" alignItems="center">
          <UploadCSV updateData={updateData} place={tab} />
          <AddCardToWhitelistModal place={tab} updateData={updateData} />
        </Grid>
        <DataTable
          table={{
            columns: [
              { Header: "Id", accessor: "_id" },
              { Header: "Merchant", accessor: "merchant" },
              { Header: "Score Override", accessor: "scoreOverride" },
              { Header: "Masked PAN", accessor: "maskedPAN" },
              { Header: "Expiry Month", accessor: "expiryMonth" },
              { Header: "Expiry Year", accessor: "expiryYear" },
              { Header: "Created At", accessor: "createdAt" },
              { Header: "Updated At", accessor: "updatedAt" },
              { Header: "Actions", accessor: "actions" },
            ],
            rows: getRows(),
          }}
          manualPagination={true}
          nextPage={_.get(whitelist, "nextPage", 0)}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          pageCount={_.get(whitelist, "totalPages", 0)}
          rowCount={_.get(whitelist, "totalDocs", 0)}
          isLoading={isLoading}
          currentPage={currentPage}
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Grid container display="flex" justifyContent="space-between" alignItems="center">
          <UploadCSV updateData={updateData} place={tab} />
          <AddCardToWhitelistModal place={tab} updateData={updateData} />
        </Grid>
        <DataTable
          table={{
            columns: [
              { Header: "Id", accessor: "_id" },
              { Header: "Masked PAN", accessor: "maskedPAN" },
              { Header: "Expiry Month", accessor: "expiryMonth" },
              { Header: "Expiry Year", accessor: "expiryYear" },
              { Header: "Created At", accessor: "createdAt" },
              { Header: "Updated At", accessor: "updatedAt" },
              { Header: "Actions", accessor: "actions" },
            ],
            rows: getRows(),
          }}
          manualPagination={true}
          nextPage={_.get(blacklist, "nextPage", 0)}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          pageCount={_.get(blacklist, "totalPages", 0)}
          rowCount={_.get(blacklist, "totalDocs", 0)}
          isLoading={blacklistLoading}
          currentPage={currentPage}
        />
      </TabPanel>
    </MainLayout>
  );
};

export default CardsManagement;
