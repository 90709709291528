import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Card } from "@mui/material";

import SoftBox from "components/SoftBox";
import DataTable from "examples/Tables/DataTable";

import { getVirtualAccountStatus, useVirtualAccounts } from "pages/brand-detail/hooks";
import StatusChip from "../../../../../components/StatusChip";
import EnabledChip from "../../../../../components/EnabledChip";
import AddVirtualAccount from "./AddVirtualAccount";
import DeleteVirtualAccount from "./DeleteVirtualAccount";
import EditVirtualAccount from "./EditVirtualAccount";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import { toastSuccess } from "../../../../../utils/toast";

const VirtualAccounts = ({ pspMidId }) => {
  const { id: merchantId, brandId } = useParams();
  const [newStatus, setNewStatus] = useState("");
  const { data } = useVirtualAccounts({ merchantId, brandId });
  const checkStatus = (id, index) => {
    getVirtualAccountStatus({ merchantId, brandId, id }).then((res) => {
      setNewStatus(res);
      data[index].status = res.status;
      toastSuccess({ description: "Status has been updated" });
    });
  };

  const getRows = () => {
    return _.map(data, (item, index) => {
      return {
        _id: item._id,
        accountNumber: item.accountNumber,
        country: item.country,
        virtualAccountNumber: item.virtualAccountNumber,
        beneficiaryName: item.beneficiaryName,
        status: (
          <>
            <StatusChip label={item.status} size="small" />
            <Button
              sx={{ cursor: "pointer", fontSize: "30px!important" }}
              onClick={() => checkStatus(item._id, index)}
            >
              <Icon sx={{ fontSize: "30px!important" }}>update</Icon>
            </Button>
          </>
        ),
        enabled: <EnabledChip label={item.enabled} size="small" />,
        actions: (
          <SoftBox
            display="flex"
            alignItems="center"
            mt={{ xs: 2, sm: 0 }}
            ml={{ xs: -1.5, sm: 0 }}
          >
            {item.status !== "DELETED" && (
              <>
                <DeleteVirtualAccount id={item._id} />
                <EditVirtualAccount id={item._id} />
              </>
            )}
          </SoftBox>
        ),
      };
    });
  };

  return (
    <Card sx={{ mt: 2 }}>
      <SoftBox>
        <SoftBox display="flex" justifyContent="space-between" m={2}>
          <SoftBox></SoftBox>
          <SoftBox>
            {/*button Add Virtual Account*/}
            <AddVirtualAccount pspMidId={pspMidId} merchantId={merchantId} brandId={brandId} />
          </SoftBox>
        </SoftBox>
        <SoftBox mt={2}>
          <DataTable
            table={{
              columns: [
                { Header: "External ID", accessor: "_id" },
                { Header: "Account Number", accessor: "accountNumber" },
                { Header: "Country", accessor: "country" },
                { Header: "Virtual Account Number", accessor: "virtualAccountNumber" },
                { Header: "Beneficiary Name", accessor: "beneficiaryName" },
                { Header: "Status", accessor: "status" },
                { Header: "Enabled", accessor: "enabled" },
                { Header: "Action", accessor: "actions" },
              ],
              rows: getRows(),
            }}
          />
        </SoftBox>
      </SoftBox>
    </Card>
  );
};

VirtualAccounts.propTypes = {
  pspMidId: PropTypes.string.isRequired,
};
export default VirtualAccounts;
