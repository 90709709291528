import { Box, Divider, Grid } from "@mui/material";
import _ from "lodash";
import React from "react";

import {
  MultipleSelectField,
  SelectInputField,
  TextInputField,
} from "../../../components/FormField";
import { countriesCode } from "../../../constants/countriesCode";
import { useGetUserRoles } from "../users/hooks";
import { LoginTypes } from "constants/roles";
import { generateOptions } from "utils/options";

export const sanitizeRequest = ({
  id,
  name,
  legalName,
  registrationNumber,
  website,
  companyPhone,
  companyEmail,
  companyAddress,
  country,
  currency,
  mccCode,
  vat,
  settlementCurrency,
  paymentFac,
  code,
}) => {
  const filteredPayload = _.pickBy(
    {
      id,
      name,
      legalName,
      registrationNumber,
      website,
      companyPhone,
      companyEmail,
      companyAddress,
      country,
      currency,
      mccCode,
      vat,
      code: code,
      settlementCurrency,
    },
    _.identity
  );
  return {
    ...filteredPayload,
    paymentFac,
  };
};
const ManageUserForm = () => {
  const { data: roles } = useGetUserRoles({ loginType: LoginTypes.ADMIN });
  const rolesOptions = generateOptions(roles, false);
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Name" name="name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Surname" name="surname" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <MultipleSelectField
            name="roles"
            label="Roles"
            options={rolesOptions}
            placeholder="Select an option"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Email" name="email" />
        </Grid>
        <Grid container display="flex" pl={3} xs={6} sx={{ marginBottom: 2 }}>
          <Grid item xs={4}>
            <SelectInputField
              name="code"
              label="Code"
              options={_.map(countriesCode, (option) => ({
                label: `${option.label} +${option.phone}`,
                value: `+${option.phone}`,
              }))}
              placeholder="Select an option"
            />
          </Grid>
          <Grid item xs={8} pl={2}>
            <TextInputField label="Phone" name="phone" />
          </Grid>
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};

export default ManageUserForm;
