import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";

import { SelectInputField } from "components/FormField";

import { currencies } from "constants/currencies";
import { currenciesFlags } from "constants/currenciesFlags";

const CurrencySelect = ({ name, label, required, additionalCurrencies, ...rest }) => {
  const currentOptions = additionalCurrencies.concat(
    rest?.withAll ? currencies : currencies.slice(1, -1)
  );
  const currenciesOptions = currentOptions.map((item) => {
    const flag = _.get(currenciesFlags, _.get(item, "code", "").toLowerCase());
    return {
      value: item.code,
      label: (
        <SoftBox display="flex" py={1.5} px={2}>
          <SoftBox mr={1}>
            <SoftAvatar src={flag} size="sm" alt="title" />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column" justifyContent="center">
            <SoftTypography
              component="div"
              variant="button"
              textTransform="capitalize"
              fontWeight="medium"
            >
              {_.get(item, "symbol", "")} {item.name}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ),
    };
  });
  return (
    <SelectInputField
      name={name}
      label={label}
      options={currenciesOptions}
      required={required}
      filterOption={(option, inputValue) => {
        return (
          currencies
            .filter((i) => i?.name?.toLowerCase().includes(inputValue?.toLowerCase()))
            .some((i) => i.code === option.value) ||
          currencies
            .filter((i) => i?.code?.toLowerCase().includes(inputValue?.toLowerCase()))
            .some((i) => i.code === option.value) ||
          additionalCurrencies
            .filter((i) => i?.name?.toLowerCase().includes(inputValue?.toLowerCase()))
            .some((i) => i.code === option.value)
        );
      }}
      {...rest}
    />
  );
};

CurrencySelect.defaultProps = {
  required: false,
  label: "",
  withAll: false,
  additionalCurrencies: [],
};
CurrencySelect.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  additionalCurrencies: PropTypes.array,
};

export default CurrencySelect;
