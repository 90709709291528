import React, { useEffect, useState } from "react";

import { Dialog, DialogContent, DialogTitle, Grid, Stack } from "@mui/material";
import { useForm } from "react-hook-form";

import SoftButton from "components/SoftButton";
import FormProvider from "components/FormProviders";
import SoftBox from "components/SoftBox";
import { useCreateOrganization } from "../hooks";

import { yupResolver } from "@hookform/resolvers/yup";
import { schema, defaultValues, sanitizeRequest } from "pages/organization-details/schemas";
import ButtonSet from "../../../components/ButtonSet";
import { TextInputField } from "components/FormField";
import { useDisclosure } from "hooks/useDisclosure";

const AddOrganizationModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit, reset } = methods;

  //hooks
  const { isLoading, mutate } = useCreateOrganization();

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    mutate(sanitizeRequest(values), {
      onSuccess: () => {
        onClose();
      },
    });
  };

  useEffect(() => {
    reset(defaultValues);
  }, []);

  return (
    <>
      <SoftBox pt={2} px={2} mb={3}>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <SoftButton variant="gradient" color="info" size="small" onClick={onOpen}>
            Create Organization
          </SoftButton>
        </Stack>
      </SoftBox>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Create New Organization
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextInputField name="name" label="Name" />
                </Grid>
              </Grid>
            </SoftBox>
          </DialogContent>
          <ButtonSet onClose={onClose} isLoading={isLoading} title={"Create"} />
        </FormProvider>
      </Dialog>
    </>
  );
};

export default AddOrganizationModal;
