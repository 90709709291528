import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import typography from "assets/theme/base/typography";

import error404 from "assets/images/illustrations/error-404.png";

function Error403() {
  const { d1, d3, d4, d5 } = typography;

  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100%", mt: 4 }}
    >
      <Grid item xs={11} sm={9} container alignItems="center">
        <Grid item xs={12} lg={6}>
          <SoftBox
            fontSize={{ xs: d5.fontSize, sm: d4.fontSize, md: d3.fontSize, lg: d1.fontSize }}
            lineHeight={1.2}
          >
            <SoftTypography variant="inherit" color="error" textGradient fontWeight="bold">
              Error 403
            </SoftTypography>
          </SoftBox>
          <SoftTypography variant="h2" color="dark" textGradient fontWeight="bold">
            Access Denied
          </SoftTypography>
          <SoftBox mt={1} mb={2}>
            <SoftTypography variant="body1" color="text">
              Sorry about that, but you don&apos;t have permission to access this page
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <SoftBox component="img" src={error404} alt="error-404" width="100%" />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Error403;
