import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";

import CollapseWrapper from "components/FormField/CollapseWrapper";
import { getRegion, isFromRegion } from "utils/country";
import CountriesTable from "./CountriesTable";

const ContinentsView = ({ countries, type }) => {
  const [continents, setContinents] = useState([]);
  useEffect(() => {
    if (countries) {
      const _continents = _.map(countries, (c) => getRegion(c.code));
      setContinents(_.uniq(_continents));
    }
  }, [countries]);
  return (
    <SoftBox ml={2} mt={3} mb={1} mr={2}>
      {_.map(continents, (c) => {
        const data = _.filter(countries, (country) => isFromRegion(country.code, c));
        return (
          <CollapseWrapper title={c} key={c} openDefault={true}>
            <CountriesTable data={data} type={type} />
          </CollapseWrapper>
        );
      })}
    </SoftBox>
  );
};

ContinentsView.propTypes = {
  countries: PropTypes.array.isRequired,
  type: PropTypes.number,
};
export default ContinentsView;
