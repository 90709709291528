import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { toastError } from "../../../utils/toast";

import { Card, Grid, Icon, IconButton, Stack } from "@mui/material";

import {
  SelectInputField,
  TextInputField,
  MultipleSelectField,
  SwitchField,
} from "components/FormField";

import FormProvider from "components/FormProviders";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import { schema, defaultValues } from "../schemas/update";

import { useGetPspMid, useUpdatePspMid } from "../hooks";
import _ from "lodash";
import SoftTypography from "../../../components/SoftTypography";
import { yupResolver } from "@hookform/resolvers/yup";
import CardTypeDivider from "./CardTypeDevider";
import { cardValues } from "constants/cards";
import { DeleteModal } from "components/Modal";
import { useDeleteMid } from "pages/psp-details/hooks";
import { useNavigate } from "react-router-dom";

export const VelocitiesArray = () => {
  const { control } = useFormContext();
  const {
    fields: velocities,
    remove,
    append,
  } = useFieldArray({
    control,
    name: `velocities`,
  });

  return (
    <div>
      {_.map(velocities, (field, index) => (
        <Grid
          container
          key={field.id}
          spacing={2}
          display="flex"
          pl={2}
          alignItems="center"
          justifyContent={"start"}
        >
          <Grid item xs={12} mb={1}>
            <SoftTypography variant="h6">Velocity {index + 1}</SoftTypography>
          </Grid>
          <Grid container spacing={2} xs={11}>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={12} sm={3.5}>
              <SelectInputField
                name={`velocities[${index}].per`}
                label="Per"
                options={[
                  { label: "Email", value: "EMAIL" },
                  { label: "Card", value: "CARD" },
                ]}
                placeholder="Select"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInputField name={`velocities[${index}].hour`} label="Hour" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInputField name={`velocities[${index}].count`} label="Count" />
            </Grid>
          </Grid>
          <Grid item xs={0.5} sm={1}>
            <IconButton onClick={() => remove(index)}>
              <Icon>delete_forever</Icon>
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid container spacing={2} display="flex" justifyContent="end">
        <Grid item xs={4}>
          <SoftButton sx={{ mt: 2 }} onClick={() => append({})}>
            Add velocities
          </SoftButton>
        </Grid>
      </Grid>
    </div>
  );
};
const EditMid = () => {
  const { id, midId } = useParams();
  const navigate = useNavigate();
  // form
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, setValue } = methods;
  const handleSubmitForm = (values) => {
    // if intersection of allowCardTypes and disallowCardTypes is not empty
    // then raise error
    const intersection = _.intersection(values.allowCardTypes, values.disallowCardTypes);
    if (intersection.length > 0) {
      toastError({ description: "Allowed and Disallowed card types should be mutually exclusive" });
      return;
    }
    mutate({ pspId: id, midId, ...values, isDefault: values.default });
  };

  // hooks
  const { data, isLoading, isError } = useGetPspMid({ pspId: id, midId });
  const { mutate, isLoading: isUpdating } = useUpdatePspMid();
  const { isLoading: isDeleting, mutateAsync: deleteMid } = useDeleteMid();

  // effect
  useEffect(() => {
    reset(data);
    if (data) {
      data.dailyUsdLimit === null
        ? setValue("dailyUsdLimit", "")
        : setValue("dailyUsdLimit", data.dailyUsdLimit);
      data.monthlyUsdLimit === null
        ? setValue("monthlyUsdLimit", "")
        : setValue("monthlyUsdLimit", data.monthlyUsdLimit);
    }
  }, [data]);
  return (
    <Card sx={{ p: 2 }}>
      {isLoading && <SoftTypography variant="h6">Loading...</SoftTypography>}
      {isError && <SoftTypography variant="h6">Error...</SoftTypography>}
      <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
        <SoftBox py={2}>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12} sm={6}>
              <TextInputField label="Name" name="name" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInputField label="Mid" name="mid" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInputField label="Monthly USD Limit" name="monthlyUsdLimit" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInputField label="Daily USD Limit" name="dailyUsdLimit" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInputField label="Min Success Tx" name="minSuccessTx" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInputField label="Hours since first successful tx" name="hsfst" />
            </Grid>
            <Grid item xs={6} sm={3} sx={{ marginLeft: 2 }}>
              <SwitchField label="Active" name="active" />
            </Grid>
            <Grid item xs={0} sm={3} />
            <Grid item xs={6} sm={3} sx={{ marginLeft: 2 }}>
              <SwitchField label="Default" name="default" />
            </Grid>

            <CardTypeDivider />

            <Grid item xs={12} sm={6}>
              <MultipleSelectField
                label="Allowed Card Types"
                name="allowCardTypes"
                options={cardValues}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MultipleSelectField
                label={"Disallowed Card Types"}
                name={"disallowCardTypes"}
                options={cardValues}
              />
            </Grid>

            <Grid item xs={12}>
              <VelocitiesArray />
            </Grid>
          </Grid>
          <SoftBox display="flex" justifyContent="flex-start" gap={2} mt={4}>
            <SoftBox pt={2} px={2}>
              <Stack spacing={1} direction="row" justifyContent="flex-end">
                <SoftButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  disabled={isUpdating}
                  isLoading={isUpdating}
                >
                  Update
                </SoftButton>
              </Stack>
            </SoftBox>

            <DeleteModal
              type="button"
              title="Delete MID"
              message={
                <>
                  Are you sure you want to delete mid <b>{data?.name}</b>? This mid will be removed
                  from all the assigned brands.
                </>
              }
              onDelete={async () => {
                await deleteMid({ pspId: id, midId });
              }}
              isLoading={isDeleting}
              onClose={() => {
                navigate(`/psps/${id}`);
              }}
            />
          </SoftBox>
        </SoftBox>
      </FormProvider>
    </Card>
  );
};

export default EditMid;
