import _ from "lodash";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Checkbox from "@mui/material/Checkbox";
import CardContent from "@mui/material/CardContent";

import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";

import SoftMultipleCheckBox from "components/SoftMultipleCheckBox";
import { countriesFlags } from "constants/countriesFlags";
import ContinentNav from "./ContinentNav";
import { countriesCode } from "constants/countriesCode";
import { ContinentEnum } from "constants/tabs";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useFormContext, Controller } from "react-hook-form";

const MultipleCountriesSelect = ({ name, label, required, ...rest }) => {
  const [continent, setContinent] = useState(0);
  const [countries, setCountries] = useState(countriesCode);
  const [refresh, setRefresh] = useState(false);
  const [isAllChecked, setAllChecked] = useState(false);

  const {
    control,
    getValues,
    formState: { isSubmitted },
    watch,
  } = useFormContext();
  const valueWatch = watch(name);

  const filterCountriesByContinent = (continent) => {
    const continentName = Object.keys(ContinentEnum)[continent];
    if (continent == ContinentEnum.ALL) {
      return countriesCode;
    }
    return _.filter(countriesCode, { continent: continentName });
  };
  const continentOnChange = (continent) => {
    const countries = filterCountriesByContinent(continent);
    setCountries(countries);
    setContinent(continent);
    setAllChecked(false);
    setRefresh(!refresh);
  };

  const allSelectOnChange = (e, fn) => {
    let _countries = countries;
    if (e.target.checked == true) {
      _countries.forEach((e) => {
        e.checked = true;
      });
      const countryCodes = _.map(_countries, (c) => c.code);
      fn(countryCodes);
      setAllChecked(true);
    } else {
      _countries.forEach((e) => {
        e.checked = false;
      });
      fn([]);
      setAllChecked(false);
    }

    setRefresh(!refresh);
    setCountries(_countries);
  };
  const isChecked = (countryCode) => {
    const country = _.find(countries, { code: countryCode });
    return country?.checked || false;
  };
  const onCountryCheckChange = (e, countryCode, fn) => {
    let _countries = countries;
    _countries.forEach((c) => {
      if (c.code == countryCode) {
        if (e.target.checked == true) {
          c.checked = true;
          fn([...getValues(name), countryCode]);
        } else {
          c.checked = false;
          fn(_.filter(getValues(name), (c) => c != countryCode));
        }
      }
    });
    setCountries(_countries);
    setRefresh(!refresh);
  };

  const allChecked = () => {
    return isAllChecked;
  };

  // reset form state after submitting
  useEffect(() => {
    if (isSubmitted) {
      let _countries = countriesCode;
      _countries.forEach((e) => {
        e.checked = false;
      });
      setCountries(_countries);
      setAllChecked(false);
    }
  }, [isSubmitted]);

  // setValue on init
  useEffect(() => {
    let _countries = countries;
    _countries.forEach((c) => {
      if (valueWatch.includes(c.code)) {
        c.checked = true;
      }
    });
    setCountries(_countries);
  }, [valueWatch]);

  return (
    <SoftMultipleCheckBox>
      <ContinentNav onChange={continentOnChange} value={continent} />
      <CardContent sx={{ overflow: "auto" }}>
        <SoftBox component="ul" display="flex" flexDirection="column">
          <SoftBox display="flex" alignItems="center" px={1} ml={1.5}>
            <Controller
              name={name}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  value={"All"}
                  onChange={(e) => {
                    allSelectOnChange(e, field.onChange);
                  }}
                  control={
                    <FormControlLabel value={true} control={<Checkbox checked={allChecked()} />} />
                  }
                />
              )}
            />

            <SoftBox display="flex" flexDirection="column" justifyContent="center">
              <SoftTypography
                component="div"
                variant="button"
                textTransform="capitalize"
                fontWeight="medium"
                ml={-1}
              >
                All
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          {countries?.map((item) => {
            const countryWithFlag = _.find(countriesFlags, { code: item.code });
            const flag = _.get(countryWithFlag, "image", "");
            return (
              <SoftBox display="flex" alignItems="center" px={1} key={item.code}>
                <Controller
                  name={name}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControlLabel
                      value={item.code}
                      onChange={(e) => {
                        onCountryCheckChange(e, item.code, field.onChange);
                      }}
                      control={<Checkbox checked={isChecked(item.code)} />}
                    />
                  )}
                />
                <SoftBox mr={1}>
                  <SoftAvatar src={flag} size="sm" alt="title" />
                </SoftBox>
                <SoftBox display="flex" flexDirection="column" justifyContent="center">
                  <SoftTypography
                    component="div"
                    variant="button"
                    textTransform="capitalize"
                    fontWeight="medium"
                  >
                    {item.label}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            );
          })}
        </SoftBox>
      </CardContent>
    </SoftMultipleCheckBox>
  );
};

MultipleCountriesSelect.defaultValues = {
  required: false,
  label: "",
};
MultipleCountriesSelect.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
};

export default MultipleCountriesSelect;
