import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";

import { SelectInputField } from "components/FormField";

const PaymentOptionSelect = ({
  name,
  label,
  required,
  paymentOptions,
  removeSelectAll,
  useLogo = true,
  ...rest
}) => {
  const paymentOptionsSettings = _.map(paymentOptions, (item) => {
    return {
      value: item.id,
      label: (
        <SoftBox display="flex" py={1.5} px={2}>
          {useLogo && (
            <SoftBox mr={1}>
              <SoftAvatar src={_.get(item, "logo", "")} size="sm" alt="title" />
            </SoftBox>
          )}

          <SoftBox display="flex" flexDirection="column" justifyContent="center">
            <SoftTypography
              component="div"
              variant="button"
              textTransform="capitalize"
              fontWeight="medium"
            >
              {item.name}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ),
    };
  });
  let options = paymentOptionsSettings;
  if (!removeSelectAll) {
    options = [{ value: "", label: "All" }, ...options];
  }
  return (
    <SelectInputField
      name={name}
      label={label}
      options={options}
      required={required}
      filterOption={(option, inputValue) =>
        option.value === "" ||
        paymentOptions
          .filter((i) => i.name.toLowerCase().includes(inputValue?.toLowerCase()))
          .some((i) => i.id === option.value)
      }
      {...rest}
    />
  );
};

PaymentOptionSelect.defaultValues = {
  required: false,
  label: "",
  options: [],
  removeSelectAll: false,
};
PaymentOptionSelect.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  paymentOptions: PropTypes.array,
  removeSelectAll: PropTypes.bool,
  useLogo: PropTypes.bool,
};

export default PaymentOptionSelect;
