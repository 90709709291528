import _ from "lodash";
import { useMutation } from "react-query";
import { FilterTransactionProps } from "./types";
import request, { DEFAULT_TIMEOUT, DOWNLOAD_TIMEOUT } from "utils/request";
import { toastError } from "utils/toast";
import { getQueryParam } from "utils/string";

const GET_TRANSACTIONS = "/transactions";
const GET_TRANSACTION = "/transactions/find-one";
const GET_TRANSACTION_SUMMARY = "/transactions/summary";
const GET_TRANSACTION_APPROVAL_RATE = "/transactions/approval-rates";
const GET_CSV = "transactions/csv-download";

const getFilterTransactions = async ({
  transactionIds,
  ...filterTransactionProps
}: FilterTransactionProps): Promise<any> => {
  const filteredPayload = _.pickBy(filterTransactionProps, _.identity);
  const queryParams = getQueryParam(filteredPayload);

  if (!filterTransactionProps.transactionId || filterTransactionProps.transactionId === "") {
    return request.get(`${GET_TRANSACTIONS}?${queryParams}`);
  } else {
    const result = await request.get(`${GET_TRANSACTION}?${queryParams}`);
    // eslint-disable-next-line prefer-const
    let resArr: any[] = [];
    resArr.push(result);
    const res = {
      docs: resArr,
    };

    return res;
  }
};

const getFilterSummaryTransactions = async ({
  transactionIds,
  ...filterTransactionProps
}: FilterTransactionProps): Promise<any> => {
  const filteredPayload = _.pickBy(filterTransactionProps, _.identity);
  const queryParams = getQueryParam(filteredPayload);
  return request.get(`${GET_TRANSACTION_SUMMARY}?${queryParams}`);
};

const getFilterApprovalTransactions = async ({
  transactionIds,
  ...filterTransactionProps
}: FilterTransactionProps): Promise<any> => {
  const filteredPayload = _.pickBy(filterTransactionProps, _.identity);
  const queryParams = getQueryParam(filteredPayload);
  return request.get(`${GET_TRANSACTION_APPROVAL_RATE}?${queryParams}`);
};

const getCsv = async ({
  transactionIds,
  ...filterTransactionProps
}: FilterTransactionProps): Promise<any> => {
  const filteredPayload = _.pickBy(filterTransactionProps, _.identity);
  const queryParams = getQueryParam(filteredPayload);
  request.defaults.timeout = DOWNLOAD_TIMEOUT;
  return request.get(`${GET_CSV}?${queryParams}`);
};

export const useGetFilterTransactions = () => {
  return useMutation(getFilterTransactions, {
    onError: (error: any) => {
      const message = _.get(error, "response.data.message[0]");
      toastError({ description: message });
    },
  });
};
export const useGetFilterSummaryTransactions = () => {
  return useMutation(getFilterSummaryTransactions, {
    onError: (error: any) => {
      const message = _.get(error, "response.data.message[0]");
      toastError({ description: message });
    },
  });
};
export const useGetFilterApprovalTransactions = () => {
  return useMutation(getFilterApprovalTransactions, {
    onError: (error: any) => {
      const message = _.get(error, "response.data.message[0]");
      toastError({ description: message });
    },
  });
};

export const useGetCsv = () => {
  return useMutation(getCsv, {
    onSuccess: () => {
      request.defaults.timeout = DEFAULT_TIMEOUT;
    },
    onError: (error: any) => {
      toastError({ description: "Failed to download CSV" });
      request.defaults.timeout = DEFAULT_TIMEOUT;
    },
  });
};
