export const ContinentEnum = {
  ALL: 0,
  Africa: 1,
  Antarctica: 2,
  Asia: 3,
  Europe: 4,
  "North america": 5,
  Oceania: 6,
  "South america": 7,
};

export const RegionEnum = {
  Africa: 0,
  "Arab States": 1,
  "Asia & Pacific": 2,
  Europe: 3,
  "Middle east": 4,
  "Non EEA": 5,
  "North America": 6,
  "South/Central America": 7,
  "South/Latin America": 8,
};

export const PaymentMethodTypeEnum = {
  Payin: 0,
  Payout: 1,
};
export const PaymentMethodMapping = {
  Payin: "payinCountries",
  Payout: "payoutCountries",
};
